<template>
  <utd-modal-dialog ref="modalDialog"
                    :close-on-overlay-click="false"
                    close-on-escape
                    :modal-classes="'sign-in-advice-modal'"
                    :dialog-size="'large'">
    <template #header>Sign-in help</template>

    <div class="sign-in-advice-content">

      <div class="sign-in-explanation">
        <h5 class="sign-in-question">Do you normally sign in with a password?</h5>
        <p> Enter the username for your UpToDate account and select "Continue".
          You will then see the input field for your password.</p>
      </div>

      <div class="sign-in-explanation">
        <h5 class="sign-in-question">Do you sign with your organization credentials?</h5>
        <p>Enter your organization email and select "Continue".
          You will be directed to your home sign-in page.
          Your organization email will allow you to sign in,
          even if you have never used it before.</p>
      </div>

      <div class="sign-in-explanation">
        <h5 class="sign-in-question">Did you forget your username or password?</h5>
        <p>For help with a forgotten username or password,
          <a href="/account/reset-password" aria-label="forgot username or password">click here</a>.</p>
      </div>

      <div class="sign-in-explanation">
        <h5 class="sign-in-question">Don't see the Password field?</h5>
        <p>Just enter your username and click "Continue".
          The input field for your password will then display.</p>
      </div>

      <div class="sign-in-explanation">
        <h5 class="sign-in-question">
          Don't see your organization in the "Sign in another way" list?</h5>
        <p>Just enter your email in the "Username" input field
          and if your organization is setup for Single Sign-On (SSO),
          you will be redirected to your home sign-in page.</p>
      </div>
      <utd-customer-support />
    </div>

    <template #footer>
      <utd-button @click="close()">OK</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdCustomerSupport from '_acaSrc/components/shared/stdlib/UtdCustomerSupport.vue';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UtdCustomerSupport
    },
    methods: {
        open() {
            this.$refs.modalDialog.open();
        },
        close() {
            this.$refs.modalDialog.close();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.sign-in-question {
  font-weight: 700;
}

.sign-in-explanation {
  p {
    .ds1-mt-0();
  }
}
</style>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@DS1-UTD-DIALOG-OUTLINE-COLOR: @WKCE-WHITE;

.sign-in-advice-modal {
  .utd-modal-content {
    .utd-dialog-frame {
      min-width: 320px;

      .utd-dialog-content {
        .ds1-pa-2();
      }
    }
  }
}
</style>