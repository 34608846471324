<template>
  <component :is="componentName"
             :result="searchResult" />
</template>

<script>
// eslint-disable-next-line max-len
import BroadQueryPanel from '_acaSrc/components/contents/search/featuredResults/BroadQueryPanel.vue';
import BroadQueryResults from '_acaSrc/utility/data-adapters/broad-query-panel-adapter';
import KeyPointsPanel from '_acaSrc/components/contents/search/featuredResults/KeyPointsPanel.vue';
import KeyPointsResults from '_acaSrc/utility/data-adapters/key-points-panel-adapter';
import GraphicsPanel from '_acaSrc/components/contents/search/featuredResults/GraphicsPanel.vue';
import GraphicsPanelResults from '_acaSrc/utility/data-adapters/graphics-panel-adapter';
import GraphicalAnswers
from '_acaSrc/components/contents/search/featuredResults/GraphicalAnswers.vue';
import GraphicalAnswerResult from '_acaSrc/utility/data-adapters/graphical-answers-adapter';
import { SEARCH_RESULTS_COMPONENTS as componentKeys } from '_acaSrc/utility/component-dictionary';
import { C_SEARCH_RESULTS } from '_acaSrc/utility/constants';
import { mapGetters } from 'vuex';

const adapterKeys = {
    [C_SEARCH_RESULTS.RESULT_TYPE.BROAD_QUERY_PANEL]: BroadQueryResults,
    [C_SEARCH_RESULTS.RESULT_TYPE.KEY_POINTS_PANEL]: KeyPointsResults,
    [C_SEARCH_RESULTS.RESULT_TYPE.GRAPHICS_PANEL]: GraphicsPanelResults,
    [C_SEARCH_RESULTS.RESULT_TYPE.GRAPHICAL_ANSWERS]: GraphicalAnswerResult
};

export default {
    components: {
        BroadQueryPanel,
        KeyPointsPanel,
        GraphicsPanel,
        GraphicalAnswers
    },
    props: {
        result: Object
    },
    data() {
        return {
            searchResult: this.result
        };
    },
    computed: {
        ...mapGetters('search', [ 'searchParamsSearchText' ]),
        componentName() {
            return this.result && this.result.type && componentKeys[this.result.type] || '';
        }
    },
    created() {
        if (this.result && adapterKeys[this.result.type]) {

            const searchRank = this.result.meta && this.result.meta.searchRank;
            const rankIndex = this.result.rankIndex;
            const searchTerm = this.searchParamsSearchText
              && encodeURIComponent(this.searchParamsSearchText);
            const initialTabIndex = this.result.initialTabIndex;

            const analyticsFields = { rankIndex, searchRank, searchTerm };
            const optionalFields = { initialTabIndex };

            this.searchResult = new adapterKeys[this.result.type](
                this.result.id,
                this.result.type,
                this.result.title,
                this.result.markupTitle,
                this.result.searchResults,
                analyticsFields,
                optionalFields
            );
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@FEATURED-RESULT-BORDER: @DS1-UTD-GRAY-SINGLE-BORDER;
@FEATURED-RESULT-ACCORDION-BG-COLOR: @WKCE-GRAY-TINT5;

@media only screen and (min-width: 768px) {
  .isDesktop {
    #searchresults #search-results-container ul.utd-listView {
        li.featured-search-result {
          .ds1-mb-2();
          padding: 15px 15px 15px 14px;

          &.BQP {
            padding: 15px 0 31px 0;
          }

          &.KPP {
            padding-bottom: 31px;
          }
        }

        li.featuredResult,
        li.graphicalAnswers,
        li.GP {
          .ds1-pa-0();
          .ds1-ma-0();
          border: 0;
        }

        li.GP {
          border-top: 0;
          border-left: 0;
          border-right: 0;
        }
    }
  }
}

// shim for "ul.utd-listView li a" rule
ul.utd-listView li {
  .key-points-accordion-text__container a {
    display: inline;
  }

  &.BQP {
    .accordion-text__container a {
      display: inline;
    }
  }
}

.isMobile,
.isSmallScreen,
.isTablet,
.mobileonDesktop {
  #searchresults #search-results-container ul.utd-listView {
    li.featuredResult {
        .ds1-ma-0();
    }

    li.GP {
        .ds1-ph-0();
        .ds1-pb-0();
        .ds1-mb-2();
        border-top: @FEATURED-RESULT-BORDER;
        border-bottom: @FEATURED-RESULT-BORDER;

        .wk-media-collection .collection-thumbnails .utd-thumbnail__container {
            .ds1-mr-0();
            .ds1-ml-2();
        }
    }

    li.KPP,
    li.BQP {
      .ds1-ph-0();
      border-top: @FEATURED-RESULT-BORDER;
      border-bottom: @FEATURED-RESULT-BORDER;
      background: @WKCE-GRAY-TINT6;

      .wk-tab-inner-content {
        background: @DS1-UTD-GRAY-BG-COLOR;

        .side-content__container {
          background: @DS1-UTD-GRAY-BG-COLOR;
        }
      }

      .wk-accordion {
        background: @DS1-UTD-GRAY-BG-COLOR;

        .wk-accordion-item.ds1-accordion {
          border-color: @DS1-UTD-GRAY-BORDER-COLOR;
          background-color: @FEATURED-RESULT-ACCORDION-BG-COLOR;

          .wk-accordion-item-element {
            border-top: @FEATURED-RESULT-BORDER;

            a {
              .ds1-pa-0();
              width: auto;
            }
          }
        }
      }
    }
  }
}
</style>