<template>
  <!-- eslint-disable vue/no-v-html -->
  <table class="med-topic-formulink-drug-table">
    <thead>
      <tr>
        <th v-text="$t('FORMULINK.DRUG_NAME')" />
        <th v-text="$t('FORMULINK.FORMULARY_STATUS')" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="drug in formularyInfoDrugList" :key="drug.drugId">
        <td class="med-topic-formulink-drug-table-drug-name">
          <a class="med-topic-formulink-drug-table-drug-link"
             :href="setUrlSource(drug)"
             v-text="drug.drugName" />
        </td>
        <td class="med-topic-formulink-drug-table-fromulary-status">
          <span v-if="!drug.isLandingPageDrug && drug.isExistInFormulary"
                v-text="$t('FORMULINK.MATCH_FOUND')" />
          <span v-if="!drug.isLandingPageDrug && !drug.isExistInFormulary"
                v-text="$t('FORMULINK.MATCH_NOT_FOUND')" />
          <a v-if="drug.isLandingPageDrug"
             class="med-topic-formulink-drug-table-drug-link"
             :href="setUrlSource(drug)"
             v-text="$t('FORMULINK.VIEW_FORMULARY_STATUS')" />
          <span v-if="drug.highlight"
                class="med-topic-formulink-drug-table-highlight"
                v-html="drug.highlight" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters } from 'vuex';
import { setQueryParamValue, getQueryParamValues } from '_acaSrc/utility/http';

export default {
    computed: {
        ...mapGetters('formulary', [ 'formularyInfoDrugList' ]),
        ...mapGetters('feature', [ 'hideNavBar' ]),
        isMobileApp() {
            return this.hideNavBar;
        }
    },
    methods: {
        setUrlSource(drug) {
            if (this.isMobileApp) {
                const topicRef = getQueryParamValues(drug.link, 'topicRef');
                return `uptodate://contents?contentId=${
                    drug.topicId}&topicRef=${
                    topicRef}&itemType=drug&source=formulary-info`;
            }
            return setQueryParamValue(drug.link, 'source', 'formulary-info');
        }
    }
};
</script>
<style lang="less">
.med-topic-formulink-drug-table-highlight {
  .block,
  .block p {
    display: inline;
  }
}
</style>
<style lang="less" scoped>
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles";
@COLOR_MED_TOPIC_HEADING: @WKCE-GRAY-SHADE1;

.med-topic-formulink-drug-table-drug-link {
  color: @DS1-UTD-JS2-LINK-BASE;
  text-decoration: none;
}

.med-topic-formulink-drug-table {
  font-size: 14px;
  line-height: 21px;
  color: @WKCE-GRAY-SHADE2;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;

  thead {
    display: none;
  }

  tr {
    display: block;
    padding: 8px 25px;
    border: @DS1-LEXI-TABLE-BORDER;
    border-bottom: none;

    &:last-child {
      border-bottom: @DS1-LEXI-TABLE-BORDER;
    }
  }

  td {
    display: block;
    text-align: left;

    &:first-child {
      margin-bottom: 8px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .med-topic-formulink-drug-table {
      width: min-content;
      height: fit-content;
      white-space: nowrap;

      thead {
        display: table-header-group;

        > tr {
          border: none;

          &:first-child {
            white-space: nowrap;
          }
        }
      }

      th {
        color: @COLOR_MED_TOPIC_HEADING;
        text-align: left;
      }

      tr {
        display: table-row;
      }

      td {
        display: table-cell;
        width: auto;
      }

      td,
      th {
        padding: 7px 24px;
      }

      .med-topic-formulink-drug-table-fromulary-status {
        width: 100%;
      }

      .med-topic-formulink-drug-table-drug-name {
        white-space: nowrap;
      }
    }
  }
}
</style>

