<template>
  <div class="skipnav">
    <a v-if="showSkipToQuickPanel"
       id="skipToQuickPanel"
       tabindex="0"
       href="#rightSidePanel">Skip to Quick Access Panel</a>

    <a v-if="showSkipToGraphicalAnswers"
       id="skipToGraphicalAnswers"
       tabindex="0"
       href="#rightSidePanel">Skip to Graphical Answers</a>

    <a v-if="showskipToOutline"
       id="skipToOutline"
       tabindex="0"
       href="#topicOutline">Skip to Outline</a>

    <a v-if="showskipToTopic"
       id="skipToTopic"
       tabindex="0"
       href="#topicArticle">Skip to Topic</a>

    <a v-if="showskipToReferences"
       id="skipToReferences"
       tabindex="0"
       href="#references">Skip to References</a>

    <a v-if="showskipToContent"
       id="skipToContent"
       tabindex="0"
       href="#utd-main">Skip to Content</a>

    <a v-if="showSkipToQuickLinks"
       id="skipToQuickLinks"
       class="wn-pcu"
       tabindex="0"
       href="#first-awareness-panel-item"
       @click="focusTarget('first-awareness-panel-item', $event)">Skip to Quick Links</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        showQuickLinks: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('footer', [
            'getFooterUrl',
            'isFooterLoaded'
        ]),
        ...mapGetters('topic', [ 'isTopicView', 'showReferences', 'fullTopicWidth' ]),
        ...mapGetters('search', [ 'searchBarBody', 'isGraphicalAnswers', 'isSearchView', 
            'isHomeSearchEmpty' ]),
        ...mapGetters('qap', [ 'isQuickAccessPanel' ]),
        ...mapGetters('feature', [ 'isWnPcuPanelEnabled', 'isHomepageRedesign2024' ]),
        ...mapGetters('app', [ 'isProspectView' ]),
        showSkipToResult() {
            return this.isSearchView;
        },
        showSkipToQuickPanel() {
            return this.isSearchView
              && this.isQuickAccessPanel
              && !this.isGraphicalAnswers;
        },
        showSkipToGraphicalAnswers() {
            return this.isSearchView
              && this.isQuickAccessPanel
              && this.isGraphicalAnswers;
        },
        showskipToOutline() {
            return this.isTopicView && !this.fullTopicWidth;
        },
        showskipToTopic() {
            return this.isTopicView;
        },
        showskipToReferences() {
            return this.isTopicView && this.showReferences;
        },
        showskipToContent() {
            return !this.isTopicView
                && !this.isSearchView
                && !this.searchBarBody;
        },
        showSkipToQuickLinks() {
            return this.isWnPcuPanelEnabled 
               && !this.isProspectView
               && this.isHomeSearchEmpty
               && this.isHomepageRedesign2024
               && this.showQuickLinks;
        }
    },
    methods: {
        focusTarget(elementId) {
            document.getElementById(elementId)?.focus();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

@SKIPNAV-BG-COLOR: @WKCE-GRAY;

.skipnav {
  text-align: left;
  display: inline-block;
  position: absolute;

  .wn-pcu {
      border: 1px solid white;
      border-radius: 0;

      &:focus {
          outline: none;
      }
  }

  > a {
    padding: 5px;
    background: @SKIPNAV-BG-COLOR;
    color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    font-size: 12px;
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:focus,
    &:active {
      position: static;
      left: 0;
      width: auto;
      height: auto;
      overflow: visible;
      text-decoration: underline;
    }
  }
}
</style>