<template>
  <div v-if="isFitDialogShown"
       ref="fitDialog"
       class="fit-fixed-box"
       :class="{'is-fixed': isFixedPosition}"
       tabindex="-1"
       :style="fitDialogStyle"
       @mousedown="dragStart"
       @mouseup="dragStop"
       @keydown="onKeyDown">

    <div class="fit-input">
      <input ref="fitInput"
             v-model="fitTerm"
             :disabled="isLoadingXHR"
             type="text"
             autocomplete="off"
             placeholder="Find in Topic"
             @mousedown.stop
             @focus="$event.target.select()"
             @keyup.enter="findInTopicFn">
      <span v-if="fitTerm"
            class="search-clear"
            aria-label="Clear the input"
            tabindex="0"
            role="button"
            @click="clearSearch"
            @keyup.enter="clearSearch" />
      <utd-button class="fit-search-button"
                  :disabled="fitSearchButtonDisabled"
                  button-icon="fit-white"
                  button-color="blue"
                  aria-label="Find in topic"
                  @click.prevent="findInTopicFn"
                  @mousedown.stop />
    </div>

    <ul class="fit-controls">
      <li>
        <utd-checkbox :checked="findInTopicMatchSynonyms"
                      class="fit-checkbox"
                      @checkbox-changed="onCheckboxChange"
                      @mousedown.stop>
          {{ synonymsCheckboxText }}
        </utd-checkbox>
      </li>
      <li v-show="findInTopicIsActive" class="fit-chevron-btn">
        <utd-button button-style="text"
                    button-icon="chevron-up"
                    button-color="blue"
                    :aria-Label="`Previous result ${fitCountLabel}`"
                    @click.prevent="prevMatch"
                    @mousedown.stop />
        <utd-button button-style="text"
                    button-icon="chevron-down"
                    button-color="blue"
                    :aria-Label="`Next result ${fitCountLabel}`"
                    @click.prevent="nextMatch"
                    @mousedown.stop />
        <span class="count" v-text="fitCountLabel" />
      </li>
      <li class="fit-done-btn">
        <utd-button button-color="blue"
                    button-style="text"
                    button-size="large"
                    @click="closeFindInTopic()"
                    @mousedown.stop>
          {{ $t('MYUPTODATE.DONE') }}
        </utd-button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    elAddClass,
    elementsOverlap,
    elRemoveClass,
    getDocument,
    getWindow
} from '_acaSrc/utility/DOM';
import {
    SET_FIND_IN_TOPIC_HITS,
    SET_FIND_IN_TOPIC_INDEX,
    SET_FIND_IN_TOPIC_MATCH_SYNONYMS,
    SET_FIND_IN_TOPIC_MATCHES,
    SET_FIND_IN_TOPIC_TERM,
    SET_IS_FIT_DIALOG_SHOWN
} from '_acaSrc/store/topic/topic.store';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdCheckbox from '_acaSrc/components/shared/stdlib/UtdCheckbox.vue';
import { C_KEYCODES } from '_acaSrc/utility/constants';

const HIGHLIGHTED_CURRENT_CLASS = 'highlightedCurrent';
const HIGHLIGHTED_CLASS = 'highlighted';
const PADDING_TOP = 32;
const SCROLL_WIDTH = 17;
const FIT_BOX_HEIGHT = 96;
const FIT_BOX_WIDTH = 394;
const RENDERING_TIMEOUT = 100;
const OVERLAP_DELAY_MS = 50;

export default {
    components: {
        UtdButton,
        UtdCheckbox
    },
    emits: [ 'toggle-outline' ],
    data() {
        return {
            isLoadingXHR: false,
            matchCount: 0,
            topicToolbarEl: null,
            topicOutlineElem: null,
            toolBarHeight: 0,
            fitDialogPosition: {
                top: 0,
                right: 0
            },
            previous: { // IE11 property
                x: 0,
                y: 0
            },
            nextMatchButtonEl: null,
            prevMatchButtonEl: null,
            wasDragged: false
        };
    },
    computed: {
        ...mapGetters('device', [
            'isBrowserNameMSIE',
            'isNotDesktopView',
            'isMobileOnDesktop'
        ]),
        ...mapGetters('app', [
            'additiveContentClasses',
            'isFixedToolbar',
            'isProspectView'
        ]),
        ...mapGetters('search', [
            'searchParamsSearchText',
            'searchParamsLanguage',
            'translatedSearchTerm',
            'translationDetectedLanguage'
        ]),
        ...mapGetters('topic', [
            'topicLanguage',
            'findInTopicTerm',
            'findInTopicMatchSynonyms',
            'findInTopicIsActive',
            'findInTopicHits',
            'findInTopicIndex',
            'findInTopicMatches',
            'isFitDialogShown',
            'isTopicShowing',
            'getUrlScrollPosition',
            'isTitleCollapsed',
            'topicLanguage'
        ]),
        ...mapGetters('feature', [ 'hasPathwaysContent' ]),
        fitTerm: {
            get() {
                return this.findInTopicTerm;
            },
            set(value) {
                this[SET_FIND_IN_TOPIC_TERM](value);
            }
        },
        fitSearchButtonDisabled() {
            return this.isLoadingXHR || !this.fitTerm;
        },
        isFixedPosition() {
            return this.isFixedToolbar || this.wasDragged
                || this.isMobileView || this.isProspectView;
        },
        fitDialogStyle() {
            return `right: ${this.fitDialogPosition.right}px;
                    top: ${this.fitDialogPosition.top}px`;
        },
        isMobileView() {
            return this.isNotDesktopView || this.isMobileOnDesktop;
        },
        fitCountLabel() {
            return `${this.findInTopicIndex + 1} of ${this.findInTopicHits}`;
        },
        synonymsCheckboxText() {
            if (this.isMobileView) {
                return 'Synonyms';
            }
            return 'Include Synonyms';
        }
    },
    watch: {
        isFitDialogShown(val) {
            if (val) {
                this.$nextTick(() => {
                    this.matchCount = 0;
                    this.$refs.fitInput.focus();
                    this.setFitInitialPosition();
                    this.nextMatchButtonEl
                        = this.$el.querySelector('.fit-chevron-btn > button:nth-child(2)');
                    this.prevMatchButtonEl
                        = this.$el.querySelector('.fit-chevron-btn > button:first-child');
                });
            }
            else {
                this.findInTopicIsActive && this.clearSearch();
                this.nextMatchButtonEl = null;
                this.prevMatchButtonEl = null;
            }
        },
        isTitleCollapsed() {
            if (this.isMobileView) {
                this.setFitInitialPosition();
            }
        },
        isFixedPosition() {
            if (this.isMobileView) {
                return;
            }
            this.setFitInitialPosition();
        }
    },
    created() {
        this.setInitialSearchTerm();
    },
    beforeUnmount() {
        this.dragStop();
        this.isFitDialogShown && this.closeFindInTopic();
        getWindow().removeEventListener('resize', this.onWindowResize);
    },
    mounted() {
        this.topicToolbarEl = getDocument().getElementById('topic-toolbar');
        this.topicOutlineElem = getDocument().getElementById('topicOutline');
        getWindow().addEventListener('resize', this.onWindowResize);
    },
    methods: {
        ...mapActions('app', [
            'publish',
            'logEvent'
        ]),
        ...mapActions('topic', [
            'resetFindInTopic',
            'findInTopic',
            'showOutline'
        ]),
        ...mapMutations('topic', [
            SET_FIND_IN_TOPIC_TERM,
            SET_FIND_IN_TOPIC_MATCH_SYNONYMS,
            SET_FIND_IN_TOPIC_MATCHES,
            SET_FIND_IN_TOPIC_INDEX,
            SET_FIND_IN_TOPIC_HITS,
            SET_IS_FIT_DIALOG_SHOWN
        ]),
        getIEMovement(e) {
            // Need fot IE 11
            const movementX = (this.previous.x ? e.screenX - this.previous.x : 0);
            const movementY = (this.previous.y ? e.screenY - this.previous.y : 0);

            this.previous.x = e.screenX;
            this.previous.y = e.screenY;
            return {
                movementX,
                movementY
            };
        },
        onCheckboxChange(val) {
            this[SET_FIND_IN_TOPIC_MATCH_SYNONYMS](val);
            this.findInTopicIsActive && this.$nextTick(this.findInTopicFn);
        },
        closeFitDialog() {
            this[SET_IS_FIT_DIALOG_SHOWN](false);
        },
        onWindowResize() {
            if (this.isMobileView) {
                return;
            }
            this.isFitDialogShown && this.closeFindInTopic();
        },
        setInitialSearchTerm() {
            if (this.searchParamsLanguage !== 'en'
                && this.translatedSearchTerm && this.translationDetectedLanguage
                && (this.translatedSearchTerm !== this.searchParamsSearchText)
                && (this.translationDetectedLanguage !== this.topicLanguage)) {
                this[SET_FIND_IN_TOPIC_TERM](this.translatedSearchTerm);
            }
            else {
                this[SET_FIND_IN_TOPIC_TERM](this.searchParamsSearchText);
            }
        },
        getInlineGraphicsFunctions() {
            const injectInlineGraphicsOnHTMLString
              = this.$parent && this.$parent.setupInlineGraphics;
            const inlineGraphicsInjectedEventsHandler
                = this.$parent && this.$parent.inlineGraphicsInjectedEventsHandler;

            return { injectInlineGraphicsOnHTMLString, inlineGraphicsInjectedEventsHandler };
        },
        findInTopicFn() {
            if (!this.findInTopicTerm) {
                return;
            }

            const { injectInlineGraphicsOnHTMLString, inlineGraphicsInjectedEventsHandler }
                = this.getInlineGraphicsFunctions();

            // Ensure we restore original body and outline before new search
            if (this.findInTopicIsActive) {
                this.resetFindInTopic({
                    injectInlineGraphicsOnHTMLString,
                    inlineGraphicsInjectedEventsHandler
                });
            }

            this.isLoadingXHR = true;
            this.findInTopic({
                injectInlineGraphicsOnHTMLString,
                inlineGraphicsInjectedEventsHandler
            })
                .then(this.setupFindInTopicResults)
                .finally(() => this.isLoadingXHR = false);
        },
        closeFindInTopic() {
            this.wasDragged = false;
            this.dragStop();
            this.findInTopicIsActive && this.clearSearch();
            this.closeFitDialog();
        },
        setupFindInTopicResults() {
            this.$nextTick(() => {
                if (!this.findInTopicIsActive) {
                    return;
                }

                this.publish({ eventName: 'wkutd.relink-outline-events' });
                this[SET_FIND_IN_TOPIC_MATCHES]([]);
                this[SET_FIND_IN_TOPIC_INDEX](-1);
                this.matchCount = 0;

                this.processFindInTopicSection('topicOutline', 'mark_outline_');
                this.processFindInTopicSection('topicArticle', 'mark_text_');

                if (this.matchCount > 0) {
                    this.nextMatch();
                }
            });
        },
        processFindInTopicSection(section, marker) {
            const markedElements = this.getMarkedSectionEls(section, marker);

            // Check for unauthorized additive content and remove it. This
            // filter is needed because the find in topic service does
            // not remove additive content from results, and some users
            // do not have permission to view additive content.
            markedElements.forEach(el => {
                if (this.isElInsideAuthorizedContent(el)) {
                    this.findInTopicMatches[this.matchCount++] = el.id;
                }
                else {
                    const hits = this.findInTopicHits - 1;
                    this[SET_FIND_IN_TOPIC_HITS](hits);
                }
            });
        },
        // "Marked" elements are the sections of DOM that are highlighted as
        // matched results.
        getMarkedSectionEls(section, marker) {
            const sectionElement = getDocument().getElementById(section);
            return sectionElement
                && Array.from(sectionElement.querySelectorAll(`[id^="${marker}"]`))
                || [];
        },
        isElInsideAuthorizedContent(el) {
            const additiveContentEl = el.closest('.utd-adt-cnt');
            return !additiveContentEl
                || (this.isAdditiveContentElAuthorized(additiveContentEl)
                    && this.isAdditiveContentElDisplayed(additiveContentEl));
        },
        isAdditiveContentElAuthorized(el) {
            const authorizedClasses = this.additiveContentClasses;
            return authorizedClasses && authorizedClasses.length
                ? authorizedClasses.some(c => el.classList.contains(c)) : true;
        },
        isAdditiveContentElDisplayed(el) {
            return el.getAttribute('style')
                ? el.getAttribute('style').indexOf('display:none') === -1
                : this.hasPathwaysContent;
        },
        nextMatch(e) {
            !e && this.nextMatchButtonEl.focus();
            if (!this.findInTopicMatches.length) {
                return;
            }
            this.clearMatch();
            let index = this.findInTopicIndex + 1;
            index %= this.findInTopicMatches.length;
            this[SET_FIND_IN_TOPIC_INDEX](index);
            this.$nextTick(this.scrollToMatch);
        },
        prevMatch(e) {
            !e && this.prevMatchButtonEl.focus();
            if (!this.findInTopicMatches.length) {
                return;
            }
            this.clearMatch();
            const index = this.findInTopicIndex - 1;
            this[SET_FIND_IN_TOPIC_INDEX](index);
            if (this.findInTopicIndex < 0) {
                this[SET_FIND_IN_TOPIC_INDEX](this.findInTopicMatches.length - 1);
            }
            this.$nextTick(this.scrollToMatch);
        },
        clearMatch() {
            Array.from(getDocument().getElementsByClassName(HIGHLIGHTED_CURRENT_CLASS))
                .forEach(el => {
                    elRemoveClass(el, HIGHLIGHTED_CURRENT_CLASS);
                    elAddClass(el, HIGHLIGHTED_CLASS);
                });
        },
        toggleOutline() {
            return new Promise(resolve => {
                this.$emit('toggle-outline');
                setTimeout(resolve, RENDERING_TIMEOUT);
            });
        },
        isToggleOutlineNeeded(isMatchInTopic) {
            if (isMatchInTopic && !this.isTopicShowing) {
                return true;
            }
            return !isMatchInTopic && this.isTopicShowing;
        },
        async scrollToMatch() {
            // Find the next match and mark it as the current match.
            const match = this.findInTopicMatches[this.findInTopicIndex];
            const matchEl = getDocument().getElementById(match);
            if (!matchEl) {
                return;
            }

            elRemoveClass(matchEl, HIGHLIGHTED_CLASS);
            elAddClass(matchEl, HIGHLIGHTED_CURRENT_CLASS);
            const isMatchInTopic = matchEl.id.indexOf('mark_text_') > -1;

            if (this.isMobileView && this.isToggleOutlineNeeded(isMatchInTopic)) {
                await this.toggleOutline();
            }

            let top = matchEl.getBoundingClientRect().top;
            const distance = this.topicToolbarEl.offsetTop + this.topicToolbarEl.offsetHeight;
            top -= distance;
            top -= PADDING_TOP;
            if (isMatchInTopic || this.isMobileView) {
                getWindow().scrollBy(0, top);
                this.scrollIfMatchFitOverlaps(matchEl);
            }
            else {
                this.topicOutlineElem && this.topicOutlineElem.scrollBy(0, top);
            }
        },
        scrollIfMatchFitOverlaps(matchEl) {
            setTimeout(() => {
                const overlapResults = elementsOverlap(matchEl, this.$refs.fitDialog);
                if (!overlapResults.isElementsOverlap) {
                    return;
                }
                getWindow().scrollBy({ behavior: 'smooth',
                    left: 0,
                    top: -(overlapResults.deltaY + PADDING_TOP) });
            }, OVERLAP_DELAY_MS);
        },
        dragStart() {
            if (this.isMobileView) {
                return;
            }
            getDocument().body.addEventListener('mousemove', this.onDrag);
            getDocument().body.addEventListener('mouseup', this.dragStop);
            getDocument().body.addEventListener('mouseleave', this.dragStop);
            getDocument().body.classList.add('prevent-select');
        },
        dragStop() {
            getDocument().body.removeEventListener('mousemove', this.onDrag);
            getDocument().body.removeEventListener('mouseup', this.dragStop);
            getDocument().body.removeEventListener('mouseleave', this.dragStop);
            getDocument().body.classList.remove('prevent-select');
        },
        getTranslateFitPosition(event) {
            let newTranslateX, newTranslateY;
            if (this.isBrowserNameMSIE) {
                // Workaround For IE11, no movementX property in event
                const movement = this.getIEMovement(event);
                newTranslateX = this.fitDialogPosition.right - movement.movementX;
                newTranslateY = this.fitDialogPosition.top + movement.movementY;
            }
            else {
                newTranslateX = this.fitDialogPosition.right - event.movementX;
                newTranslateY = this.fitDialogPosition.top + event.movementY;
            }
            return {
                newTranslateX,
                newTranslateY
            };
        },
        onDrag(event) {
            this.wasDragged = true;
            const translateYLimit = getWindow().innerHeight - FIT_BOX_HEIGHT;
            const translateXLimit = getWindow().innerWidth - FIT_BOX_WIDTH - SCROLL_WIDTH;

            let { newTranslateX, newTranslateY } = this.getTranslateFitPosition(event);

            if (this.isBrowserNameMSIE) {
                // Workaround For IE11, no movementX property in event
                const movement = this.getIEMovement(event);
                newTranslateX = this.fitDialogPosition.right - movement.movementX;
                newTranslateY = this.fitDialogPosition.top + movement.movementY;
            }
            else {
                newTranslateX = this.fitDialogPosition.right - event.movementX;
                newTranslateY = this.fitDialogPosition.top + event.movementY;
            }

            if (Math.abs(newTranslateX) > translateXLimit) {
                newTranslateX = translateXLimit;
            }
            if (newTranslateX < 0) {
                newTranslateX = 0;
            }
            if (newTranslateY > translateYLimit) {
                newTranslateY = translateYLimit;
            }
            if (newTranslateY < 0) {
                newTranslateY = 0;
            }
            this.fitDialogPosition.top = newTranslateY;
            this.fitDialogPosition.right = newTranslateX;
        },
        clearSearch() {
            // Close dialog if open
            if (this.findInTopicIsActive) {
                this.logEvent({
                    eventUrl: 'event/findInTopic/json',
                    eventParams: {
                        search: this.findInTopicTerm,
                        language: this.topicLanguage,
                        userAction: 'clear'
                    }
                });

                const { injectInlineGraphicsOnHTMLString, inlineGraphicsInjectedEventsHandler }
                    = this.getInlineGraphicsFunctions();

                this.resetFindInTopic({
                    injectInlineGraphicsOnHTMLString,
                    inlineGraphicsInjectedEventsHandler
                });
            }
            this.fitTerm = '';
        },
        // eslint-disable-next-line complexity
        onKeyDown(e) {
            if (!this.findInTopicIsActive) {
                return;
            }

            if ((e.keyCode === C_KEYCODES.LEFT_ARROW || e.keyCode === C_KEYCODES.UP_ARROW)
                || (e.keyCode === C_KEYCODES.ENTER_KEY && e.shiftKey)) {
                e.preventDefault();
                this.prevMatch();
                return;
            }

            if (e.keyCode === C_KEYCODES.RIGHT_ARROW || e.keyCode === C_KEYCODES.DOWN_ARROW) {
                this.nextMatch();
                e.preventDefault();
                return;
            }

            if (e.target.tagName === 'BUTTON' || e.target.tagName === 'INPUT') {
                return;
            }

            if (e.keyCode === C_KEYCODES.ENTER_KEY) {
                this.nextMatch();
                e.preventDefault();
            }
        },
        setFitInitialPosition() {
            if (this.isFixedPosition) {
                this.fitDialogPosition.top = this.topicToolbarEl.getBoundingClientRect().bottom - 1;
            }
            else {
                this.fitDialogPosition.top = this.topicToolbarEl.getBoundingClientRect().bottom - 1
                    + (getWindow().pageYOffset | getWindow().scrollTop);
            }
            this.fitDialogPosition.right = 0;
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#topicContainer {
  .fit-fixed-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: @DS1-UTD-GRAY-BG-COLOR;
    border: @DS1-UTD-GRAY-SINGLE-BORDER;
    box-shadow: @DS1-BOX-SHADOW-RAISED;
    height: 98px; // Used in JS as FIT_HEIGHT constant
    width: 100%;
    z-index: @ZINDEX-FIT;
    bottom: calc(-$height - 1px);

    &.is-fixed {
      position: fixed;
    }

    .fit-search-button {
      padding: 0 4px;

      .wkce-icon-fit-white {
        background-image: url('~_acaAssets/topic/fitIconWhite.svg');
        width: 22px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 0;
        margin-bottom: 2px;
      }
    }

    .fit-input {
      .ds1-pa-2();
      .ds1-pb-1();
      display: flex;
      align-items: center;

      .search-clear {
        .ds1-pa-compact();
        top: auto;
        right: 50px;

        .InternetExplorer & {
          top: 18px;
        }
      }

      > input {
        .ds1-pl-1();
        .ds1-pr-4();
        height: 32px;
        flex-grow: 1;
        border: @DS1-PDS-INPUT-BORDER;
        box-sizing: border-box;
        color: @DS1-UTD-TOPIC-TEXT-COLOR;

        &:hover,
        &:focus {
          padding-left: 7px;
          padding-right: 33px;
        }

        &:hover {
          border: solid 2px @WKCE-BLUE;
        }

        &:focus {
          border: solid 2px @WKCE-BLACK;
          outline: none;
        }

        .InternetExplorer & {
          &::-ms-clear { /* stylelint-disable-line selector-no-vendor-prefix */
            display: none;
            height: 0;
            width: 0;
          }
        }
      }
    }

    .fit-controls {
      .ds1-mh-1();
      .ds1-mv-0();
      .ds1-pl-0();
      display: flex;
      justify-content: space-between;

      > li {
        .ds1-ph-compact();
        display: flex;
        align-items: center;
      }

      .fit-checkbox {
        .ds1-utd-size-2();
        color: @DS1-UTD-HEADER-TEXT-COLOR;

        .wkce-checkbox {
          box-sizing: border-box;
          padding: 4px;
          border: solid 2px transparent;

          &.is-active {
            border: solid 2px @WKCE-BLACK;
            outline: none;
            box-sizing: border-box;
          }
        }
      }

      .fit-chevron-btn {
        .count {
          .ds1-utd-size-1();
          .ds1-ml-1();
        }

        > button {
          padding: 0;
          display: flex;
          align-items: center;

          &:hover,
          &:focus {
            text-decoration: none;
          }

          + button {
            .ds1-ml-1();
          }
        }

        span[class^='wkce-icon-chevron'] {
          display: flex;
          font-size: 26px;
          margin: 0;
        }
      }

      .fit-done-btn {
        > button {
          .ds1-utd-size-3();

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    &.prevent-select {
      user-select: none;

      #topicContainer .fit-fixed-box {
        cursor: grabbing;
      }
    }

    #topicContainer {
      .fit-fixed-box {
        cursor: grab;
        width: 394px;

        .fit-controls .fit-done-btn > button {
          .ds1-ph-compact();
        }
      }
    }
  }
}
</style>
