<template>
  <section id="prospect-login-register-header-section">
    <header class="wk-header" role="banner">
      <div class="wk-header-container">
        <utd-logo />
        <utd-content-searchbar v-if="searchBarHeader" />
        <ul class="wk-nav" role="menu">
          <template v-if="renderHeaderNavButtons">
            <li v-if="!isUccState" role="menuitem">
              <utd-button button-type="link"
                          :button-link="storeUrl"
                          button-size="small"
                          button-color="green-shade-3">{{ $t('HEADER.SUBSCRIBE') }}</utd-button>
            </li>
            <li role="menuitem">
              <utd-button button-type="link"
                          :button-link="'/login'"
                          button-size="small"
                          button-color="blue">{{ $t('MISC.LOG_IN') }}</utd-button>
            </li>
          </template>
          <li role="menuitem">
            <div class="wk-dropdown wk-dropdown-onclick">
              <a id="prospect-language-selector"
                 v-hover-delay="{ afterDelay: showDropdown,
                                  afterUnhoverDelay: hideDropdown,
                                  sharedHoverState: 'languageDropdown' }"
                 aria-haspopup="true"
                 role="combobox"
                 tabindex="0"
                 :aria-expanded="isShowDropdown"
                 aria-labelledby="prospect-language-selector"
                 aria-controls="prospect-language-selector"
                 class="wk-dropdown-toggle"
                 @keyup.esc="toggleDropdown"
                 @keyup.enter="toggleDropdown"
                 @click="toggleDropdown"
                 v-text="currentLanguage" />
              <div v-show="isShowDropdown"
                   v-hover-delay="{ afterDelay: showDropdown,
                                    afterUnhoverDelay: hideDropdown,
                                    sharedHoverState: 'languageDropdown' }"
                   aria-hidden="true"
                   aria-label="submenu"
                   :aria-expanded="isShowDropdown"
                   aria-labelledby="prospect-language-selector"
                   class="wk-language-dropdown-menu"
                   :class="{'wk-dropdown-menu': isShowDropdown}"
                   @keyup.esc="toggleDropdown">
                <ul role="listbox">
                  <li v-for="lang in prospectLanguages"
                      :key="lang.value"
                      role="option">
                    <a href="#"
                       tabindex="0"
                       @click="setDialogLanguage(lang.value)"
                    >{{ $t(lang.name) }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <nav class="wk-navbar" role="navigation">
      <div class="wk-navbar-container">
        <ul class="wk-nav" role="menu">
          <li role="menuitem">
            <a :href="addMarketingLanguagePrefix(headerUrls.WHY_UPTODATE)">{{
              $t('HEADER.WHY_UPTODATE')
            }}</a>
          </li>
          <li role="menuitem">
            <a :href="addMarketingLanguagePrefix(headerUrls.PRODUCT)">{{
              $t('HEADER.PRODUCT')
            }}</a>
          </li>
          <li role="menuitem">
            <a :href="addMarketingLanguagePrefix(headerUrls.EDITORIAL)">{{
              $t('HEADER.EDITORIAL')
            }}</a>
          </li>
          <li role="menuitem">
            <a :href="addMarketingLanguagePrefix(headerUrls.SUBOPTS)">{{
              $t('HEADER.SUBSCRIPTION_OPTIONS')
            }}</a>
          </li>
        </ul>
      </div>
    </nav>
    <prospect-banner-vue v-if="renderHeaderMarketingBanner" />
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ProspectBannerVue from '../ui/ProspectBanner.vue';
import UtdContentSearchbar from '_acaSrc/components/contents/search/SearchBar.vue';
import HoverDelay from '_acaSrc/components/shared/utd/UtdHoverDelay.vue';
import { C_HEADER, C_LANGUAGES_LIST } from '_acaSrc/utility/constants';
import { SET_DIALOGUE_LANGUAGE } from '_acaSrc/store/user.store';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

export default {
    components: {
        UtdButton,
        ProspectBannerVue,
        UtdContentSearchbar,
        UtdLogo
    },
    directives: {
        HoverDelay
    },
    data() {
        return {
            isShowDropdown: false
        };
    },
    computed: {
        ...mapGetters('app', [
            'router',
            'isUccState',
            'routeLoading'
        ]),
        ...mapGetters('search', [ 'searchBarHeader' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('auth', [ 'storeUrl' ]),
        ...mapGetters('user', [
            'languageName',
            'language',
            'localization' ]),
        headerUrls() {
            return this.isUccState
                ? C_HEADER.UCC_URLS
                : C_HEADER.URLS;
        },
        currentLanguage() {
            return this.languageName;
        },
        prospectLanguages() {
            const languages = C_LANGUAGES_LIST
                .filter(lang => lang.marketing
                     && lang.value !== this.language);

            if (!this.isUccState) {
                return languages;
            }

            if (this.language !== 'zh-Hans') {
                languages.unshift({
                    name: 'SIMPLIFIED_CHINESE',
                    value: 'zh-Hans',
                    marketing: true
                });
            }

            return languages;
        },
        logoSource() {
            return `~_acaAssets/utd-menu/${
                this.isUccState ? 'utdcc' : 'utd'
            }-logo-desktop.svg`;
        },
        renderHeaderNavButtons() {
            return this.routeLoading !== 'login';
        },
        renderHeaderMarketingBanner() {
            return this.routeLoading === 'topic'
                || this.routeLoading === 'topicLanguage';
        }
    },
    methods: {
        ...mapActions('user', [
            'changeLanguage'
        ]),
        ...mapMutations('user', [
            SET_DIALOGUE_LANGUAGE
        ]),
        hideDropdown() {
            this.isShowDropdown = false;
        },
        setDialogLanguage(langKey) {
            this[SET_DIALOGUE_LANGUAGE](langKey);
            this.changeLanguage();
        },
        showDropdown() {
            this.isShowDropdown = true;
        },
        toggleDropdown() {
            if (this.isDesktopView) {
                this.isShowDropdown = !this.isShowDropdown;
            }
        },
        addMarketingLanguagePrefix(url) {
            if (!this.isUccState && this.isMarketingLanguage(this.language)) {
                return this.localizeAnchor('', url, this.language);
            }
            return url;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';
@import (reference) '~_acaAssets/global/variables';
@import (reference) '~_acaAssets/utd-button/utd-button';

@media only screen and (min-width: 768px) {
  .utdWkHomePage2024 {
    .wk-navbar-container, .wk-navbar {
      background-color: @WKCE-GRAY-TINT1;
    }

    .wk-navbar .wk-nav {
      &:not(.utd-right) {
        > li > a {
          background-color: @WKCE-GRAY-TINT1;

          &:hover {
            background-color: @WKCE-GRAY-TINT2;
          }

          &:focus {
            outline: none;
            border: 2px solid @WKCE-WHITE;
          }
        }
      }

      .btn-subscribe a {
        background-color: @WKCE-GREEN-SHADE1;
        &:hover {
          background-color: @WKCE-GREEN-SHADE2;
        }
      }

      .btn-login a {
        background-color: @WKCE-BLUE;
        &:hover {
          background-color: @WKCE-BLUE-SHADE1;
        }
      }
    }
  }

  #prospect-login-register-header-section .wk-header .wk-header-container {

    .es & , 
    .de & ,
    .ja & 
    {
        :deep(.search-bar-ac-unit) {
          right: 24em;
        }
    }

    .pt & {
      :deep(.search-bar-ac-unit) {
        right: 20em;
      }
    }

    .wk-nav {
      .ds1-ml-2();

      .wk-dropdown .wk-dropdown-toggle {
        padding: 6px;
        font-size: 12px;
        line-height: 18px;
      }

      li a.utd-button.utd-button-link {
        .ds1-mr-2();
        font-family: @NOTOSANS_FONT_STACK;
        color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

</style>