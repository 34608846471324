<template>
  <div>
    <span class="graphic__appears-in-callout">{{ $t("CONTENT.PLEASE_VIEW_GRAPHICS") }}</span>
    <ul class="graphic__appears-in-list">
      <li v-for="(topic,idx) in graphicRelatedTopics"
          :key="idx"
          class="ds1-pb-2"><a target="_blank"
                              rel="noopener"
                              :href="topic.url">{{ topic.title }}</a></li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('graphic', [
            'graphicRelatedTopics'
        ])
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphic__appears-in-callout {
  font-style: italic;
  font-size: 12px;
  margin-bottom: 15px;
  /* stylelint-disable-next-line color-no-hex */
  color: #444;
  display: block;
}

.graphic__appears-in-list {
  list-style: disc;
  margin: 5px 0 0 1px;
  padding: 0 0 0 32px;
  overflow-y: auto;
  font-size: 13px;

  & > li {
    .ds1-mr-1();
    text-transform: none;
    /* stylelint-disable-next-line color-no-hex */
    color: #444;

    & > a:hover {
      text-decoration: none;
    }
  }
}
</style>