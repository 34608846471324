<template>
  <ul id="uccContentsMenu">
    <li>
      <span class="menu-title">{{ $t('HEADER.CONTENT') }}</span>
    </li>
    <li id="navbarTocPcu">
      <a href="/contents/practice-changing-updates"
         role="menuitem"
         tabindex="0"
         class="menu-link"
         @click="onLinkClick()"
         v-text="$t('HEADER.PRACTICE_CHANGING_UPDATES')" />
    </li>
    <li id="navbarTocWhatsNew">
      <a :href="localizeAnchor('/contents/', 'table-of-contents/whats-new', language)"
         tabindex="0"
         role="menuitem"
         @click="onLinkClick()"
         v-text="$t('HEADER.WHATS_NEW')" />
    </li>
    <li id="navbarTocPatientEd">
      <a :href="localizeAnchor('/contents/', 'table-of-contents/patient-education', language)"
         tabindex="0"
         role="menuitem"
         @click="onLinkClick()"
         v-text="$t('HEADER.PATIENT_EDUCATION')" />
    </li>
    <li id="navbarTocDrugInfo">
      <a :href="localizeAnchor('/contents/', 'table-of-contents/drug-information', language)"
         tabindex="0"
         role="menuitem"
         @click="onLinkClick()"
         v-text="$t('HEADER.DRUG_INFORMATION')" />
    </li>
    <li id="navbarTocSpecialties">
      <a :href="localizeAnchor('/contents/', 'table-of-contents', language)"
         tabindex="0"
         role="menuitem"
         @click="onLinkClick()"
         v-text="$t('HEADER.TOPICS_SPECIALTY')" />
    </li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('user', [ 'language' ])
    },
    methods: {
        onLinkClick() {
            this.$emit('onLinkClick');
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
ul#uccContentsMenu {
  list-style: none;
  .ds1-pa-0();
  .ds1-ma-0();

  >li {

    >a,
    >.menu-title {
      .ds1-ph-2();
      display: block;
    }

    .menu-title {
      padding-top: 12px;
      padding-bottom: 12px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 20px;
      color: @DS1-UTD-SUB-TEXT-COLOR;
    }

    >a {
      .ds1-pv-1();
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
      color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;

      &::after {
        position: absolute;
        top: 8px;
        color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
        right: 16px;
        content: '\e911';
        font-family: "wk-icons-open";
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  ul#uccContentsMenu {
    flex-direction: column;
    width: 100%;
    display: flex;

    >li {

      >a,
      > span.menu-title {
        .ds1-pv-1();
      }

      > span.menu-title {
        font-size: 16px;        
        display: block;
        color: @WKCE-GRAY-TINT1;
        text-transform: uppercase;
      }

      > a {
        font-size: 15px;

        &:hover {
          background-color: @WKCE-BLUE-TINT5;
          color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
        }

        &::after {
          display: none;
        }
      }
    }

    &:last-child {
      .ds1-pb-1();
    }

    &:not(:last-child)::after {
      width: calc(100% - 32px);
      margin: 4px auto;
      display: block;
      height: 1px;
      background-color: @UTD-LEGACY-WHITE-TINT5;
    }
  }
}
</style>