import {
    SET_DEFAULT_PAGE_LANG,
    SET_FIXED_HTML,
    SET_IS_PRINT_VIEW
} from '_acaSrc/store/app.store';
import { RESET_ERROR } from '_acaSrc/store/error.store';
import {
    getRelativeUrl,
    getUrlHash,
    getQueryParamValues,
    setQueryParamValue
} from '_acaSrc/utility/http';
import {
    elAddClass,
    elRemoveClass,
    getDocument,
    getWindow,
    injectCssStyle
} from '_acaSrc/utility/DOM';
import { safeTimeout } from '_acaSrc/utility/timers';
import IndexManager from '_acaSrc/utility/IndexManager';
import { ScrollTargets, ScrollTarget } from '_acaSrc/utility/contents/topic/ScrollTarget';
import {
    bindRehashHandler,
    collapseAllFormulinkAccordions,
    onTopicElementsStable,
    renderInjectableCssAssets,
    renderInjectableHtmlAssets,
    setFormulinkSrollTargetIds,
    postInjectionCleanup,
    bindFormulinkRehashHandlers,
    getPathwayLinks,
    getRxTransitionsLinks,
    hideDisabledPathwayLink,
    showEnabledPathwayLink,
    setPathwayUnavailableClass,
    moveSameArticleClickToTop,
    unbindRehashHandler,
    unbindFormulinkRehashHandlers,
    processBodyHtml,
    processOutlineHtml,
    checkAppendDrugSectionLabel,
    getProspectDesktopArticlePaddingTop,
    rebindCollapsibleIndicationLinks as rebindCIL,
    createSharedTopicSection,
    getOutlineHeadingHash,
    setUpAccordionAriaLabel
} from '_acaSrc/utility/contents/topic/topic';
import {
    C_TOPIC,
    C_GRAPHICS,
    C_RESPONSIVE,
    C_BROWSER,
    C_EVENTS,
    C_LANGUAGES_NAMES,
    C_PATHWAYS,
    C_LOCALIZATION,
    C_KEYCODES
} from '_acaSrc/utility/constants';
import { SET_SHOW_BACK_TO_SEARCH } from '../feature.store';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import {
    collapseFormulariesList
} from '_acaSrc/components/contents/topic/formulary-info/Formulary';
import { PathwayTooltip } from '_acaSrc/utility/tooltip';
import { findLastIndex } from '_acaSrc/utility/Array';
import { isEqual } from '_acaSrc/utility/ObjectComparison';
import { makeRestUrl } from '_acaSrc/utility/http/UtdUrlUtilities';
import UtdTopicCore from '_acaSrc/components/contents/topic/UtdTopicCore';
import { SET_SEARCH_PARAMS_TEXT } from '_acaSrc/store/search.store';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';
import UtdCache from '_acaSrc/utility/UtdCache';
import UtdQueuedCache, {
    UTD_SPA_CONTENT_CACHE_DEFAULT_LIMIT
} from '_acaSrc/utility/UtdQueuedCache';
import { nullUndefinedOrEmpty } from '_acaSrc/utility/Validators';
import { SET_OVERFLOW_GRAPHIC_TOOLS } from '_acaSrc/store/graphic.store';
/**TODO: These are temporary imports to mock pcu/wn data. 
This will be removed once the API is ready. **/
import pcuMockData from '_acaSrc/components/homePageRedesign/MockedPCUDataFourUpdates.json';
import wnMockData1 from 
    '_acaSrc/components/homePageRedesign/MockedWNDataCardiovascularMedicine.json';
import wnMockData2 from '_acaSrc/components/homePageRedesign/MockedWNDataEmergencyMedicine.json';

const {
    ANCHOR_HASH_PREFIX,
    SCROLL_TARGET_SUFFIX,
    VIEW_TOPIC_KEY,
    VIEW_OUTLINE_KEY
} = C_TOPIC;

const UTD_ADT_PATHWAYS_CLASS = 'utd-adt-pathwys';
const UTD_CONTENT_CLASS_PREFIX = 'utd-content-';
const UTD_PATHWAYS_UNAVAILABLE_CLASS = 'pathway-unavailable';
const UTD_CUSTOMIZED_PATHWAY_LABEL_CLASS = 'customized-pathway-label';
const UTD_PROSPECT_CACHE_KEY = '__isProspectMode';

export const TOPIC_LINK_CLICK_DEBOUNCE_MS = 200;

const state = {
    topicCore: new UtdTopicCore(),
    topicLoaded: false,
    findInTopic: {
        active: false,
        matchSynonyms: true,
        term: '',
        body: '',
        outline: '',
        hits: '...',
        matches: [],
        index: -1,
        isFitDialogShown: false,
        topicWhatsNewList: []
    },
    toolbar: {
        visible: false,
        formulary: {
            visible: false,
            showFormulinkNotification: false
        },
        outlineToggle: {
            labelKey: '' // Contains key to translate View Topic/Outline toggle
        },
        findInTopic: {
            visible: false
        },
        print: {
            visible: true,
            url: ''
        },
        share: {
            visible: false
        },
        bookmarks: {
            visible: false,
            bookmarkedTopics: []
        },
        overflow: {
            visible: false
        },
        bookmarkIsActive: false
    },
    topicUrl: {
        url: '',
        language: 'en',
        cacheHit: false,
        elementsStable: false,
        resetElementsStable: true
    },
    topicWhatsNewDialogData: {
        title: '',
        content: ''
    },
    showRelatedGraphicsModal: false,
    topic: {
        rating: 0,
        whatsNewList: [],
        translatedTopicInfos: [],
        machineLearningPaused: false,
        calculatorMarkupView: C_RESPONSIVE.DESKTOP_VIEW,
        scroller: {
            active: false,
            scroller: {},
            scrolling: false,
            scrollTargets: new ScrollTargets(),
            scrollTrigger: 0, // Increments to trigger a scroll
            useSmoothScroll: true,
            lastScrollTopicId: 0,
            urlPositions: {},
            saveScrollPosition: true,
            reset: false
        },
        pointer: {
            animationClass: '',
            visible: false,
            target: null,
            position: 0
        },
        isRenderInjectableAssetsFirstPass: true
    },
    topicTools: {
        isSampleTopic: false,
        currentTopic: null,
        showGraphicTabContent: false
    },
    onOptimizelyLinkClick: null,
    error: {
        formulaLogs: [],
        isHasError: false,
        messageKey: '',
        clearSearch: false
    },
    lastTopicSectionViewEvent: {},
    isTopicView: false,
    isTitleCollapsed: false,
    showReferences: false,
    fullTopicWidth: false,
    hideMarketingBanner: '',
    fontSize: 'textSize-M',
    backToSearchUrl: '',
    initialTopicLoadHash: '',
    selectedTranslationToggleOption: C_LOCALIZATION.ENGLISH_ID,
    untranslatedTopicContents: {},
    nearestOutlineHeadingId: '',
    shouldShowTranslationWarning: true,
    wnPcuPanelInfo: {
        wnPanelInfo: {},
        pcuPanelInfo: {},
        specialtyList: []
    }
};

export const getters = {
    topicId: state => state.topicCore.id,
    topicType: state => state.topicCore.type,
    topicSubtype: state => state.topicCore.subtype,
    topicVersion: state => state.topicCore.version,
    topicTitle: state => state.topicCore.title,
    topicLanguage: state => state.topicCore.language,
    topicUrlLanguage: state => state.topicUrl.language,
    topicSpecialtyId: state => state.topicCore.specialtyId,
    topicIsCacheHit: state => state.topicUrl.cacheHit,
    topicRating: state => state.topic.rating,
    topicMeta: (state, getters, rootState) => {
        return (state.topicCore.metaDescription || rootState.app.metaDescription);
    },
    topicAnchor: state => state.topicCore.topicAnchor,
    topicBodyHtml: state => state.topicCore.bodyHtml,
    topicBodyDesktop: state => state.topicCore.bodyDesktop,
    topicOutlineHtml: state => state.topicCore.outlineHtml,

    toolbar: state => state.toolbar,
    topicFeedbackVisible: (state, getters, rootState) => {
        return state.topicCore.showTopicFeedback
            && rootState.device.browser.type === C_BROWSER.TYPE_DESKTOP;
    },
    topic: state => state.topic,
    topicUrl: state => state.topicUrl.url,
    isOutlineVisible: state => state.topicCore.outlineVisible,
    topicWhatsNewList: state => state.topic.whatsNewList,
    topicTools: state => state.topicTools,
    isTopicView: state => state.isTopicView,
    // eslint-disable-next-line complexity
    isTabbedContent: (state, getters, rootState, rootGetters) => {
        return state.topicCore.type === C_TOPIC.TYPES.MEDICAL
            && state.topicCore.topicGraphics.length
            && state.topicCore.subtype === C_TOPIC.SUBTYPES.GENERAL_MEDICAL
            && !rootGetters['app/isProspectMode']
            && !rootGetters['app/isLapsedUser']
            && (rootGetters['device/isDesktopView']
                || (!getters.isFitDialogShown && getters.isTopicShowing)
                || (!getters.isTopicShowing && getters.showGraphicTabContent)
            );
    },
    isTopicCalculator: state => state.topicCore.isTopicCalculator,
    isSampleCalculator: (state, getters, rootState, rootGetters) => {
        return rootGetters['app/isProspectView']
            && state.topicCore.isHasCalculatorContent;
    },
    hasCalculatorContent: state => {
        return state.topicCore.isHasCalculatorContent;
    },
    isDrugTopic: state => state.topicCore.type === 'drug',
    isMedicalTopic: state => state.topicCore.type === 'medical',
    isDxyPackageInsert: state => state.topicCore.subtype === 'drug_dxy_package_insert',
    isDxyDrugTopic: state => state.topicCore.subtype === 'drug_dxy',
    isNarrativeLabI: state => state.topicCore.isNarrativeLabI,
    isLanguageEs419: state => state.topicCore.isLanguageEs419,
    isLanguageArabic: state => state.topicCore.isLanguageArabic,
    isTopicAndRtlLang: state => {
        // For RTL languages (only Arabic currently), image should be in upper right of thumbnail
        return state.isTopicView &&
               state.topicCore.language === C_LANGUAGES_NAMES.AR.CODE;
    },
    findInTopic: state => state.findInTopic,
    findInTopicTerm: state => state.findInTopic.term,
    findInTopicBody: state => state.findInTopic.body,
    findInTopicOutline: state => state.findInTopic.outline,
    findInTopicMatchSynonyms: state => state.findInTopic.matchSynonyms,
    findInTopicIsActive: state => state.findInTopic.active,
    findInTopicHits: state => state.findInTopic.hits,
    findInTopicIndex: state => state.findInTopic.index,
    findInTopicMatches: state => state.findInTopic.matches,
    findInTopicWhatsNew: state => state.findInTopic.topicWhatsNewList,
    topicLoaded: state => state.topicLoaded,
    calculatorScriptDesktop: state => state.topicCore.calculatorScript.desktop,
    calculatorScriptMobile: state => state.topicCore.calculatorScript.mobile,
    calculatorMarkupView: state => state.topic.calculatorMarkupView,
    topicScroller: state => state.topic.scroller.scroller,
    isScrollerActive: state => state.topic.scroller.active,
    topicIsScrolling: state => state.topic.scroller.scrolling,
    previousSection: state => state.topic.previousSection,
    topicElementsStable: state => state.topicUrl.elementsStable,
    resetElementsStable: state => state.topicUrl.resetElementsStable,
    hasPendingScrollTargets: state => state.topic.scroller.scrollTargets.hasElements,
    scrollTrigger: state => state.topic.scroller.scrollTrigger,
    activeScrollTarget: state => state.topic.scroller.scrollTargets.first,
    lastPendingScrollTarget: state => state.topic.scroller.scrollTargets.last,
    scrollTargets: state => state.topic.scroller.scrollTargets,
    activeScrollTargetHash: state => state.topic.scroller.scrollTargets.firstPropValue('hash'),
    isScrollTargetFromClick: state =>
        state.topic.scroller.scrollTargets.firstPropValue('fromClick'),
    lastScrollTopicId: state => state.topic.scroller.lastScrollTopicId,
    doScrollerReset: state => state.topic.scroller.reset,
    getUrlScrollIndex: state => useHash => {
        const hashVal = useHash || getUrlHash();
        return `${state.topicCore.id}~${state.topic.anchor}#${hashVal}`;
    },
    getUrlScrollPosition: (state, getters) =>
        () => state.topic.scroller.urlPositions[getters.getUrlScrollIndex()],
    isSaveScrollPosition: state => state.topic.scroller.saveScrollPosition,
    isTopicShowing: state => state.topicCore.topicShowing,
    isShowOutline: state => state.topicCore.showOutline,
    showOutlineToggle: state => state.topicCore.showOutlineToggle,
    outlineToggleVisible: state => state.topicCore.outlineToggleVisible,
    toolbarOverflowVisible: state => state.toolbar.overflow.visible,
    toolbarPrintUrl: state => state.topicLoaded
        ? `${getWindow().location.pathname}/print${getWindow().location.search}`
        : '',
    toolbarFormularyVisible: state => state.toolbar.formulary.visible,
    isNotLocalizedTopicView: state => {
        return state.selectedTranslationToggleOption === C_LOCALIZATION.ENGLISH_ID;
    },
    toolbarPrintVisible(state, getters) {
        return !state.topicCore.isTopicCalculator
            && !state.topicCore.isDrugDxySubtype
            && !state.findInTopic.active
            && state.topicCore.showPrintLink
            && getters.isNotLocalizedTopicView;
    },
    toolbarToolsVisible: state => !state.topicCore.isTopicCalculator
        && !state.topicCore.isDrugDxySubtype,
    toolbarBackLinkVisible(state, getters, rootState, rootGetters) {
        return (rootState.feature.showBackToSearch
            || rootState.app.isAlwaysShowBackButton)
            && rootGetters['device/isDesktopView'];
    },
    toolbarShareVisible(state, getters, rootState, rootGetters) {
        return state.topicCore.isCanShare
            && rootGetters['feature/isHasContentShare']
            && getters.isNotLocalizedTopicView;
    },
    toolbarFontSizeVisible(state, getters, rootState, rootGetters) {
        return rootGetters['feature/topicRedesign']
            && !state.topicCore.isTopicCalculator
            && !state.topicCore.isDrugDxySubtype;
    },
    toolbarViewToggleLabelKey: state => state.topicLoaded
        ? state.toolbar.outlineToggle.labelKey : '',
    toolbarInlineCmeVisible: (state, getters, rootState) => {
        return rootState.cme.inlineCmeEligible
            && rootState.device.browser.isSupported.tippy
            && getters.isNotLocalizedTopicView;
    },
    toolbarBookmarkVisible: (state, getters) => {
        return state.topicCore.isCanBookmark
            && getters.isNotLocalizedTopicView;
    },
    isShowFormulinkNotification: state => state.toolbar.formulary.showFormulinkNotification,
    isSampleTopic: state => state.topicTools.isSampleTopic,
    // eslint-disable-next-line complexity
    toolbarFindInTopicVisible: (state, getters, rootState, rootGetters) => {
        return state.toolbar.findInTopic.visible
            && (state.topicCore.isLanguageEnglish || state.topicCore.isLanguageChinese)
            && !(state.topicCore.isTopicCalculator
                || state.topicCore.isDrugDxySubtype
                || (!rootGetters['user/isCustomer'] && !state.topicTools.isSampleTopic)
                || state.topicTools.showGraphicTabContent)
            && getters.isNotLocalizedTopicView;
    },
    toolbarTranslationFeedbackVisible: (state, getters) => {
        return getters.hasMachineTranslation && !getters.isNotLocalizedTopicView;
    },
    hideMarketingBanner: state => state.hideMarketingBanner,
    bookmarkedTopics: state => state.toolbar.bookmarks.bookmarkedTopics,
    topicGraphics: state => state.topicCore.topicGraphics,
    topicGraphicImageKeyMap: state => state.topicCore.topicGraphicImageKeyMap,
    pointer: state => state.topic.pointer,
    pointerTarget: state => state.topic.pointer.target,
    pointerClass: state => state.topic.pointer.animationClass,
    pointerVisible: state => state.topic.pointer.visible,
    pointerPosition: state => state.topic.pointer.position,
    machineLearningPaused: state => state.topic.machineLearningPaused,
    onOptimizelyLinkClick: state => state.onOptimizelyLinkClick,
    topicWhatsNewDialogData: state => state.topicWhatsNewDialogData,
    relatedGraphicsModal: state => state.showRelatedGraphicsModal,
    errorFormulaLogs: state => state.topicCore.topicErrorFormulaLogs,
    lastTopicSectionViewEvent: state => state.lastTopicSectionViewEvent,
    injectableAssets: state => state.topicCore.injectableAssets,
    isRenderInjectableAssetsFirstPass: state => state.isRenderInjectableAssetsFirstPass,
    alternatePrintLanguages: state => state.topicCore.alternatePrintLanguages,
    topicInlineGraphics: state => state.topicCore.inlineGraphics,
    topicInlineGraphicsType: state => state.topicCore.inlineGraphicsType,
    topicInlineMediaHeading: state => state.topicCore.inlineMediaHeading,
    hasInlineMedia: state => state.topicCore.hasInlineMedia,
    topicError: state => state.topicCore.topicError,
    topicHasError: state => state.topicCore.topicHasError,
    ibnList: state => state.topicCore.ibnList,
    topicGalleryGraphics: state => state.topicCore.galleryGraphics,
    showGraphicTabContent: state => state.topicTools.showGraphicTabContent,
    isFontSizeSettingsUpdateAllowed: (state, getters, rootState, rootGetters) => {
        return rootGetters['device/isDesktopView']
            && rootGetters['app/isUserRegistered'];
    },
    topicFontSize: (state, getters, rootState, rootGetters) => {
        // If Font size update is allowed, then return fontSize set by
        // backend else return fontSize in localStorage.
        return getters.isFontSizeSettingsUpdateAllowed
            ? getters.fontSize
            : rootGetters['app/storedSetting']('textSize');
    },
    isFitDialogShown: state => state.findInTopic.isFitDialogShown,
    showTopicFeedback: state => state.topicCore.showTopicFeedback,
    isTitleCollapsed: state => state.isTitleCollapsed,
    showReferences: state => state.showReferences,
    fullTopicWidth: state => state.fullTopicWidth,
    fontSize: state => state.fontSize,
    backToSearchUrl: state => state.backToSearchUrl,
    initialTopicLoadHash: state => state.initialTopicLoadHash,
    machineTranslationBundle: state =>
        state.topicCore.machineTranslationBundle,
    machineTranslationWarnings: state =>
        state.topicCore.machineTranslationBundle.machineTranslationWarnings,
    machineTranslationLabels: state =>
        state.topicCore.machineTranslationBundle.machineTranslationLabels,
    practicePointsLabels: state =>
        state.topicCore.machineTranslationBundle.practicePointsLabels,
    contentLanguage: state =>
        state.topicCore.machineTranslationBundle.contentLanguage,
    selectedLocalizationLanguage: (state, getters) => {
        if (getters.isNotLocalizedTopicView) {
            return C_LOCALIZATION.ENGLISH_CODE;
        }
        return getters.contentLanguage && getters.contentLanguage.toLowerCase();
    },
    hasMachineTranslation: (state, getters, rootState, rootGetters) => {
        if (nullUndefinedOrEmpty(getters.machineTranslationBundle)) {
            return false;
        }
        if (!getters.machineTranslationLabels
            || Object.keys(getters.machineTranslationLabels).length === 0) {
            return false;
        }
        if (!getters.machineTranslationWarnings
            || Object.keys(getters.machineTranslationWarnings).length === 0) {
            return false;
        }
        return rootGetters['user/userLoggedIn'] || rootGetters['app/isAuthenticatedByIp'];
    },
    shouldShowTranslationWarning: state => state.shouldShowTranslationWarning,
    machineTranslationUrl: state => state.topicCore.machineTranslationBundle.url,
    selectedTranslationToggleOption: state => state.selectedTranslationToggleOption,
    translatedTopicContents: state =>
        state.topicCore.machineTranslationBundle.translatedTopicContents,
    untranslatedTopicContents: state =>
        state.untranslatedTopicContents,
    localizedContent: (state, getters) => {
        if (!getters.contentLanguage || !getters.translatedTopicContents) {
            return null;
        }
        const translatedContents
            = getters.translatedTopicContents[getters.contentLanguage.toLowerCase()];
        const { bodyHtml, outlineHtml, title, metaDescription } = translatedContents;
        if (!bodyHtml || !outlineHtml || !title || !metaDescription) {
            return null;
        }
        return { bodyHtml, outlineHtml, title, metaDescription };
    },
    englishContent: (state, getters) => {
        if (!getters.untranslatedTopicContents) {
            return null;
        }
        const { bodyHtml, outlineHtml, title, metaDescription }
            = getters.untranslatedTopicContents;
        if (!bodyHtml || !outlineHtml || !title || !metaDescription) {
            return null;
        }
        return { bodyHtml, outlineHtml, title, metaDescription };
    },
    nearestOutlineHeadingId: state => state.nearestOutlineHeadingId,
    wnPcuPanelInfo: state => state.wnPcuPanelInfo
};

export const RESET_TOPIC_DATA = 'RESET_TOPIC_DATA';
export const RESOLVE_TOPIC_DATA = 'RESOLVE_TOPIC_DATA';
export const SET_TOPIC_ID = 'SET_TOPIC_ID';
export const SET_TOPIC_TITLE = 'SET_TOPIC_TITLE';
export const SET_TOPIC_LANGUAGE = 'SET_TOPIC_LANGUAGE';
export const SET_TOPIC_RATING = 'SET_TOPIC_RATING';
export const SET_TOPIC_URL_LANGUAGE = 'SET_TOPIC_URL_LANGUAGE';
export const SET_TOPIC_OUTLINE_VISIBLE = 'SET_TOPIC_OUTLINE_VISIBLE';
export const SET_TOPIC_GRAPHICS = 'SET_TOPIC_GRAPHICS';
export const SET_TOPIC_TYPE = 'SET_TOPIC_TYPE';
export const SET_TOPIC_SUBTYPE = 'SET_TOPIC_SUBTYPE';
export const SET_TOPIC_META = 'SET_TOPIC_META';
export const SET_TOPIC_URL = 'SET_TOPIC_URL';
export const SET_TOPIC_ELEMENTS_STABLE = 'SET_TOPIC_ELEMENTS_STABLE';
export const SET_RESET_ELEMENTS_STABLE = 'SET_RESET_ELEMENTS_STABLE';
export const SET_TOPIC_ANCHOR = 'SET_TOPIC_ANCHOR';
export const SET_TOPIC_SHOWING = 'SET_TOPIC_SHOWING';
export const SET_TOPIC_SCROLLER = 'SET_TOPIC_SCROLLER';
export const SET_TOPIC_SCROLLING = 'SET_TOPIC_SCROLLING';
export const SET_TOPIC_BODY_HTML = 'SET_TOPIC_BODY_HTML';
export const SET_TOPIC_OUTLINE_HTML = 'SET_TOPIC_OUTLINE_HTML';
export const SET_TOPIC_CACHE_HIT = 'SET_TOPIC_CACHE_HIT';
export const SET_CALCULATOR_SCRIPT_DESKTOP = 'SET_CALCULATOR_SCRIPT_DESKTOP';
export const SET_CALCULATOR_SCRIPT_MOBILE = 'SET_CALCULATOR_SCRIPT_MOBILE';
export const SET_CALCULATOR_MARKUP_VIEW = 'SET_CALCULATOR_MARKUP_VIEW';
export const SET_SCROLLER_ACTIVE = 'SET_SCROLLER_ACTIVE';
export const SET_PREVIOUS_SECTION = 'SET_PREVIOUS_SECTION';
export const DO_NEXT_TOPIC_SCROLL_SMOOTHLY = 'DO_NEXT_TOPIC_SCROLL_SMOOTHLY';
export const UPDATE_LAST_SCROLL_TOPIC_ID = 'UPDATE_LAST_SCROLL_TOPIC_ID';
export const QUEUE_SCROLL_TARGET = 'QUEUE_SCROLL_TARGET';
export const TRIGGER_SCROLL = 'TRIGGER_SCROLL';
export const RESET_SCROLL_TARGET_QUEUE = 'RESET_SCROLL_TARGET_QUEUE';
export const SET_URL_SCROLL_POSITION = 'SET_URL_SCROLL_POSITION';
export const SET_SAVE_SCROLL_POSITION = 'SET_SAVE_SCROLL_POSITION';
export const SET_SCROLLER_RESET = 'SET_SCROLLER_RESET';
export const ADD_WHATS_NEW_ENTRY = 'ADD_WHATS_NEW_ENTRY';
export const SET_TOOLBAR_FORMULARY_VISIBLE = 'SET_TOOLBAR_FORMULARY_VISIBLE';
export const SET_TOOLBAR_OVERFLOW_VISIBLE = 'SET_TOOLBAR_OVERFLOW_VISIBLE';
export const SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE
    = 'SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE';
export const SET_IS_TOPIC_VIEW = 'SET_IS_TOPIC_VIEW';
export const SET_TOPIC_LOADED = 'SET_TOPIC_LOADED';
export const SET_TOPIC_TOOLS_SAMPLE_TOPIC = 'SET_TOPIC_TOOLS_SAMPLE_TOPIC';
export const SET_TOOLBAR_FINDINTOPIC_VISIBLE = 'SET_TOOLBAR_FINDINTOPIC_VISIBLE';
export const SET_TOOLBAR_BOOKMARKS_VISIBLE = 'SET_TOOLBAR_BOOKMARKS_VISIBLE';
export const SET_TOOLBAR_BOOKMARKED_TOPICS = 'SET_TOOLBAR_BOOKMARKED_TOPICS';
export const ADD_TOPIC_BOOKMARK = 'ADD_TOPIC_BOOKMARK';
export const REMOVE_TOPIC_BOOKMARK = 'REMOVE_TOPIC_BOOKMARK';
export const SET_TOOLBAR_BOOKMARK_ACTIVE = 'SET_TOOLBAR_BOOKMARK_ACTIVE';
export const SET_TOOLBAR_OUTLINE_TOGGLE_VISIBLE = 'SET_TOOLBAR_OUTLINE_TOGGLE_VISIBLE';
export const SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY = 'SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY';
export const SET_SECTION_POINTER_CLASS = 'SET_SECTION_POINTER_CLASS';
export const SET_SECTION_POINTER_TARGET = 'SET_SECTION_POINTER_TARGET';
export const SET_SECTION_POINTER_VISIBLE = 'SET_SECTION_POINTER_VISIBLE';
export const SET_SECTION_POINTER_POSITION = 'SET_SECTION_POINTER_POSITION';
export const SET_MACHINE_LEARNING_PAUSED = 'SET_MACHINE_LEARNING_PAUSED';
export const SET_TOPIC_TRANSLATION_INFOS = 'SET_TOPIC_TRANSLATION_INFOS';
export const SET_ON_OPTIMIZELY_LINK_CLICK = 'SET_ON_OPTIMIZELY_LINK_CLICK';
export const SET_TOPIC_WHATS_NEW_DIALOG_DATA = 'SET_TOPIC_WHATS_NEW_DIALOG_DATA';
export const SET_RELATED_GRAPHICS_DIALOG_VISIBILITY = 'SET_RELATED_GRAPHICS_DIALOG_VISIBILITY';
export const SET_ERROR_FORMULA_LOGS = 'SET_ERROR_FORMULA_LOGS';
export const SET_TOPIC_ERROR = 'SET_TOPIC_ERROR';
export const SET_TOPIC_ERROR_MESSAGE_KEY = 'SET_TOPIC_ERROR_MESSAGE_KEY';
export const SET_LAST_TOPIC_SECTION_VIEW_EVENT = 'SET_LAST_TOPIC_SECTION_VIEW_EVENT';
export const SET_TOPIC_INJECTABLE_ASSETS = 'SET_TOPIC_INJECTABLE_ASSETS';
export const SET_INJECTABLE_ASSETS_FIRST_PASS = 'SET_INJECTABLE_ASSETS_FIRST_PASS';

export const SET_TOPIC_BODY_HTML_TO_MOBILE = 'SET_TOPIC_BODY_HTML_TO_MOBILE';
export const SET_TOPIC_BODY_HTML_TO_DESKTOP = 'SET_TOPIC_BODY_HTML_TO_DESKTOP';
export const SET_FIND_IN_TOPIC_ACTIVE = 'SET_FIND_IN_TOPIC_ACTIVE';
export const SET_FIND_IN_TOPIC_HITS = 'SET_FIND_IN_TOPIC_HITS';
export const SET_FIND_IN_TOPIC_BODY = 'SET_FIND_IN_TOPIC_BODY';
export const SET_FIND_IN_TOPIC_OUTLINE = 'SET_FIND_IN_TOPIC_OUTLINE';
export const SET_FIND_IN_TOPIC_MATCH_SYNONYMS = 'SET_FIND_IN_TOPIC_MATCH_SYNONYMS';
export const SET_FIND_IN_TOPIC_TERM = 'SET_FIND_IN_TOPIC_TERM';
export const SET_FIND_IN_TOPIC_MATCHES = 'SET_FIND_IN_TOPIC_MATCHES';
export const SET_FIND_IN_TOPIC_INDEX = 'SET_FIND_IN_TOPIC_INDEX';
export const SET_SHOW_GRAPHIC_TAB_CONTENT = 'SET_SHOW_GRAPHIC_TAB_CONTENT';
export const SET_IS_FIT_DIALOG_SHOWN = 'SET_IS_FIT_DIALOG_SHOWN';
export const SET_IS_TITLE_COLLAPSE = 'SET_IS_TITLE_COLLAPSE';
export const SET_SHOW_REFERENCES = 'SET_SHOW_REFERENCES';
export const SET_FULL_TOPIC_WIDTH = 'SET_FULL_TOPIC_WIDTH';
export const SET_HIDE_MARKETING_BANNER = 'SET_HIDE_MARKETING_BANNER';
export const SET_FONT_SIZE_PREFERENCE = 'SET_FONT_SIZE_PREFERENCE';
export const SET_BACK_TO_SEARCH_URL = 'SET_BACK_TO_SEARCH_URL';
export const SET_FIND_IN_TOPIC_WHATS_NEW = 'SET_FIND_IN_TOPIC_WHATS_NEW';
export const SET_TOPIC_WHATS_NEW = 'SET_TOPIC_WHATS_NEW';
export const SET_INITIAL_TOPIC_LOAD_HASH = 'SET_INITIAL_TOPIC_LOAD_HASH';
export const SET_SELECTED_TRANSLATION_TOGGLE_OPTION
    = 'SET_SELECTED_TRANSLATION_TOGGLE_OPTION';
export const SET_UNTRANSLATED_TOPIC_CONTENTS
    = 'SET_UNTRANSLATED_TOPIC_CONTENTS';
export const SET_TRANSLATED_OUTLINE_BODY_HTML
    = 'SET_TRANSLATED_OUTLINE_BODY_HTML';
export const SET_NEAREST_OUTLINE_HEADING_ID
    = 'SET_NEAREST_OUTLINE_HEADING_ID';
export const SET_SHOW_TRANSLATION_WARNING
    = 'SET_SHOW_TRANSLATION_WARNING';
export const RESET_TOPIC_LOCALIZATION_DATA
    = 'RESET_TOPIC_LOCALIZATION_DATA';
export const SET_WN_PCU_PANEL_INFO = 'SET_WN_PCU_PANEL_INFO';

export const mutations = {
    [RESET_TOPIC_DATA]: state => {
        state.topicLoaded = false;
        state.topicCore = new UtdTopicCore();
        state.topic.rating = 0;
        state.topic.whatsNewList = [];
        state.toolbar.findInTopic.visible = true;
        state.topicUrl.url = '';
        state.topicUrl.language = 'en';
        state.topicUrl.cacheHit = false;
    },
    [RESOLVE_TOPIC_DATA]: (state, payload) => {
        const { data, forPrint = false } = payload;
        state.topicCore.resolveTopic(data, forPrint);
    },
    [SET_TOPIC_ID](state, id) {
        state.topicCore.id = id;
    },
    [SET_TOPIC_TITLE]: (state, title) => state.topicCore.title = title,
    [SET_TOPIC_LANGUAGE](state, language) {
        state.topicCore.language = language;
    },
    [SET_TOPIC_RATING]: (state, rating) => state.topic.rating = rating,
    [SET_TOPIC_URL_LANGUAGE](state, language) {
        state.topicUrl.language = language;
    },
    [SET_TOPIC_OUTLINE_VISIBLE](state, visible) {
        state.topicCore.outlineVisible = visible;
    },
    [SET_TOPIC_GRAPHICS](state, graphics) {
        state.topicCore.topicGraphics = graphics;
    },
    [SET_TOOLBAR_FORMULARY_VISIBLE](state, visible) {
        state.toolbar.formulary.visible = visible;
    },
    [SET_TOPIC_OUTLINE_HTML](state, outlineHtml) {
        state.topicCore.outlineHtml = outlineHtml;
    },
    [SET_TOPIC_BODY_HTML](state, bodyHtml) {
        state.topicCore.bodyHtml = bodyHtml;
    },
    [SET_TOPIC_CACHE_HIT]: (state, cacheHit) => state.topicUrl.cacheHit = cacheHit,
    [SET_TOOLBAR_OVERFLOW_VISIBLE](state, visible) {
        state.toolbar.overflow.visible = visible;
    },
    [SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE](state, visible) {
        state.toolbar.formulary.showFormulinkNotification = visible;
    },
    [SET_TOPIC_TOOLS_SAMPLE_TOPIC](state, visible) {
        state.topicTools.isSampleTopic = visible;
    },
    [SET_TOOLBAR_FINDINTOPIC_VISIBLE](state, visible) {
        state.toolbar.findInTopic.visible = visible;
    },
    [SET_TOOLBAR_BOOKMARKED_TOPICS](state, topics) {
        state.toolbar.bookmarks.bookmarkedTopics = topics;
    },
    [SET_RELATED_GRAPHICS_DIALOG_VISIBILITY](state, visible) {
        state.showRelatedGraphicsModal = visible;
    },
    [ADD_TOPIC_BOOKMARK](state) {
        state.toolbar.bookmarks.bookmarkedTopics.unshift(state.topicCore.currentTopic);
    },
    [REMOVE_TOPIC_BOOKMARK](state) {
        let topicIndex;
        for (let i = state.toolbar.bookmarks.bookmarkedTopics.length - 1; i >= 0; i--) {
            const value = state.toolbar.bookmarks.bookmarkedTopics[i];
            // eslint-disable-next-line max-len
            if (value.contentId === state.topicCore.id && value.languageCode === state.topicCore.language) {
                topicIndex = state.toolbar.bookmarks.bookmarkedTopics.indexOf(value);
                break;
            }
        }
        state.toolbar.bookmarks.bookmarkedTopics.splice(topicIndex, 1);
    },
    [SET_TOOLBAR_BOOKMARK_ACTIVE](state, active) {
        state.toolbar.bookmarkIsActive = active;
    },
    [SET_TOOLBAR_OUTLINE_TOGGLE_VISIBLE](state, visible) {
        state.topicCore.outlineToggleVisible = visible;
    },
    [SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY](state, key) {
        state.toolbar.outlineToggle.labelKey = key;
    },
    [SET_TOPIC_TYPE]: (state, type) => state.topicCore.type = type,
    [SET_TOPIC_SUBTYPE]: (state, subtype) => state.topicCore.subtype = subtype,
    [SET_TOPIC_META]: (state, meta) => state.topicCore.metaDescription = meta,
    [SET_TOPIC_URL]: (state, url) => {
        // Only reset stability of elements if going to a different topic
        if (url !== state.topicUrl.url) {
            state.topicUrl.resetElementsStable = true;
            state.topicUrl.elementsStable = false;
        }

        state.topicUrl.url = url;
    },
    [SET_TOPIC_ELEMENTS_STABLE]: (state, isStable) => {
        state.topicUrl.elementsStable = isStable;
    },
    [SET_RESET_ELEMENTS_STABLE]: (state, doReset) => {
        state.topicUrl.resetElementsStable = doReset;
    },
    [SET_TOPIC_ANCHOR]: state => state.topic.anchor
        = getQueryParamValues(getRelativeUrl(), 'anchor'),
    [SET_TOPIC_SHOWING]: (state, showing) => state.topicCore.topicShowing = showing,
    [SET_TOPIC_SCROLLER]: (state, scroller) => state.topic.scroller.scroller = scroller,
    [SET_TOPIC_SCROLLING]: (state, isScrolling) => state.topic.scroller.scrolling = isScrolling,
    [SET_CALCULATOR_MARKUP_VIEW]: (state, value) => {
        state.topic.calculatorMarkupView = value;
    },
    [SET_SCROLLER_ACTIVE]: (state, active) => state.topic.scroller.active = active,
    [SET_PREVIOUS_SECTION]: (state, section) => state.topic.previousSection = section,
    [DO_NEXT_TOPIC_SCROLL_SMOOTHLY]: (state, isSmooth) => {
        state.topic.scroller.useSmoothScroll = isSmooth;
    },
    [UPDATE_LAST_SCROLL_TOPIC_ID]: state => {
        state.topic.scroller.lastScrollTopicId = state.topicCore.id;
    },
    [QUEUE_SCROLL_TARGET]: (state, target) => {
        target.queued = true;
        target.useSmoothScroll = state.topic.scroller.useSmoothScroll;
        state.topic.scroller.useSmoothScroll = true;
        state.topic.scroller.scrollTargets.enqueue(target);
    },
    [TRIGGER_SCROLL]: state => {
        state.topic.scroller.scrollTrigger++;
    },
    [RESET_SCROLL_TARGET_QUEUE]: state => {
        // If 0 index element exists and has a 'replaceUrl', then replace the URL before resetting
        const replaceUrl = state.topic.scroller.scrollTargets.firstPropValue('replaceUrl');
        if (replaceUrl) {
            getWindow().location.replace(replaceUrl);
        }

        if (state.topic.scroller.scrollTargets.length > 1) {
            state.topic.scroller.scrollTargets.purgeUntilLastTarget();
            return;
        }

        state.topic.scroller.scrollTargets.purge();
    },
    [SET_URL_SCROLL_POSITION]: (state, payload = {}) => {
        if (!state.topic.scroller.saveScrollPosition
            || getUrlHash() === C_TOPIC.GRAPHIC_TAB_HASH) {
            return;
        }

        const { position } = payload;
        const urlIndex = payload.urlIndex
            || `${state.topicCore.id}~${state.topic.anchor}#${getUrlHash()}`;

        if (position) {
            state.topic.scroller.urlPositions[urlIndex] = position;
            return;
        }

        delete state.topic.scroller.urlPositions.urlIndex;
    },
    [SET_SAVE_SCROLL_POSITION]: (state, active) => state.topic.scroller.saveScrollPosition = active,
    [SET_SCROLLER_RESET]: (state, reset) => state.topic.scroller.reset = reset,
    [ADD_WHATS_NEW_ENTRY]: (state, entry) => state.topic.whatsNewList.push(entry),
    [SET_TOPIC_WHATS_NEW]: (state, value) => state.topic.whatsNewList = value,
    [SET_IS_TOPIC_VIEW]: (state, isTopicView) => {
        state.isTopicView = isTopicView;
        new IndexManager().bodyCss.setOrClear('topicView', state.isTopicView);
    },
    [SET_TOPIC_LOADED]: (state, loaded) => state.topicLoaded = loaded,
    [SET_SECTION_POINTER_TARGET]: (state, target) => state.topic.pointer.target = target,
    [SET_SECTION_POINTER_CLASS]: (state, animClass) => {
        state.topic.pointer.animationClass = animClass;
    },
    [SET_SECTION_POINTER_VISIBLE]: (state, visible) => {
        state.topic.pointer.visible = visible;
        if (!visible) {
            // When hidden, reset pointer position to 0 so the watch is triggered
            // when new non-zero position is assigned.
            state.topic.pointer.position = 0;
        }
    },
    [SET_SECTION_POINTER_POSITION]: (state, pos) => state.topic.pointer.position = pos,
    [SET_MACHINE_LEARNING_PAUSED]: (state, paused) => state.topic.machineLearningPaused = paused,
    [SET_TOPIC_TRANSLATION_INFOS]: (state, infos) => state.topic.translatedTopicInfos = infos,
    [SET_ON_OPTIMIZELY_LINK_CLICK]: (state, handler) => state.onOptimizelyLinkClick = handler,
    [SET_TOPIC_WHATS_NEW_DIALOG_DATA]: (state, data) => state.topicWhatsNewDialogData = data,
    [SET_ERROR_FORMULA_LOGS]: (state, payload) => {
        const { topicId, formulaLog } = payload;
        state.topicCore.topicErrorFormulaLogs[topicId] = formulaLog;
    },
    [SET_TOPIC_ERROR]: (state, error) => {
        state.topicCore.topicHasError = error;
    },
    [SET_TOPIC_ERROR_MESSAGE_KEY]: (state, key) => {
        state.topicCore.topicErrorMessageKey = key;
    },
    [SET_LAST_TOPIC_SECTION_VIEW_EVENT]: (state, options) => {
        state.lastTopicSectionViewEvent = options;
    },
    [SET_TOPIC_INJECTABLE_ASSETS]: (state, injectableAssets) => {
        state.topic.injectableAssets = injectableAssets;
    },
    [SET_INJECTABLE_ASSETS_FIRST_PASS]: (state, value) => {
        state.isRenderInjectableAssetsFirstPass = value;
    },
    [SET_FIND_IN_TOPIC_ACTIVE]: (state, active) => state.findInTopic.active = active,
    [SET_FIND_IN_TOPIC_HITS]: (state, textCount) => state.findInTopic.hits = textCount,
    [SET_FIND_IN_TOPIC_BODY]: (state, body) => state.findInTopic.body = body,
    [SET_FIND_IN_TOPIC_OUTLINE]: (state, outline) => state.findInTopic.outline = outline,
    [SET_FIND_IN_TOPIC_MATCH_SYNONYMS]: (state, matchSynonyms) => {
        state.findInTopic.matchSynonyms = matchSynonyms;
    },
    [SET_FIND_IN_TOPIC_TERM]: (state, term) => state.findInTopic.term = term,
    [SET_FIND_IN_TOPIC_MATCHES]: (state, matches) => state.findInTopic.matches = matches,
    [SET_FIND_IN_TOPIC_INDEX]: (state, index) => state.findInTopic.index = index,
    [SET_FIND_IN_TOPIC_WHATS_NEW]: (state, value) => state.findInTopic.topicWhatsNewList = value,
    [SET_TOPIC_BODY_HTML_TO_MOBILE]: (state) => 
        state.topicCore.bodyHtml = state.topicCore.bodyMobile,
    [SET_TOPIC_BODY_HTML_TO_DESKTOP]: (state) => 
        state.topicCore.bodyHtml = state.topicCore.bodyDesktop,
    [SET_SHOW_GRAPHIC_TAB_CONTENT]: (state, show) => state.topicTools.showGraphicTabContent = show,
    [SET_IS_FIT_DIALOG_SHOWN]: (state, show) => state.findInTopic.isFitDialogShown = show,
    [SET_IS_TITLE_COLLAPSE]: (state, value) => state.isTitleCollapsed = value,
    [SET_SHOW_REFERENCES]: (state, showReferences) => state.showReferences = showReferences,
    [SET_FULL_TOPIC_WIDTH]: (state, value) => {
        state.fullTopicWidth = value;
        new IndexManager().bodyCss.setOrClear('fullWidth', state.fullTopicWidth);
    },
    [SET_HIDE_MARKETING_BANNER]: (state, value) => {
        state.hideMarketingBanner = value;
        new IndexManager().appCss.setOrClear('hideMarketingBanner', state.hideMarketingBanner);
    },
    [SET_FONT_SIZE_PREFERENCE]: (state, value) => state.fontSize = value,
    [SET_BACK_TO_SEARCH_URL]: (state, bckToSearch) => state.backToSearchUrl = bckToSearch,
    [SET_INITIAL_TOPIC_LOAD_HASH]: (state, value) => {
        state.initialTopicLoadHash = value;
    },
    [SET_SELECTED_TRANSLATION_TOGGLE_OPTION]: (state, value) =>
        state.selectedTranslationToggleOption = value,
    [SET_UNTRANSLATED_TOPIC_CONTENTS]: (state, value) =>
        state.untranslatedTopicContents = value,
    [SET_TRANSLATED_OUTLINE_BODY_HTML]: (state, data) =>
        state.topicCore.setOutlineAndBodyHtml(data),
    [SET_NEAREST_OUTLINE_HEADING_ID]: (state, value) =>
        state.nearestOutlineHeadingId = value,
    [SET_SHOW_TRANSLATION_WARNING]: (state, value) => state.shouldShowTranslationWarning = value,
    [RESET_TOPIC_LOCALIZATION_DATA]: state => {
        state.selectedTranslationToggleOption = C_LOCALIZATION.ENGLISH_ID;
        state.untranslatedTopicContents = {};
        state.nearestOutlineHeadingId = '';
    },
    [SET_WN_PCU_PANEL_INFO]: (state, value) => {
        state.wnPcuPanelInfo = { ...state.wnPcuPanelInfo, ...value };
    }
};

export const actions = {
    logTopicUsage({ dispatch }, payload) {
        const { topicId, outputString, searchTerm, topicLanguage, isClosing } = payload;

        const param = {
            topicKey: topicId,
            payload: outputString,
            query: searchTerm,
            language: topicLanguage
        };

        if (isClosing) {
            utdRest('topic/logTopicUsageSynchronous', param);
        }
        else {
            dispatch('app/logEvent', {
                eventUrl: 'event/topicUsage/bundle/json',
                eventParams: param
            }, { root: true });
        }
    },
    logTopicSectionViewEvent({ getters, rootGetters, commit, dispatch }, payload) {
        const { hashVal, source } = payload;

        let language = getters.topicLanguage;
        if (!language) {
            language = 'en-US';
        }

        const topicSectionViewEventOptions = {
            anchor: hashVal,
            source,
            id: getters.topicId,
            language,
            search: rootGetters['search/searchParamsSearchText']
        };

        if (isEqual(topicSectionViewEventOptions, getters.lastTopicSectionViewEvent)) {
            return;
        }
        commit(SET_LAST_TOPIC_SECTION_VIEW_EVENT, topicSectionViewEventOptions);
        dispatch('app/logEvent', {
            eventUrl: 'event/topicSectionView/json',
            eventParams: topicSectionViewEventOptions
        }, { root: true });
    },
    resetAllTopicMenus({ commit }) {
        commit(SET_TOOLBAR_OVERFLOW_VISIBLE, false);
        commit(`graphic/${SET_OVERFLOW_GRAPHIC_TOOLS}`, false, { root: true });
    },
    showTopicShareForm({ commit, getters, dispatch }, evt) {
        if (!getters.topicLoaded) {
            return;
        }
        commit(SET_TOOLBAR_OVERFLOW_VISIBLE, false);
        dispatch('share/showShareForm', evt, { root: true });
    },
    toggleFindInTopic({ commit, dispatch }, active) {
        commit(SET_FIND_IN_TOPIC_ACTIVE, active);
        dispatch('graphic/updateGfxHoverTooltipBinding', null, { root: true });
    },
    setTopicGraphics({ commit, getters }) {
        commit('graphic/SET_GRAPHIC_VIEWER_GRAPHICS', getters.topicGraphics, { root: true });
    },
    showOutline({ commit }) {
        commit('SET_TOPIC_SHOWING', false);
        commit('SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY', VIEW_TOPIC_KEY);
    },
    hideOutline({ commit, dispatch }) {
        commit('SET_TOPIC_SHOWING', true);
        dispatch('app/publish', {
            eventName: 'wkutd.refresh-scaled-elements'
        }, { root: true });
        commit('SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY', VIEW_OUTLINE_KEY);
    },
    async whenTopicElementsStable({ getters, commit }) {
        if (!getters.topicElementsStable) {
            const doReset = getters.resetElementsStable;
            commit(SET_RESET_ELEMENTS_STABLE, false);

            await onTopicElementsStable(doReset);
            commit(SET_TOPIC_ELEMENTS_STABLE, true);
        }
    },
    getRelatedGraphics({ getters, commit, rootGetters }, payload) {
        commit('SET_TOPIC_GRAPHICS', []);
        if (!getters.topicLanguage) {
            commit('SET_TOPIC_LANGUAGE', 'en-US');
        }
        const params = {
            source: 'outline_link',
            search: rootGetters['search/searchParamsSearchText'],
            type: payload.graphicType
        };

        return utdRest('contents/outline/related-graphics', {
            topicId: payload.topicId || getters.topicId,
            topicLanguage: payload.topicLanguage || getters.topicLanguage,
            params
        }).then(res => {
            commit('SET_TOPIC_GRAPHICS', res.graphics);
        }).catch((err = {}) => {
            if (err.error && err.error.message && err.error.number) {
                Logger.error(`Error retrieving related graphics - error: ${err.error.message}`,
                    err.error.number);
            }
        });
    },
    showRelatedGraphicsModal({ commit, dispatch }, payload) {
        return dispatch('getRelatedGraphics', payload)
            .then(() => commit('SET_RELATED_GRAPHICS_DIALOG_VISIBILITY', true));
    },
    showRelatedGraphicsModalFromFlyout({ commit, dispatch, rootGetters }, payload) {
        commit('SET_TOPIC_LANGUAGE', payload.topicLanguage);
        return dispatch('getRelatedGraphics', payload)
            .then(() => {
                commit(SET_TOPIC_TITLE,
                    rootGetters['search/outlineFlyoutTopicTitle']);
                commit('SET_RELATED_GRAPHICS_DIALOG_VISIBILITY', true);
            });

    },
    queueScrollTarget({ commit, getters }, target) {
        // Ensure we do not populate the queue with multiple requests to the same target
        // Ignore requests for #topicContent and #topicGraphics
        if (target.isTargetMatch(getters.lastPendingScrollTarget)
            || target.hash === C_TOPIC.GRAPHIC_TAB_HASH) {
            return;
        }

        // If transitioning from topic A to topic B, disable smooth scrolling
        if (getters.topicId !== getters.lastScrollTopicId) {
            commit('DO_NEXT_TOPIC_SCROLL_SMOOTHLY', false);
            commit('UPDATE_LAST_SCROLL_TOPIC_ID');
        }

        commit(QUEUE_SCROLL_TARGET, target);
    },
    handleTopicLinkClick({ dispatch, getters }, event) {
        onTopicLinkClick({ dispatch, getters }, event);
    },
    // eslint-disable-next-line complexity
    scrollToActiveHash({ dispatch, commit, getters, rootGetters }) {
        // TODO: This and the 'processScrollRequest' actions should be moved into
        // a TopicView.vue component file once it's created.

        // Ignore any scrollToActiveHash() when routeLoading is not 'topic' or 'topicLanguage'
        // Also ignore if the scroller is not set to 'active'.
        if (!rootGetters['app/routeLoading']
            .match(/\btopic\b|\btopicLanguage\b|\bdxyPackageInsert\b/)
            || !getters.isScrollerActive) {
            return;
        }

        let scrollTarget = getters.lastPendingScrollTarget;
        if (!scrollTarget || !scrollTarget.hash) {
            scrollTarget = new ScrollTarget({
                hash: getUrlHash(),
                position: getters.getUrlScrollPosition()
            });
        }

        if (scrollTarget.position && getters.topicScroller) {
            getters.topicScroller.scroll({ top: scrollTarget.position });
        }

        if (scrollTarget && scrollTarget.hash) {
            dispatch('processScrollRequest', scrollTarget);
        }
        else {
            dispatch('showOutline');
            // Resume machine learning here, in case user changed orientation
            // and there is no active hash.
            commit('SET_MACHINE_LEARNING_PAUSED', false);
        }
    },
    processScrollRequest({ dispatch, getters }, scrollTarget) {
        // For mobile view, ensure that topic is showing
        if (!getters.showGraphicTabContent && getUrlHash()) {
            dispatch('hideOutline');
        }

        // If scroll was not triggered from a click event, then check
        // if there is a stored scroll position for URL to restore.
        if (!scrollTarget.fromClick) {
            const urlScrollPos = getters.getUrlScrollPosition();
            if (urlScrollPos) {
                dispatch('queueScrollTarget', new ScrollTarget({ position: urlScrollPos }));
                dispatch('processNextScrollRequest');
                return;
            }
        }

        if (!scrollTarget.queued) {
            dispatch('queueScrollTarget', scrollTarget);
        }
        dispatch('processNextScrollRequest');
    },
    processNextScrollRequest({ getters, commit }) {
        // Proceed with triggering a scroll if there are targets pending.
        // Subsequent requests will be picked up at end of scroll in TopicSmoothScroller.vue
        if (getters.hasPendingScrollTargets) {
            commit(TRIGGER_SCROLL);
        }
    },
    bindOptimizelyGraphicModalTrigger({ dispatch, commit, getters }) {
        if (!getters.onOptimizelyLinkClick) {
            commit('SET_ON_OPTIMIZELY_LINK_CLICK', evt => {
                const imageKey = getQueryParamValues(evt.target.getAttribute('href'), 'imageKey');
                const imageKeys = imageKey.split(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR);
                if (imageKeys.length > 1) {
                    dispatch('graphic/setGraphicViewerCollection',
                        { event: evt, imageKeys, checkCollectionTitles: true },
                        { root: true })
                        .then(() => {
                            dispatch('graphic/handleUseGraphicLink', { evt }, { root: true });
                        });
                }
                else {
                    dispatch('graphic/handleUseGraphicLink', { evt }, { root: true });
                }
            });
        }
        Array.from(document.querySelectorAll('.optimizely-gvd-trigger')).forEach(el => {
            el.addEventListener('click', getters.onOptimizelyLinkClick);
        });
    },
    unbindOptimizelyGraphicModalTrigger({ getters }) {
        Array.from(document.querySelectorAll('.optimizely-gvd-trigger')).forEach(el => {
            el.removeEventListener('click', getters.onOptimizelyLinkClick);
        });
    },
    getTopicWhatsNewChanges({ getters, rootGetters }) {
        return utdRest('profile/new-whats-new', {
            topicId: getters.topicId,
            languageCode: getters.topicLanguage,
            source: rootGetters['app/router'].stateParams().source
        });
    },
    resolveWhatsNewNarratives({ getters, commit }, whatsNewInfo) {
        if (!whatsNewInfo) {
            return;
        }
        commit(SET_TOPIC_WHATS_NEW, []);

        let wnIdx = -1;
        let pcuIdx = -1;
        let divId, title;

        whatsNewInfo.forEach(whatsNew => {
            const html = `<div class="utdArticleSection utdStyle">${
                processBodyHtml(whatsNew.html, getters.topicSubtype)}</div>`;

            if (whatsNew.pcu) {
                pcuIdx++;
                divId = `PCU${pcuIdx}`;
                title = 'Practice Changing UpDate';
            }
            else {
                wnIdx++;
                divId = `WhatsNew${wnIdx}`;
                title = 'What\'s New';
            }

            commit(ADD_WHATS_NEW_ENTRY, {
                divId,
                contentId: whatsNew.contentId,
                title,
                content: html
            });
        });
    },
    injectCalculatorJs({ getters }) {
        // Method to inject any calculator specific JavaScript into the DOM
        // This action should be moved into any Topic.vue component we create later on
        let calcJs = null;
        if (getters.calculatorMarkupView === C_RESPONSIVE.MOBILE_VIEW) {
            calcJs = getters.calculatorScriptMobile;
        }
        else if (getters.calculatorMarkupView === C_RESPONSIVE.DESKTOP_VIEW) {
            calcJs = getters.calculatorScriptDesktop;
        }

        // Abort if calc JS not found, or we already installed it
        const calcScript = document.querySelector(`#${getters.calculatorMarkupView}CalcScriptJs`);
        if (!calcJs || calcScript) {
            return;
        }

        // !WORKAROUND for CESUS-2141
        // Remove calc script init function from cache
        if (typeof window.initCalc === 'function') {
            window.initCalc = null;
        }
        // END WORKAROUND

        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('id', `${getters.calculatorMarkupView}CalcScriptJs`);
        scriptTag.textContent = C_TOPIC.CALCULATOR_SCRIPT_TEMPLATE(calcJs);

        // Some SI Immunology calculators require this initializer call
        // DE6914: Added initDropdowns for this defect
        const initTag = document.createElement('script');
        initTag.setAttribute('id', `${getters.calculatorMarkupView}CalcInitJs`);
        initTag.textContent = C_TOPIC.CALCULATOR_INIT_JAVASCRIPT;

        const eTopArt = document.getElementById('topicArticle');
        if (eTopArt) {
            eTopArt.appendChild(scriptTag);
            eTopArt.appendChild(initTag);
        }
    },
    renderInjectableAssets({ dispatch, getters, commit }) {
        const htmlAssets = [];
        const cssAssets = [];
        getters.injectableAssets.forEach(asset => {
            if (asset.type === 'html') {
                htmlAssets.push(asset);
            }
            if (asset.type === 'css') {
                cssAssets.push(asset);
            }
        });
        setFormulinkSrollTargetIds(htmlAssets);
        renderInjectableHtmlAssets(htmlAssets);

        const style = renderInjectableCssAssets(cssAssets);
        injectCssStyle(style);

        setTimeout(() => {
            dispatch('addFormulinkAccordionEventHandlers');
            dispatch('addSearchInFormulinkEventHandlers');
            dispatch('setFormulinkScrollTargets',
                getOnTopicLinkClickContextBinded({ dispatch, getters }));
            collapseFormulariesList();
            postInjectionCleanup();
            dispatch('app/publish', {
                eventName: 'wkutd.done-rendering-injectables'
            }, { root: true });

            bindRehashHandler(getOnTopicLinkClickContextBinded({ dispatch, getters }));

            if (getters.isRenderInjectableAssetsFirstPass) {
                commit(SET_INJECTABLE_ASSETS_FIRST_PASS, false);
                dispatch('app/publish', {
                    eventName: 'wkutd.relink-outline-events',
                    eventData: { highlightHash: true }
                }, { root: true });
            }
            else {
                dispatch('app/publish', {
                    eventName: 'wkutd.relink-outline-events'
                }, { root: true });
            }
        }, 0);
    },
    addSearchInFormulinkEventHandlers() {
        const formulinkSearchLink = getDocument().querySelector('.formulink-search-link');
        formulinkSearchLink && formulinkSearchLink.addEventListener('click', () => {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'formulink-search-link'
            });
        });
    },
    setFormulinkScrollTargets(context, handlerFn) {
        Array.from(getDocument().querySelectorAll(`[id^="${ANCHOR_HASH_PREFIX}fiu"]`))
            .forEach(el => {
                elAddClass(el, `${el.getAttribute('id')}${SCROLL_TARGET_SUFFIX}`);
            });

        // Bind rehash/outline link handlers for topic outline Formulink anchors
        bindFormulinkRehashHandlers(handlerFn);
    },
    addFormulinkAccordionEventHandlers({ dispatch, commit }) {
        Array.from(getDocument().querySelectorAll('[data-accordion]')).forEach(accordionEl => {
            let accordionState = '';
            const toggleAccordion = () => {
                commit(SET_SECTION_POINTER_VISIBLE, false);
                if (accordionEl.classList.contains('drawer-open')) {
                    elRemoveClass(accordionEl, 'drawer-open');
                    accordionState = 'collapsed';
                }
                else {
                    collapseAllFormulinkAccordions();
                    elAddClass(accordionEl, 'drawer-open');
                    accordionState = 'expanded';
                    dispatch('processScrollRequest',
                        new ScrollTarget({
                            element: accordionEl.firstElementChild.firstElementChild
                        }));
                }
                dispatch('logFormulinkClickEvent', { accordionEl, accordionState });
            };

            const accordionClickArea = accordionEl.querySelector('[data-accordion-click-area]');
            if (!accordionClickArea) {
                return;
            }
            accordionClickArea.addEventListener('click', toggleAccordion);
            accordionClickArea.addEventListener('keypress', (event)=>{
                if (event.which === C_KEYCODES.ENTER_KEY) {
                    toggleAccordion();
                }
            });
            accordionClickArea.setAttribute('tabIndex', '0');
            setUpAccordionAriaLabel (accordionClickArea);
        });
    },
    navigateToFormulinkInfo({ rootGetters, commit }) {
        commit(SET_IS_FIT_DIALOG_SHOWN, false);

        if (rootGetters['device/isNotDesktopView']) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'formulary-link-info'
            });
            return rootGetters['app/router'].go('topicFormularyInfo', {
                topic: rootGetters['app/router'].getParams('topic')
            });
        }

        if (rootGetters['formulary/formularyInfoDrugList'].length) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'formulary-link-info'
            });
            rootGetters['app/router'].go('topicFormularyInfo', {
                topic: rootGetters['app/router'].getParams('topic')
            });
        }
        else {
            commit(SET_TOOLBAR_FORMULINK_NOTIFICATION_VISIBLE, true);
        }
    },
    logFormulinkClickEvent(state, payload) {
        const accordionFormulink
            = payload.accordionEl.querySelector(`[id^=${ANCHOR_HASH_PREFIX}fiuAcct]`);
        if (accordionFormulink && accordionFormulink.id) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: `formulary-accordion-${payload.accordionState}`,
                optData: accordionFormulink.id
            });
        }
    },
    hideOrShowPathways({ rootGetters, dispatch }) {
        const payload = {
            disabledPathways: rootGetters['app/disabledPathways'],
            enabledPathways: rootGetters['app/enabledPathways'],
            hasPathwaysContent: rootGetters['feature/hasPathwaysContent']
        };
        if (payload.hasPathwaysContent) {
            hideDisabledPathwaysLinks(payload);
            dispatch('setDisabledPathwayTooltip', payload);
            dispatch('setPathwayTippyTooltip', {
                pathwayClass: UTD_CUSTOMIZED_PATHWAY_LABEL_CLASS,
                content: C_PATHWAYS.pathwayCustomizedTooltipHTML
            });
        }
        else {
            showEnabledPathwaysLinks(payload);
            dispatch('setNotEnabledPathwayTooltip', payload);
        }
        hideOrShowRelatedPathways({
            ...payload,
            hasPathwaysAccess: rootGetters['feature/hasPathwaysAccess']
        });
    },
    showRxTransitionsLinks({ rootGetters }) {
        if (rootGetters['feature/isShowRxTransitions']) {
            Array.from(getRxTransitionsLinks()).forEach(rxTransitionsLink => {
                rxTransitionsLink.setAttribute('style', 'display:inline !important');
            });
        }
    },
    setDisabledPathwayTooltip({ dispatch }, payload) {
        payload.disabledPathways.forEach(pathwayId => {
            const pathwayLinks
                = getDocument().querySelectorAll(`a.${UTD_CONTENT_CLASS_PREFIX}${pathwayId}`);
            Array.from(pathwayLinks).forEach(pathwayLink => {
                if (!isPathwayEnabled({ pathwayLink, ...payload })) {
                    setPathwayUnavailableClass(pathwayLink);
                }
            });
        });

        dispatch('setPathwayTippyTooltip', {
            pathwayClass: UTD_PATHWAYS_UNAVAILABLE_CLASS,
            content: C_PATHWAYS.pathwayDisabledTooltipHTML
        });
    },
    setNotEnabledPathwayTooltip({ dispatch }, payload) {
        const pathwayLinks = getDocument().querySelectorAll(`span.${UTD_ADT_PATHWAYS_CLASS} a`);
        Array.from(pathwayLinks).forEach(pathwayLink => {
            if (!isPathwayEnabled({ pathwayLink, ...payload })) {
                setPathwayUnavailableClass(pathwayLink);
            }
        });

        dispatch('setPathwayTippyTooltip', {
            pathwayClass: UTD_PATHWAYS_UNAVAILABLE_CLASS,
            content: C_PATHWAYS.pathwayNotEnabledTooltipHTML
        });
    },
    async setPathwayTippyTooltip({ rootGetters, dispatch }, payload) {
        const triggerEvent
            = rootGetters['device/isDesktopView'] ? 'mouseenter' : 'click';
        const ele = payload.pathwayClass === UTD_PATHWAYS_UNAVAILABLE_CLASS
            ? `.${payload.pathwayClass}` : `span span.${payload.pathwayClass} + a`;
        const hideTooltipOnClick = (payload.pathwayClass === UTD_CUSTOMIZED_PATHWAY_LABEL_CLASS);
        try {
            await dispatch('app/confirmConfigLoaded', null, { root: true });
            const pathwayTooltips = new PathwayTooltip(ele,
                rootGetters['app/tooltipConfig'], {
                    content: payload.content,
                    trigger: triggerEvent,
                    hideOnClick: hideTooltipOnClick,
                    allowHTML: true,
                    touch: true,
                    onClickOutside(instance) {
                        instance.hide();
                    }
                });
            await pathwayTooltips.initialize();
        }
        catch (e) {
            if (e.indexOf('IGNORE_ERROR') === -1) {
                // eslint-disable-next-line no-console
                console.log(`Warning setting pathway tippy tooltip - error: ${e}`);
            }
        }
    },
    rebindWhatsNewLinks({ dispatch, getters }) {
        bindRehashHandler(getOnTopicLinkClickContextBinded({ dispatch, getters }));
        dispatch('scrollToActiveHash');
        moveSameArticleClickToTop('#topicWhatsNewContainer a',
            smoothScrollToArticleTop.bind(null, dispatch));
        document.querySelectorAll('#topicArticle #topicWhatsNewContainer a.local')
            .forEach(element => {
                element.addEventListener('click', logWhatsNewSectionViews.bind(null, dispatch));
            });
    },
    logWhatsNewSectionViews({ dispatch }, payload) {
        const { evt } = payload;
        logWhatsNewSectionViews(dispatch, evt);
    },
    logTopicPrintEvent({ dispatch, getters }, payload) {
        return dispatch('app/logEvent', {
            eventUrl: 'event/topic/print/json',
            eventParams: {
                id: getters.topicId,
                language: getters.topicLanguage,
                requestedView: payload.requestedView
            }
        }, { root: true });
    },
    bindRehashHandler({ dispatch, getters }) {
        bindRehashHandler(getOnTopicLinkClickContextBinded({ dispatch, getters }));
    },
    unbindRehashHandler({ dispatch, getters }) {
        unbindRehashHandler(getOnTopicLinkClickContextBinded({ dispatch, getters }));
    },
    unbindFormulinkRehashHandlers({ dispatch, getters }) {
        unbindFormulinkRehashHandlers(getOnTopicLinkClickContextBinded({ dispatch, getters }));
    },
    bookmarkTopic({ commit, dispatch, getters }) {
        const topicParams = {
            contentId: getters.topicId,
            languageCode: getters.topicLanguage
        };
        commit(ADD_TOPIC_BOOKMARK);
        return dispatch('profile/putProfileItem',
            { path: 'bookmark/topic', params: topicParams },
            { root: true });
    },
    removeBookmarkTopic({ commit, dispatch, getters }) {
        const topicParams = {
            contentType: getters.topicType,
            contentSubType: getters.topicSubtype,
            contentVersion: getters.topicVersion,
            contentId: getters.topicId,
            languageCode: getters.topicLanguage
        };
        commit(REMOVE_TOPIC_BOOKMARK);
        return dispatch('profile/deleteProfileItem',
            { path: 'bookmark/delete', params: topicParams },
            { root: true });
    },
    async resolveBookmarkContent({ rootGetters, commit, dispatch, getters }) {
        await dispatch('app/confirmConfigLoaded', null, { root: true });
        commit(SET_TOOLBAR_BOOKMARK_ACTIVE, false);
        if (rootGetters['profile/permissions'].bookmarks) {
            if (await dispatch('profile/isCanRefresh', 'bookmarks', { root: true })) {
                dispatch('profile/getBookmarks', null, { root: true })
                    .then(() => {
                        markBookmarkedTopic({ rootGetters, commit, dispatch, getters });
                    });
            }
            else {
                markBookmarkedTopic({ rootGetters, commit, dispatch, getters });
            }
        }
    },
    rebindCollapsibleIndicationLinks({ getters }, options) {
        const onClick = ({ id, title, action, uiElementName }) =>
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName,
                contentId: getters.topicId,
                optData: { id, title, action }
            });

        rebindCIL({ ...options, onClick });
    },
    getTopic({ dispatch }, payload = {}) {
        const { dataPromise, forPrint = false } = payload;

        if (dataPromise) {
            return dataPromise
                .then(data => dispatch('resolveTopic', { data, forPrint }))
                .catch(error => dispatch('resolveTopicError', error));
        }

        return dispatch('getTopicFromCacheOrServer', payload);
    },
    onContentWasUpdated() {
        // This should get called any time we determine that the server's
        // content was updated. We have to clear the  entire cache, since there
        // isn't enough information to do anything more selective.
        const cache = new UtdQueuedCache();
        cache.delete('utdSPAContentCache');
        cache.init('utdSPAContentCache', UTD_SPA_CONTENT_CACHE_DEFAULT_LIMIT);

        // We considered doing a window.location.reload() for instant refresh,
        // but decided it wasn't worth it. The vast majority of the time, stale
        // content will not be the current topic being used. If it is, the user
        // will get the fresh topic next time they do something that triggers a
        // call to getTopicFromCacheOrServer. If we later decide that stale
        // content needs to be refreshed instantly for all clients, we'd need an
        // entirely new architecture for cache busting that constantly polls the
        // server. For now, this gets us at least 90% of the utility with much
        // less cost.
    },
    // eslint-disable-next-line complexity
    getTopicFromCacheOrServer({ commit, rootGetters, getters, dispatch }, payload) {
        const { forPrint = false } = payload;

        if (!getters.topicUrl) {
            return;
        }

        let uri = makeRestUrl(`contents/topic/${getters.topicUrl}`,
            getters.topicUrlLanguage,
            `${forPrint ? '/print' : ''}/json`);

        uri = `${uri}${getWindow().location.search}`;
        const cid = rootGetters['inlineCme/correlationId'];
        uri = setCorrelationId({ cid, forPrint, uri });

        const source = getQueryParamValues(uri, 'source');

        if (source && (source === 'kpp' || source === 'bqp')) {
            uri = addEventQps(rootGetters, source, uri);
        }

        const cacheKey = rootGetters['app/isProspectMode'] ? UTD_PROSPECT_CACHE_KEY : '';

        let promise = new UtdQueuedCache().getDeferred('utdSPAContentCache',
            getWindow().location.pathname + cacheKey);

        if (!promise) {
            commit(SET_TOPIC_CACHE_HIT, false);
            promise = utdRest('topic/getTopic', { uri });
        }
        // Don't record a topic view full if requesting print.
        else {
            commit(SET_TOPIC_CACHE_HIT, true);
            if (new UtdCache().doCacheHitEvent
                && !forPrint) {
                try {
                    utdRest('topic/cacheHitEvent', {
                        id: getters.topicUrl,
                        languageCode: getters.topicUrlLanguage,
                        uri
                    });
                }
                catch (e) {
                    Logger.warn(`Could not record topic event for topicUrl:${
                        uri
                    }params: ${
                        getWindow().location.search
                    },exception:${e}`);
                }
            }
        }

        return promise
            .then(data => dispatch('resolveTopic', { data, forPrint }))
            .catch(error => dispatch('resolveTopicError', error));
    },
    // eslint-disable-next-line complexity
    resolveTopic({ commit, dispatch, getters, rootGetters }, payload) {
        const { data, forPrint } = payload;

        if (!data) {
            dispatch('resolveTopicError', { status: -2 });
            return;
        }

        if (forPrint) {
            if (getters.topicIsCacheHit) {
                dispatch('logTopicPrintEvent', { requestedView: '' });
            }
            commit(`app/${SET_IS_PRINT_VIEW}`, true, { root: true });
        }
        const cacheKey = rootGetters['app/isProspectMode'] ? UTD_PROSPECT_CACHE_KEY : '';

        new UtdQueuedCache().setPersistent(
            'utdSPAContentCache',
            getWindow().location.pathname + cacheKey,
            data,
            rootGetters['app/contentVersion']
        );

        commit(RESOLVE_TOPIC_DATA, { data, forPrint });
        commit(SET_TOPIC_TRANSLATION_INFOS, data.topicInfo.translatedTopicInfos);
        dispatch('setTopicGraphics');

        commit(SET_IS_TOPIC_VIEW, true);
        commit(SET_FULL_TOPIC_WIDTH, !getters.isShowOutline);
        commit(RESET_TOPIC_LOCALIZATION_DATA);

        if (!getters.isShowOutline) {
            dispatch('app/publish', { eventName: 'wkutd.refresh-scaled-elements' }, { root: true });
        }

        dispatch('resolveWhatsNewNarratives', data.whatsNewInfo);
        dispatch('resolveBookmarkContent');

        if (getters.isLanguageEs419) {
            commit(`app/${SET_DEFAULT_PAGE_LANG}`, C_LANGUAGES_NAMES.ES.CODE, { root: true });
        }

        if (getters.isDrugDxySubtype && getUrlHash()) {
            dispatch('scrollToActiveHash');
        }

        commit(SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY, VIEW_TOPIC_KEY);
        commit(SET_TOPIC_LOADED, true);

        if (getters.topicHasError) {
            Logger.warn(`Error resolving the topic error: ${getters.topicError}`);
            dispatch('resolveTopicError');
        }
        return true;
    },
    resolveTopicError({ rootGetters, getters, commit, dispatch }, payload) {
        const error = payload || getters.topicError;
        commit(SET_TOPIC_ERROR, true);
        // 403 = Forbidden access, check utdStatus code...
        if (Number(error.status) === 403) {
            commit(SET_TOPIC_ERROR, dispatch('error/resolveContent403', error, { root: true }));
            if (rootGetters['error/errorClearSearch']) {
                commit(`search/${SET_SEARCH_PARAMS_TEXT}`, '', { root: true });
            }
        }
        else if (Number(error.status) === 404) {
            rootGetters['app/router'].url('/page-not-found');
        }
        else {
            commit(SET_TOPIC_ERROR_MESSAGE_KEY, 'ERRORS.TOPIC_ERROR');
        }
        return true;
    },
    findInTopic(
        { getters, rootGetters, dispatch },
        { injectInlineGraphicsOnHTMLString, inlineGraphicsInjectedEventsHandler } = {}
    ) {
        const params = {
            topicKey: getters.topicId,
            query: getters.findInTopicTerm,
            matchSynonym: getters.findInTopic.matchSynonyms,
            language: getters.topicLanguage,
            detectedLanguage: rootGetters['search/searchLanguageCode']
        };

        return utdRest('topic/findInTopic', params)
            .then(data => {
                dispatch('resolveFindInTopic', { data, injectInlineGraphicsOnHTMLString });
                dispatch('updateViewAfterFindInTopic', inlineGraphicsInjectedEventsHandler);
            })
            .catch(err => {
                dispatch('resetFindInTopic');
                dispatch('resolveFindInTopicError', err);
            });
    },
    resolveFindInTopic(
        { commit, getters, dispatch },
        { data, injectInlineGraphicsOnHTMLString } = {}
    ) {
        if (data.status !== '0') {
            return;
        }
        commit(SET_FIND_IN_TOPIC_HITS, data.textCount);
        commit(SET_FIND_IN_TOPIC_BODY, getters.topicBodyHtml);
        commit(SET_FIND_IN_TOPIC_OUTLINE, getters.topicOutlineHtml);
        commit(SET_FIND_IN_TOPIC_WHATS_NEW, getters.topicWhatsNewList);
        const body = processBodyHtml(data.textHtml, getters.topicSubtype);
        commit(SET_TOPIC_BODY_HTML, body);
        const outlineHtml = processOutlineHtml(data.outlineHtml);
        commit(SET_TOPIC_OUTLINE_HTML, outlineHtml);
        dispatch('resolveWhatsNewNarratives', data.whatsNewInfoList);
        if (injectInlineGraphicsOnHTMLString) {
            injectInlineGraphicsOnHTMLString();
        }
        commit(SET_FIND_IN_TOPIC_ACTIVE, true);
    },
    updateViewAfterFindInTopic({ dispatch, getters }, inlineGraphicsInjectedEventsHandler) {
        setTimeout(() => {
            inlineGraphicsInjectedEventsHandler && inlineGraphicsInjectedEventsHandler();
        });
        setTimeout(() => {
            dispatch('app/publish', {
                eventName: 'wkutd.render-whats-new-topics-event'
            }, { root: true });
            dispatch('rebindCollapsibleIndicationLinks', { openAll: true });
            dispatch('renderInjectableAssets');
            if (getters.toolbarFormularyVisible) {
                dispatch('formulary/enhanceDrugReferences', null, { root: true });
            }
            dispatch('hideOrShowPathways');
            dispatch('showRxTransitionsLinks');
            checkAppendDrugSectionLabel(getters.topicTitle, getters.initialTopicLoadHash);
        }, 100);
    },
    // eslint-disable-next-line no-unused-vars
    resolveFindInTopicError({ rootGetters }, error) {
        Logger.error(`Error retrieving "Find In Topic" results - error :
            ${error.errors} status:${error.status}`);
    },
    resetFindInTopic(
        { getters, commit, dispatch },
        { injectInlineGraphicsOnHTMLString, inlineGraphicsInjectedEventsHandler } = {}
    ) {
        const body = getters.findInTopicBody;

        commit(SET_TOPIC_BODY_HTML, body);
        commit(SET_TOPIC_OUTLINE_HTML, getters.findInTopicOutline);
        commit(SET_TOPIC_WHATS_NEW, getters.findInTopicWhatsNew);
        commit(SET_FIND_IN_TOPIC_HITS, '...');
        commit(SET_FIND_IN_TOPIC_INDEX, -1);
        commit(SET_FIND_IN_TOPIC_WHATS_NEW, []);
        if (injectInlineGraphicsOnHTMLString) {
            injectInlineGraphicsOnHTMLString();
        }

        dispatch('toggleFindInTopic', false);

        // Force all outline event handlers to relink
        dispatch('app/publish', {
            eventName: 'wkutd.relink-outline-events'
        }, { root: true });

        dispatch('updateViewAfterFindInTopic', inlineGraphicsInjectedEventsHandler);
    },
    resetTopic({ rootGetters, commit }) {
        commit(`error/${RESET_ERROR}`, {}, { root: true });

        commit(RESET_TOPIC_DATA);
        commit(SET_FIND_IN_TOPIC_ACTIVE, false);
        commit(SET_FIND_IN_TOPIC_HITS, '...');
        commit(SET_FIND_IN_TOPIC_BODY, '');
        commit(SET_FIND_IN_TOPIC_OUTLINE, '');
        commit(SET_FIND_IN_TOPIC_MATCH_SYNONYMS, true);
        commit(SET_FIND_IN_TOPIC_TERM, '');
        commit(SET_FIND_IN_TOPIC_MATCHES, []);
        commit(SET_FIND_IN_TOPIC_INDEX, -1);
        commit(SET_TOOLBAR_OUTLINE_TOGGLE_LABEL_KEY, VIEW_TOPIC_KEY);
        commit(SET_TOPIC_TOOLS_SAMPLE_TOPIC, false);
        commit(SET_TOOLBAR_FORMULARY_VISIBLE, false);
        commit(SET_TOOLBAR_FINDINTOPIC_VISIBLE, true);
        commit(SET_SHOW_GRAPHIC_TAB_CONTENT, false);
        commit(SET_IS_TITLE_COLLAPSE, false);

        commit(`feature/${SET_SHOW_BACK_TO_SEARCH}`, false, { root: true });
        commit(SET_SHOW_REFERENCES, false);
        // Ensure that 'fixedHTML' setting gets cleared, unless it needs to be on by default
        if (!rootGetters['app/isDefaultFixed']) {
            commit(`app/${SET_FIXED_HTML}`, '', { root: true });
        }

        commit(SET_LAST_TOPIC_SECTION_VIEW_EVENT, {});
    },
    setArticleTop({ rootGetters }, elements) {
        const { toolbarEl, articleEl, articleInlineGfxEl } = elements;
        const toolbarHeightPx = (toolbarEl && toolbarEl.offsetHeight) || 0;

        if (rootGetters['app/isProspectMode']) {
            if (rootGetters['device/isDesktopView']) {
                articleEl.style.paddingTop
                    = `${getProspectDesktopArticlePaddingTop(elements)}px`;
            }
            else {
                articleEl.style.top
                    = `${toolbarHeightPx}px`;
                articleEl.style.paddingTop = 0;
            }
        }
        else if (rootGetters['device/isDesktopView']) {
            articleEl.style.paddingTop = `${toolbarHeightPx}px`;
        }
        else if (rootGetters['topic/isFitDialogShown']) {
            articleEl.style.paddingTop = `${toolbarHeightPx + C_TOPIC.FIT_HEIGHT}px`;
        }
        else {
            articleEl.style.paddingTop = `${toolbarHeightPx}px`;
        }

        setArticleInlineGfxTop(articleInlineGfxEl, articleEl);
    },
    setTopicFontSizeBodyClass({ getters }) {
        new IndexManager().bodyCss.set('textSize', getters.topicFontSize);
    },
    setTopicFontSize({ getters, commit }, size) {
        if (getters.isFontSizeSettingsUpdateAllowed) {
            // Update fontSize and also update
            // the font settings in DB for the user.
            commit(SET_FONT_SIZE_PREFERENCE, size);
            new IndexManager().bodyCss.set('textSize', size);
            utdRest('user/setting', {
                settingCode: 'font-size-preference',
                settingValue: size
            });
        }
        else {
            commit('app/SET_STORED_SETTING', {
                key: 'textSize',
                value: size
            }, { root: true });
        }
    },
    setupLocalizationFeature({ rootGetters }, localizationElement) {
        if (!rootGetters['user/userLoggedIn'] && !rootGetters['app/isAuthenticatedByIp']) {
            return;
        }
        if (!localizationElement) {
            return;
        }
        createSharedTopicSection(localizationElement);
    },
    setLocalizationContent({ getters, commit, dispatch }, payload = {}) {
        const { language = C_LOCALIZATION.ENGLISH_ID } = payload;
        let localizationContent;
        if (language === C_LOCALIZATION.LOCAL_LANGUAGE_ID) {
            dispatch('storeUntranslatedContent');
            localizationContent = getters.localizedContent;
        }
        else {
            localizationContent = getters.englishContent;
        }
        if (!localizationContent) {
            return false;
        }
        const { bodyHtml, outlineHtml, title, metaDescription } = localizationContent;
        commit(SET_TOPIC_TITLE, title);
        commit(SET_TOPIC_META, metaDescription);
        commit(SET_TOPIC_OUTLINE_HTML, outlineHtml);
        commit(SET_TOPIC_BODY_HTML, bodyHtml);
        commit(SET_TRANSLATED_OUTLINE_BODY_HTML, localizationContent);
        return true;
    },
    storeUntranslatedContent({ getters, commit }) {
        commit(SET_UNTRANSLATED_TOPIC_CONTENTS, {
            title: getters.topicTitle,
            metaDescription: getters.topicMeta,
            outlineHtml: getters.topicOutlineHtml,
            bodyHtml: getters.topicBodyHtml
        });
        return true;
    },
    triggerTopicViewRemount({ commit, getters }) {
        commit(SET_URL_SCROLL_POSITION);
        commit(SET_SAVE_SCROLL_POSITION, false);
        const nearestHash = getOutlineHeadingHash(getters.nearestOutlineHeadingId || getUrlHash());
        getWindow().location.hash = nearestHash;
        new PubSub().publish(C_EVENTS.REMOUNT_TOPIC_VIEW);
    },
    setLocalizationInfo({ commit }) {
        utdRest('localization/agreement-status')
            .then(result => {
                const hasNotAcknowledgeTranslationWarning = result.value !== 'true';
                commit(SET_SHOW_TRANSLATION_WARNING, hasNotAcknowledgeTranslationWarning);
            })
            .catch(() => {
                commit(SET_SHOW_TRANSLATION_WARNING, true);
            });
    },
    fetchWnPcuPanelInfo: async({ commit }, payload) => {
        /**TODO: This is temporary code to mock pcu/wn data. 
         This will be removed once the API is ready. **/

        return new Promise(() => {
            const { infoType, specialtyItem = '' } = payload;

            if (infoType === 'pcu') {
                const pcuData = pcuMockData;
                commit(SET_WN_PCU_PANEL_INFO, pcuData);
            }
            else if (infoType === 'wn') {
                if (specialtyItem.replace(/\s+/g, '-').toLowerCase() ===
                    'cardiovascular-medicine') {
                    const wnData1 = wnMockData1;
                    commit(SET_WN_PCU_PANEL_INFO, wnData1);
                }
                else if (specialtyItem.replace(/\s+/g, '-').toLowerCase() ===
                    'emergency-medicine') {
                    const wnData2 = wnMockData2;
                    commit(SET_WN_PCU_PANEL_INFO, wnData2);
                }
                else {
                    commit(SET_WN_PCU_PANEL_INFO, { wnPanelInfo: {} });
                }
            }
        });
    }
};

const topic = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default topic;

const addEventQps = (rootGetters, source, uri) => {
    const eventData = rootGetters[`search/${source}EventData`];
    if (eventData) {
        const search = encodeURIComponent(rootGetters['search/searchParamsSearchText']);

        uri = eventData.rankIndex
            ? setQueryParamValue(uri, 'display_rank', eventData.rankIndex) : uri;
        uri = eventData.searchRank
            ? setQueryParamValue(uri, 'selectedTitle', eventData.searchRank) : uri;
        uri = search
            ? setQueryParamValue(uri, 'search', search) : uri;
    }

    return uri;
};

const setArticleInlineGfxTop = (articleInlineGfxEl, articleEl) => {
    if (!articleInlineGfxEl) {
        return;
    }
    articleInlineGfxEl.style.marginTop = articleEl.style.paddingTop;
};

const isPathwayEnabled = payload => {
    if (!payload.pathwayLink) {
        return false;
    }
    return Array.from(payload.pathwayLink.classList)
        .some(pathwaysClass => {
            if (pathwaysClass.indexOf(UTD_CONTENT_CLASS_PREFIX) === -1) {
                return false;
            }
            const pathwayId = pathwaysClass.substring(UTD_CONTENT_CLASS_PREFIX.length);
            if (payload.disabledPathways.includes(pathwayId)) {
                return false;
            }
            else if (payload.enabledPathways.includes(pathwayId)) {
                return true;
            }
            else if (payload.hasPathwaysContent) {
                return true;
            }
            return false;
        });
};

const hideDisabledPathwaysLinks = payload => {
    payload.disabledPathways.forEach(pathwayId => {
        const pathwayLinks = getPathwayLinks(pathwayId);
        Array.from(pathwayLinks).forEach(pathwayLink => {
            if (!isPathwayEnabled({ pathwayLink, ...payload })) {
                hideDisabledPathwayLink(pathwayLink);
            }
        });
    });
};

const showEnabledPathwaysLinks = payload => {
    payload.enabledPathways.forEach(pathwayId => {
        const pathwayLinks = getPathwayLinks(pathwayId);
        Array.from(pathwayLinks).forEach(pathwayLink => {
            if (isPathwayEnabled({ pathwayLink, ...payload })) {
                showEnabledPathwayLink(pathwayLink);
            }
        });
    });
};

const hideOrShowRelatedPathways = payload => {
    const pathwayLinks = getDocument().querySelectorAll('#outlineRelatedPathwaysLinks li');
    const displayRelatedPathways = Array.from(pathwayLinks).some(pathwayLink => {
        return isPathwayEnabled({ pathwayLink, ...payload });
    });

    const ele = getDocument().getElementById('outlineRelatedPathwaysLinks');
    if (ele && !(displayRelatedPathways && payload.hasPathwaysAccess)) {
        ele.setAttribute('style', 'display:none !important');
    }
    else if (ele) {
        ele.setAttribute('style', 'display:inline !important');
    }
};

export const markBookmarkedTopic = async({ rootGetters, commit, getters }) => {
    commit(SET_TOOLBAR_BOOKMARKED_TOPICS, rootGetters['profile/bookmarksData']);

    const topicIndex = findLastIndex(getters.bookmarkedTopics,
        topic => topic.contentId === getters.topicId
            && topic.languageCode === getters.topicLanguage);

    if (topicIndex > -1) {
        commit(SET_TOOLBAR_BOOKMARK_ACTIVE, true);
    }
};
let onTopicLinkClickContextBinded = null;
export const getOnTopicLinkClickContextBinded = context => {
    // As far we need same handler we need to create singleton.
    if (!onTopicLinkClickContextBinded) {
        onTopicLinkClickContextBinded = onTopicLinkClick.bind(null, context);
    }
    return onTopicLinkClickContextBinded;
};

export const onTopicLinkClick = ({ dispatch, getters }, event) => {
    const scrollTarget = new ScrollTarget({ event });

    // As this action is only called from clicking on a topic link,
    // reject the request if there is not a hash assigned
    if (!scrollTarget.hash
        || scrollTarget.isEqual(getters.lastPendingScrollTarget)) {
        return;
    }

    // When user clicks on a topic link different from the one
    // currently being scrolled to, then prevent default handling
    // so new URL is not added to browser history.
    // Once all ScrollTargets in the pipeline have been processed,
    // then the last target URL will replace the URL from the initial
    // ScrollTarget request in the browser history.
    const currentTargetHash = getters.activeScrollTargetHash;
    if (getters.topicIsScrolling
        && currentTargetHash
        && scrollTarget.hash !== currentTargetHash) {
        scrollTarget.setReplaceUrlFromTarget();
        event && event.preventDefault();
    }

    dispatch('queueScrollTarget', scrollTarget);

    safeTimeout(() => dispatch('scrollToActiveHash'), 1);
};

export const smoothScrollToArticleTop = dispatch => {
    const target = new ScrollTarget({
        position: C_TOPIC.SCROLL_TOP_POSITION,
        fromClick: true
    });
    dispatch('processScrollRequest', target);
};

export const logWhatsNewSectionViews = (dispatch, event) => {
    if (!event || !event.target || !event.target.hash) {
        return;
    }
    const aHash = event.target.hash.split('#');
    if (aHash.length > 1) {
        dispatch('logTopicSectionViewEvent',
            { hashVal: aHash[1], source: 'see_link' });
    }
};

export const setCorrelationId = ({ cid, forPrint, uri }) => (!forPrint && cid)
    ? setQueryParamValue(uri, 'searchCorrelationId', cid)
    : uri;
