<template>
  <div class="home-account-menu">
    <ul>
      <li v-if="isNotDesktopView" id="homeHeader">
        <a href="/"
           tabindex="0"
           role="menuitem"
           @click="collapseExpandedSearch()"
           v-text="$t('HOME')" />
      </li>
      <li v-if="showCmeLink" id="navbarCME">
        <a :href="dashboardLink"
           tabindex="0"
           role="menuitem">
          <utd-cme-ticker />
        </a> 
      </li>
    </ul>
    <ul>
      <li>
        <span class="menu-title" role="menuitem"> {{ $t("CONTENT.TOOLS") }}</span>
      </li>
      <li v-if="showDrugInteractionsApp" id="navbarDrugInt">
        <a href="/drug-interactions?source=navbarDrugInt"
           tabindex="0"
           role="menuitem"
           v-text="$t('HEADER.DRUG_INTERACTIONS')" />
      </li>
      <li v-if="shouldShowCalculators" id="navbarCalculators">
        <a :href="localizeAnchor('/contents/','table-of-contents/calculators/categorized', language)"
           role="menuitem"
           tabindex="0"
           @focus="hideContents"
           v-text="$t('HEADER.CALCULATORS')" />
      </li>
      <li v-if="isShowRxTransitions" id="navbarRxTransitions">
        <a href="/rxtransitions?source=navbarRxTransitions"
           role="menuitem"
           v-text="$t('HEADER.RX_TRANSITIONS')" />
      </li>
      <li v-show="hasPathwaysAccess" id="navbarPathways">
        <a :href="localizeAnchor('/contents/',
                                 'table-of-contents/pathways', language)"
           role="menuitem"
           v-text="$t('SEARCH.UPTODATE_PATHWAYS')" />
      </li>
    </ul>
    <ucc-menu-items v-if="isUccState" class="home-account-menu" />
    <ul v-if="!isUccState">
      <li>
        <span class="menu-title" role="menuitem"> {{ $t("HEADER.CONTENTS") }}</span>
      </li>
      <li v-for="(item) in visibleTocRootItems" :key="item.key">
        <a :id="item.navClass"
           :class="item.navClass"
           role="menuitem"
           tabindex="0"
           :href="buildTocUrl(item)"
           :target="item.newTab ? '_blank' : undefined">
          {{ $t(item.key) }}
        </a>
      </li>
    </ul>
    <ul v-if="permissions.myUpToDate">
      <li>
        <span class="menu-title" role="menuitem"> {{ $t("MYUPTODATE.SHORTCUTS") }}</span>
      </li>
      <li :id="`navHistory${idModifier}`" 
          :class="{ 'separator': isNotDesktopView }">
        <a :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.HISTORY}`"
           role="menuitem"
           @click="showPersonalizationModal($event, myUtdTabs.HISTORY)">
          {{ $t('MYUPTODATE.HISTORY') }}
        </a>
      </li>
      <li :id="`navViewed${idModifier}`">
        <a role="menuitem"
           :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.MOST_VIEWED}`"
           @click="showPersonalizationModal($event, myUtdTabs.MOST_VIEWED)">
          {{ $t('MYUPTODATE.MOST_VIEWED') }}
        </a>
      </li>
      <li :id="`navBookmarks${idModifier}`">
        <a role="menuitem"
           :href="`/myuptodatemobile?myUtdTab=${myUtdTabs.BOOKMARKS}`"
           @click="showPersonalizationModal($event, myUtdTabs.BOOKMARKS)">
          {{ $t('MYUPTODATE.BOOKMARKS') }}
        </a>
      </li>
    </ul>
    <ul>
      <li>
        <span class="menu-title">{{ $t('HEADER.OTHER') }}</span>
      </li>
      <li v-if="showMyAccount" :id="`navAccount${idModifier}`" class="separator">
        <a role="menuitem" href="#" @click="openMyAccountFlyout($event)">
          {{ $t('MYUPTODATE.MY_ACCOUNT') }}
        </a>
      </li>
      <li :id="`navLanguage${idModifier}`" 
          @keypress="$event.which === ENTER_KEY && openChangeLanguageDialog($event)">
        <a class="no-redirect"
           role="menuitem"
           tabindex="0"
           href="/settings/change-language">
          Language
        </a>
      </li>
      <li v-if="showAnkiInfo" 
          :id="`navAnkiDownload${idModifier}`">
        <a role="menuitem"
           href="#"
           tabindex="0"
           @click="showAnkiDownloadInfo()">
          {{ $t('ANKI.TITLE') }}
        </a>
      </li>
      <li v-if="showManageCookiesLink" 
          :id="`navCookies${idModifier}`">
        <a id="ot-sdk-btn"
           class="no-redirect ot-sdk-show-settings"
           :class="{ 'one-trust-mobile': isNotDesktopView }"
           role="menuitem"
           tabindex="0">
          {{ $t('MYACCOUNT.COOKIE_PREFERENCES') }}
        </a>
      </li>
      <li v-if="isFooterLoaded" 
          :id="`navHelp${idModifier}`">
        <a :href="getFooterUrl('help')" target="_blank" role="menuitem">
          {{ $t('HEADER.HELP') }}
        </a>
      </li>
      <li v-if="isNotDesktopView"
          :id="`navSettings${idModifier}`"
          :class="{ 'separator': !isShowCmeInDropdown && !showNavAccount }">
        <a href="/settings" role="menuitem">
          {{ $t('MYACCOUNT.SETTINGS') }}
        </a>
      </li>
      <li v-if="isLoggedIn" id="navLogout">
        <a href="/logout" role="menuitem" @click="resolveLogout('/logout')">
          {{ $t('LOGIN.SIGN_OUT') }}
        </a>
      </li>
      <li v-if="isRegister && !isLoggedIn"
          :id="`navRegister${idModifier}`">
        <a :href="navRegisterUrl"
           role="menuitem">
          {{ $t('LOGIN.REGISTER') }}
        </a>
      </li>

      <li v-if="!isLoggedIn"
          :id="`navLogin${idModifier}`">
        <a href="/login"
           role="menuitem">
          {{ $t('MISC.LOG_IN') }}
        </a>
      </li>
    </ul>
    <my-account-sidebar v-if="isLoggedIn" ref="myAccountRef" tabindex="-1" />
    <anki-download-modal-dialog v-if="showAnkiInfo" ref="ankiDownloadRef" />
    <my-account-error-dialog ref="myAccountModalErrorRef" />
  </div>
</template>
<script>
import { 
    C_ACCOUNT, 
    C_CME_UI_LINKS, 
    C_EVENTS, 
    C_MY_ACCOUNT, 
    C_MYUTD_TABS, 
    C_PATHS } from '_acaSrc/utility/constants';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import UccMenuItems from '_acaSrc/components/homePageRedesign/UccMenuItems.vue';
import PubSub from '_acaSrc/utility/PubSub';
import AnkiDownloadModalDialog from '../shared/stdlib/AnkiDownloadModalDialog.vue';
import MyAccountErrorDialog from '_acaSrc/components/header/my-account/MyAccountErrorDialog.vue';
import { SET_MYACCOUNT_SIDEBAR_REQUEST } from '_acaSrc/store/app.store';
import { SET_MOBILE_WEB_SEARCH_INTERACTION } from '_acaSrc/store/search.store';
import { SET_LOGIN_ERROR_MESSAGE, SET_LOGIN_INFO_MESSAGE } from '_acaSrc/store/login.store';
import MyAccountSidebar from '_acaSrc/components/header/my-account/MyAccountSidebar.vue';
import UtdCmeTicker from '_acaSrc/components/header/UtdCmeTicker.vue';

export default {
    components: {
        UccMenuItems,
        AnkiDownloadModalDialog,
        MyAccountErrorDialog,
        MyAccountSidebar,
        UtdCmeTicker
    },
    data(){
        return {
            myUtdTabs: C_MYUTD_TABS,
            tocPrefix: C_PATHS.TOC_PREFIX,
            dashboardLink: C_CME_UI_LINKS.dashboard
        };
    },
    computed: {
        ...mapGetters('device', [ 
            'isDesktopView', 
            'showManageCookiesLink',
            'isAnkiAddon', 'isNotDesktopView' 
        ]),
        ...mapGetters('search', [ 'isMobileWebSearchExpanded' ]),
        ...mapGetters('app', [ 'isUccState', 'isAnonymousUser' ]),
        ...mapGetters('toc', [ 'tocRootItems' ]),
        ...mapGetters('footer', [ 'getFooterUrl', 'isFooterLoaded' ]),
        ...mapGetters('user', [ 'language', 'userLoggedIn', 'isCustomer' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('feature', [
            'showDrugInteractionsApp',
            'hasPathwaysAccess',
            'isShowRxTransitions',
            'topicRedesign'
        ]),
        ...mapGetters('cme', [
            'showTicker',
            'cmeTickerId'
        ]),
        ...mapGetters('login', [ 'loginHref', 'isRegister' ]),
        visibleTocRootItems() {
            return this.tocRootItems.filter(item => {
                return item.display !== false && (!item.featureCheck || (item.featureCheck()));
            }).sort((a, b) => a.redesignPosition >= b.redesignPosition ? 1 : -1);
        },
        shouldShowCalculators() {
            return (this.isCustomer || this.userLoggedIn);
        },
        showNavAccount() {
            return this.isLoggedIn && this.permissions.myAccount && this.isDesktopView;
        },
        showMyAccount() {
            return this.isLoggedIn
          && this.permissions.myAccount;
        },
        isLoggedIn() {
            return this.userLoggedIn;
        },
        idModifier() {
            return this.isNotDesktopView ? '_mobile' : '';
        },
        isShowCmeInDropdown() {
            return this.isLoggedIn
          && this.showTicker
          && this.isNotDesktopView;
        },
        showAnkiInfo() {
            return !this.isUccState && !this.isAnkiAddon;
        }, 
        showCmeLink(){
            return !this.isUccState && !this.isAnonymousUser; 
        },
        navRegisterUrl() {
            return `${this.loginHref}?source=header`;
        }
    },
    methods: {
        ...mapMutations('app', [ SET_MYACCOUNT_SIDEBAR_REQUEST ]),
        ...mapMutations('search', [ SET_MOBILE_WEB_SEARCH_INTERACTION ]),
        ...mapMutations('login', [
            SET_LOGIN_ERROR_MESSAGE,
            SET_LOGIN_INFO_MESSAGE
        ]),
        ...mapActions('account', [ 'getMyAccountInfo' ]),
        collapseExpandedSearch() {
            if (!this.isMobileWebSearchExpanded) { 
                return;
            }
            this[SET_MOBILE_WEB_SEARCH_INTERACTION](false);
        },
        buildTocUrl(item) {
            const contentsPrefix = item.hasContentsPrefix ? '/contents/' : '';

            if (item.notLocalized) {
                return contentsPrefix + item.url;
            }

            const prefixedItemUrl = `${item.hasTocPrefix ? this.tocPrefix : ''}${item.url}`;
            const localizedUrl = this.localizeAnchor(contentsPrefix, prefixedItemUrl, this.language);

            if (item.source) {
                return setQueryParamValue(localizedUrl, 'source', item.source);
            }

            return localizedUrl;
        },
        showAnkiDownloadInfo() {
            this.logUiClickEvent('ankiDownloadInfo');
            this.$refs.ankiDownloadRef.openModal();
        },
        logUiClickEvent(element) {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: `accountMenu_${element}`
            });
        },
        async openMyAccountFlyout($event) {
            $event && $event.preventDefault();
            if (this.isDisasterRecovery) {
                this.$refs.myAccountModalErrorRef.openModal();
                return;
            }
            await this.getMyAccountInfo();
            this[SET_MYACCOUNT_SIDEBAR_REQUEST](C_MY_ACCOUNT.SIDEBAR_REQUEST.SHOWN);
            if (this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.UNREGISTERED) {
                this.$refs.myAccountModalErrorRef.openModal();
            }
            else if (this.myAccountAccessLevel === C_MY_ACCOUNT.ACCESS_LEVELS.NOT_LOGGED_IN) {
                this[SET_MYACCOUNT_SIDEBAR_REQUEST](C_MY_ACCOUNT.SIDEBAR_REQUEST.PENDING);
                this[SET_LOGIN_INFO_MESSAGE]({
                    title: C_ACCOUNT.FORM_MESSAGING.ERROR_TITLE_LOGIN_REQUIRED,
                    error: C_ACCOUNT.FORM_MESSAGING.ERROR_MESSAGE_MY_ACCOUNT_LOGIN,
                    level: 'warn'
                });
                this.router.url('/login');
            }
            else {
                this.$refs.myAccountRef.open();
                await this.$nextTick();
                this.$refs.myAccountRef.$el.focus();
            }
        }
    }
 
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
.home-account-menu {
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  border-left: @DS1-UTD-GRAY-SINGLE-BORDER;

  * {
    box-sizing: border-box;
  }

  > ul {
    list-style: none;
    .ds1-pa-0();
    .ds1-ma-0();

    > li {
      > a, > .menu-title {
        .ds1-ph-2();
        display: block;
      }

      .menu-title {
        padding-top: 12px;
        padding-bottom: 12px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 20px;
        color: @DS1-UTD-SUB-TEXT-COLOR;
      }

      > a {
        .ds1-pv-1();
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;
        color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;

        &::after {
          position: absolute;
          top: 8px;
          color: @DS2-UTD-MENU-LINK-TEXT-COLOR;
          right: 16px;
          content: '\e911';
          font-family: "wk-icons-open";
        }

        &#ot-sdk-btn.ot-sdk-show-settings {
          .ds1-pl-2();
          color: @DS2-UTD-MENU-LINK-TEXT-COLOR !important; // no other wa to remove this
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          border: none !important; // no other way to fix this

          &:hover {
            background-color: @WKCE-WHITE !important; // no other way to fix this
          }

          &:focus {
            outline: 2px solid @WKCE-BLACK;
          }
        }
      }
    }

    &::after {
      display: block;
      content: "";
      width: calc(100% - 32px);
      margin: 4px auto;
      height: 1px;
      background-color: @UTD-LEGACY-WHITE-TINT5;
    }
  }
}
</style>