<template>
  <div class="wk-media-collection">
    <div v-if="title"
         class="wk-media-title ds1-utd-size-2"
         role="heading"
         aria-level="1">{{ title }}</div>
    <div class="collection-links">
      <utd-graphic-link v-for="(media, idx) in mediaCollection"
                        :key="media.imageKey || idx"
                        :graphic="media"
                        @utd-graphic-link-click="showModal($event, media)" />
    </div>
    <div ref="collectionThumbnails" class="collection-thumbnails" role="list">
      <utd-thumbnail v-for="(media, idx) in mediaCollection"
                     :id="`image-${formatId(media.imageKey)}`"
                     :key="media.imageKey || idx"
                     tabindex="0"
                     role="listitem"
                     :large="withLargeThumbnails"
                     :graphic="media"
                     :has-border="true"
                     @utd-thumbnail-click="showModal($event, media)" />
    </div>
  </div>
</template>

<script>
// USAGE:
// By default, component will render ".collection-links" div as vertical list of UtdGraphicLink.
// Apply class "collection-type-thumbnails" to component to display collection as
// UtdThumbnails via the ".collection-thumbnails" div.
// Apply class "collection-display-row" to component to display collection
// in a wrapping horizontal list.


import UtdGraphicLink from '_acaSrc/components/shared/stdlib/UtdGraphicLink.vue';
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';
import { mapGetters, mapActions } from 'vuex';
import { makeGraphicUrl } from '_acaSrc/utility/contents/graphic/graphic';
import { setSearchUrlParamsHelper, getQueryParamValues } from '_acaSrc/utility/http';
import { getWindow } from '_acaSrc/utility/DOM';

export default {
    components: {
        UtdGraphicLink,
        UtdThumbnail
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        mediaCollection: {
            type: Array,
            default: () => []
        },
        skipSidebarStateReset: {
            type: Boolean,
            default: true
        },
        withLargeThumbnails: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'onGvdInit' ],
    data: () => ({
        lastMedia: null
    }),
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('graphic', [ 'graphicViewerVisible' ])
    },
    watch:{
        graphicViewerVisible(newval){
            if (newval == false && this.lastMedia) {
                this.onModalDismiss();
            }
        }
    },
    methods: {
        ...mapActions('graphic', [ 'launchGraphicViewerModal' ]),
        async showModal(event, media) {
            const { rankIndex, searchRank, tabTitle, accordionTitle } = media;
            let mediaUrl = makeGraphicUrl(media);
            let searchTerm = media.searchTerm;
            this.lastMedia = media.imageKey;
            if (!searchTerm) {
                searchTerm = getQueryParamValues(getWindow().location.href, 'search');
            }

            mediaUrl = setSearchUrlParamsHelper(mediaUrl, {
                rankIndex, searchRank, searchTerm, tabTitle, accordionTitle
            });

            event.preventDefault();

            if (!this.isDesktopView) {
                getWindow().location.assign(mediaUrl);
                return false;
            }

            this.$emit('onGvdInit');
            await this.launchGraphicViewerModal({
                imageKey: media.imageKey,
                imageUrl: mediaUrl,
                skipSidebarStateReset: this.skipSidebarStateReset
            });
        },
        formatId(id){
            return id.replace('/', '-');
        },
        onModalDismiss() {
            const formatted= this.formatId(this.lastMedia);
            const image = this.$refs.collectionThumbnails.querySelector(`#image-${formatted}`);
            image.contentEditable = true;
            image.focus();
            image.contentEditable = false;
            this.lastMedia = null;
        }
    }
};

</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-media-collection {
  .collection-links {
    display: flex;
    flex-direction: column;

    :deep(.utd-graphic-link__container) {
      .ds1-mb-1();
    }
  }

  .collection-thumbnails {
    display: none;
    flex-direction: column;
    align-items: center;
  }

  &.collection-type-thumbnails {
    .collection-links {
      display: none;
    }

    .collection-thumbnails {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &.collection-display-row {
    .collection-thumbnails,
    .collection-links {
      flex-direction: row;
    }
  }
}
</style>