<template>
  <div v-if="searchBarBody"
       v-show="!isUtdHeaderNavVisible()"
       id="new-search"
       class="utd-search"
       :class="newSearchClass">
    <div class="hero-logo" />
    <div class="new-search-box">
      <utd-content-searchbar />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { SET_MOBILE_WEB_SEARCH_INTERACTION } from '_acaSrc/store/search.store';
import UtdContentSearchbar from '_acaSrc/components/contents/search/SearchBar.vue';

export default {
    components: {
        UtdContentSearchbar
    },
    computed: {
        ...mapGetters('app', [
            'isOidcSearchLanding',
            'isProspectView',
            'hasHeader'
        ]),
        ...mapGetters('device', [
            'isBrowserTypeSmallScreen',
            'isMobileOnDesktop'
        ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('feature', [ 'hideNavBar', 'isHeaderRedesign' ]),
        ...mapGetters('search', [ 'searchBarBody', 'searchBarHeader' ]),
        newSearchClass() {
            return {
                hideWidget: this.permissions.myUpToDate
            };
        }
    },
    mounted() {
        this[SET_MOBILE_WEB_SEARCH_INTERACTION](false);
    },
    methods: {
        ...mapMutations('search', [ SET_MOBILE_WEB_SEARCH_INTERACTION ]),
        isUtdHeaderNavVisible() {
            // Method shows when utd header nav bar fit all window size.
            // Used to fix CESUS-1535 issue
            return !this.isHeaderRedesign
                && !this.isProspectView
                && !this.isOidcSearchLanding
                && this.hasHeader
                && !this.searchBarHeader
                && !this.hideNavBar;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) "~_acaAssets/global/variables";

@UTD-SEARCH-BAR-DESKTOP-HEIGHT: 54px;

.utd-search {
  margin: 8px 16px 0;
  /* stylelint-disable-next-line color-no-hex */
  background: #f8f8f8;
  border-radius: 4px;
  position: relative;

  :deep(.hasfocus .search-bar) {
    left: auto !important;
    right: auto !important;
  }

  &.hideWidget {
    padding-bottom: 14px;
  }

  :deep(.search-bar) {
    width: 100%;
    left: auto;
    right: auto;
    top: -1px;
    position: relative;
  }

  h3 {
    font-weight: 300;
    color: @UTD-LEGACY-DARK-GRAY;
    margin: 10px 0;
  }

  :deep(.siyol-link) {
    position: absolute;
    top: 45px;
    right: 60px;
    font-size: 0.8em;
    color: @WKCE-GRAY-TINT1;
  }

  :deep(.newsearch-submit) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .utdWkHeader & {
    display: block;
    background: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 43px;
    margin-bottom: 0;
    width: auto;

    :deep(.search-bar-ac-unit) {
      position: relative;

      .autocomplete {
        top: 52px;
        left: 16px;
        right: 40px;
      }
    }

    h3 {
      display: none;
    }

    :deep(.search-icon) {
      display: none;
    }

    :deep(.search-bar) {
      height: 40px;

      .utdBarSearchCtrlContainer .utdBarSearchCtrl {
        padding-right: 32px;
      }
    }
  }
}

.show-personalization-widget {
  .ds1-utd-js2-link();
  display: none;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (min-aspect-ratio: ~"4/3") {
  .isTablet.utdWkHeader {
    #new-search {
      .show-personalization-widget {
        display: inline-block;
        font-size: 15px;
        margin-top: 1px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .wkProspect .isDesktop {
    &:not(.hasPromo) .utd-search {
      margin-bottom: 300px;
    }
  }

  .isDesktop {
    .hero-logo {
      background: url('~_acaAssets/utd-menu/hero-logo.svg') no-repeat;
      background-position: center;
      height: 64px;
      margin: 4vh auto;
    }

    &.utd-logo-cc .hero-logo {
      background: url('~_acaAssets/utd-menu/hero-logo-ucc.svg') no-repeat;
      background-position: center;
    }

    .utd-search {
      display: block;
      width: 100%;
      max-width: 768px;
      margin: auto;

      :deep(.search-bar .utdBarSearchCtrlContainer) {
        .utdBarSearchCtrl {
          .wkProspect & {
            position: relative;
          }
        }
      }

      :deep(.hasfocus .search-bar) {
        left: auto !important;
        right: auto !important;
      }

      .show-personalization-widget {
        display: inline-block;
        font-size: 11px;
        margin-top: 7px;
      }
    }

    .search-bar {
      height: @UTD-SEARCH-BAR-DESKTOP-HEIGHT;
    }
  }

  .utd-search {
    :deep(.search-bar-ac-unit) {
      .autocomplete {
        top: 58px;
        left: 24px;
        right: 58px;
      }
    }

    .utdWkHeader & {
      :deep(.search-bar-ac-unit) {
        .autocomplete {
          top: 69px;
          left: 28px;
          right: 58px;
        }
      }
    }
  }
}
</style>
