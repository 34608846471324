import {
    collapseSection as runCollapseAnimation,
    expandSection as runExpandAnimation
} from '_acaSrc/utility/animations/expand-collapse';
import { getElementStyleProperty, getDocument, wrap } from '_acaSrc/utility/DOM';
import { addClickEventAccessible } from '_acaSrc/utility/Events';
import { throttle } from '_acaSrc/utility/timers';

const dbiToggleAllBtn = '<button class="collapsible-indication-toggle-all" aria-label="Expand All Items" aria-expanded="false"><span /></button>';
const titleSelector = '.collapsible-indication-title, .collapsible-title';
const toggleBtnSelector = '.collapsible-indication-toggle-all';
const ciSelector = '.collapsible-indication';
const contentClass = 'collapsible-indication-wrap';
const contentSelector = `.${contentClass}, .collapsible-wrap`;
const contentFrameClass = `${contentClass}-frame`;
const contentFrameSelector = `.${contentFrameClass}`;
const ibnAccordionClass = 'brand-names-accordion';
const TITLE_DATE_SPACER = '\xa0\xa0\xa0\xa0';
const THROTTLE_MS = 200;

export function rebindCollapsibleIndicationLinks({ openAll, onClick } = {}) {
    const elList = Array.from(getDocument().querySelectorAll(ciSelector));
    const collapsibleElList = Array.from(getDocument().querySelectorAll('.collapsible'));
    const indicationSectionsArray = getIndicationSectionsArray(elList, ciSelector);
    const collapsibleSectionArray = getIndicationSectionsArray(collapsibleElList, '.collapsible');
    if (openAll) {
        expandAll(elList);
        expandAll(collapsibleElList);
    }

    const concatArrays = indicationSectionsArray.concat(collapsibleSectionArray);
    onClick = typeof onClick === 'function' ? onClick : () => 0;
    concatArrays.forEach(sectionElList => {
        bindCollapsibleIndicationAction(sectionElList, onClick);
        !openAll && expandCollapseSection(sectionElList);
    });
}

const expandCollapseSection = sectionElList => {

    if (sectionElList.length === 1) {
        expandAll(sectionElList);
    }
    else {
        collapseAll(sectionElList);
    }
};

const showExpandCollapse = (index, indicationsArrLength) => {
    return index === 0 && indicationsArrLength > 1;
};

const bindCollapsibleIndicationAction = (elList, onClick) => {
    elList.forEach((parentEl, i, indicationsArr) => {
        let uiElementName = '';
        if (parentEl.classList.contains(ibnAccordionClass)) {
            uiElementName = 'international-brand-names-accordion';
        }
        else if (parentEl.classList.contains('collapsible')) {
            uiElementName = 'collapsible-accordion';
        }
        else {
            uiElementName = 'dosing-by-indication-accordion';
        }

        if (showExpandCollapse(i, indicationsArr.length)) {
            parentEl.insertAdjacentHTML('afterBegin', dbiToggleAllBtn);

            parentEl.querySelector(toggleBtnSelector)
                .addEventListener('click', throttle(() => {
                    const actionTaken = onClickToggleAll(elList);
                    onClick({
                        action: actionTaken,
                        uiElementName
                    });
                }, THROTTLE_MS));
        }

        const titleEl = parentEl.querySelectorAll(titleSelector)[0];
        const dateEl = parentEl.querySelector('.collapsible-date');
        const id = titleEl.getAttribute('data-indication-id');

        if (dateEl) {
            dateEl.innerText = `${TITLE_DATE_SPACER}${dateEl.innerText}`;
            titleEl.appendChild(dateEl);
        }

        setAccordionIndent(parentEl);
        addClickEventAccessible(titleEl, throttle(e => {
            e.preventDefault();
            e.stopPropagation();
            const actionTaken = toggleAccordion(parentEl, elList);
            onClick({
                id,
                title: titleEl.innerText && titleEl.innerText.trim(),
                action: actionTaken,
                uiElementName
            });
        }, THROTTLE_MS));

        const contentId = `content-${id}`;
        titleEl.setAttribute('aria-controls', contentId);
        titleEl.setAttribute('tabindex', '0');
        titleEl.setAttribute('role', 'button');

        const contentEl = parentEl.querySelectorAll(contentSelector)[0];

        contentEl.setAttribute('tab-index', '-1');
        contentEl.setAttribute('role', 'region');
        contentEl.setAttribute('id', contentId);
    });
};

const getIndicationSectionsArray = (elements, selector) => {
    // In case we have multiple indication selections sections on page
    // we create array with section like [[item, item], [item]]
    const sectionsArray = [];
    let sectionsArrayIndex = 0;
    elements.forEach((elem, index) => {
        if (index !== 0 && !elem.matches(`${selector} + ${selector}`)) {
            sectionsArrayIndex++;
        }
        if (!Array.isArray(sectionsArray[sectionsArrayIndex])) {
            sectionsArray[sectionsArrayIndex] = [];
        }
        sectionsArray[sectionsArrayIndex].push(elem);
    });
    return sectionsArray;
};

const defaultOptions = { skipUpdateAllClasses: false };
const setCollapsedState = (el, elList, { skipUpdateAllClasses } = defaultOptions) => {
    const frameEl = getSetFrameEl(el);
    if (!isCollapsed(el)) {
        runCollapseAnimation(frameEl);
    }
    const expandAllButton = el.querySelector('.collapsible-indication-toggle-all');

    el.classList.add('collapsed');

    if(expandAllButton) {
        expandAllButton.setAttribute('aria-expanded', 'false');
    }

    el.querySelectorAll(titleSelector)[0]
        .setAttribute('aria-expanded', 'false');

    if (skipUpdateAllClasses) {
        return;
    }
    updateToggleAllClasses(elList);
};

const setExpandedState = (el, elList, { skipUpdateAllClasses } = defaultOptions) => {
    const frameEl = getSetFrameEl(el);
    if (!isExpanded(el)) {
        runExpandAnimation(frameEl);
    }
    const expandAllButton = el.querySelector('.collapsible-indication-toggle-all');

    el.classList.remove('collapsed');

    if(expandAllButton) {
        expandAllButton.setAttribute('aria-expanded', 'true');
    }

    el.querySelectorAll(titleSelector)[0]
        .setAttribute('aria-expanded', 'true');
    if (skipUpdateAllClasses) {
        return;
    }
    updateToggleAllClasses(elList);
};

const updateToggleAllClasses = elList => {
    if (isAllIndicationsExpanded(elList)) {
        elList.forEach(el => el.classList.add('all-expanded'));
    }
    else {
        elList.forEach(el => el.classList.remove('all-expanded'));
    }
};

const collapseAll = elList => elList.forEach(el => {
    setCollapsedState(el, elList, { skipUpdateAllClasses: true });
    el.classList.remove('all-expanded');
});

const expandAll = elList => elList.forEach(el => {
    setExpandedState(el, elList, { skipUpdateAllClasses: true });
    el.classList.add('all-expanded');
});

const onClickToggleAll = elList => {
    if (isAllIndicationsExpanded(elList)) {
        collapseAll(elList);
        return 'collapse-all';
    }
    expandAll(elList);
    return 'expand-all';
};

const toggleAccordion = (el, elList) => {
    // indication is expanded by default.
    if (el.classList.contains('collapsed')) {
        setExpandedState(el, elList);
        return 'expand';
    }

    setCollapsedState(el, elList);
    return 'collapse';
};

function isAllIndicationsExpanded(elList) {
    return !elList.some(el => {
        return el.classList.contains('collapsed');
    });
}

// A simple frame element is used to simplify animation math since the child has
// padding, margin, etc.
const getSetFrameEl = el => {
    let frameEl = el.querySelector(contentFrameSelector);
    if (frameEl) {
        return frameEl;
    }

    frameEl = wrap(el.querySelectorAll(contentSelector)[0]);
    frameEl.classList.add(contentFrameClass);
    return frameEl;
};

const isCollapsed = el => el.classList.contains('collapsed');
const isExpanded = el => !isCollapsed(el);

const setAccordionIndent = el => {
    if (!el) {
        return;
    }

    const contentWrapEl = el.querySelectorAll(contentSelector)[0];
    if (contentWrapEl) {
        // eslint-disable-next-line max-len
        const elMarginLeft = getElementStyleProperty(contentWrapEl.firstElementChild, 'margin-left');
        if (elMarginLeft) {
            el
            && el.firstChild
            && el.firstChild.nextElementSibling
            && el.firstChild.nextElementSibling
                .setAttribute('style', `padding-left: ${elMarginLeft}`);
        }
    }
};

export const _setAccordionIndent = setAccordionIndent; // For unit tests