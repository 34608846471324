<template>
  <div v-if="!hideForOidcPartner" data-utd-content-searchbar>
    <div id="search-combobox"
         class="search-bar-ac-unit"
         role="combobox"
         aria-haspopup="listbox"
         aria-owns="search-listbox"
         :aria-expanded="!!isExpanded">
      <div :class="[searchClass]" class="search-bar-container"><!--
       --><button v-if="searchBarHeader"
                  class="search-bar-left automation_searchBarMobileBackBtn"
                  @click="goBack" /><!--
       --><div class="search-bar">
            <form role="search"
                    name="searchForm"
                    aria-label="Sitewide"
                    novalidate
                    @submit="performSearch(isValid, false, $event)">
            <span v-if="!isHomepageRedesign2024" class="search-icon" />
            <div class="utdBarSearchCtrlContainer">
                <input id="tbSearch"
                        type="search"
                        spellcheck="false"
                        role="combobox"
                        class="utdBarSearchCtrl term searchTerm"
                        :placeholder="$t('SEARCH.SEARCH_UPTODATE')"
                        data-autocorrect="off"
                        aria-labelledby="search-uptodate"
                        :aria-expanded="!!isExpanded"
                        aria-controls="search-listbox"
                        aria-autocomplete="list"
                        data-autocapitalize="off"
                        autocomplete="off"
                        :aria-activedescendant="activeAutocompleteEleId"
                        autocapitalize="off"
                        autocorrect="off"
                        required
                        :value="searchParamsSearchText"
                        @input="onTbSearchInput($event)"
                        @keyup="onKeyUp($event)"
                        @keydown="onKeyDown($event)"
                        @focus="onFocus()"
                        @blur="onBlur()"
                        @click="onClick()">
            </div>
            <clear-input-box id="clearSearch"
                             :aria-expanded="!!isExpanded"
                            :control="searchParamsSearchText"
                            outer-class="search-clear"
                            :clear-fn="() => clearSearch()" />
            <input id="searchControl"
                v-model="searchControlModel"
                type="hidden"
                name="searchControl"
                value="TOP_PULLDOWN">
            <input id="source"
                v-model="sourceModel"
                type="hidden"
                name="source"
                value="USER_INPUT">
            <input id="searchType"
                v-model="searchTypeModel"
                type="hidden"
                name="searchType"
                value="PLAIN_TEXT">
            <span class="newsearch-submit"
                tabindex="0"
                role="button"
                aria-label="Submit search"
                @keypress="onSubmitKeypress($event)"
                @click="performSearch(isValid)" />
          </form>
        </div><!--
      --><a v-if="searchBarHeader" href="/contents/search" class="search-bar-right"><!--
          --><span class="utd-menu-icon" /><!--
      --></a>
      </div>
      <AutosuggestDropdownList v-show="searchBarAutoComplete && ac.results"
                               ref="acContainer"
                               :class="{'backtosearch': isBackToSearch}"
                               :list="autocompleteList()"
                               :active-index="selectedAcIndex" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
    goBack,
    hideKeyboard,
    elGetSetText,
    getWindow,
    decodeHtml,
    retainElementFocus,
    getDocument
} from '_acaSrc/utility/DOM';
import { setQueryParamValue, getQueryParamValues, safeDecodeHtmlUriComponent }
    from '_acaSrc/utility/http';
import { C_KEYCODES } from '_acaSrc/utility/constants';
import { throttleBounce } from '_acaSrc/utility/timers';
import { Listener } from '_acaSrc/utility/Events';
import { incrementCarousel } from '_acaSrc/utility/math';
import ClearInputBox from '_acaSrc/components/ui/ClearInputBox.vue';
import AutosuggestDropdownList from './autocomplete/AutocompleteDropdownList.vue';
import * as acAdapter from '_acaSrc/utility/data-adapters/search-autocomplete-adapter';
import { recodeSearchPlusToSpace } from '_acaSrc/utility/contents/search/search';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';

import {
    SET_TOOLTIP_HIDETIP,
    SET_TOOLTIP_SHOW,
    RESET_TOOLTIP
} from '_acaSrc/store/profile.store';
import {
    SET_SEARCH_PARAMS_AUTO_COMPLETE,
    SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM,
    SET_SEARCH_PARAMS_CONTROL,
    SET_SEARCH_PARAMS_INDEX,
    SET_SEARCH_PARAMS_SOURCE,
    SET_SEARCH_PARAMS_TEXT,
    SET_SEARCH_PARAMS_TYPE,
    SET_SEARCHBAR_AUTOCOMPLETE,
    SET_MOBILE_WEB_SEARCH_INTERACTION
} from '_acaSrc/store/search.store';
import { sterilizeString } from '_acaSrc/utility/String';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';

const selectedTitleDelimiter = '---';

const getDisplayRank = (idx, len) => `${idx + 1}~${len}`;

const { LEFT_ARROW, DOWN_ARROW, UP_ARROW, ESCAPE_KEYCODE, ENTER_KEY, RIGHT_ARROW,
    HOME_KEYCODE, END_KEYCODE, TAB_KEY } = C_KEYCODES;

const DRUG_SUBSECTION_HIT_TYPE = 'DRUG_SUBSECTION';

const AUTO_COMPLETE_URL = '/contents/search/autocomplete/json';
const AUTO_COMPLETE_DEBOUNCE_DELAY_MS = 200;
const SEARCH_BAR_FOCUS_DELAY_MS = 100;

export const DRUG_INTERACTION_HIT_TYPE = 'DRUG_INTERACTION';

const NEW_SEARCH_INPUT_SELECTOR = '#new-search #tbSearch';
const DRUG_SECTION_AVAILABILITY = [ 'CAN', 'CA', 'USA', 'US' ];

export default {
    components: {
        ClearInputBox,
        AutosuggestDropdownList
    },
    data() {
        return {
            selectedAcIndex: -1,
            ac: {
                results: '',
                experimentClasses: '',
                lastUrl: ''
            },
            drugSections: {
                results: []
            },
            drugSectionLimit: 4,
            acFilteredLimit: 4,
            acFiltered: {
                lastUrl: '',
                results: ''
            },
            searchClass: '',
            track: {
                isFromAC: false,
                previousKey: '',
                nextKey: '',
                lastUserTypedTerm: ''
            },
            activeAutocompleteEleId: '',
            unbindDismiss: () => 0,
            urlParamFixTimeoutId: null,
            lastWindowSize: {
                lastHeight: null,
                lastWidth: null
            },
            resizeListener: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'router',
            'isAuthenticatedByIp',
            'isAlwaysShowBackButton',
            'hideForOidcPartner',
            'isProspectMode',
            'contentVersion',
            'isLapsedUser'
        ]),
        ...mapGetters('search', [
            'searchParams',
            'searchParamsSearchText',
            'searchParamsType',
            'searchParamsSource',
            'searchParamsControl',
            'searchParamsAutoComplete',
            'searchBarBody',
            'searchBarHeader',
            'searchBarAutoComplete',
            'searchBarAutoCompleteLimit',
            'searchBarDisableAc',
            'searchFor'
        ]),
        ...mapGetters('topic', [ 'isTopicView' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('device', [
            'isBrowserTypeDesktop',
            'isNotDesktopView',
            'isDesktopView',
            'isMobileOnDesktop'
        ]),
        ...mapGetters('user', [ 'language' ]),
        ...mapGetters('feature', [
            'featureValue',
            'showBackToSearch',
            'isHeaderRedesign',
            'featureAttributes',
            'isHomepageRedesign2024'
        ]),
        autoComplete() {
            return this.searchBarAutoComplete;
        },
        isValid() {
            return this.searchParams
            && this.searchParamsSearchText
            && this.searchParamsSearchText.trim().length > 0;
        },
        isDrugSectionsEnabled() {
            return !this.isLapsedUser
                && (DRUG_SECTION_AVAILABILITY.includes(this.featureAttributes.geoIpCountry)
                || (!this.featureAttributes.geoIpCountry
                    && DRUG_SECTION_AVAILABILITY.includes(
                        this.featureAttributes.accountCountryCode
                    ))
                );
        },
        isShowFilteredACHeader() {
            return this.acFiltered.results.length > 0
                && this.permissions.hasFilteredAutoComplete;
        },
        isBackToSearch() {
            return (this.isAlwaysShowBackButton && !this.searchBarBody)
                || (this.showBackToSearch && this.isTopicView);
        },
        isExpanded() {
            return this.searchBarAutoComplete && this.hasDataToShow;
        },
        hasDataToShow() {
            // This has never checked acFiltered results, historically.
            return (this.ac.results && this.ac.results.length)
                    || this.drugSections.results.length;
        },
        drugSectionLinks() {
            const result = [];
            let count = 0;
            const limit = this.drugSectionLimit;
            this.drugSections.results.forEach(s => {
                if (count >= limit) {
                    // don't add any more links since would exceed limit
                    return;
                }
                // routed drugs count towards limit as N where N = num routes
                if (this.hasDrugRoutes(s)) {
                    const routesCount = this.drugRoutes(s).routes.length;
                    if (count + routesCount > limit) {
                        // don't add routed drug since would exceed limit
                        return;
                    }
                    count += routesCount;
                    result.push(s);
                    return;
                }
                count++;
                result.push(s);
                return;
            });
            return result;
        },
        drugRoutesCount() {
            if (!Array.isArray(this.drugSectionLinks)) {
                return 0;
            }
            return this.drugSectionLinks.reduce((acc, link) => {
                return acc + (link.drugRoutes ? link.drugRoutes.length : 0);
            }, 0);
        },
        acFilteredLinks() {
            if (this.acFiltered.results.length > this.acFilteredLimit) {
                return this.acFiltered.results.slice(0, this.acFilteredLimit);
            }
            return this.acFiltered.results;
        },
        searchControlModel: {
            get() {
                return this.searchParamsControl;
            },
            set(value) {
                this[SET_SEARCH_PARAMS_CONTROL](value);
            }
        },
        sourceModel: {
            get() {
                return this.searchParamsSource;
            },
            set(value) {
                this[SET_SEARCH_PARAMS_SOURCE](value);
            }
        },
        searchTypeModel: {
            get() {
                return this.searchParamsType;
            },
            set(value) {
                this[SET_SEARCH_PARAMS_TYPE](value);
            }
        }
    },
    watch: {
        autoComplete(newVal, oldVal) {
            const removedFromView = !newVal && oldVal !== newVal;
            if (removedFromView) {
                this.resetScrollPosition();
            }
        }
    },
    created() {
        const s = this.router.search().search;
        if (s) {
            this[SET_SEARCH_PARAMS_TEXT](safeDecodeHtmlUriComponent(recodeSearchPlusToSpace(s)));
        }
    },
    mounted() {
        new PubSub().subscribe('wkutd.dismissAutoComplete', this.clearAutoComplete);
        const tbSearch = document.querySelector(NEW_SEARCH_INPUT_SELECTOR);
        if (tbSearch) {
            tbSearch.setAttribute('readonly', true);
            setTimeout(() => {
                tbSearch.blur();
                tbSearch.focus();
                tbSearch.removeAttribute('readonly');
            }, SEARCH_BAR_FOCUS_DELAY_MS);
        }

        // TODO: Once we decommission old homepage, we can remove this retainElementFocus call
        this.$nextTick(() => {
            setTimeout(() => {
                if (!this.isHomepageRedesign2024) {
                    retainElementFocus(NEW_SEARCH_INPUT_SELECTOR);
                }
            }, 50);
        });
        this.setupCloseAcOnResize();

        this.urlParamFixTimeoutId = setTimeout(() => {
            const search = this.router.search().search;
            if (!this.searchParamsSearchText && search) {
                this[SET_SEARCH_PARAMS_TEXT](safeDecodeHtmlUriComponent(search));
            }
        }, 250);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.dismissAutoComplete', this.clearAutoComplete);
        clearTimeout(this.urlParamFixTimeoutId);
        this.clearListener(this.resizeListener);
    },
    methods: {
        ...mapMutations('profile', [
            SET_TOOLTIP_HIDETIP,
            SET_TOOLTIP_SHOW,
            RESET_TOOLTIP ]),
        ...mapActions('app', [
            'setListener',
            'clearListener'
        ]),
        ...mapActions('inlineCme', {
            cmeHandleSearch: 'handleSearch'
        }),
        ...mapActions('search', [
            'resetFromHL7Search'
        ]),
        ...mapMutations('search', [
            SET_SEARCH_PARAMS_TEXT,
            SET_SEARCH_PARAMS_INDEX,
            SET_SEARCH_PARAMS_SOURCE,
            SET_SEARCH_PARAMS_CONTROL,
            SET_SEARCH_PARAMS_AUTO_COMPLETE,
            SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM,
            SET_SEARCHBAR_AUTOCOMPLETE,
            SET_MOBILE_WEB_SEARCH_INTERACTION
        ]),
        goBack: () => goBack(),
        resetScrollPosition() {
            if (this.$refs && this.$refs.acContainer) {
                this.$refs.acContainer.scrollTop = 0;
            }
        },
        onTbSearchInput(event) {
            if (this.isMobileOnDesktop
            && getDocument().querySelector('.my-account-sidebar') !== null) {
                new PubSub().publish('wkutd.closeMyAccountSidebar');
            }
            this.onMobileWebInteraction();
            this.track.lastUserTypedTerm = event.target.value;
            this[SET_SEARCH_PARAMS_TEXT](event.target.value);

            // As user has changed the search input, reset the index as
            // it will no longer be valid when new AC results are returned
            this.selectedAcIndex = -1;
        },
        // eslint-disable-next-line complexity
        performSearch(isValid, isFromAC = false, evt) {
            if (evt) {
                evt.preventDefault();
            }

            if (!isValid) {
                this[SET_SEARCH_PARAMS_TEXT]('');
                return;
            }

            // Escape input search field for comparison
            this[SET_SEARCH_PARAMS_TEXT](sterilizeString(this.searchParamsSearchText));

            // Determine what new "autoComplete" state will be...
            let newAc = this.searchParamsAutoComplete;
            if (this.searchFor !== this.searchParamsSearchText) {
                newAc = ((isFromAC || this.track.isFromAC) ? 'true' : 'false');
            }

            if (!isFromAC) {
                this[SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM]('');
                this[SET_SEARCH_PARAMS_INDEX]('');
            }

            // Determine if this is the same search user just performed
            // If it is, just reload last data set
            if (this.searchFor === this.searchParamsSearchText
                    && this.router.current().name === 'search'
                    && this.searchParamsAutoComplete === newAc) {
                this.router.reload();
            }
            else {
                this[SET_SEARCH_PARAMS_AUTO_COMPLETE](newAc);
                this.clearAutoComplete();
                this.resetFromHL7Search();

                hideKeyboard();

                this.router.go('search', this.searchParams);

                this[SET_SEARCH_PARAMS_TEXT](decodeHtml(decodeURIComponent(
                    encodeURIComponent(this.searchParamsSearchText))));
            }
        },
        onKeyUp($event) {
            this.getAutoComplete($event);
        },
        onKeyDown($event) {
            this.processKeyInput($event);
        },
        onFocus() {
            this.searchClass = 'hasfocus';
        },
        onBlur() {
            this.searchClass = '';
            if (this.isBrowserTypeDesktop
            || this.isHeaderRedesign
            || (this.isProspectMode && !this.isBrowserTypeDesktop)) {
                this.clearAutoComplete();
            }
        },
        onClick() {
            this.onMobileWebInteraction();
        },
        onMobileWebInteraction() {
            if (this.isDesktopView) {
                return;
            }
            this[SET_MOBILE_WEB_SEARCH_INTERACTION](true);
        },
        // eslint-disable-next-line complexity
        getAutoComplete(e) {
            if (this.isNotDesktopView) {
                this[SET_TOOLTIP_HIDETIP](true);
                this[SET_TOOLTIP_SHOW](false);
            }
            if (!this.searchBarDisableAc) {
                const IGNORED_KEYCODES = [ HOME_KEYCODE, END_KEYCODE, LEFT_ARROW, RIGHT_ARROW ];
                if (IGNORED_KEYCODES.indexOf(e.keyCode) > -1) {
                    return true;
                }

                if (e.keyCode !== UP_ARROW
                 && e.keyCode !== DOWN_ARROW
                 && e.keyCode !== ESCAPE_KEYCODE
                 && e.keyCode !== TAB_KEY) {
                    const term = this.searchParamsSearchText;
                    if (typeof term !== 'undefined' && term.trim() !== '') {
                        this[SET_SEARCH_PARAMS_AUTO_COMPLETE_TERM](term);
                        const url = `${AUTO_COMPLETE_URL
                        }?term=${encodeURIComponent(term)
                        }&limit=${this.searchBarAutoCompleteLimit}`;

                        return this.throunceAutoComplete(AUTO_COMPLETE_URL, url);
                    }
                    this.acFiltered.results = '';
                    this.clearAutoComplete();
                }
            }
        },
        processKeyInput(e) {
            try {
                // Ignore certain keys
                if ([ END_KEYCODE, HOME_KEYCODE, LEFT_ARROW, RIGHT_ARROW ]
                    .includes(e.keyCode)) {
                    return true;
                }

                this.trackPreviousKeypress(e);

                if (e.keyCode === DOWN_ARROW || e.keyCode === UP_ARROW) {
                    this.handleACKeyboardNav(e);
                }
                else if (e.keyCode === ESCAPE_KEYCODE) {
                    this.clearAutoComplete();
                }
                else if (e.keyCode === ENTER_KEY) {
                    this.handleACEnterKey(e);
                }
            }
            catch (error) {
                Logger.error(`Error processing key input with key=${e.keyCode} error:`, error);
            }
        },
        clearAutoComplete() {
            this[RESET_TOOLTIP]();
            this.updateSearchbarAutocomplete('');
            this.ac.results = '';
            this.selectedAcIndex = -1;
        },
        updateSearchbarAutocomplete(newValue = '') {
            this[SET_SEARCHBAR_AUTOCOMPLETE](newValue);
        },
        processACclick(term, index) {
            this[SET_SEARCH_PARAMS_INDEX](`${index}~${this.ac.results.length}`);
            this[SET_SEARCH_PARAMS_TEXT](sterilizeString(term));
            this.clearAutoComplete();
            this.performSearch(true, true);
        },

        // eslint-disable-next-line complexity
        async processAcFilteredClick(e, url) {
            if (!e) {
                return;
            }
            const targetAttr = e.target.getAttribute('target');
            const showInNewTab = targetAttr && targetAttr !== '_self';
            if (showInNewTab && e.type === 'click') {
                return true;
            }
            e && e.preventDefault();
            if (showInNewTab && e.type === 'mousedown') {
                return false;
            }
            hideKeyboard();
            if (!url) {
                url = this.getRedirectUrl(e);
            }
            this.clearAutoComplete();

            if (url && getQueryParamValues(url, 'source') === 'auto_suggest') {
                const selectedTitle = getQueryParamValues(url, 'selectedTitle') || '';
                const split = selectedTitle.split(selectedTitleDelimiter);
                if (split && split.length > 2) {
                    const search = split[2];
                    await this.cmeHandleSearch(search);
                }
            }

            this.router.url(url);
        },
        getRedirectUrl(e) {
            let url = e.target.getAttribute('href');
            let search = url ? getQueryParamValues(url, 'search') : '';
            search = sterilizeString(search || this.searchParamsSearchText);
            url = url && setQueryParamValue(url, 'search', search);
            return url;
        },
        throunceAutoComplete(index, url) {
            if (typeof this.throunceAutoCompleteFn === 'function') {
                return this.throunceAutoCompleteFn(index, url);
            }

            this.throunceAutoCompleteFn = throttleBounce((index, url) => {
                if (url !== this.ac.lastUrl) {
                    return utdRest('search/autoComplete', url)
                        .then(this.resolveAutoComplete)
                        .then(this.resolveAutoCompleteFiltered)
                        .catch(this.resolveError);
                }
            }, 'searchbar-ac', AUTO_COMPLETE_DEBOUNCE_DELAY_MS);

            return this.throunceAutoCompleteFn(index, url);
        },
        checkAutoCompleteExperiment(data) { // Optimizely-provided ac results
            const acDataSet = getWindow().acDataSet || [];

            acDataSet.forEach(experimentItem => {
                if (typeof experimentItem?.regex?.test === 'function' &&
                    experimentItem.regex.test(this.searchParamsSearchText)) {
                    if (!experimentItem.results ||
                        !Array.isArray(experimentItem.results)) {
                        return;
                    }

                    const validResults = experimentItem.results.filter(result => {
                        return result.hasOwnProperty('acText') && 
                               result.acText &&
                               typeof result.acText === 'string';
                    });
                    const acResults = validResults.map(result => {
                        return result.acText;
                    });
                    if (acResults.length === 0) {
                        return;
                    }
                    const acResultClasses = validResults.map(result => {
                        return result.class || '';
                    });

                    data.searchTerms = acResults;
                    data.experimentClasses = acResultClasses;
                }
            });
        },
        resolveAutoComplete(data) { // eslint-disable-line complexity
            try {
                this.checkAutoCompleteExperiment(data);
                this.ac.results = data.searchTerms;
                this.ac.experimentClasses = data.experimentClasses || [];

                data.drugSections = data.drugSections
                                 && data.drugSections.filter(this.drugSectionFilter);

                const drugHits = this.isDrugSectionsEnabled
                                    && data.drugSections
                                    || [];

                this.drugSections.results = this.shouldShowSubsections(drugHits)
                    ? this.flattenAutosuggestSubsections(drugHits)
                    : drugHits;

                this.ac.lastUrl = data.route;

                if (this.isAutoCompleteResolved()) {
                    this.logAutosuggestViewEvents();
                    this.handleACSessionCaching(data);
                    this.updateSearchbarAutocomplete('ac-open');

                    // Hiding the mobile hamburger menu and locking scroll used to
                    // happen here, but it was in a broken state for 2 years and
                    // nobody noticed, so it was removed (presumed not needed).
                    // Manual testing showed no noticable user impact.
                    return data;
                }

                // Otherwise clear if fails to resolve
                this.clearAutoComplete();
                return data;
            }
            catch (e) {
                /* noop */
            }
        },
        drugSectionFilter(hit) {
            const hasDrugSections = this.isDrugSectionsEnabled;
            return hasDrugSections
                && ((hit.hitType === 'UTD_TOPIC')
                || (hit.hitType === 'DRUG_INTERACTION')
                || (hit.hitType === 'DRUG_LANDING_PAGE'));
        },
        shouldShowSubsections(results) {
            return results.length < 2;
        },
        hasDrugRoutes(drug) {
            return drug.drugRoutes && drug.drugRoutes.length > 0;
        },
        drugRoutes(drug) {
            return {
                label: drug.title,
                routes: drug.drugRoutes.map(r => {
                    return { label: r.title, ...r };
                })
            };
        },
        isAutoCompleteResolved() {
            return this.hasDataToShow
                    && this.searchParamsSearchText
                    && (this.searchClass === 'hasfocus');
        },
        flattenAutosuggestSubsections(results) {
            return results.reduce((acc, hit) => {
                hit = { ...hit };
                acc.push(hit);
                if (!(Array.isArray(hit.drugSubSections) && hit.drugSubSections.length > 0)) {
                    return acc;
                }
                const transformedSubsections = hit.drugSubSections.map(s => {
                    const copy = { ...s };
                    delete copy.drugSubSections;
                    copy.hitType = DRUG_SUBSECTION_HIT_TYPE;
                    return copy;
                });
                delete hit.drugSubSections;
                return acc.concat(transformedSubsections);
            }, []);
        },
        resolveAutoCompleteFiltered(data) {
            this.acFiltered.results = data && data.searchResults || [];
            this.acFiltered.lastUrl = data && data.route || '';
            this.acFiltered.results.forEach((result, index) => {
                let url = result && result.url;
                if (url === null || url === undefined) {
                    return;
                }
                if (url.search(/\?/) > -1) {
                    url += '&';
                }
                else {
                    url += '?';
                }
                result.url
                    = `${url}source=autocomplete&index=${index}~${this.acFiltered.results.length}`;
            });
        },
        // eslint-disable-next-line complexity
        resolveError(error) {
            const errorStatus = error.status || error._status;
            if (errorStatus === 403
             || errorStatus === 500
             || errorStatus === '403'
             || errorStatus === '500') {
                return;
            }
            // Ignore when autocomplete requests get aborted
            if (errorStatus && errorStatus !== -1) {
                const term = this.searchParamsSearchText || 'searchParamsSearchText is undefined';
                Logger.warn('Error trying to display autocomplete results for '
                + `term [${term}] - error: ${error.errors} status: ${errorStatus}`);
            }
        },
        clearSearch() {
            new PubSub().publish('wkutd.closeMyAccountSidebar');
            this[SET_SEARCH_PARAMS_TEXT]('');
            this.track.lastUserTypedTerm = '';
            this.clearAutoComplete();
            const searchBar = document.querySelector('#tbSearch');
            if (searchBar) {
                searchBar.focus();
            }
        },
        onSubmitKeypress(evt) {
            if (evt.which !== 13) {
                return;
            }
            this.performSearch(this.isValid);
        },
        setupCloseAcOnResize() {
            // Mobile safari reports scrolling as a 'resize' event, as the
            // viewport height can change. Account for this by tracking the
            // width of the viewport to determine if it was a 'true' resize.
            this.lastWindowSize.lastWidth = document.documentElement.clientWidth;
            this.lastWindowSize.lastHeight = document.documentElement.clientHeight;

            // Handle dismissing auto complete on device orientation change or desktop resize
            this.resizeListener = new Listener(getWindow(), 'resize', () => {
                if (this.searchBarAutoComplete !== '' || this.ac.results !== '') {
                    const resizeResult = this.isTrueResizeEvent({
                        lastWidth: this.lastWindowSize.lastWidth,
                        lastHeight: this.lastWindowSize.lastHeight
                    });
                    if (resizeResult.isTrueResize) {
                        this.lastWindowSize.lastWidth = resizeResult.newWidth;
                        this.lastWindowSize.lastHeight = resizeResult.newHeight;
                        this.clearAutoComplete();
                    }
                }
            });
            this.setListener(this.resizeListener);
        },
        handleACKeyboardNav(e) {
            const prevIdx = this.selectedAcIndex;
            const acLi = Array.from(this.$refs.acContainer.$el.querySelectorAll('li.ac-navable'));

            if (acLi.length === 0) { // Abort if nothing to navigate to...
                return;
            }

            if (prevIdx < 0) {
                // Set entry to be selected, if nothing selected yet
                this.selectedAcIndex = e.keyCode === UP_ARROW ? acLi.length - 1 : 0;
            }
            else {
                const incr = e.keyCode === UP_ARROW ? -1 : 1;
                this.selectedAcIndex = incrementCarousel(prevIdx, incr, acLi.length);
            }

            // Handle updating term in input box when up/down arrow over just a suggestion
            let currentTerm = this.track.lastUserTypedTerm;
            if (acLi[this.selectedAcIndex].classList.contains('ac-search-suggestion')) {
                currentTerm = acLi[this.selectedAcIndex].innerText;
            }
            this[SET_SEARCH_PARAMS_TEXT](currentTerm);

            // Needed for UP_ARROW to prevent placing cursor at beginning of search string
            if (e.keyCode === UP_ARROW) {
                e.preventDefault();
            }
        },
        trackPreviousKeypress(e) {
            if (!this.track.previousKey) {
                this.track.previousKey = e.keyCode;
                this.track.currentKey = e.keyCode;
            }
            else {
                this.track.previousKey = this.track.currentKey;
                this.track.currentKey = e.keyCode;
            }
        },
        handleACEnterKey(e) {
            // We need to properly record autocomplete, so if the user only
            // used up and down before hitting enter, it came from
            // autocomplete
            this.track.isFromAC = this.track.previousKey === DOWN_ARROW
                               || this.track.previousKey === UP_ARROW;

            const autocompleteEle = this.$refs.acContainer.$el
                .querySelector('.autocomplete li.active');

            if (!autocompleteEle) {
                return;
            }
            if (autocompleteEle.classList.contains('ac-search-suggestion')) {
                const term = elGetSetText(autocompleteEle);
                const index = this.ac.results.indexOf(term);
                this.processACclick(term, index);
            }
            else {
                const innerAnchorEle = autocompleteEle.querySelector('a');
                if (innerAnchorEle) {
                    if (autocompleteEle.classList.contains(DRUG_INTERACTION_HIT_TYPE)) {
                        e.preventDefault();
                        innerAnchorEle.click();
                        return false;
                    }
                    const url = innerAnchorEle.getAttribute('href');
                    this.processAcFilteredClick(e, url);
                }
                return false;
            }
        },
        handleACSessionCaching(data) {
            if (this.searchParamsSearchText.length <= 3 && !data.isCached) {
                data.isCached = true;
                const key = 'utdSPAAutoComplete';
                new UtdQueuedCache().setPersistent(key, data.route, data, this.contentVersion);
            }
        },
        filterResults(searchSuggestions = []) {
            const MIN_RESULTS = 2;
            const MAX_AC_ITEMS = 10;
            const personalizedHitsCount = this.acFilteredLinks.length;
            const drugHitsCount = this.drugSectionLinks.length + this.drugRoutesCount;
            const TOTAL_SUGGESTIONS = searchSuggestions.length
                + drugHitsCount
                + personalizedHitsCount;

            if (searchSuggestions.length <= MIN_RESULTS || TOTAL_SUGGESTIONS <= MAX_AC_ITEMS) {
                return searchSuggestions;
            }

            const reduceBy = MAX_AC_ITEMS - drugHitsCount - personalizedHitsCount;

            return searchSuggestions.slice(0, Math.max(MIN_RESULTS, reduceBy));
        },
        logAutosuggestViewEvents() {
            if (!this.drugSections.results.length > 0) {
                return;
            }
            utdRest('showAutosuggestResults', {
                visibleAutosuggestItems: this.autocompleteList()
                    .filter(acDropdownValue => acDropdownValue.subType === 'AutosuggestHit')
                    .map(acDropdownValue => acDropdownValue.drugSectionLink)
                    .map((result, idx, list) => {
                        result.displayRank = getDisplayRank(idx, list.length);
                        result.input = this.searchParamsSearchText;
                        return result;
                    })
            });
        },
        decorateUrlAutosuggest(url, idx, result) {
            if (!url) {
                return url;
            }
            // Autosuggest needs multiple types of ranks and search terms for
            // analytics purposes. The query params for them are shared via
            // delimiter to reduce api churn just for this special case.
            const resultsLength = this.drugSectionLinks.reduce((count, item) => {
                return item.drugRoutes && item.drugRoutes.length > 0
                    ? count + item.drugRoutes.length
                    : count + 1;
            }, 0);
            const displayRank = getDisplayRank(idx, resultsLength);
            const rank = result.searchRank;
            const input = sterilizeString(this.searchParamsSearchText);
            const selectedTitle = `${rank}${selectedTitleDelimiter}${displayRank}`
                                + `${selectedTitleDelimiter}${encodeURIComponent(input)}`;
            url = setQueryParamValue(url, 'selectedTitle', selectedTitle);
            // If drug subsection, include parameter to indicate we should display the
            // drug name next to section header label in topic view
            if (result.hitType === DRUG_SUBSECTION_HIT_TYPE) {
                url = setQueryParamValue(url, 'showDrugLabel', 'true');
            }
            // Set the search to the full title since they picked an autocomplete term.
            // This is consistent with how noraml autocomplete works and affects the
            // prepopulated searchbox after navigation.
            return setQueryParamValue(url, 'search', result.searchTitle);
        },
        addDrugSectionItems(returnList) {
            if (this.isDrugSectionsEnabled && this.drugSectionLinks.length > 0) {
                returnList.push(acAdapter.makeGoToHeader()); // Adds drug "Go to" header
            }
            let drugLinksIdx = -1;
            this.drugSectionLinks.forEach(r => {
                if (this.hasDrugRoutes(r)) { // If routed drug, add routed header and routed link
                    returnList.push(acAdapter.makeRoutedDrugHeader(r));
                    this.drugRoutes(r).routes.forEach(route => {
                        drugLinksIdx++;
                        returnList.push(acAdapter.makeRoutedDrugSectionLink(
                            route,
                            this.decorateUrlAutosuggest(route.url, drugLinksIdx, route),
                            this.processAcFilteredClick
                        ));
                        returnList[returnList.length - 1].drugSectionLink = route;
                    });
                }
                else { // If non-routed drug, just add drug link
                    drugLinksIdx++;
                    returnList.push(acAdapter.makeDrugSectionLink(
                        r,
                        drugLinksIdx,
                        this.decorateUrlAutosuggest(r.url, drugLinksIdx, r),
                        this.processAcFilteredClick
                    ));
                    returnList[returnList.length - 1].drugSectionLink = r;
                }
            });
            return returnList;
        },
        addSearchSuggestionItems(returnList) {
            if (this.ac.results.length > 0) {
                returnList.push(acAdapter.makeSearchSuggestionsHeader());
                this.filterResults(this.ac.results).forEach((r, index) => {
                    returnList.push(acAdapter.makeSearchSuggestionLink(
                        r,
                        index,
                        this.processACclick,
                        { additionalClasses: this.ac.experimentClasses?.at(index) || '' }
                    ));
                });
            }
            return returnList;
        },
        addPersonalizedItems(returnList) {
            if (this.isShowFilteredACHeader) {
                returnList.push(acAdapter.makePersonalizedHeader());
            }
            if (this.permissions.hasFilteredAutoComplete
                    && Array.isArray(this.acFilteredLinks)) {
                this.acFilteredLinks.forEach((r, i) => {
                    returnList.push(acAdapter.makePersonalizedLink(
                        r, i, this.processAcFilteredClick
                    ));
                });
            }
            return returnList;
        },
        autocompleteList() {
            const returnList = [];
            this.addDrugSectionItems(returnList);
            this.addSearchSuggestionItems(returnList);
            this.addPersonalizedItems(returnList);
            return returnList;
        }
    }
};
</script>

<style lang="less">
  @import '~_acaAssets/search/searchbar.less';
</style>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
  @import (reference) '~_acaAssets/global/global.less';

  :deep(.autocomplete-ul) {
    .ac-drug-section-result,
    #ac-go-to-header {
      display: list-item;
    }

    #ac-search-suggestions-header:not(:first-child) {
      .ds1-mt-compact();
      border-top: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;
    }
  }

  .utdWkHomePage2024& #search__container #redesign-header .search-bar-container {
    .search-bar:hover {
      //Search Input
      .utdBarSearchCtrl {
        border: 3px solid @WKCE-BLUE-TINT1;
      }
    }

    //Search Input
    .utdBarSearchCtrl:focus {
      border: 2px solid @WKCE-BLUE-TINT1;
    }
    // Search Button
    .newsearch-submit:focus-visible {
      outline: 2px solid @WKCE-WHITE;
    }
  }

.utdWkHomePage2024 {
  header .ui-searchbar-header {
    .search-bar-container {
      background-color: @DS2-UTD-NAVBAR-BG-COLOR;
      box-shadow: none;
      padding: 14px 54px 14px 46px;
      height: 28px;

      &.hasfocus .search-bar-left, &.hasfocus .search-bar-right {
        opacity: 1;
        display: block;
      }

      .search-bar {
        left: 44px !important;
        right: 54px !important;
        height: 30px;
        padding-right: 58px;

        .utdBarSearchCtrlContainer .utdBarSearchCtrl {
          border-radius: 100px;
        }
      }

      .search-clear {
        right: 30px;
        font-size: 14px;
        line-height: 1;
        padding: 9px 12px;

        &::before {
          #utd-main {
            content: '\e865';
          }
        }
      }

      .newsearch-submit {
        display: block;
        background-color: @DS2-UTD-NAVABAR-SEARCH-SUBMIT-BG-COLOR;
        width: 29px;

        &::after {
          top: 8px;
        }
      }

      .search-bar-left {
        background: none  ;
        background-color: transparent;
        color: @DS2-UTD-NAVBAR-BARS-BG-COLOR;
        font-family: wk-icons-open;
        height: 56px;
        width: 44px;
        font-size: 16px ;
        line-height: 16px;
        left: 0;
        padding: 20px 14px;

        &:before {
          width: 16px;
          height: 16px;
          content: "";
          display: block;
        }
      }

      .search-bar-right {
        .ds1-mt-2();
        right: 0;
        width: 24px;
        height: 24px;
        margin-right: 15px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiM0MDlCRDIiLz4KPG1hc2sgaWQ9Im1hc2swXzYwOTVfMTAzNzMiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiPgo8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzYwOTVfMTAzNzMpIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSIjQTZEMUVBIi8+CjxyZWN0IHg9Ii01IiB5PSI4IiB3aWR0aD0iMzQiIGhlaWdodD0iOCIgZmlsbD0iI0Q0RThCMSIvPgo8cmVjdCB4PSI4IiB5PSI4IiB3aWR0aD0iMjEiIGhlaWdodD0iOCIgZmlsbD0iIzQwOUJEMiIvPgo8cmVjdCB4PSI4IiB5PSItNSIgd2lkdGg9IjgiIGhlaWdodD0iMzQiIGZpbGw9IiNENEU4QjEiLz4KPHJlY3QgeD0iOCIgeT0iOCIgd2lkdGg9IjgiIGhlaWdodD0iMjEiIGZpbGw9IiM0MDlCRDIiLz4KPHJlY3QgeD0iOCIgeT0iOCIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSIjQTRDRDU4Ii8+CjxyZWN0IHg9IjgiIHk9IjgiIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIGZpbGw9IiNFNTIwMkUiLz4KPHJlY3QgeD0iMTYiIHk9IjE2IiB3aWR0aD0iOCIgaGVpZ2h0PSI4IiBmaWxsPSIjMDA3QUMzIi8+CjwvZz4KPC9zdmc+Cg==);
        background-size: 24px 24px !important
      }
    }
  }
}

</style>