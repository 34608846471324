<template>
  <div v-if="searchBarBody" id="promo-container">
    <utd-promo-box class="disclaimer" :category="'home'" :type="'specific-messaging'" />
  </div>
</template>

<script>
import UtdPromoBox from '_acaSrc/components/promobox/PromoBox.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'HomeSearchDisclaimer',
    components: {UtdPromoBox},
    computed: {
        ...mapGetters('search', [ 'searchBarBody' ])
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
</style>