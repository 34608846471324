<template>
  <div class="graphic-viewer-zoom" v-bind="$attrs">
    <button
      :disabled="hasReachMaxZoom"
      tabindex="0"
      aria-label="Zoom in"
      class="graphic-viewer-zoom__button wkce-icon-plus"
      @click="handleOnZoom('in')"
      @keypress="$event.which === ENTER_KEY && handleOnZoom('in')"
    />

    <span class="graphic-viewer-zoom__label"
          tabindex="0"
          :aria-label="`Reset Zoom`"
          @click="resetZoom()"
    >
      {{ zoomPercentage }}
    </span>

    <button
      :disabled="hasReachMinZoom"
      tabindex="0"
      aria-label="Zoom out"
      class="graphic-viewer-zoom__button wkce-icon-minus"
      @click="handleOnZoom('out')"
      @keypress="$event.which === ENTER_KEY && handleOnZoom('out')"
    />
  </div>
</template>
<script>
import { C_KEYCODES, C_UI } from '_acaSrc/utility/constants';
import { mapGetters, mapActions } from 'vuex';
import { ZoomControllerTooltip } from '_acaSrc/utility/tooltip';

const ZOOM_STEP_SIZE = 0.25;
const MIN_ZOOM_SIZE = 0.25;
const MAX_ZOOM_SIZE = 2;

const ADD_KEY = '+';
const SUBSTRACT_KEY = '-';

export default {
    data() {
        return {
            ENTER_KEY: C_KEYCODES.ENTER_KEY,
            imageZoom: 1,
            baseWidth: 0,
            baseHeight: 0
        };
    },
    computed: {
        ...mapGetters('app', [ 'tooltipConfig' ]),
        ...mapGetters('graphic', [ 'graphicViewerImageKey' ]),
        hasReachMaxZoom() {
            return this.imageZoom >= MAX_ZOOM_SIZE;
        },
        hasReachMinZoom() {
            return this.imageZoom === MIN_ZOOM_SIZE;
        },
        zoomPercentage() {
            return `${this.imageZoom * 100}%`;
        }
    },
    watch: {
        graphicViewerImageKey() {
            this.$nextTick(() => {
                this.resetZoom();
            });
        }
    },
    mounted() {
        this.setUpListeners();
        this.setupTooltips();
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    },
    methods: {
        ...mapActions('app', [ 'confirmConfigLoaded' ]),
        handleOnZoom(flow) {
            this.imageZoom = flow === 'in'
                ? this.imageZoom + ZOOM_STEP_SIZE
                : this.imageZoom - ZOOM_STEP_SIZE;

            this.setZoom(flow);
        },
        setZoom(flow) {
            const overlay = document.querySelector('.graphic__overlay-content');
            if (overlay) {
                overlay.style.height = `${overlay.offsetHeight}px`;
            }

            const img = document.querySelector('.graphic__overlay-content img');
            if (img) {
                const { offsetHeight, offsetWidth } = img;
                const { widthStepSize, heightStepSize }
                  = this.calculateZoomSizes(offsetHeight, offsetWidth);

                const width = flow === 'in'
                    ? offsetWidth + widthStepSize
                    : offsetWidth - widthStepSize;

                const height = flow === 'in'
                    ? offsetHeight + heightStepSize
                    : offsetHeight - heightStepSize;

                img.style.width = `${width}px`;
                img.style.height = `${height}px`;
            }
        },
        calculateZoomSizes(height, width) {
            if (this.baseHeight === 0 && this.baseWidth === 0) {
                this.baseHeight = height;
                this.baseWidth = width;
            }

            const widthStepSize = this.baseWidth * ZOOM_STEP_SIZE;
            const heightStepSize = this.baseHeight * ZOOM_STEP_SIZE;

            return {
                widthStepSize,
                heightStepSize
            };
        },
        setUpListeners() {
            document.addEventListener('keydown', this.onKeyDown);
        },
        onKeyDown(e) {
            if (e.key === ADD_KEY) {
                if (this.hasReachMaxZoom) {
                    return;
                }
                this.handleOnZoom('in');
            }
            else if (e.key === SUBSTRACT_KEY) {
                if (this.hasReachMinZoom) {
                    return;
                }
                this.handleOnZoom('out');
            }
        },
        resetZoom() {
            if (this.imageZoom === 1) {
                return;
            }

            this.imageZoom = 1;
            const overlay = document.querySelector('.graphic__overlay-content');
            if (overlay) {
                overlay.style.height = '';
            }

            const img = document.querySelector('.graphic__overlay-content img');
            if (img) {
                img.style.width = `${this.baseWidth}px`;
                img.style.height = `${this.baseHeight}px`;
            }
            this.baseHeight = 0;
            this.baseWidth = 0;
        },
        async setupTooltips() {
            const zoomButtons = document.querySelectorAll('.graphic-viewer-zoom__button, .graphic-viewer-zoom__label');
            if (zoomButtons) {
                await this.confirmConfigLoaded();
                this.gfxNavTooltip = new ZoomControllerTooltip(zoomButtons, this.tooltipConfig, {
                    theme: 'utd-tooltip-interactive-simple',
                    delay: C_UI.MENU_SHOW_DELAY_MS,
                    trigger: 'mouseenter',
                    placement: 'right',
                    content: ele => ele.getAttribute('aria-label'),
                    aria: {
                        content: null,
                        expanded: false
                    }
                });
                this.gfxNavTooltip.initialize().catch(() => {
                    // No-op
                });
            }
        }
    }
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.graphic-viewer-zoom {
  display: none;
  background-color: @WKCE-WHITE;
  position: fixed;
  top: 132px;
  z-index: 999;
  left: 60px;
  width: 36px;
  border: 1px solid @WKCE-GRAY-TINT3;

  .graphic-viewer-zoom__button,
  .graphic-viewer-zoom__label {
    display: inline-block;
    .ds1-ma-0();
  }

  .graphic-viewer-zoom__button {
    background-color: @WKCE-WHITE;
    color: @WKCE-BLUE-SHADE1;
    .ds1-pa-1();
    font-weight: 600;
    border: none;
    width: 100%;
    height: 36px;
    font-size: 14px;
    display: inline-block;
    position: relative;

    &::before {
      position: absolute;
      top: 12px;
      left: 12px;
    }

    &:first-child {
      border-bottom: 1px solid @WKCE-GRAY-TINT3;
    }

    &:last-child {
      border-top: 1px solid @WKCE-GRAY-TINT3;
    }

    &:hover {
      background-color: @WKCE-GRAY-TINT4;
    }

    &[disabled] {
      opacity: 0.75;
      background-color: @WKCE-GRAY-TINT5;
    }
  }

  .graphic-viewer-zoom__label {
    display: block;
    font-size: 11px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    color: @WKCE-GRAY-SHADE1;

    &:hover {
      background-color: @WKCE-GRAY-TINT4;
    }
  }
}

@media only screen and (min-width: 768px) {
  .graphic-viewer-zoom {
    display: block;
  }
}
</style>