<template>
  <div class="visuallyHidden">
    <div ref="userNameTooltip" class="username-tooltip-box">
      <div id="username-info-tool-tip" class="tooltip-content">
        <p>
          If your organization permits Single Sign-On (SSO) for UpToDate, you may use your
          email address here.
        </p>
        <p>If not, please enter your Username.</p>
      </div>
      <div class="tooltip-action">
        <utd-button :tabindex="hasUserNameInfo ? '0': '1'" @click="closeTooltip">
          {{ $t('CLOSE') }}
        </utd-button>
      </div>
    </div>
  </div>
</template>

<script>
import { RichContentTooltip } from '_acaSrc/utility/tooltip';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'UserNameInfoTooltip',
    components: {
        UtdButton
    },
    data() {
        return {
            userNameTooltip: null,
            hasUserNameInfo: false
        };
    },
    computed: {
        ...mapGetters('app', [ 'tooltipConfig' ])
    },
    mounted() {
        this.setupTooltip();
    },
    methods: {
        ...mapActions('app', [ 'confirmConfigLoaded' ]),
        async setupTooltip() {
            await this.confirmConfigLoaded();
            const userNameInfoBtn = document.querySelector('#userNameInfo');
            if (userNameInfoBtn) {
                this.hasUserNameInfo = true;
                this.userNameTooltip = new RichContentTooltip(userNameInfoBtn, this.tooltipConfig, {
                    placement: 'right',
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                enabled: true,
                                options: {
                                    fallbackPlacements: [ 'bottom' ]
                                }
                            }
                        ]
                    }
                });
                try {
                    await this.userNameTooltip.initialize();
                    this.userNameTooltip.setTooltipContent(this.$refs.userNameTooltip);
                    this.userNameTooltip.onClick(this.closeTooltip);
                }
                catch {
                    // No-op
                }
            }
        },
        closeTooltip() {
            this.userNameTooltip.hideTooltip();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.username-tooltip-box {
  .ds1-pa-2();
  width: 220px;
}

.tooltip-content > p {
  .ds1-mt-0();
  .ds1-mb-2();
}

.tooltip-action {
  display: flex;
  justify-content: flex-end;
}
</style>