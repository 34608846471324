<template>
  <div class="wk-field wk-field-password" :class="inputClasses" v-bind="$attrs">
    <div class="wk-field-header">
      <label class="wk-field-label"
             :for="$attrs['input-id']"
             v-text="$attrs.label" />
    </div>
    <div ref="wkFieldBody"
         class="wk-field-body"
         @keyup="checkCapsLock($event)">
      <slot :custom-blur="resetCaps" />
      <button type="button"
              class="wk-button
                     wk-button-text
                     wk-button-icon-left
                     wk-field-password-toggle-button"
              @click="toggleShowField">
        <span class="wk-field-helper-text" aria-label="Show password">
          <i :class="`wk-icon wkce-icon-${eyeIcon}`"
             aria-hidden="true" />{{ hideText ? 'Show' : 'Hide' }} </span>
      </button>
      <div class="wk-field-inlay">
        <span class="wk-field-icon wk-field-check-mark-icon">
          <span v-if="isSuccess"
                role="alert"
                aria-live="assertive"
                aria-hidden="true"
                class="success-state-icon wkce-icon" />
        </span>
        <span v-if="showCapsIcon"
              aria-hidden="true"
              class="wkce-icon-filled-shift" />
      </div>
      <div v-if="isError && errorMessage"
           role="alert"
           aria-live="assertive"
           class="wk-field-error"
           v-text="errorMessage" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        inputSize: {
            type: String,
            default: 'small'
        },
        errorMessage: {
            type: String,
            default: ''
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'text-input-toggle-show' ],
    data() {
        return {
            hideText: true,
            capsLockOn: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'noNativeCapsLock' ]),
        eyeIcon() {
            return this.hideText ? 'visible' : 'invisible';
        },
        inputClasses() {
            return [
                this.$attrs['type-class'],
                { 'wk-field-errored': this.isError },
                { 'wk-field-success': this.isSuccess },
                { 'wk-field-is-disabled': this.isDisabled },
                { 'wk-field-has-required-indicator': this.hasAttr('required') }
            ];
        },
        showCapsIcon() {
            return this.capsLockOn && !this.isSuccess;
        }
    },
    methods: {
        hasAttr(attr) {
            return this.$attrs.hasOwnProperty(attr);
        },
        toggleShowField() {
            this.hideText = !this.hideText;
            this.$emit('text-input-toggle-show', this.hideText);
        },
        checkCapsLock(event) {
            if (this.noNativeCapsLock) {
                this.capsLockOn = event.getModifierState && event.getModifierState('CapsLock');
            }
        },
        resetCaps() {
            this.capsLockOn = false;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.wk-js2 {
  .wk-field-label {
    .ds1-utd-weight-600();
  }

  .wk-button {
    .ds1-pa-0();

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: @DS1-PDS-INPUT-SELECTED-BORDER;
    }
  }

  .wk-field-success {
    input {
      padding-right: 40px;
    }

    &.wk-field-small {
      input {
        padding-right: 30px;
      }
    }
  }

  .success-state-icon {
    .ds1-ph-1();

    &::after {
      content: '\E863';
      font-family: 'wk-icons-filled';
      color: @DS1-UTD-SUCCESS;
      font-size: 14px;
    }
  }

  .wk-field-inlay {
    max-height: 40px;
  }
}
</style>
