<template>
  <utd-modal-dialog ref="changePasswordRef"
                    :modal-classes="'change-password-modal'"
                    :close-on-overlay-click="isMobileOnDesktop"
                    :close-on-escape="!isMobileOnDesktop"
                    @closed="handleClose()">
    <template #header>{{ $t('MYACCOUNT.CHANGE_PASSWORD') }}</template>
    <div class="change-password-dialog">
      <div v-if="successMessage === ''" class="dialog__content">
        <utd-notification v-if="isUnexpectedError"
                          id="unexpectedServerError"
                          :notification-mode="'error'">
          <p class="dialog__server-error-text">
            {{ $t('MYACCOUNT.UPDATE_INFORMATION_UNEXPECTED_ERROR') }}
          </p>
          <p class="dialog__server-error-text">Please try again later or contact us using
            <a v-utd-new-tab="true"
               href="https://www.wolterskluwer.com/en/solutions/uptodate/contact-us"
               class="ds1-utd-weight-600">
              UpToDate Support.
            </a>
          </p>
        </utd-notification>
        <p class="dialog__license-info">
          {{ $t('MYACCOUNT.LICENSE_AGREEMENT_INFO') }}
        </p>
        <form class="dialog__login-info-form">
          <fieldset class="dialog__fieldset">
            <div class="dialog__input-container wk-js2">
              <h3 class="dialog__input-title">
                {{ $t('MYACCOUNT.PASSWORD_OLD_CONFIRM') }}
              </h3>
              <utd-password-input ref="passwordInputRef"
                                  input-id="curPasswordInput"
                                  :is-error="!isCurPasswordValid && isDirty.curPassword"
                                  :label="$t('MYACCOUNT.ENTER_OLD_PASSWORD')"
                                  :error-message="curPasswordValidator"
                                  @text-input-toggle-show="toggleVisibleInput(
                                    'curPassword', $event )">
                <template v-slot="parentScope">
                  <input id="curPasswordInput"
                         v-model="curPassword"
                         class="wk-field-input"
                         :type="inputType.curPassword"
                         autocapitalize="off"
                         autocorrect="off"
                         name="curPasswordInput"
                         @blur="handleCurPasswordBlur(parentScope)">
                </template>
              </utd-password-input>
            </div>
            <p>
              <a href="/account/reset-password" @click="closeDialog(true)">
                {{ $t('MYACCOUNT.FORGOT_PASSWORD') }}
              </a>
            </p>
          </fieldset>
          <fieldset class="dialog__fieldset">
            <div class="dialog__input-container wk-js2">
              <h3 class="dialog__input-title">
                {{ $t('MYACCOUNT.PASSWORD_NEW') }}
              </h3>
              <utd-password-input input-id="newPasswordInput"
                                  :is-error="!isNewPasswordValid && isDirty.newPassword"
                                  :is-success="isNewPasswordValid"
                                  :label="$t('MYACCOUNT.ENTER_NEW_PASSWORD')"
                                  :error-message="newPasswordValidator"
                                  @text-input-toggle-show="toggleVisibleInput(
                                    'newPassword', $event)">
                <template v-slot="parentScope">
                  <input id="newPasswordInput"
                         v-model="newPassword"
                         class="wk-field-input"
                         :type="inputType.newPassword"
                         autocapitalize="off"
                         autocorrect="off"
                         name="newPasswordInput"
                         @blur="handleNewPasswordBlur(parentScope)">
                </template>
              </utd-password-input>
              <utd-password-input input-id="confirmPasswordInput"
                                  :is-error="!isConfirmPasswordValid && isDirty.confirmPassword"
                                  :is-success="isConfirmPasswordValid"
                                  :label="$t('MYACCOUNT.REENTER_NEW_PASSWORD')"
                                  :error-message="confirmPasswordValidator"
                                  @text-input-toggle-show="toggleVisibleInput(
                                    'confirmPassword', $event)">
                <template v-slot="parentScope">
                  <input id="confirmPasswordInput"
                         v-model="confirmPassword"
                         class="wk-field-input"
                         :type="inputType.confirmPassword"
                         autocapitalize="off"
                         autocorrect="off"
                         name="confirmPasswordInput"
                         @blur="handleConfirmPasswordBlur(parentScope)">
                </template>
              </utd-password-input>
            </div>
            <p class="dialog__rules-title">Password rules:</p>
            <ul class="dialog__form-rules">
              <li>
                <p class="dialog__rule-item">
                  Cannot match username
                </p>
              </li>
              <li>
                <p class="dialog__rule-item">
                  8 to 24 characters
                </p>
              </li>
              <li>
                <p class="dialog__rule-item">
                  At least 1 uppercase letter
                </p>
              </li>
              <li>
                <p class="dialog__rule-item">
                  At least 1 number, or special character from the following set:
                </p>
                <p class="dialog__rule-item dialog__characters">@ # $ * ! ( ) - _ + = .</p>
              </li>
            </ul>
          </fieldset>
        </form>
      </div>
      <div v-else
           ref="successMessage"
           class="dialog__success-message"
           role="alert"
           aria-live="assertive"
           tab-index="0">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t(successMessage)" />
        <p>{{ $t('MYACCOUNT.CREDENTIAL_CHANGE_WAIT_ADVICE') }}</p>
      </div>
    </div>
    <template #footer>
      <utd-button id="closeDialogButton"
                  :button-style="successMessage ? '' : 'ghost'"
                  @click="closeDialog()">Close</utd-button>
      <utd-button v-if="!successMessage"
                  id="savePasswordButton"
                  :disabled="isSubmitDisabled"
                  button-style="ghost"
                  @click="submitPassword()">Save Changes</utd-button>
    </template>
  </utd-modal-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { validatePassword } from '_acaSrc/utility/Validators';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import UtdPasswordInput from '_acaSrc/components/shared/input/UtdPasswordInput.vue';
import { C_MY_ACCOUNT } from '_acaSrc/utility/constants';

export default {
    directives: {
        UtdNewTab
    },
    components: {
        UtdModalDialog,
        UtdButton,
        UtdNotification,
        UtdPasswordInput
    },
    data() {
        return {
            inputType: {
                curPassword: 'password',
                newPassword: 'password',
                confirmPassword: 'password'
            },
            isDirty: {
                curPassword: false,
                newPassword: false,
                confirmPassword: false
            },
            isSubmitting: false,
            curPassword: '',
            newPassword: '',
            confirmPassword: '',
            serverErrors: {
                curPassword: '',
                newPassword: ''
            },
            successMessage: '',
            isUnexpectedError: false
        };
    },
    computed: {
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapGetters('account', [ 'myAccountUserName' ]),
        curPasswordValidator() {
            if (this.serverErrors.curPassword) {
                return this.$t(this.serverErrors.curPassword);
            }
            if (!this.curPassword.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            return '';
        },
        newPasswordValidator() {
            if (this.serverErrors.newPassword) {
                return this.$t(this.serverErrors.newPassword);
            }
            return this.$t(validatePassword(this.newPassword, this.myAccountUserName));
        },
        confirmPasswordValidator() {
            if (this.newPassword !== this.confirmPassword) {
                return this.$t('MYACCOUNT.PASSWORD_INVALID_MISMATCH');
            }
            if (!this.confirmPassword.length > 0) {
                return this.$t('MYACCOUNT.EMPTY_FIELD');
            }
            return '';
        },
        isSubmitDisabled() {
            return !this.isFormValid || this.isSubmitting || this.isUnexpectedError;
        },
        isCurPasswordValid() {
            return this.curPasswordValidator === '';
        },
        isNewPasswordValid() {
            return this.newPasswordValidator === '';
        },
        isConfirmPasswordValid() {
            return this.confirmPasswordValidator === '';
        },
        isFormValid() {
            return this.isCurPasswordValid
                && this.isNewPasswordValid
                && this.isConfirmPasswordValid;
        }
    },
    beforeUnmount() {
        this.resetForm();
    },
    methods: {
        toggleVisibleInput(field, hideText) {
            this.inputType[field] = hideText ? 'password' : 'text';
        },
        handleCurPasswordBlur(parentScope) {
            this.isDirty.curPassword = true;
            this.clearPasswordServerError();
            parentScope && parentScope.customBlur && parentScope.customBlur();
        },
        handleNewPasswordBlur(parentScope) {
            this.isDirty.newPassword = true;
            this.clearNewPasswordServerError();
            parentScope && parentScope.customBlur && parentScope.customBlur();
        },
        handleConfirmPasswordBlur(parentScope) {
            this.isDirty.confirmPassword = true;
            parentScope && parentScope.customBlur && parentScope.customBlur();
        },
        resetForm() {
            this.clearServerErrors();
            this.clearFields();
            this.setDirtyFields(false);
            this.setPasswordFields();
            this.successMessage = '';
            this.isUnexpectedError = false;
        },
        clearPasswordServerError() {
            this.serverErrors.curPassword = '';
        },
        clearNewPasswordServerError() {
            this.serverErrors.newPassword = '';
        },
        clearServerErrors() {
            this.clearPasswordServerError();
            this.clearNewPasswordServerError();
        },
        setPasswordFields() {
            this.inputType = {
                curPassword: 'password',
                newPassword: 'password',
                confirmPassword: 'password'
            };
        },
        setDirtyFields(state) {
            this.isDirty = {
                curPassword: state,
                newPassword: state,
                confirmPassword: state
            };
        },
        clearFields() {
            this.curPassword = '';
            this.newPassword = '';
            this.confirmPassword = '';
        },
        open() {
            this.$refs.changePasswordRef.open();
        },
        async closeDialog(redirect = false) {
            this.$refs.changePasswordRef.close();
            new PubSub().publish('wkutd.closeMyAccountDialog');
            if (redirect) {
                new PubSub().publish('wkutd.closeMyAccountSidebar');
            }
            this.resetForm();
        },
        handleClose() {
            this.successMessage = '';
            new PubSub().publish('wkutd.closeMyAccountDialog');
            this.resetForm();
        },
        async submitPassword() {
            if (this.isSubmitting) {
                return;
            }
            try {
                this.isUnexpectedError = false;
                this.isSubmitting = true;
                this.setDirtyFields(true);
                const data = await utdRest('myAccount/changePassword', {
                    password: this.curPassword,
                    newPassword: this.newPassword
                });
                const res = data.data || data;
                this.handleResponse(res);
            }
            catch (e) {
                this.isUnexpectedError = true;
            }
            this.isSubmitting = false;
        },
        handleResponse(res) {
            if (!res) {
                return;
            }
            if (res === C_MY_ACCOUNT.UPDATE_LOGIN_SUCCESS
              || res === C_MY_ACCOUNT.UPDATE_LOGIN_PENDING) {
                this.successMessage = 'MYACCOUNT.PASSWORD_CHANGE_SUCCESS';
                this.$refs.successMessage.focus();
                return;
            }
            for (const msg of res) {
                this.handleError(msg);
            }
        },
        handleError(msg) {
            if (msg === C_MY_ACCOUNT.NEW_PASSWORD_ERROR) {
                this.serverErrors.newPassword = 'MYACCOUNT.PASSWORD_INVALID';
            }
            if (msg === C_MY_ACCOUNT.NEW_PASSWORD_USER_NAME_ERROR) {
                this.serverErrors.newPassword = 'MYACCOUNT.PASSWORD_INVALID_MATCHES_USERNAME';
            }
            if (msg === C_MY_ACCOUNT.OLD_PASSWORD_INVALID_ERROR) {
                this.serverErrors.curPassword = 'MYACCOUNT.PASSWORD_INVALID';
            }
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';

.change-password-modal {
  .utd-dialog-content {
    .ds1-pa-2();
  }

  .utd-dialog-footer .utd-button {
    .ds1-ml-2();
  }
}

@media screen and (min-width: 768px) {
  .change-password-modal {
    .utd-modal-content .utd-dialog-frame {
      max-width: 670px;
      min-width: 360px;

      .utd-dialog-content {
        height: 550px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .change-password-modal {
    .utd-modal-content .utd-dialog-frame {
      width: auto;
      max-width: 360px;
    }
  }
}
</style>