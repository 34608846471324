<template>
  <utd-modal ref="utdGraphicModal"
             :modal-classes="'graphic__modal ds1-utd-font'"
             :close-on-overlay-click="true"
             :class="{ 'utd-modal-maxed': isDialogMaxed }"
             :close-on-escape="!shareModalVisible"
             @utd-modal-closing="close"
             @utd-modal-overlay-click="close">
    <GraphicViewerZoom v-if="showZoomControls" :class="{'invisible': hideGraphic }" />
    <div class="graphic__container">
      <div class="graphic__toolbar"
           :class="{ 'collapsed': toolbarLabelsCollapsed,
                     'hideItems': hideGraphic }">
        <ul v-if="!hasError" class="graphic__toolbar-links ds1-ph-2">
          <li v-if="showCounter" id="toolbarCounter">
            <graphic-viewer-counter @onNextClick="throttleNextGraphic" @onPrevClick="throttlePrevGraphic" />
          </li>
          <li v-show="powerpointLinkVisible"
              class="toolBarHoverTooltip"
              :aria-label="`${$t('CONTENT.EXPORT_TO_POWERPOINT')}`">
            <a id="exportPwrPntLink"
               class="wkce-icon-file-powerpoint"
               :href="tools.powerPointUrl"
               target="_blank"
               tabindex="0"
               @click="logUiClickEvent('exportPwrPntLink')"
            >{{ $t("CONTENT.EXPORT_TO_POWERPOINT") }}</a>
          </li>
          <li v-if="printLinkVisible"
              class="toolBarHoverTooltip"
              :aria-label="`${$t('CONTENT.TOOLS_PRINT')}`">
            <a id="graphicsPrintLink"
               class="wkce-icon-print"
               :href="tools.printUrl"
               target="_blank"
               rel="noopener"
               tabindex="0"
               @click="logUiClickEvent('printLink')"
            >{{ $t("CONTENT.TOOLS_PRINT") }}</a>
          </li>
          <li v-if="shareLinkVisible"
              class="graphicsShare toolBarHoverTooltip"
              :aria-label="`${$t('SHARE.SHARE_KEY')}`"
          >
            <topic-toolbar-share :share-type="'graphic'"
                                 @share-modal-visible="handleDisplayChildModal" />
          </li>
          <li v-if="bookmarkVisible"
              id="bookmarkGraphic"
              :aria-label="`${$t('MYUPTODATE.BOOKMARK')}`"
              class="liBookmark toolBarHoverTooltip"
              @click="bufferBookmark">
            <utd-bookmark id="gfxBookmark"
                          :bookmark-active="toolsBookmarkIsActive"
                          :bookmark-tooltip="tooltip"
                          :bookmark-on-modal="true"
                          :bookmark-remove-focus="false"
                          @tooltip-closed="closeTooltip" />
          </li>
          <li v-if="showStarRating"
              id="toolbarGraphicRating"
              class="toolbar-star-rating">
            <utd-star-rating id="graphicViewerStarRating"
                             star-rating-aria-label="Rate this graphic"
                             :user-rating="graphicRating" />
          </li>
          <li v-if="showContentFeedback"
              aria-label="Provide Feedback"
              class="mobileOverflow">
            <utd-content-feedback-dialog ref="feedbackDialog"
                                         :feedback-title="USER_CONTENT_FEEDBACK.TITLE.GRAPHIC"
                                         has-chat-box-icon
                                         :button-text="$t('HEADER.FEEDBACK')"
                                         @feedback-modal-visible="handleDisplayChildModal" />
          </li>
          <li v-else-if="showFeedbackLink">
            <utd-feedback-dialog ref="feedbackDialog"
                                 :feedback-title="USER_CONTENT_FEEDBACK.TITLE.GRAPHIC"
                                 :feedback-url="getFeedbackUrl()" />
            <a id="graphicsFeedback"
               href="#"
               class="wkce-icon-chat-box"
               tabindex="0"
               role="button"
               aria-label="open graphic feedback window"
               @click.prevent="openGraphicFeedbackWindow"
            ><span>{{ $t("HEADER.FEEDBACK") }}</span></a>
          </li>
        </ul>
      </div>
      <div id="graphicViewerDialogToggleSize"
           class="graphic__overlay-toggle"
           tabindex="0"
           role="button"
           :class="overlayToggleIcon"
           aria-label="Toggle Dialog Size"
           :title="toggleDialogCaption"
           @keypress="$event.which === ENTER_KEY && toggleDialogMaxed()"
           @click="toggleDialogMaxed" />
      <div id="graphicViewerDialogClose"
           class="graphic__overlay-close wkce-icon-filled-close"
           tabindex="0"
           role="button"
           aria-label="Close graphic window"
           @click="userCloseDialog"
           @keypress="$event.which === ENTER_KEY && close()" />
      <div class="graphic__overlay-container"
           :class="{ 'graphic__sidebar-expanded': isSidebarExpanded,
                     'animate-sidebar': sidebarAnimationOn,
                     'content-overflow-x': contentXOverflowed,
                     'content-overflow-y': contentYOverflowed }">
        <div v-if="showLoadingSpinner"
             class="graphic__graphic-loading"
        ><span class="wkce-icon-filled-spinner-alt wk-spin" /></div>

        <button v-if="isTopicView || hasAppearsIn || hasGraphicCollection"
                :class="thumbnailToggleIcon"
                class="graphic__sidebar-toggle hoverTooltip"
                :title="isSidebarExpanded ? `Collapse` : tooltipCaption"
                tabindex="0"
                @click="toggleInnerSidebar"
                @keyup.enter="toggleInnerSidebar"
                @keyup.space="toggleInnerSidebar" />

        <div class="graphic__overlay-content"
             tabindex="-1"
             :class="{ isFixedTable }">
          <utd-notification v-if="hasError"
                            class="graphic-viewer-error ds1-mh-4"
                            :notification-mode="'error'">
            <template v-slot:title>There was an error loading this image.<!--
              --> Please try refreshing your browser or <!--
              --><a href="/home/contact-us" target="_blank" rel="noopener">contact customer<!--
              --> support</a> if the problem continues.</template>
          </utd-notification>

          <!-- eslint-disable vue/no-v-html -->
          <div class="graphic-viewer-content graphic__height-extents"
               :tabindex="isFixedTable ? '-1' : '0' "
               aria-label="Graphic viewer content"
               :class="{ 'visiblyHidden': hasError ,
                         'widthZoom': showZoomControls,
                         'invisible': hideGraphic }"
               v-html="graphicBufferHtml" />
        </div>

        <GraphicViewerSidebar :has-appears-in="hasAppearsIn"
                              :has-graphic-collection="hasGraphicCollection"
                              :is-sidebar-expanded="isSidebarExpanded"
                              @selected-item-id="handleSelectedItem" />

        <GraphicFixedTableHeaders :container-dimensions="getTableContainerDimensions" />
      </div>
    </div>
  </utd-modal>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { safeTimeout, throttle } from '_acaSrc/utility/timers';
import {
    SET_GRAPHIC_VIEWER_VISIBILITY,
    SET_BOOKMARK_IS_ACTIVE,
    RESET_GRAPHIC_VIEWER_LAST_VISIBLE
} from '_acaSrc/store/graphic.store';
import UtdFeedbackDialog from '_acaSrc/components/shared/utd/UtdFeedbackDialog.vue';
import UtdContentFeedbackDialog from '_acaSrc/components/shared/utd/UtdContentFeedbackDialog.vue';
import UtdModal from '_acaSrc/components/shared/stdlib/UtdModal.vue';
import UtdNotification from '_acaSrc/components/shared/utd/UtdNotification.vue';
import UtdBookmark from '_acaSrc/components/shared/utd/UtdBookmark.vue';
import UtdStarRating from '_acaSrc/components/shared/stdlib/UtdStarRating.vue';
import TopicToolbarShare from '_acaSrc/components/contents/topic/toolbar/TopicToolbarShare.vue';
import GraphicViewerSidebar from './GraphicViewerSidebar.vue';
import GraphicFixedTableHeaders from './GraphicFixedTableHeaders.vue';
import GraphicViewerCounter from './GraphicViewerCounter.vue';
import GraphicViewerZoom from './GraphicViewerZoom.vue';
import { logUiClickEventHelper, throttleUiClickEvent } from '_acaSrc/utility/Events';
import { addVideoPlayer } from '_acaSrc/utility/UtdVideoPlayer';
import { lockRatingControls } from '_acaSrc/utility/PendoGuides/RatingGuide';
import { C_KEYCODES, C_UI } from '_acaSrc/utility/constants';
import {
    wrap,
    elAddClass,
    elRemoveClass,
    getWindow,
    elHasOverflowedContent,
    getVerticalScrollbarWidth,
    disableTabbingForContainedElements,
    restoreTabbingForContainedElements,
    getDocument
} from '_acaSrc/utility/DOM';
import { RichContentTooltip, GraphicToolBarLinksHoverTooltip } from '_acaSrc/utility/tooltip';
import { MarkupImageLoader } from '_acaSrc/utility/MarkupImageLoader';
import {
    BOOKMARK_BUFFER_PAUSE_MS,
    SET_BOOKMARK_BUFFER_DELAY,
    TOGGLE_TOOLTIP_HIDE,
    RESET_TOOLTIP
} from '_acaSrc/store/profile.store';
import { toggleBookmark } from '_acaSrc/utility/BookmarkHelper';
import Logger from '_acaSrc/utility/Logger';
import { USER_CONTENT_FEEDBACK } from '_acaSrc/utility/constants';

const GRAPHIC_GET_ELEMENTS_DELAY_MS = 250;
const GRAPHIC_CONTENT_LOADED_DELAY_MS = 300;
const SET_ZOOM_CONTROL_DELAY_MS = 5;
// If this next value is adjusted, you should also adjust
// @SIDEBAR_ANIMATION_MS in the LESS below.
const SIDEBAR_ANIMATION_DELAY_MS = 500;
const GET_GRAPHIC_THROTTLE_MS = 500;
const SPINNER_VISIBILITY_DELAY_MS = 250;

const MINIMUM_GRAPHIC_HEIGHT_PX = 550;
const DEFAULT_DIALOG_MARGIN_PX = 64;
const MIN_HEIGHT_THRESHOLD_PX = DEFAULT_DIALOG_MARGIN_PX + MINIMUM_GRAPHIC_HEIGHT_PX;

const TOOLBAR_LABEL_COLLAPSE_BUFFER_PX = 50;
const STICKY_TITLE_BUFFER_PX = 50;
const VERTICAL_SCROLLBAR_WIDTH_PX = 18;
const STICKY_TITLE_BOTTOM_PADDING_PX = 16;

export default {
    components: {
        UtdModal,
        UtdBookmark,
        UtdStarRating,
        TopicToolbarShare,
        UtdNotification,
        GraphicViewerSidebar,
        GraphicFixedTableHeaders,
        GraphicViewerCounter,
        GraphicViewerZoom,
        UtdFeedbackDialog,
        UtdContentFeedbackDialog
    },
    props: {
        showCounter: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    emits: [ 'mounted' ],
    data() {
        return {
            shareModalVisible: false,
            selectedItemId: null,
            USER_CONTENT_FEEDBACK,
            ENTER_KEY: C_KEYCODES.ENTER_KEY,
            isSidebarExpanded: false,
            isDialogMaxed: false,
            hasAppearsIn: false,
            sidebarAnimationOn: false,
            contentYOverflowed: false,
            contentXOverflowed: false,
            hasError: false,
            graphicBufferHtml: '',
            showLoadingSpinner: false,
            isFixedTable: false,
            toolbarElements: [],
            toolbarLabelsCollapsed: false,
            toolbarItemsVisible: false,
            minimumToolbarWidth: 0,
            graphicRatings: [],
            tableScrollerEl: null,
            showFeedbackLink: false,
            showStarRating: false,
            toolbarLinksTooltip: null,
            isImage: false,
            toolbarCounterTooltip: null,
            hideGraphic: true
        };
    },
    computed: {
        ...mapGetters('pendo', [ 'showRatingGuide' ]),
        ...mapGetters('app', [
            'tooltipConfig',
            'copyrightHtmlFull',
            'copyrightHtmlUcc',
            'isUccState'
        ]),
        ...mapGetters('graphic', [
            'graphicModalHtml',
            'graphicRelatedTopics',
            'getGraphicFeedbackUrl',
            'powerpointLinkVisible',
            'shareLinkVisible',
            'printLinkVisible',
            'feedbackLinkVisible',
            'bookmarkVisible',
            'graphicId',
            'graphicTitle',
            'graphicVersion',
            'graphicViewerImageKey',
            'graphicViewerNavVisible',
            'graphicViewerImageLoading',
            'graphicViewerGraphics',
            'graphicViewerCollection',
            'tools',
            'toolsBookmarkIsActive',
            'toolsBookmarkedGraphics',
            'isGraphicTypeNormal',
            'showGraphicFeedback'
        ]),
        ...mapGetters('topic', [
            'topicId',
            'topicTitle',
            'topicVersion',
            'topicLanguage',
            'isTopicView'
        ]),
        ...mapGetters('profile', [
            'permissions',
            'tooltip',
            'bookmarkBufferDelayMs'
        ]),
        ...mapGetters('device', [
            'browserName',
            'isBrowserOSAppleDesktop',
            'isMobileOnDesktop'
        ]),
        ...mapGetters('feature', [
            'isContentFeedbackMigration'
        ]),
        ...mapGetters('user', [ 'qualtricsURL' ]),
        hasRelatedTopics() {
            return this.graphicRelatedTopics
                && this.graphicRelatedTopics.length > 0;
        },
        hasGraphicCollection() {
            return this.graphicViewerCollection
                && this.graphicViewerCollection.length > 0;
        },
        overlayToggleIcon() {
            return this.isDialogMaxed ? 'wkce-icon-contract-angle' : 'wkce-icon-expand-angle';
        },
        thumbnailToggleIcon() {
            return this.isSidebarExpanded ? 'wkce-icon-chevron-right' : 'wkce-icon-chevron-left';
        },
        toggleDialogCaption() {
            return this.isDialogMaxed ? 'Restore Dialog' : 'Maximize Dialog';
        },
        tooltipCaption() {
            if (this.hasRelatedGraphics && this.hasAppearsIn) {
                return this.$t('GRAPHICS.EXPAND_RELATED_CONTENT');
            }
            else if (this.isTopicView || this.hasGraphicCollection) {
                return this.$t('GRAPHICS.EXPAND_GRAPHICS_IN_THIS_TOPIC');
            }
            return this.$t('GRAPHICS.EXPAND_APPEARS_IN_TOPICS');
        },
        hasRelatedGraphics() {
            return this.graphicViewerGraphics && this.graphicViewerGraphics.length > 0;
        },
        graphicRating: {
            get() {
                return this.graphicRatings[this.graphicViewerImageKey] || 0;
            },
            set(rating) {
                this.graphicRatings[this.graphicViewerImageKey] = rating;
            }
        },
        showZoomControls() {
            return this.isImage && !this.isGraphicTypeNormal;
        },
        showContentFeedback() {
            return this.isContentFeedbackMigration && this.showFeedbackLink;
        }
    },
    watch: {
        isMobileOnDesktop(newVal) {
            if (newVal) {
                this.close();
            }
        },
        graphicModalHtml(newVal, oldVal) {
            this.hideGraphic = true;
            if (newVal && oldVal !== newVal) {
                newVal = this.injectCopyright(newVal);

                const {
                    srcHtml,
                    hasImages
                } = this.imageLoader.loadImagesFromMarkup(newVal);

                if (!hasImages) {
                    this.stopLoadingSpinner();
                }

                this.graphicBufferHtml = srcHtml;
                this.onGraphicContentLoaded();
            }
        },
        graphicViewerImageKey(newVal) {
            this.hasError = !newVal;
        },
        graphicViewerImageLoading(newVal) {
            clearTimeout(this.spinnerTimeout);
            if (newVal) {
                this.spinnerTimeout = setTimeout(() => {
                    this.showLoadingSpinner = true;
                }, SPINNER_VISIBILITY_DELAY_MS);
            }
        },
        contentXOverflowed(newVal) {
            if (newVal) {
                this.setSidebarState();
            }
        },
        toolbarLabelsCollapsed(newVal) {
            this.setupGraphicLinkTooltips(newVal && this.toolbarLinksTooltip === null);
        }
    },
    beforeUnmount() {
        clearTimeout(this.timeoutDomSetup);
        clearTimeout(this.spinnerTimeout);
        this.emitter.emit('beforeUnmount');
        this.clearScrollListener(this.graphicScrollListener);
        this.unsubscribe({
            eventName: 'wkutd.star-rating',
            handlerFn: this.doRatingGuide
        });

        // clean up vuex state
        this.close();

        // Usually we destroy graphic viewer after change the view
        // Need to reset last visible time, to avoid block on scroll behaviour
        this[RESET_GRAPHIC_VIEWER_LAST_VISIBLE]();
    },
    beforeMount() {
        this.throttleNextGraphic = throttle(() => {
            this.hideGraphic = true;
            this.logUiClickEvent('nextGraphic');
            this.getNextSingleModalGraphic();
        }, GET_GRAPHIC_THROTTLE_MS);

        this.throttlePrevGraphic = throttle(() => {
            this.hideGraphic = true;
            this.logUiClickEvent('previousGraphic');
            this.getPreviousSingleModalGraphic();
        }, GET_GRAPHIC_THROTTLE_MS);
    },
    created() {
        this.imageLoader = new MarkupImageLoader(this.stopLoadingSpinner, this.imageLoadError);
    },
    mounted() {
        // For consumers who cannot or prefer not to utilize refs.
        this.$emit('mounted', {
            open: () => this.open(),
            close: () => this.close()
        });

        this.subscribe({
            eventName: 'wkutd.star-rating',
            handlerFn: this.doRatingGuide
        });
    },
    methods: {
        ...mapMutations('graphic', [
            SET_GRAPHIC_VIEWER_VISIBILITY,
            SET_BOOKMARK_IS_ACTIVE,
            RESET_GRAPHIC_VIEWER_LAST_VISIBLE
        ]),
        ...mapMutations('profile', [
            TOGGLE_TOOLTIP_HIDE,
            RESET_TOOLTIP,
            SET_BOOKMARK_BUFFER_DELAY ]),
        ...mapActions('app', [ 'publish', 'subscribe', 'unsubscribe', 'confirmConfigLoaded' ]),
        ...mapActions('pendo', [ 'launchGuide' ]),
        ...mapActions('graphic', [ 'getNextSingleModalGraphic',
            'getPreviousSingleModalGraphic',
            'bookmarkGraphic',
            'deleteBookmark' ]),
        ...mapActions('profile', [ 'resetBookmarkDelay' ]),
        handleDisplayChildModal(isVisible) {
            this.shareModalVisible = isVisible;
        },
        open() {
            this.graphicRatings = [];
            this.resetToolbarCollapsedVisibility();

            this.isDialogMaxed = false;
            if (!this.$refs.utdGraphicModal || !this.$refs.utdGraphicModal.open) {
                return;
            }
            this.$refs.utdGraphicModal.open();
            if (this.graphicViewerImageKey) {
                getWindow().addEventListener('keyup', this.onKeyUp);
                getWindow().addEventListener('keydown', this.onKeyDown);
                getWindow().addEventListener('resize', this.onViewportResize, { passive: true });
                this.postOpenDomSetup();
                this.setSidebarState();
            }
        },
        setSidebarState() {
            // This needs to be updated once the 'accordian' sidebar variant is ready
            if (!this.isTopicView) {
                this.hasAppearsIn = this.hasRelatedTopics;
            }

            if (this.wouldSidebarOverlapContent()) {
                this.isSidebarExpanded && this.toggleInnerSidebar(null, { logUiEvent: false });
            }
            else {
                this.isSidebarExpanded = this.hasAppearsIn || this.isTopicView;
                this.setSidebarElementsTabState();
            }
        },
        wouldSidebarOverlapContent() {
            return this.contentXOverflowed && !this.hasGraphicCollection;
        },
        userCloseDialog() {
            this.logUiClickEvent('close');
            this.close();
        },
        close() {
            getWindow().removeEventListener('keyup', this.onKeyUp);
            getWindow().removeEventListener('keydown', this.onKeyDown);
            getWindow().removeEventListener('resize', this.onViewportResize, { passive: true });
            this[SET_GRAPHIC_VIEWER_VISIBILITY](false);
            this.$refs.utdGraphicModal.close();
            this.hasAppearsIn = false;
            this.isSidebarExpanded = false;
            this.contentXOverflowed = false;
        },
        logUiClickEvent(element) {
            logUiClickEventHelper({
                uiElementName: `graphicViewerDialog_${element}`,
                optData: this.graphicViewerImageKey
            });
        },
        toggleDialogMaxed() {
            this.isDialogMaxed = !this.isDialogMaxed;
            if (this.isDialogMaxed) {
                elAddClass(this.modalEl, 'utd-modal-maxed');
            }
            else {
                elRemoveClass(this.modalEl, 'utd-modal-maxed');
            }
            this.logUiClickEvent('toggleSize');
            this.onViewportResize();
        },
        postOpenDomSetup() {
            this.timeoutDomSetup = setTimeout(() => {
                this.contentEl = document.querySelector('.graphic__overlay-content');
                this.modalEl = document.querySelector('.utd-modal-content');
                this.toolbarEl = document.querySelector('.graphic__toolbar');
                this.sidebarEl = document.querySelector('.graphic__sidebar');
                this.graphicContentEl = document.querySelector('.graphic-viewer-content');
                this.toolbarElements
                    = Array.from(document.querySelectorAll('.graphic__toolbar-links li'));

                if (this.graphicViewerNavVisible) {
                    this.setupTooltips();
                    this.setupGraphicCounterTooltips();
                }
                this.setIsImage();
            }, GRAPHIC_GET_ELEMENTS_DELAY_MS);
        },
        initVideoPlayer() {
            const videoEle = document.querySelector('.graphic-viewer-content .videoplayer');
            if (videoEle) {
                addVideoPlayer(videoEle);
            }
        },
        doRatingGuide(eventData) {
            if (eventData.id !== 'graphicViewerStarRating') {
                return;
            }

            this.graphicRating = eventData.rating;
            lockRatingControls();
            this.logUiClickEvent(eventData.id);

            const topicData = this.isTopicView
                ? {
                    topicId: this.topicId,
                    topicTitle: this.topicTitle,
                    topicVersion: this.topicVersion,
                    topicLanguage: this.topicLanguage
                }
                : {};

            this.launchGuide({
                guideName: 'RatingGuide',
                guideData: {
                    ...topicData,
                    graphicId: this.graphicId,
                    graphicTitle: this.graphicTitle,
                    graphicVersion: this.graphicVersion,
                    element: eventData.id,
                    rating: eventData.rating,
                    feedbackUrl: eventData.feedbackUrl
                }
            });
        },
        async setupTooltips() {
            const gfxNavButtons = document.querySelectorAll('.graphic-viewer-image-nav');
            if (gfxNavButtons) {
                await this.confirmConfigLoaded();
                this.gfxNavTooltip = new RichContentTooltip(gfxNavButtons, this.tooltipConfig, {
                    theme: 'utd-tooltip-interactive-simple',
                    placement: 'left',
                    delay: C_UI.MENU_SHOW_DELAY_MS,
                    trigger: 'mouseenter',
                    content: ele => ele.getAttribute('aria-label'),
                    zIndex: 9999999
                });
                this.gfxNavTooltip.initialize().catch(() => {
                    // No-op
                });
            }
        },
        async setupGraphicLinkTooltips(shouldInitialize = true) {
            if (shouldInitialize) {
                const navLinks = document.querySelectorAll('.toolBarHoverTooltip');
                this.toolbarLinksTooltip = new GraphicToolBarLinksHoverTooltip(
                    navLinks,
                    this.tooltipConfig
                );

                try {
                    await this.toolbarLinksTooltip.initialize();
                }
                catch (error) {
                    this.toolbarLinksTooltip = null;
                }
            }
            else if (this.toolbarLinksTooltip !== null) {
                this.toolbarLinksTooltip.destroy();
                this.toolbarLinksTooltip = null;
            }
        },
        resetToolbarCollapsedVisibility() {
            this.toolbarItemsVisible = false;
            this.toolbarLabelsCollapsed = false;
            this.minimumToolbarWidth = 0;
        },
        openGraphicFeedbackWindow() {
            this.logUiClickEvent('graphicsFeedback');

            this.$refs.feedbackDialog.openFeedbackForm();
        },
        getFeedbackUrl() {
            return this.getGraphicFeedbackUrl();
        },
        handleSelectedItem(selectedItem) {
            this.selectedItemId = selectedItem;
        },
        onGraphicContentLoaded() {
            this.resetToolbarCollapsedVisibility();
            this.showFeedbackLink = this.feedbackLinkVisible;
            this.showStarRating = this.showRatingGuide
                    && this.showGraphicFeedback;
            this.contentEl && this.contentEl.scrollTo(0, 0);
            this.contentEl && this.contentEl.focus();
            const { cancelTimeout } = safeTimeout(() => {
                try {
                    this.initVideoPlayer();
                    this.expandGraphicContent();
                    this.setupFixedTableHeaders();
                    this.onViewportResize();
                    this.setMinimumToolbarWidth();
                    this.setIsImage();
                    this.setStickyGraphicTitle();
                    this.setupGraphicScrollHandler();
                    this.setZoomControlPosition();
                }
                catch (err) {
                    Logger.error('Error during onGraphicContentLoaded on graphic', err);
                }
                this.hideGraphic = false;
            }, GRAPHIC_CONTENT_LOADED_DELAY_MS, { cancelHook: true });
            this.emitter.on('beforeUnmount', () => {
                cancelTimeout && cancelTimeout();
            });
        },
        setupGraphicScrollHandler() {
            this.throttleScrollEvent = throttleUiClickEvent();
            this.graphicScrollListener
                = this.setScrollListener(this.contentEl, this.onGraphicScrollEvent);
        },
        stopLoadingSpinner() {
            clearTimeout(this.spinnerTimeout);
            this.showLoadingSpinner = false;
        },
        imageLoadError() {
            this.hasError = true;
            this.stopLoadingSpinner();
        },
        injectCopyright(graphicSrc) {
            // Inject copyright HTML into received graphic HTML, after the `#graphicVersion` element
            const regex = /(<div id="graphicVersion">.+?<\/div>)/g;
            let graphicHtml = graphicSrc.toString();
            if (graphicHtml.match(regex)) {
                graphicHtml = graphicHtml.replace(regex, $1 => {
                    return `${$1}<div class="graphic__copyright ds1-mt-4 ds1-utd-size-2 ds1-pb-2">${
                        this.isUccState ? this.copyrightHtmlUcc : this.copyrightHtmlFull}</div>`;
                });
            }
            return graphicHtml;
        },
        onKeyDown(event) {
            // Handle tabbing.
            // Put focus to first toolbar element
            if (event.keyCode !== C_KEYCODES.TAB_KEY) {
                return;
            }
            if (getDocument().activeElement.id !== 'tableScroller'
                    && getDocument().activeElement !== this.contentEl) {
                return;
            }

            if(this.selectedItemId) {
                const graphicIcon = document.getElementById(this.selectedItemId);

                if(graphicIcon) {
                    graphicIcon.focus();
                }
                return;
            }

            event.preventDefault();
            this.toolbarEl.querySelector('li:first-child button').focus();
        },
        onKeyUp(event) {
            // This handler is bound to the window instead of being set in the template
            // so that pressing on the 'Left'/'Right' arrow keys will trigger the event
            // regardless of which element has the focus, as long as the browser
            // window has focus.
            if (!this.graphicViewerNavVisible) {
                return;
            }
            if (event.code === 'ArrowRight' || event.keyCode === C_KEYCODES.RIGHT_ARROW) {
                this.throttleNextGraphic();
            }
            else if (event.code === 'ArrowLeft' || event.keyCode === C_KEYCODES.LEFT_ARROW) {
                this.throttlePrevGraphic();
            }
        },
        onViewportResize() {
            // Ensure toolbar will always be visible, except at extremely small browser height
            if (!this.graphicContentEl) {
                return;
            }

            this.graphicContentEl.removeAttribute('style', '');
            if (getWindow().innerHeight < (this.toolbarEl.offsetHeight + MIN_HEIGHT_THRESHOLD_PX)) {
                let minHeight = getWindow().innerHeight
                      - (this.toolbarEl.offsetHeight + DEFAULT_DIALOG_MARGIN_PX);
                minHeight = minHeight < 0 ? 0 : minHeight;
                this.graphicContentEl.setAttribute('style', `min-height: ${minHeight}px`);
            }

            this.setOverflows();
            this.setToolbarLabelsCollapsed();
            this.updateStickyTitleDimensions();
            this.setZoomControlPosition();
            if (this.isFixedTable) {
                this.updateGraphicTables();
            }
        },
        setMinimumToolbarWidth() {
            this.minimumToolbarWidth = this.toolbarElements
                .reduce((prvEl, curEl) => {
                    return { width: prvEl.width + Math.ceil(curEl.getBoundingClientRect().width) };
                }, { width: 0 }).width + TOOLBAR_LABEL_COLLAPSE_BUFFER_PX;

            this.setToolbarLabelsCollapsed();

            this.toolbarItemsVisible = true;
        },
        setToolbarLabelsCollapsed() {
            if (!this.toolbarEl) {
                return;
            }

            this.toolbarLabelsCollapsed
                = this.toolbarEl.offsetWidth < this.minimumToolbarWidth;
        },
        setOverflows() {
            let elem = this.contentEl;
            if (this.isFixedTable) {
                elem = this.tableScrollerEl;
            }
            const overflows = elHasOverflowedContent(elem);

            this.contentYOverflowed = overflows.yOverflowed;

            // Handle user's sidebar state selection
            if (!this.contentXOverflowed) {
                this.contentXOverflowed = overflows.xOverflowed;
            }
        },
        setupFixedTableHeaders() {
            this.publish({
                eventName: 'wkutd.setup-graphic-fixed-table-headers',
                eventData: (isFixedTable, elem) => {
                    this.isFixedTable = isFixedTable;
                    this.tableScrollerEl = elem;
                }
            });
        },
        getTableContainerWidth(tableScrollerEl) {
            const sidebarWidthPx = Math.ceil(this.isSidebarExpanded
                ? this.sidebarEl.offsetWidth
                : 0);

            const modalLeftPx = Math.ceil(this.modalEl.getBoundingClientRect().left);
            const scrollerLeftPx = Math.ceil(tableScrollerEl.getBoundingClientRect().left);
            const tableLeftPx = scrollerLeftPx - modalLeftPx;

            const width = `${this.modalEl.offsetWidth - (sidebarWidthPx + tableLeftPx)}px`;

            return width;
        },
        getTableContainerHeight() {
            const tableTitleEl = document.querySelector('.graphic .figure .ttl:not(.sticky-title)');

            let heightPx = this.modalEl.clientHeight
                  - this.toolbarEl.offsetHeight;
            if (tableTitleEl) {
                heightPx -= (tableTitleEl.offsetHeight + 2);
            }

            return `${heightPx}px`;
        },
        getTableContainerDimensions(tableScrollerEl) {
            const width = this.getTableContainerWidth(tableScrollerEl);
            const height = this.getTableContainerHeight();

            return { width, height };
        },
        updateGraphicTables() {
            this.$nextTick(() => {
                this.publish({
                    eventName: 'wkutd.update-graphic-fixed-table-headers',
                    eventData: () => {
                        this.setOverflows();
                        this.updateStickyTitleDimensions();
                        this.setZoomControlPosition();
                    }
                });
            });
        },
        expandGraphicContent() {
            // Purpose of this method is to expand the graphic container width
            // by GRAPHIC_CONTAINER_MARGIN_PX. This is only done for graphics
            // that have an inline width set on their `.figure' elements.

            // This is to allow for spacing between the right edge of the
            // graphic content, and the right edge of the viewport, when the
            // graphic content overflows the container horizontally, and
            // the user has moved the scrollbar all the way to the right.

            // This was done using JS, as significant graphic content CSS
            // adjustments would affect other areas of the site.
            let maxGraphicEl = null;
            let maxGraphicWidth = 0;
            Array.from(document.querySelectorAll('.graphic-viewer-content > .graphic'))
                .forEach(gfx => {
                    const figure = gfx.querySelector('.figure');
                    if (figure) {
                        const graphicWidth = figure.offsetWidth;
                        const hasInlineStyle = figure.getAttribute('style');
                        if (hasInlineStyle && graphicWidth > maxGraphicWidth) {
                            maxGraphicWidth = graphicWidth;
                            maxGraphicEl = gfx;
                        }
                    }
                });

            if (maxGraphicWidth > 0) {
                maxGraphicEl.setAttribute('style', `width: ${maxGraphicWidth}px`);
            }
        },
        toggleInnerSidebar(event, options = { logUiEvent: true }) {
            // sidebarAnimationOn has two purposes:
            // > Prevent "sloshing" of sidebar during resize events
            // > Prevent double clicking of sidebar toggle
            if (!this.sidebarAnimationOn) {
                this.sidebarAnimationOn = true;
                this.isSidebarExpanded = !this.isSidebarExpanded;

                if (options.logUiEvent) {
                    this.logUiClickEvent('sidebarToggle');
                }

                setTimeout(() => {
                    this.sidebarAnimationOn = false;
                    this.setSidebarElementsTabState();
                    this.updateStickyTitleDimensions();
                    this.setZoomControlPosition();
                    if (this.isFixedTable) {
                        this.updateGraphicTables();
                    }
                }, SIDEBAR_ANIMATION_DELAY_MS);
            }
        },
        setSidebarElementsTabState() {
            if (this.isSidebarExpanded) {
                restoreTabbingForContainedElements(this.sidebarTabData);
            }
            else {
                this.sidebarTabData = disableTabbingForContainedElements(this.sidebarEl);
            }
        },
        updateSidebarToggleLabel({ label }) {
            this.collapsedSidebarToggleLabel = label;
        },
        _getVerticalScrollbarWidth() {
            // Return width of main graphic content area vertical scrollbar.
            let scrollbarWidth = getVerticalScrollbarWidth(this.contentEl,
                this.browserName);
            if (scrollbarWidth > 0) {
                // Necessary for correct positioning of 'Hide' when scrollbar present.
                if (this.isSidebarExpanded) {
                    scrollbarWidth++;
                }
            }
            return scrollbarWidth;
        },
        bufferBookmark() {
            clearTimeout(this.toBookmark);
            this.toBookmark = setTimeout(() => {
                toggleBookmark({
                    tools: this.tools,
                    tooltip: this.tooltip,
                    bookmarks: this.toolsBookmarkedGraphics,
                    permissions: this.permissions,
                    fnAddBookmark: this.bookmarkGraphic,
                    fnRemoveBookmark: this.deleteBookmark,
                    fnCloseTooltip: this.closeTooltip,
                    fnSetBookmarkActive: this[SET_BOOKMARK_IS_ACTIVE]
                });
                this.logUiClickEvent('bookmark');
                // Long pause before resetting delay back to 0
                this.resetBookmarkDelay();
            }, this.bookmarkBufferDelayMs);

            this[SET_BOOKMARK_BUFFER_DELAY](BOOKMARK_BUFFER_PAUSE_MS);
        },
        closeTooltip(tooltipId) {
            if (this.toolsBookmarkedGraphics.length
              >= this.permissions.bookmarkLimit) {
                this[RESET_TOOLTIP]();
            }
            if (tooltipId) {
                this.logUiClickEvent(tooltipId);
            }
            this[TOGGLE_TOOLTIP_HIDE]();
        },
        onGraphicScrollEvent(event) {
            this.throttleScrollEvent({
                uiElementName: 'graphicViewerDialog_graphicScroller',
                optData: this.graphicViewerImageKey
            });
            if (this.stickyWrapperEl && event.target.scrollTop > 0) {
                elAddClass(this.stickyWrapperEl, 'title-scrolled');
                return;
            }
            this.stickyWrapperEl && elRemoveClass(this.stickyWrapperEl, 'title-scrolled');
        },
        updateStickyTitleDimensions() {
            if (this.stickyWrapperEl && this.stickyTitleGraphic && this.contentEl) {
                const scrollbarWidthOffsetPx = this.contentYOverflowed
                    && this._getVerticalScrollbarWidth() === 0
                    && !this.isBrowserOSAppleDesktop
                    ? VERTICAL_SCROLLBAR_WIDTH_PX
                    : this._getVerticalScrollbarWidth();
                this.stickyWrapperEl.style.width
                    = `${this.contentEl.getBoundingClientRect().width
                          - scrollbarWidthOffsetPx}px`;
                this.stickyTitleGraphic.style.paddingTop
                    = `${this.stickyWrapperEl.getBoundingClientRect().height
                          + STICKY_TITLE_BOTTOM_PADDING_PX}px`;
            }
        },
        setStickyGraphicTitle() {
            this.stickyTitleGraphic
                = document.querySelector('.graphic-viewer-content .graphic .cntnt');
            const graphicTitle = document.querySelector('.graphic .figure .ttl');

            const abortSetup = !this.contentEl
                || this.contentEl.getBoundingClientRect().width < STICKY_TITLE_BUFFER_PX
                || this.contentEl.getBoundingClientRect().height < STICKY_TITLE_BUFFER_PX;

            if (abortSetup) {
                this.stickyTitleGraphic = false;
                return;
            }

            if (this.stickyTitleGraphic && graphicTitle
                   && !this.isGraphicTypeNormal) {
                graphicTitle.innerHTML
                  = graphicTitle.innerHTML.replace(/&nbsp;/g, ' ');
                this.stickyWrapperEl = wrap(graphicTitle);
                elAddClass(this.stickyTitleGraphic, 'sticky-title-graphic');
                elAddClass(graphicTitle, 'sticky-title');
                graphicTitle.setAttribute('role', 'heading');
                graphicTitle.setAttribute('aria-level', '2');
                elAddClass(this.stickyWrapperEl, 'sticky-title-wrapper');
                this.updateStickyTitleDimensions();
            }
            this.setZoomControlPosition();
        },
        setIsImage() {
            const img = document.querySelector('.graphic__overlay-content img');
            const videoEle = document.querySelector('.graphic-viewer-content .videoplayer');
            this.isImage = !!img && !videoEle;
        },
        async setupGraphicCounterTooltips() {
            const counters = document.querySelectorAll('.counterHoverTooltip');
            if (counters) {
                this.toolbarCounterTooltip = new GraphicToolBarLinksHoverTooltip(
                    counters,
                    this.tooltipConfig
                );

                try {
                    await this.toolbarCounterTooltip.initialize();
                }
                catch (error) {
                    this.toolbarCounterTooltip = null;
                }
            }
        },
        setZoomControlPosition() {
            setTimeout(() => {
                const title = document.querySelector('.ttl');
                const zoom = document.querySelector('.graphic-viewer-zoom');
                if (title && zoom) {
                    const position = title.getBoundingClientRect();

                    const newTop = position.bottom + 20;
                    const newLeft = position.left + 20;

                    zoom.style.top = `${newTop}px`;
                    zoom.style.left = `${newLeft}px`;
                }
            }, SET_ZOOM_CONTROL_DELAY_MS);

        }
    }
};
</script>
<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphic__modal .utd-modal-content {
  .ds1-mvw-8();
  position: relative;
  width: 100%;
  overflow: hidden;

  &.utd-modal-maxed {
    max-width: calc(100vw - @ds1-spacing-multiplier);
  }

  .graphic-viewer-content {
    margin-left: @ds1-spacing-multiplier * 2;

    > .graphic {
      /** [CORE-12535]: !important needed to override .graphic width */
      width: auto !important;
      float: none;
      clear: both;
      padding-right: @ds1-spacing-multiplier * 5;

      > .figure {
        /** [CORE-12535]: !important needed to override .figure width */
        width: auto !important;
        float: none;
        margin-left: 0;

        &.utd-rtl {
          direction: inherit;
        }

        &:not([style]) {
          margin-right: @ds1-spacing-multiplier * 11;
        }
      }
    }

    &.widthZoom {
      >.graphic {
        padding-left: 60px;
      }

      .sticky-title-wrapper {
        margin-left: -76px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    max-width: calc(100vw - 80px);
  }

  @media screen and (min-width: 1680px) {
    max-width: calc(100vw - 80px);
  }

  .invisible {
    opacity: 0;
  }
}

.graphic-viewer-feedback-link .wkce-icon-chat-box {
    font-size: 16px;
}

</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/global/variables';
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
// If this next value is adjusted, you should also adjust
// the SIDEBAR_ANIMATION_DELAY_MS above.
@SIDEBAR_ANIMATION_MS: 0.4s;
@TOGGLE_ANIMATION_MS: 0.1s;
@SPINNER_COLOR: @DS1-UTD-JS2-LINK-BASE;
@GRAPHIC-VIEWER-TOOLBAR-BG-COLOR: @WKCE-GRAY-TINT5;
@SCROLL-SLIDER-WIDTH: 17px;
@GRAPHIC-SIDEBAR-WIDTH: 374px;

:deep(.utd-notification) {
  .ds1-mt-8();
  .ds1-mr-8();
  max-width: 900px;
}

.graphic__graphic-loading {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  bottom: 0;
  background: @DS1-BOX-SHADOW-RGBA;
  z-index: @ZINDEX-GVD-GRAPHIC-LOADING;
  text-align: center;

  .graphic__sidebar-expanded & {
    right: @GRAPHIC-SIDEBAR-WIDTH;
  }

  .wkce-icon-filled-spinner-alt {
    font-size: 3em;
    height: 48px;
    color: @SPINNER_COLOR;
  }

  &::before,
  .wkce-icon-filled-spinner-alt {
    display: inline-block;
    vertical-align: middle;
  }

  &::before {
    content: '';
    width: 0;
    height: 100%;
  }
}

.graphic__overlay-close {
  .ds1-ma-0();
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 12px;
}

:deep(.copyright) {
  padding-left: @ds1-spacing-multiplier * 7;
}

.graphic__toolbar {
  height: 40px;
  overflow: hidden;
  background: @GRAPHIC-VIEWER-TOOLBAR-BG-COLOR;
  border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;

  &.hideItems .graphic__toolbar-links > li {
    visibility: hidden;
  }

  &.collapsed .graphic__toolbar-links > li:not(#toolbarGraphicRating,#toolbarCounter) {
    width: 24px;
  }
}

.graphic__toolbar-links {
  display: flex;
  list-style-type: none;
  margin-top: 8px;

  > li {
    display: inline-block;
    visibility: visible;
    cursor: pointer;
    overflow: hidden;

    > a,
    :deep(a),
    :deep(.utd-bookmark .utd-button) {
      .ds1-utd-size-2();
      .ds1-mr-3();
      .ds1-ml-0();
      height: 21px;
      white-space: nowrap;
      vertical-align: baseline;
      display: inline;

      &:focus,
      &:focus-visible {
        outline-offset: 0;
        outline-width: 2px;
      }

      &:hover {
        text-decoration: none;
      }

      .Firefox & {
        outline-offset: -2px;
      }
    }

    &#bookmarkGraphic{
      overflow: visible;
    }

    [class^='wkce-icon-']::before {
      .ds1-mr-1();
      position: relative;
      font-size: 16px;
      top: 3px;
    }
  }

  .toolbar-star-rating {
    .ds1-mr-2();
    border-left: @DS1-LEXI-TABLE-BORDER;
  }

  .star-rating__container {
    .ds1-ml-3();

    :deep(.star-rating-label) {
      .ds1-mr-1();
      font-size: 14px;
    }

    :deep(.wkce-icon-star::before),
    :deep(.wkce-icon-filled-star::before) {
      width: 14px;
      font-size: 14px;
    }
  }
}

.graphic__overlay-container {
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
}

:deep(.sticky-title-wrapper) {
  .ds1-pr-0();
  position: fixed;
  margin-left: -16px;
  background: @DS1-UTD-PRIMARY-BG-COLOR;
  border-bottom: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;

  .ttl.sticky-title {
    .ds1-pv-1();
    .ds1-pl-2();
    .ds1-pr-3();
  }

  &.title-scrolled {
    box-shadow: @DS1-BOX-SHADOW-RAISED;
  }
}

.graphic__overlay-content {
  overflow: auto;
  position: relative;
  margin-right: 0;
  z-index: @ZINDEX-GVD-OVERLAY-CONTENT;

  &:focus {
    outline: none;
  }

  &.isFixedTable {
    overflow: hidden;
  }

  .graphic__sidebar-expanded & {
    margin-right: @GRAPHIC-SIDEBAR-WIDTH;
  }

  .utd-modal-maxed & {
    height: calc(100vh - 49px);
  }

  .animate-sidebar & {
    .css-transition(margin @SIDEBAR_ANIMATION_MS);
  }

  .graphic-viewer-content {
    :deep(.graphic_lgnd),
    :deep(.graphic_footnotes),
    :deep(.graphic_reference) {
      max-width: 1000px;
    }
  }
}

.graphic-viewer-content {
  &.visiblyHidden {
    visibility: hidden;
  }

  :deep(.graphic .ttl) {
    .ds1-utd-size-3();
    .ds1-utd-weight-bold();
  }
}

.graphic__overlay-toggle {
  .ds1-utd-weight-600();
  position: absolute;
  top: 12px;
  right: 48px;
  cursor: pointer;
}

:deep(.graphic__height-extents) {
  // Sets max available height for the graphic content container
  // Viewport height - top/bottom dialog margins - minus toolbar height
  height: calc(100vh - ((@ds1-spacing-multiplier * 8) + 41px));

  :deep(.utd-modal-maxed &) {
    max-height: none;
  }
}

.graphic__sidebar {
  position: absolute;
  top: 41px;
  bottom: 0;
  right: -@GRAPHIC-SIDEBAR-WIDTH;
  display: flex;
  flex-direction: column;
  border-left: @DS1-UTD-GRAY-SINGLE-BORDER;
  width: @GRAPHIC-SIDEBAR-WIDTH;
  background: @GRAPHIC-VIEWER-TOOLBAR-BG-COLOR;
  box-sizing: border-box;

  .graphic__sidebar-expanded & {
    right: 0;
  }

  .animate-sidebar & {
    .css-transition(right @SIDEBAR_ANIMATION_MS);
  }
}

.graphic__sidebar-toggle {
  .ds1-ma-0();
  .ds1-mt-1();
  .ds1-pa-0();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 26px;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  color: @DS1-UTD-GRAY-TEXT-COLOR;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  position: absolute;
  z-index: @ZINDEX-GVD-THUMBNAIL-TOGGLE;
  opacity: 1;

  .animate-sidebar & {
    opacity: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: @DS1-FLYOUT-TOGGLE-HOVER-BGCLR;
  }

  &.wkce-icon-chevron-left {
    right: 0;
    box-shadow: @DS1-BOX-SHADOW-RAISED;

    .content-overflow-y & {
      right: @SCROLL-SLIDER-WIDTH;
    }
  }

  &.wkce-icon-chevron-right {
    right: 336px;
  }
}

.graphic__container {
  min-width: 600px;

  .utd-modal-maxed & {
    height: calc(100vh - @ds1-spacing-multiplier);
  }
}

.graphic-viewer-image-nav {
  position: absolute;
  z-index: @ZINDEX-GVD-IMAGE-NAV;
  top: 50%;
  .border-radius(50%);
  display: block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: @DS1-UTD-JS2-LINK-BASE;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  box-shadow: @DS1-BOX-SHADOW-OVERLAY;
  border: @DS1-UTD-GRAY-SINGLE-BORDER;

  &::before {
    position: relative;
    top: 11px;
  }

  &:hover {
    background-color: @DS1-UTD-JS2-LINK-HOVER;
    color: @DS1-UTD-PRIMARY-BG-COLOR;
    text-decoration: none;
  }
}

.wkce-icon-arrow-right {
  right: -10px;

  .content-overflow-y & {
    right: 16px;
  }

  .graphic__sidebar-expanded & {
    right: @GRAPHIC-SIDEBAR-WIDTH;
  }

  .content-overflow-y.graphic__sidebar-expanded & {
    right: 390px;
  }

  .animate-sidebar & {
    .css-transition(right @SIDEBAR_ANIMATION_MS);
  }
}

.wkce-icon-arrow-left {
  left: 0;
}
</style>
