<template>
  <div class="ibn-box">
    <label for="ibn-input" class="ibn-box-label">
      Find brand name(s) by country
      <span v-if="showUSandCANote">
        (for United States and Canada see separate Brand Names sections)
      </span>
    </label>
    <div class="ibn-box-container">
      <span class="magnifier-icon" />
      <input id="ibn-input"
             ref="ibnInput"
             :value="ibnSearchText"
             class="ibn-input"
             type="text"
             placeholder="Enter a Country or Country Code"
             @input="onInput($event)"
             @blur="onBlur"
             @focus="onFocus"
             @keydown="onKeyDown($event)">
      <span v-show="ibnSearchText"
            class="clear-icon"
            tabindex="0"
            role="button"
            aria-label="Clear the search"
            @click="clearSearch"
            @keydown.enter="clearSearch" />
      <ul v-if="isSuggestionDropdownVisible" ref="dropdownMenu" class="dropdown-menu">
        <li class="dropdown-menu-item no-result">
          <p class="italic">{{ $t('SEARCH.NO_COUNTRY_MATCH') }}</p>
        </li>
        <li v-for="(country, index) in filteredCountries"
            :key="formatCode(country)"
            class="dropdown-menu-item">
          <a :class="{'highlighted': index === ibnCountrySelectedIndex}"
             tabindex="0"
             role="listitem"
             @click.prevent="selectCountry(country)"
             v-text="formatCode(country)" />
        </li>
      </ul>
    </div>
    <div v-if="resultBoxTitle" class="drug-names">
      <h4 v-text="resultBoxTitle" />
      <p v-if="resultBoxText" v-text="resultBoxText" />
      <p v-else class="italic">{{ $t('SEARCH.NO_COUNTRY_MATCH') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { C_EVENTS, C_KEYCODES, C_TOPIC } from '_acaSrc/utility/constants';
import expressionSearchFilter from '_acaSrc/filters/ExpressionSearchFilter';
import { getDocument } from '_acaSrc/utility/DOM';
import PubSub from '_acaSrc/utility/PubSub';

const IBN_TITLE_SELECTOR = '.fbnlist .drugH1';
const RENDERING_TIMEOUT = 500;

export default {
    data() {
        return {
            ibnCountrySelected: null,
            ibnSearchText: '',
            ibnCountrySelectedIndex: null,
            isIbnInputHasFocus: false
        };
    },
    computed: {
        ...mapGetters('topic', [
            'ibnList',
            'topicId',
            'topicTitle',
            'topicSubtype'
        ]),
        ...mapGetters('device', [ 'isBrowserNameMSIE' ]),
        isSuggestionDropdownVisible() {
            return this.ibnSearchText.length > 0 && this.isIbnInputHasFocus;
        },
        isNoResultError() {
            return this.ibnSearchText
                && this.ibnSearchText.trim() && !this.filteredCountries.length;
        },
        filteredCountries() {
            if (!this.ibnSearchText.trim()) {
                return [];
            }
            const expression = {
                country: this.ibnSearchText.trim(),
                countryCode: this.ibnSearchText.trim()
            };
            const searchByCountryItems = expressionSearchFilter(this.ibnList, expression) || [];
            const searchByFormatCode = this.ibnList.filter(item => {
                return this.formatCode(item).indexOf(this.ibnSearchText.trim()) !== -1;
            }) || [];

            // Returns array with unique items
            return [ ...new Set([
                ...searchByCountryItems,
                ...searchByFormatCode
            ]) ];
        },
        resultBoxTitle() {
            if (!this.ibnCountrySelected) {
                return '';
            }
            return `${this.ibnCountrySelected.countryCode} ${this.ibnCountrySelected.country}`;
        },
        resultBoxText() {
            if (!this.ibnCountrySelected || !this.ibnCountrySelected.drugNames.length) {
                return '';
            }
            return this.ibnCountrySelected.drugNames.join('; ');
        },
        showUSandCANote() {
            return this.topicSubtype === C_TOPIC.SUBTYPES.DRUG_GENERAL
                || this.topicSubtype === C_TOPIC.SUBTYPES.DRUG_PEDIATRIC;
        }
    },
    watch: {
        isNoResultError(newVal) {
            if (!newVal) {
                return;
            }
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'get-brand-name',
                optData: this.getOptEventData(true),
                contentId: this.topicId
            });
        },
        ibnSearchText() {
            this.ibnCountrySelectedIndex = null;
        },
        isSuggestionDropdownVisible(newVal) {
            if (!newVal) {
                return;
            }
            this.$nextTick(() => {
                const dropdownElem = this.$refs.dropdownMenu;
                if (!this.isBrowserNameMSIE) {
                    dropdownElem && dropdownElem.scrollIntoView && dropdownElem.scrollIntoView({
                        block: 'nearest',
                        inline: 'start'
                    });

                    // Scroll back to center if small height of screen
                    const topicTitleElem = document.querySelector('#topic-title');
                    if (!this.$refs.ibnInput || !topicTitleElem) {
                        return;
                    }
                    if (this.$refs.ibnInput.getBoundingClientRect().top
                            < topicTitleElem.getBoundingClientRect().bottom) {
                        this.$refs.ibnInput.scrollIntoView({
                            block: 'center',
                            inline: 'nearest'
                        });
                    }
                }
            });
        }
    },
    mounted() {
        this.injectInTopic();

        // Reset IBN selector when Find-in-Topic state changes
        new PubSub().subscribe('wkutd.relink-outline-events', this.injectInTopic);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.relink-outline-events', this.injectInTopic);
    },
    methods: {
        ...mapActions('topic', [
            'whenTopicElementsStable'
        ]),
        async injectInTopic() {
            await this.whenTopicElementsStable();
            const viewParentEle = getDocument().querySelector(IBN_TITLE_SELECTOR);
            if (viewParentEle && this.$el.parentNode === viewParentEle.parentNode) {
                return;
            }

            this.$el.parentNode && this.$el.parentNode.removeChild(this.$el);
            viewParentEle && viewParentEle.insertAdjacentElement('afterend', this.$el);
        },
        hideDropdown() {
            this.ibnSearchText = '';
        },
        clearSearch() {
            this.hideDropdown();
        },
        selectCountry(country) {
            this.ibnSearchText = this.formatCode(country);
            this.ibnCountrySelected = country;
            this.$refs.ibnInput.blur();

            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'get-brand-name',
                optData: this.getOptEventData(),
                contentId: this.topicId
            });
        },
        getOptEventData(noResult) {
            const delimiter = '---';
            let genericDragName = this.topicTitle.replace(/\s/g, '-');
            genericDragName = genericDragName.substring(0, genericDragName.indexOf(':'));
            if (noResult) {
                return `${genericDragName}${delimiter}no-result-found${delimiter
                }${this.ibnSearchText}`;
            }
            return `${genericDragName}${delimiter}${this.ibnCountrySelected.countryCode}${delimiter
            }itemsCount-${this.ibnCountrySelected.drugNames.length}`;
        },
        onInput(e) {
            this.ibnSearchText = e.target.value;
        },
        onBlur() {
            setTimeout(() => {
                this.isIbnInputHasFocus = false;
                this.ibnCountrySelectedIndex = null;
            }, RENDERING_TIMEOUT);
        },
        onFocus() {
            this.isIbnInputHasFocus = true;
        },
        formatCode(country) {
            return `${country.countryCode} ${country.country}`;
        },
        onKeyDown(e) {
            if (!this.isSuggestionDropdownVisible) {
                return;
            }
            if (e.keyCode === C_KEYCODES.ESCAPE_KEYCODE) {
                this.hideDropdown();
            }

            if (e.keyCode === C_KEYCODES.UP_ARROW || e.keyCode === C_KEYCODES.DOWN_ARROW) {
                e.preventDefault();
                this.setIbnCountrySelectedIndex(e);
                this.scrollIbnListIntoView();
            }

            if (e.keyCode === C_KEYCODES.ENTER_KEY && this.ibnCountrySelectedIndex !== null) {
                this.selectCountry(this.filteredCountries[this.ibnCountrySelectedIndex]);
            }
        },
        setIbnCountrySelectedIndex(e) {
            if (this.ibnCountrySelectedIndex === null) {
                this.ibnCountrySelectedIndex = 0;
                return;
            }
            if (e.keyCode === C_KEYCODES.DOWN_ARROW) {
                if (this.ibnCountrySelectedIndex >= this.filteredCountries.length - 1) {
                    this.ibnCountrySelectedIndex = 0;
                }
                else {
                    this.ibnCountrySelectedIndex++;
                }
            }
            else if (this.ibnCountrySelectedIndex === 0) {
                this.ibnCountrySelectedIndex = this.filteredCountries.length - 1;
            }
            else {
                this.ibnCountrySelectedIndex--;
            }
        },
        scrollIbnListIntoView() {
            this.$nextTick(() => {
                const highlightedElem = this.$el.querySelector('.highlighted');
                if (!highlightedElem) {
                    return;
                }

                if (this.isBrowserNameMSIE) {
                    const dropdownElem = this.$refs.dropdownMenu;
                    dropdownElem.scrollTop = highlightedElem.offsetTop - dropdownElem.offsetTop;
                    return;
                }

                highlightedElem.scrollIntoView({ block: 'nearest', inline: 'start' });
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) '~_acaAssets/global/global.less';
@IBN-BOX-WIDTH: 500px;

#topicContainer {
  .ibn-box {
    .ds1-mt-1();
    .ds1-mb-4();

    @media only screen and (min-width: 768px) {
      .ds1-ml-2();
    }

    .drug-names {
      .ds1-ph-2();
      .ds1-pv-1();
      .ds1-mt-1();
      border: @DS1-UTD-GRAY-SINGLE-BORDER;
      max-width: @IBN-BOX-WIDTH;
      box-sizing: border-box;

      > h4 {
        .ds1-ml-0();
      }

      > p {
        .ds1-mt-compact();
        font-size: 0.875em;
      }
    }
  }

  .ibn-box-container {
    position: relative;
    max-width: @IBN-BOX-WIDTH;

    .ibn-input {
      .ds1-ph-4();
      width: 100%;
      height: 38px;
      box-sizing: border-box;
      border: solid 1px @WKCE-GRAY-TINT1;

      &::-ms-clear { /* stylelint-disable-line selector-no-vendor-prefix */
        display: none;
        height: 0;
        width: 0;
      }
    }

    .clear-icon,
    .magnifier-icon {
      .ds1-ml-1();
      position: absolute;
      top: 0;
      display: flex;
      height: 100%;
      align-items: center;

      &::after {
        display: block;
        position: relative;
        color: @WKCE-GRAY-TINT1;
        font-family: wk-icons-open;
      }
    }

    .clear-icon {
      .ds1-mr-1();
      right: 0;
      cursor: pointer;

      &::after {
        content: '\e866';
        font-size: 16px;
      }
    }

    .magnifier-icon {
      &::after {
        content: '\e87a';
        font-size: 22px;
      }
    }
  }

  .ibn-box-label {
    .ds1-utd-size-2();
    .ds1-utd-weight-bold();

    .textSize-L & {
      .ds1-utd-size-4();
    }
  }

  .drugBrandNames .dropdown-menu {
    .reset-ul();
    .ds1-pv-compact();
    position: absolute;
    top: 42px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height: 210px;
    width: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border: 1px solid @WKCE-GRAY-TINT2;
    box-shadow: @DS1-BOX-SHADOW-THUMBNAILS;
    z-index: @ZINDEX-IBN-DROPDOWN;

    @media only screen and (min-width: 768px) {
      max-height: 300px;
    }
  }

  .dropdown-menu-item {
    .reset-li();
    display: flex;
    flex-direction: column;

    &.no-result {
      display: none;

      &:only-child {
        display: flex;
      }

      > p {
        .ds1-pv-compact();
        .ds1-utd-size-2();
        .ds1-ph-2();
        .ds1-ma-0();

        .textSize-L & {
          .ds1-utd-size-3();
        }
      }
    }

    > a {
      .ds1-ph-2();
      .ds1-utd-size-2();
      .ds1-utd-weight-bold();
      .ds1-pv-compact();
      text-decoration: none;
      border-bottom: none;
      color: @WKCE-GRAY-SHADE2;
      cursor: pointer;

      &:hover,
      &.highlighted {
        background-color: @WKCE-BLUE-TINT5;
      }

      .textSize-L & {
        .ds1-utd-size-3();
      }
    }
  }
}
</style>
