<template>
  <div class="login-register-header-section wk-header-container">
    <ul class="wk-nav" role="menu">
      <li v-if="showRegisterButton" role="menuitem">
        <div class="login-register-item">
          <utd-button class="register-btn"
                      button-type="link"
                      :button-link="registerHref"
                      button-size="small"
                      button-color="green-shade-3">{{ registerButtonText }}</utd-button>
        </div>
      </li>
      <li v-if="!isLoggedIn" role="menuitem">
        <div class="login-register-item">
          <utd-button class="login-btn"
                      button-type="link"
                      :button-link="'/login'"
                      button-size="small"
                      button-color="blue">{{ loginButtonText }}</utd-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

export default {
    components: { UtdButton },
    computed: {
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('login', [ 'loginRegisterButton', 'loginHref' ]),
        ...mapGetters('feature', [ 'isHomepageRedesign2024' ]),
        registerButtonText() {
            return this.$t('LOGIN.REGISTER');
        },
        loginButtonText() {
            return this.$t('MISC.LOG_IN');
        },
        registerHref() {
            return `${this.loginHref}?source=header`;
        },
        isLoggedIn() {
            return this.userLoggedIn;
        },
        showRegisterButton() {
            return this.loginRegisterButton
                && !this.isLoggedIn;
        }
    }
};
</script>

<style lang="less" scoped>
.utdWkHomePage2024 #loginRegisterButtons .wk-nav {
  display: block;
}
</style>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles.less';
@import (reference) '~_acaAssets/utd-button/utd-button';

.login-register-header-section.wk-header-container .wk-nav,
.utdWkHomePage2024  .wk-navbar .login-register-header-section .wk-nav > li {
  a.utd-button.utd-button-link {
    margin-right: 24px;
    font-size: 14px;
    font-weight: 500;
    color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
  }
}

.login-register-header-section.wk-header-container .wk-nav {
  display: block;
}

@media (max-width: 767px) {
  .utdWkHeader .wk-navbar .wk-navbar-container {
    .mobileonDesktop&,
    .isSmallScreen&,
    .isTablet & {
      .login-register-header-section .wk-nav li {
        float: left;

        &:nth-child(1) {
          margin: 12px 6px 12px 12px;
        }

        &:nth-child(2) {
          margin: 12px 12px 12px 6px;
        }

        a.utd-button.utd-button-link {
          margin-right: unset;
          padding: 7px 16px;
          border-width: 1px;

          &.utd-button-color-green-shade-3 {
            .utd-button-color-green-shade-3-style();
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .utdDeskBanner {
      &.navOverflowed {
        .login-register-header-section {
          display: block;

          .fixedToolbar& {
            visibility: hidden;
          }
        }
      }
    }

    #utdNavRoot {
      .login-register-header-section {
        display: none;
        position: absolute;
        right: 0;
      }

      &.navOverflowed .login-register-header-section {
          visibility: hidden;
      }
    }
  }
}

@media only screen and (min-width: 812px) {
  .isDesktop.isHomeSearchEmpty.anonymous-user {
    .utdDeskBanner .login-register-header-section {
      display: inline-block;
      
  
    }
  }
}

@media only screen and (min-width: 1126px) {
  .isDesktop.utdWkHomePage2024.anonymous-user:not(.isHomeSearchEmpty) {
    .utdDeskBanner .login-register-header-section {
      display: inline-block;
    }
  }
}

@media (min-width: 37.5rem) {
  .wk-navbar .wk-nav > li > a.utd-button.utd-button-link {
    border-width: 1px;
  }
}
</style>
