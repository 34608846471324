<template>
  <div id="slaMessageContainer">
    <div id="closeMessage">
      <a id="close" class="wkce-icon-filled-close" @click.prevent="closeSla" />
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div id="slaMessage" @click="logSlaClick" v-html="licenseAgreementsHtml" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { safeTimeout } from '_acaSrc/utility/timers';
import { SET_LICENSE_HTML, SET_LICENSE_SHOWN } from '_acaSrc/store/auth.store';
import { C_EVENTS } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';

const SHOW_SLIDER_DELAY_MS = 1000;
const BIND_EXTERNAL_WINDOW_TARGETS_DELAY_MS = 500;
const REMOVE_DISPLAYED_CLASS_DELAY_MS = 100;
const DISPLAYED_CLASS = 'displayed';
const CLOSING_CLASS = 'closing';

export default {
    data() {
        return {
            container: null,
            timers: [],
            cancelSliderTimeout: null,
            timeout2: null
        };
    },
    computed: {
        ...mapGetters('auth', [
            'licenseAgreementsHtml',
            'licenseAgreementsViewed'
        ])
    },
    beforeUnmount() {
        new PubSub().unsubscribe(C_EVENTS.SLA_MESSAGE_EVENT, this.activate);
        this.timers && this.timers.forEach(timer => clearTimeout(timer));
        this.cancelSliderTimeout && this.cancelSliderTimeout();
        this.removeListeners();
    },
    mounted() {
        new PubSub().subscribe(C_EVENTS.SLA_MESSAGE_EVENT, this.activate);
        this.activate();
    },
    methods: {
        ...mapActions('app', [ 'logEvent' ]),
        ...mapMutations('auth', [ SET_LICENSE_HTML, SET_LICENSE_SHOWN ]),
        activate() {
            const { promise, cancelTimeout } = safeTimeout(this.showSliderHelper,
                SHOW_SLIDER_DELAY_MS, { cancelHook: true });
            this.cancelSliderTimeout = cancelTimeout;

            promise.then(() => this.queueCancelTimer(
                setTimeout(() => epicClient
                      && epicClient.isActive()
                      && epicClient.bindExternalWindowTargets(),
                BIND_EXTERNAL_WINDOW_TARGETS_DELAY_MS)));
        },
        queueCancelTimer(timer) {
            this.timers.push(timer);
        },
        showSliderHelper() {
            this[SET_LICENSE_HTML]();

            if (!this.licenseAgreementsHtml || this.licenseAgreementsViewed) {
                return;
            }

            this.container = document.querySelector('#slaMessageContainer');
            this.container && this.container.classList.add(DISPLAYED_CLASS);
            this[SET_LICENSE_SHOWN]();

            // Essentially dismiss SLA on any type of mouse event
            document.addEventListener('click', this.dismissSla);
            document.addEventListener('scroll', this.dismissSla);
        },
        logSlaClick(e) {
            const target = e.target || e.srcElement;
            if (target.id === 'sla_message' || target.id === 'drug_message') {
                this.logEvent({
                    eventUrl: 'event/slaClick/json',
                    eventParams: { type: target.id }
                });
            }
        },
        removeListeners() {
            document.removeEventListener('click', this.dismissSla);
            document.removeEventListener('scroll', this.dismissSla);
        },
        dismissSla(e) {
            // Ensure that clicking on any of the license links will not dismiss the slider
            if (typeof e === 'undefined'
              || !(e.target.id === 'sla_message'
              || e.target.id === 'drug_message')) {
                if (this.container) {
                    this.container.classList.add(CLOSING_CLASS);

                    this.queueCancelTimer(setTimeout(() => {
                        this.container.classList.remove(DISPLAYED_CLASS);
                    }, REMOVE_DISPLAYED_CLASS_DELAY_MS));
                }
                this.removeListeners();
            }
        },
        closeSla() {
            this.logEvent({
                eventUrl: 'event/slaCloseButtonClick/json',
                eventParams: {}
            });
            this.dismissSla();
        }
    }
};
</script>
<style scoped lang="less">
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles.less";

#slaMessageContainer {
  display: none;
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    #slaMessageContainer {
      position: fixed;
      overflow: hidden;
      width: 75%;
      max-height: 100px;
      font-size: 14px;
      font-weight: 500;
      background: @WKCE-BLUE-TINT5;
      border: 1px solid @WKCE-BLUE-TINT3;
      z-index: @ZINDEX-SLA-SLIDER-OVERLAY;
      bottom: -100px;
      opacity: 1;
      margin-left: 12%;
      box-shadow: 0 0 10px #0003; /* stylelint-disable-line color-no-hex */

      #closeMessage {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 8px;
        display: inline-block;
        cursor: pointer;

        > a {
          text-decoration: none;
          color: @WKCE-BLUE-SHADE1;
          font-size: 14px;
          height: 11.73px;
          width: 11.73px;
        }
      }

      #slaMessage {
        margin: 13.5px 100px 13.5px 32px;
      }
    }

    #slaMessageContainer.displayed {
      display: block;
      .css-animate(@keyName:slaSlideUp,@timeSec:0.5s);
      bottom: 0;
    }

    #slaMessageContainer.closing {
      .css-animate(@keyName:cssAnimFadeOut, @timeSec:0.5s);
      opacity: 0;
    }
  }
}
</style>
