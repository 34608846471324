// Original Source: https://css-tricks.com/using-css-transitions-auto-dimensions/
import { getWindow } from '../DOM';

// Important - you need the section element to have the following styles for
// this to work (with height changed to width if using width):

// .some-element {
//   overflow: hidden;
//   height: auto;
//   /* you can change these parameters */
//   transition: height 0.2s ease-out;
// }

// Only works with height currently but width would work the same way
// See css note above.
export function collapseSection(el) {
    // get the height of the element's inner content, regardless of its actual
    // size
    const sectionHeight = el.scrollHeight;

    // temporarily disable all css transitions
    const transition = el.style.transition;
    el.style.transition = '';

    // on the next frame (as soon as the previous style change has taken
    // effect), explicitly set the element's height to its current pixel height,
    // so we aren't transitioning out of 'auto'
    getWindow().requestAnimationFrame
    && getWindow().requestAnimationFrame(function() {
        el.style.height = `${sectionHeight}px`;
        el.style.transition = transition;

        // on the next frame (as soon as the previous style change has taken
        // effect), have the element transition to height: 0
        getWindow().requestAnimationFrame(function() {
            el.style.height = `${0}px`;
            el.style.visibility = 'hidden'; 
        });
    });
}

// Only works with height currently but width would work the same way See css
// note above.
export function expandSection(el) {
    // get the height of the element's inner content, regardless of its actual
    // size
    const sectionHeight = el.scrollHeight;

    // have the element transition to the height of its inner content
    el.style.height = `${sectionHeight}px`;
    el.style.visibility = 'visible';

    // when the next css transition finishes (which should be the one we just
    // triggered)
    const transitionEndHelper = () => {
    // remove this event listener so it only gets triggered once
        el.removeEventListener('transitionend', transitionEndHelper);

        // remove "height" from the element's inline styles, so it can return to its
        // initial value
        el.style.height = null;
    };
    el.addEventListener('transitionend', transitionEndHelper);
}
