<template>
  <button v-if="buttonType === 'button'"
          :class="['utd-button', buttonClasses]"
          v-bind="$attrs"
  ><span v-if="hasLeftIcon"
         class="utd-button-icon"
         aria-hidden="true"
         :class="buttonIconClass"
  /><slot
  /><span v-if="hasRightIcon"
          class="utd-button-icon"
          aria-hidden="true"
          :class="buttonIconClass"
  /></button>
  <a v-else-if="buttonType === 'link'"
     :href="buttonLink"
     :class="['utd-button', 'utd-button-link', buttonClasses]"
     v-bind="$attrs"
     role="button">
    <span v-if="hasLeftIcon"
          class="utd-button-icon"
          aria-hidden="true"
          :class="buttonIconClass"
    />
    <span class="utd-button-link-text"><slot /></span>
    <span v-if="hasRightIcon"
          class="utd-button-icon"
          aria-hidden="true"
          :class="buttonIconClass"
    />
  </a>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        buttonStyle: {
            type: String,
            default: 'default'
        },
        buttonColor: {
            type: String,
            default: 'blue'
        },
        buttonSize: {
            type: String,
            default: 'small'
        },
        buttonIcon: String,
        buttonIconPosition: {
            type: String,
            default: 'left'
        },
        buttonType: {
            type: String,
            required: false,
            default: 'button',
            validator: value => [ 'button', 'link' ].includes(value)
        },
        buttonLink: {
            type: String,
            default: '',
            required: false
        }
    },
    computed: {
        hasLeftIcon() {
            return this.buttonIcon && this.buttonIconPosition === 'left';
        },
        hasRightIcon() {
            return this.buttonIcon && this.buttonIconPosition === 'right';
        },
        buttonIconClass() {
            return `wkce-icon-${this.buttonIcon}`;
        },
        buttonClasses() {
            const aProps = [
                `utd-button-size-${this.buttonSize}`,
                `utd-button-color-${this.buttonColor}`,
                `utd-button-style-${this.buttonStyle}`
            ];

            if (this.buttonIcon) {
                aProps.push(`utd-button-icon-${this.buttonIconPosition}`);
            }

            return aProps;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) '~_acaAssets/utd-button/utd-button';
</style>
