/* eslint-disable camelcase */

import SearchView from '_acaSrc/components/contents/search/SearchView.vue';
import TopicViewWrapper from '_acaSrc/components/contents/topic/TopicViewWrapper.vue';
import GraphicView from '_acaSrc/components/contents/graphic/Graphic.vue';
import TopicAbstract from '_acaSrc/components/contents/topic/TopicAbstract.vue';
import ErrorView from '_acaSrc/components/error/ErrorView.vue';
import Error404 from '_acaSrc/components/error/Error404.vue';
import TableOfContents from '_acaSrc/components/contents/toc/Toc.vue';
import TocAuthorsEditors from '_acaSrc/components/contents/toc/TocAuthorsEditors.vue';
import TopicGrade from '_acaSrc/components/contents/topic/TopicGrade.vue';
import LegalNotice from '_acaSrc/components/settings/LegalNotice.vue';
import TopicDisclosure from '_acaSrc/components/contents/topic/TopicDisclosure.vue';
import TopicContributors from '_acaSrc/components/contents/topic/TopicContributor.vue';
import FormularyInfo from '_acaSrc/components/contents/topic/formulary-info/FormularyInfo.vue';
import PeerReviewer from '_acaSrc/components/contents/topic/TopicPeerReviewer.vue';
import PharmaList from '_acaSrc/components/contents/dxy/PharmaList.vue';
import ExpiredEnterprise from '_acaSrc/components/login/ExpiredEnterprise.vue';
import ServerTest from '_acaSrc/components/utility/ServerTest.vue';
import UptodateMobile from '_acaSrc/components/myuptodate/MyUtdMobile.vue';
import RelatedGraphicsDialog from '_acaSrc/components/contents/graphic/RelatedGraphicsDialog.vue';
import MobileChooseLanguage from '_acaSrc/components/localization/MobileChooseLanguage.vue';
import MobileSettings from '_acaSrc/components/settings/MobileSetting.vue';
import ChangeTextSize from '_acaSrc/components/settings/ChangeTextSize.vue';
import PoliciesLegal from '_acaSrc/components/settings/PoliciesLegal.vue';
import WayfLogin from '_acaSrc/components/login/WayfLogin.vue';
import TwoFactor from '_acaSrc/components/login/TwoFactor.vue';
import LexiContributorsDisclosure
    from '_acaSrc/components/contents/topic/LexiContributorsDisclosure.vue';

import { createRouter } from './router/router';
import Logger from '_acaSrc/utility/Logger';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import UtdRouter from '_acaSrc/utility/router/UtdRouter';

import {
    setBrowserTabTitle,
    removeMetaTagNoindex,
    setMetaTagNoindex
} from '_acaSrc/utility/Browsers';

import store from '_acaSrc/store';
import {
    SET_ERROR_CLASS,
    SET_ERROR_CODE,
    RESET_ERROR,
    SET_ERROR_MESSAGE,
    SET_ERROR_PARAMS
} from '_acaSrc/store/error.store';
import {
    C_UI,
    C_SEARCH,
    C_GRAPHICS,
    C_HTTP_STATUS,
    C_SMGR_ERRORS,
    C_EVENTS
} from '_acaSrc/utility/constants';
import {
    REFRESH_STORED_SETTINGS,
    SET_NO_HEADER_CLASS,
    SET_IS_PRINT_VIEW,
    SET_FIXED_HTML,
    SET_ROUTER,
    SET_IS_SPINNER_VISIBLE
} from '_acaSrc/store/app.store';
import {
    SET_TOC_VIEW,
    SET_TOC_URL_ID,
    SET_TOC_URL_SPECIALTY,
    SET_TOC_URL_SECTION,
    SET_TOC_URL_LANGUAGE_CODE,
    SET_TOC_IS_SHOW_TOC_LANDING
} from '_acaSrc/store/toc.store';
import {
    SET_SEARCHBAR_BODY,
    SET_SEARCHBAR_HEADER,
    SET_SEARCH_PARAMS_AUTO_COMPLETE,
    SET_SEARCH_PARAMS_CONTROL,
    SET_SEARCH_PARAMS_INCLUDE,
    SET_SEARCH_PARAMS_RAW_SENTENCE,
    SET_SEARCH_PARAMS_SOURCE,
    SET_SEARCH_PARAMS_SP,
    SET_SEARCH_PARAMS_TEXT,
    SET_SEARCH_PARAMS_TYPE,
    SET_IS_SEARCH_VIEW,
    SET_IS_HOME_SEARCH_EMPTY,
    SET_SEARCH_STATE,
    SET_SEARCH_FOR,
    SET_IS_HL7,
    SET_SEARCH_RESULTS_SHOW
} from '_acaSrc/store/search.store';
import { getWindow } from '_acaSrc/utility/DOM';
import { removeNullOrUndefinedProperties } from './utility/Object';
import {
    safeDecodeUriCollection,
    cleanResetUriComponent,
    safeDecodeUriComponent,
    urlNeedsImmediateRedirect,
    isDrugInteractionsUrl,
    setQueryParamValue,
    getQueryParamValues,
    queryParamsToCollection,
    getUrlHash
} from '_acaSrc/utility/http';
import { recodeSearchPlusToSpace } from '_acaSrc/utility/contents/search/search';
import PubSub from '_acaSrc/utility/PubSub';
import UtdCache from '_acaSrc/utility/UtdCache';
import {
    SET_TOPIC_URL,
    SET_TOPIC_URL_LANGUAGE,
    SET_TOPIC_LANGUAGE,
    SET_INITIAL_TOPIC_LOAD_HASH
} from '_acaSrc/store/topic/topic.store';
import { SET_IS_MY_UTD_VIEW } from '_acaSrc/store/profile.store';
import { SET_GRAPHIC_PARAMS,
    SET_GRAPHIC_URL_LANGUAGE,
    SET_IS_PRINT_GRAPHIC,
    SET_TOOLS_IS_PRINT_VIEW } from '_acaSrc/store/graphic.store';
import {
    transitionsOnCreate,
    transitionsOnStart,
    transitionsOnSuccess,
    transitionOnBeforeCmeRouteGuard,
    transitionOnBeforeCmeRedeemGuards,
    transitionOnBeforeTopicGraphicsGuard
} from '_acaSrc/utd-app.run';

const routes = [
    {
        url: '/contents/pharmalist?topicId&dosageFormId&drug&search',
        name: 'dxyPharmaList',
        component: PharmaList,
        resolve: {
            content: [ '$stateParams', stateParams => {
                const content = {
                    toolbarTitle: 'CONTENT.PHARMA_LIST',
                    listTitle: stateParams.drug
                };
                return content;
            } ],
            dataAsync: [ '$stateParams', '$transition$', 'content', async(stateParams, transition, content) => {
                const dataAsync = await store.dispatch('dxy/getPharmaList', {
                    topicId: stateParams.topicId,
                    dosageFormId: stateParams.dosageFormId,
                    search: stateParams.search
                });
                transition.to().componentProps = { content, dataAsync };
                return dataAsync;
            } ]
        }
    },
    {
        url: '/contents/packageinsert/:id?search',
        name: 'dxyPackageInsert',
        component: TopicViewWrapper,
        resolve: {
            init: [ '$stateParams', stateParams => {
                store.dispatch('topic/resetTopic');
                return withSpinner(store.dispatch('topic/getTopic', {
                    dataPromise: store.dispatch('dxy/getPackageInsert', {
                        topicId: stateParams.id,
                        search: stateParams.search
                    })
                }));
            } ]
        }
    },
    {
        url: '/contents/search?search&sp&searchType&source&searchControl&autoComplete&include&mainSearchCriteria.v.cs&mainSearchCriteria.v.c&mainSearchCriteria.v.dn&mainSearchCriteria.v.ot&age.v.u&age.v.v&ageGroup.v.c&ageGroup.v.dn&subTopic.v.dn&patientPerson.administrativeGenderCode.c&performer&rawSentence',
        name: 'search',
        component: SearchView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                initSearch(stateParams);
            } ]
        },
        onExit() {
            removeMetaTagNoindex();
        }
    },
    {
        url: '/contents/image?imageKey&topicKey&source&search&rank&section&sectionRank&view&sp&index&display_rank&usage_type&rawSentence',
        name: 'topicGraphic',
        component: GraphicView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return graphic(stateParams);
            } ]
        }
    },
    {
        url: '/contents/image/print?imageKey&topicKey&source&search&rank&section&sectionRank&view&display_rank&usage_type', // DE7341, DE7347
        name: 'topicGraphicPrint',
        component: GraphicView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return graphicPrint(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/image?rank&imageKey&topicKey&source&search&sectionRank&view&index',
        name: 'alternateLanguageGraphic',
        component: GraphicView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return graphic(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/image/print?rank&imageKey&topicKey&source&sectionRank&view',
        name: 'alternateLanguageGraphicPrint',
        component: GraphicView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return graphicPrint(stateParams);
            } ]
        }
    },
    {
        url: '/contents/table-of-contents-home',
        name: 'tableOfContentsLanding',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initTocLanding(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/table-of-contents-home',
        name: 'tableOfContentsLandingLanguage',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initTocLanding(stateParams);
            } ]
        }
    },
    {
        url: '/contents/table-of-contents',
        name: 'tableOfContentsIndex',
        component: TableOfContents,
        data: {
            title: 'Topics By Specialty'
        },
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initToc(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/table-of-contents',
        name: 'tableOfContentsIndexLanguage',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initToc(stateParams);
            } ]
        }
    },
    {
        url: '/contents/table-of-contents/:id/:section',
        name: 'tableOfContentsSection',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initToc(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/table-of-contents/:id/:section',
        name: 'tableOfContentsSectionLanguage',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initToc(stateParams);
            } ]
        }
    },
    {
        url: '/contents/table-of-contents/:id',
        name: 'tableOfContentsSpecialty',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initToc(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/table-of-contents/:id',
        name: 'tableOfContentsSpecialtyLanguage',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initToc(stateParams);
            } ]
        }
    },
    {
        url: '/contents/authors-and-editors',
        name: 'AuthorsAndEditors',
        component: TableOfContents,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initAuthorAndEditors(stateParams);
            } ]
        }
    },
    {
        url: '/contents/authors-and-editors/:specialty',
        name: 'AuthorsAndEditorsSpecialty',
        component: TocAuthorsEditors,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initAuthorAndEditors(stateParams);
            } ]
        }
    },
    {
        url: '/contents/grade/:gradeParamsId',
        name: 'topicGrade',
        component: TopicGrade,
        resolve: {
            grades: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/grades', stateParams);
                const result = { grades: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/legal/:templateName',
        name: 'legalText',
        component: LegalNotice,
        resolve: {
            legal: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                const data = await utdRest('legal/agreement', stateParams);
                const result = { legal: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/legal/china/:templateName',
        name: 'chinaLegalText',
        component: LegalNotice,
        resolve: {
            legal: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                const data = await utdRest('legal/agreement', stateParams);
                const result = { legal: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:topicId/contributor-disclosure',
        name: 'disclosures',
        component: TopicDisclosure,
        data: {
            title: 'Contributor disclosures'
        },
        resolve: {
            disclosures: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/disclosures', stateParams);
                const result = { disclosures: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/lexi-contributors-disclosure',
        name: 'lexiContributors',
        component: LexiContributorsDisclosure,
        data: {
            title: 'Lexicomp contributor disclosures'
        },
        resolve: {
            lexiAllContributorsDisclosure: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/lexi-all-contributors-disclosure', stateParams);
                const result = { lexiAllContributorsDisclosure: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:language/:topicId/contributor-disclosure',
        name: 'disclosureLanguage',
        component: TopicDisclosure,
        data: {
            title: 'Contributor disclosures'
        },
        resolve: {
            disclosures: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/disclosures', stateParams);
                const result = { disclosures: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/pathway/:pathwayId/contributor-disclosure',
        name: 'pathwayDisclosures',
        component: TopicDisclosure,
        resolve: {
            disclosures: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('pathway/disclosures', stateParams);
                const result = { disclosures: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:topicId/contributors',
        name: 'topicContributor',
        component: TopicContributors,
        data: {
            title: 'Contributors'
        },
        resolve: {
            contributors: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/contributors', stateParams);
                const result = { contributors: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:language/:topicId/contributors',
        name: 'topicContributorLanguage',
        component: TopicContributors,
        resolve: {
            contributors: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/contributors', stateParams);
                const result = { contributors: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/pathway/:pathwayId/contributors',
        name: 'pathwayContributor',
        component: TopicContributors,
        data: {
            title: 'Contributors'
        },
        resolve: {
            contributors: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('pathway/contributors', stateParams);
                const result = { contributors: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/rxtransitions/contributors',
        name: 'rxTransitionsContributors',
        component: TopicContributors,
        data: {
            title: 'Contributors'
        },
        resolve: {
            contributors: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('rxtransitions/contributors', stateParams);
                const result = { contributors: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:topic/formulary-info',
        name: 'topicFormularyInfo',
        component: FormularyInfo,
        resolve: {
            formularyInfo: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                const data = await store.dispatch('formulary/resolveFormularyInfo',
                    { params: stateParams });
                const result = { formularyInfo: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/pathway/:pathwaysId/formulary-info?drugIds&source',
        name: 'pathwayFormularyInfo',
        component: FormularyInfo,
        data: {
            title: 'Formulary'
        },
        resolve: {
            formularyInfo: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                const data = await store.dispatch('formulary/resolveFormularyInfo',
                    { params: stateParams });
                const result = { formularyInfo: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:section/peer-reviewers?topicId',
        name: 'peerReviewer',
        component: PeerReviewer,
        resolve: {
            reviewers: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/reviewers', stateParams);
                const result = { reviewers: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/settings/change-language',
        name: 'change-language',
        component: MobileChooseLanguage
    },
    {
        url: '/settings',
        name: 'settings',
        component: MobileSettings
    },
    {
        url: '/settings/change-text-size',
        name: 'change-text-size',
        component: ChangeTextSize
    },
    {
        url: '/settings/policies-legal',
        name: 'licenses',
        component: PoliciesLegal,
        data: {
            title: 'Policies and Legal'
        },
        resolve: {
            legalSet: [ '$transition$', async transition => {
                const data = await utdRest('legalSet/agreement');
                const result = { legalSet: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:topic?search&topicId&source',
        name: 'topic',
        component: TopicViewWrapper,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initTopic(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:topic/print',
        name: 'topicPrint',
        component: TopicViewWrapper,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initTopicForPrint(stateParams);
            } ]
        }
    },
    {
        url: '/contents/:language/:topic?search&topicId&source',
        name: 'topicLanguage',
        component: TopicViewWrapper,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return withSpinner(initTopic(stateParams));
            } ]
        }
    },
    {
        url: '/contents/:language/:topic/print?articleOnly',
        name: 'topicLanguagePrint',
        component: TopicViewWrapper,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initTopicForPrint(stateParams);
            } ]
        }
    },
    {
        url: '/contents/outline/related-graphics/:topic',
        name: 'RelatedGraphics',
        component: RelatedGraphicsDialog,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return initRelatedGraphics(stateParams);
            } ]
        }
    },
    {
        url: '/login',
        name: 'login',
        component: WayfLogin,
        resolve: {
            init: initLogin
        }
    },
    {
        url: '/validate-code',
        name: 'validateCode',
        component: TwoFactor,
        resolve: {
            init: initValidateCode
        }
    },
    {
        url: '/cookies-disabled-icg',
        name: 'icgCookiesDisabledError',
        component: ErrorView,
        resolve: {
            init: errorIcgCookiesDisabled
        }
    },
    {
        url: '/content-not-available-icg',
        name: 'forbiddenIcgAccessMobile',
        component: ErrorView,
        resolve: {
            init: errorIcgNotAvailable
        }
    },
    {
        url: '/trial-error',
        name: 'cannotCreateTrialAccount',
        component: ErrorView,
        resolve: {
            init: trialAccountError
        }
    },
    {
        url: '/error',
        name: 'error',
        component: ErrorView,
        resolve: {
            init: error500
        }
    },
    {
        url: '/unspecified-error',
        name: 'error500',
        component: ErrorView,
        resolve: {
            init: error500
        }
    },
    {
        url: '/page-not-found',
        name: 'pageNotFound',
        component: Error404,
        resolve: {
            init: error404
        }
    },
    {
        url: '/cme-cert-not-found',
        name: 'cmeCertError',
        component: ErrorView,
        resolve: {
            init: errorCmeCertNotFound
        }
    },
    {
        url: '/page-not-available',
        name: 'notAvailableMobile',
        component: ErrorView,
        resolve: {
            init: errorPageNotAvailable
        }
    },
    {
        url: '/content-not-available',
        name: 'forbiddenAccessMobile',
        component: ErrorView,
        resolve: {
            init: errorContentNotAvailable
        }
    },
    {
        url: '/guid-error?status',
        name: 'smgrGuidError',
        component: ErrorView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return smgrGuidError(stateParams);
            } ]
        }
    },
    {
        url: '/share-content-messaging?errorMessage',
        name: 'contentShareMessaging',
        component: ErrorView,
        resolve: {
            init: [ '$stateParams', stateParams => {
                return contentShareMessage(stateParams);
            } ]
        }
    },
    {
        url: '/view-error',
        name: 'viewError',
        component: ErrorView,
        resolve: {
            init: error
        }
    },
    {
        url: '/contents/:topicId/abstract/:abstractIdList',
        name: 'topicAbstract',
        component: TopicAbstract,
        resolve: {
            abstracts: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/abstracts', stateParams);
                const result = { abstracts: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/contents/:language/:topicId/abstract/:abstractIdList',
        name: 'topicLanguageAbstract',
        component: TopicAbstract,
        resolve: {
            abstracts: [ '$stateParams', '$transition$', async(stateParams, transition) => {
                store.commit(`error/${RESET_ERROR}`);
                const data = await utdRest('topic/abstracts', stateParams);
                const result = { abstracts: data };
                transition.to().componentProps = result;
                return result;
            } ]
        }
    },
    {
        url: '/expired-enterprise',
        name: 'enterprise',
        component: ExpiredEnterprise
    },
    {
        url: '/server-test',
        name: 'server-test',
        component: ServerTest
    },
    /**
     * CME future state definitions
     */
    {
        name: 'cme.**',
        url: '/cme',
        lazyLoad: () => lazyLoadCmeModule()
    },
    {
        name: 'cmeRegister.**',
        url: '/cme/register',
        lazyLoad: () => lazyLoadCmeModule()
    },
    {
        name: 'account.**',
        url: '/account',
        lazyLoad: () => lazyLoadAccountModule()
    },
    {
        url: '/myuptodatemobile?myUtdTab',
        name: 'myuptodatemobile',
        component: UptodateMobile,
        resolve: {
            init: loadMobileHistory
        }
    }
];

// eslint-disable-next-line no-unused-vars
const determineRedirect = (matchValue, urlParts, router) => {
    const search = safeDecodeUriCollection(urlParts.search);
    let urlPath = urlParts.path;

    if (urlPath === '/' || urlPath === '/context.html') {
        return '/contents/search';
    }
    // If the URL being processed has parameters...
    if (search) {
        // If the parameters do not already contain "redirect"...
        if (!search.redirect) {
            // Compile parameters back into URL, and append "redirect=true"
            // so that if we see that parameter the next time we know to
            // throw an error to prevent infinite redirects.
            urlPath += `?${Object.keys(search).map(function(k) {
                return `${encodeURIComponent(k)}=${encodeURIComponent(search[k])}`;
            }).join('&')}&redirect=true`;
            getWindow().location = urlPath;
        }
        else {
            // We saw "redirect" on the parameter list, abort to prevent infinite redirects
            return '/page-not-found';
        }
    }
    else {
        // URL contains no params, process URL appending with redirect flag
        getWindow().location = `${urlPath}?redirect=true`;
    }
};

// Backward-compatible sort function for routes registration,
// see UI-Router sorting fn docs for more
const sortByRegistrationOrder = (a, b) => {
    // each rule has a $id which is its registration order
    return a.$id - b.$id;
};

// See here for UrlRule object
// https://ui-router.github.io/ng1/docs/latest/interfaces/url.urlrule.html
const removeTrailingSlashRule = {
    match(urlParts) {
        const url = urlParts.path;
        if (!isDrugInteractionsUrl(url)) {
            return (url.lastIndexOf('/') === url.length - 1) && url !== '/';
        }
    },
    handler(matchValue, urlParts) {
        const url = urlParts.path;
        return url.substr(0, url.length - 1);
    },
    type: 'URLMATCHER',
    matchPriority() {
        // it has the highest priority of all route handlers:
        return 9999;
    },
    priority: 9999
};

// See here for UrlRule object
// https://ui-router.github.io/ng1/docs/latest/interfaces/url.urlrule.html
const removeDotDoRule = {
    match(urlParts) {
        return urlParts.path.lastIndexOf('.do') !== -1;
    },
    handler(matchValue, urlParts) {
        const url = urlParts.path;
        return url.substr(0, url.length - 3);
    },
    type: 'URLMATCHER',
    matchPriority() {
        return 1;
    },
    priority: 1
};


const router = createRouter({
    mode: 'history',
    strict: false,
    sortFn: sortByRegistrationOrder,
    routes
});

router.actualLocation = '';

router.onGlobalClick((event, newUrl) => {
    // Do not allow for disabled pathways to be navigated to
    if (event.target.className === 'pathway-unavailable'
     && event.target.attributes.onclick.nodeValue === 'return false;') {
        // Return true here to above the click
        return true;
    }
    // When user clicks on a Pathway's link, we need to immediately load the page
    // without letting the router try to parse the URL.
    // This is to circumvent our app's default "route not found" behavior, which
    // adds an extra item in the browser's history. This extra item in history
    // is a bad user experience when the user tries to use the back button.
    if (urlNeedsImmediateRedirect(newUrl)) {
        event.preventDefault();

        // we still need redirect=true as a safety valve to prevent infinite redirects,
        // especially when developing locally.
        getWindow().location = setQueryParamValue(newUrl, 'redirect', 'true');

        // Return true here to disable further global link click processing.
        return true;
    }
});

router.addRule(removeTrailingSlashRule);
router.addRule(removeDotDoRule);

router.addWhen('/contents/license', '/legal/license');
router.addWhen('/contents/drug-interaction', '/drug-interactions');
router.addWhen('/legal/medcalc-disclaimer', '/legal/ebmcalc-disclaimer');
router.addWhen('/landing/covid19', '/contents/search');

router.setOtherwise(determineRedirect);

getWindow().popStateActive = false;
getWindow().isHashUrl = false;
getWindow().isLastHashUrl = false;
getWindow().isHomeSearchUrl = false;

getWindow().onpopstate = () => {
    // Prevent logging cache hit events on back/forward navigation
    new UtdCache().doCacheHit = false;
    getWindow().popStateActive = true;

    // Trigger scrollToActiveHash to restore mobile outline view
    // from article view.
    if (!store.getters['device/isDesktopView']
     && store.getters['topic/isTopicView']) {
        store.dispatch('topic/scrollToActiveHash');
    }
};

store.commit(`app/${SET_ROUTER}`, new UtdRouter(router));
store.dispatch('app/setTransitions', router.transitionService);

router.transitionService.onCreate({}, transitionsOnCreate);

router.transitionService.onBefore(
    { to: 'cme.*', from: '*' },
    transitionOnBeforeCmeRouteGuard,
    { priority: 10 }
);
router.transitionService.onBefore(
    { to: 'cme.**', from: 'cme.**' },
    transitionOnBeforeCmeRedeemGuards,
    { priority: 9 }
);

router.transitionService.onBefore({}, transitionOnBeforeTopicGraphicsGuard);

router.transitionService.onStart({}, transitionsOnStart);

router.transitionService.onSuccess({}, transitionsOnSuccess);

const runCMEApp = () => {
    const transition = router.transitionService;
    const isUserNotRegistered = () => store.getters['user/userLoggedIn'] && !store.getters['app/isUserRegistered'];

    // we need to plug to "onEnter" in this case, because we need to have at least one API call finished to gather all user info
    // see comment related to transition options for more details
    transition.onEnter({ to: 'cme.**', from: state => state.name.indexOf('cme.') === -1 }, transition => {
        if (isUserNotRegistered()) {
            return transition.router.stateService.target('cmeRegister');
        }
    }, {
    // it shall be executed only once - that covers the case when user is trying to access CME directly (which can happen at max once)
    // after that, every transition to cme state will be controller by the hook registered below
    // we could do it in 'onEnter', but that would cause unnecessary requests to cme API, as we have resolves defined for each cme state
        invokeLimit: 1
    });

    // we filter out every transition that comes from state outside of cme.** state tree
    // no need to secure navigation within cme, as the above transition hook ensures that CME is not accessible if user is not registered
    transition.onBefore({ to: 'cme.**', from: state => state.name.indexOf('cme.') === -1 }, transition => {
        if (isUserNotRegistered()) {
            return transition.router.stateService.target('cmeRegister');
        }
    });

    transition.onSuccess({}, transition => {
        const to = transition.to();
        const title = to && to.data && to.data.title;
        if (title) {
            setBrowserTabTitle(title);
        }
    });
};

export default router;

const lazyLoadCmeModule = async() => {
    runCMEApp();
    const cmeRoutes = await import(/* webpackChunkName: "cme" */ './cme/cme.route.js');
    return cmeRoutes && cmeRoutes.default;
};

const lazyLoadAccountModule = async() => {
    const accountRoutes = await import(/* webpackChunkName: "account" */ './account/account.route.js');
    return accountRoutes && accountRoutes.default;
};

const initSearch = stateParams => {
    setBrowserTabTitle('Search');
    setMetaTagNoindex();
    store.commit(`app/${SET_NO_HEADER_CLASS}`, '');
    store.dispatch('search/reset');

    // If initial data from server hasn't occurred yet, get last
    // saved searchState setting from cookie (if available)
    if (!store.getters['app/isAppDataLoaded']) {
        store.commit(`app/${REFRESH_STORED_SETTINGS}`);
        const searchState = store.getters['app/storedSetting']('searchState');
        store.commit(`search/${SET_SEARCH_STATE}`, searchState);
    }

    store.dispatch('search/setMaxResults');

    // Retrieve session storage for search priority because not all single graphics
    // have location attribute 'sp' -- DE10177 DE9558
    const searchPriorityStorage = new UtdCache().getSession('sp');

    const search = cleanResetUriComponent(recodeSearchPlusToSpace(stateParams.search));
    if (search) {
        store.commit(`search/${SET_SEARCH_FOR}`, safeDecodeUriComponent(search));
        store.commit(`search/${SET_SEARCH_RESULTS_SHOW}`, true);
        store.commit(`search/${SET_SEARCH_PARAMS_TYPE}`, stateParams.searchType);
        store.commit(`search/${SET_SEARCH_PARAMS_AUTO_COMPLETE}`, stateParams.autoComplete);
        store.commit(`search/${SET_SEARCH_PARAMS_TEXT}`, search);
        store.commit(`search/${SET_SEARCH_PARAMS_SP}`, stateParams.sp);
        store.commit(`search/${SET_SEARCH_PARAMS_SOURCE}`, stateParams.source);
        store.commit(`search/${SET_SEARCH_PARAMS_CONTROL}`, stateParams.searchControl);
        store.commit(`search/${SET_SEARCH_PARAMS_INCLUDE}`, stateParams.include);
        store.commit(`search/${SET_SEARCH_PARAMS_RAW_SENTENCE}`, stateParams.rawSentence);
        store.commit(`search/${SET_IS_SEARCH_VIEW}`, true);

        if (typeof store.getters['search/searchParamsPriority'] === 'undefined') {
            if (searchPriorityStorage !== null) {
                store.commit(`search/${SET_SEARCH_PARAMS_SP}`, searchPriorityStorage);
            }
            else {
                store.commit(`search/${SET_SEARCH_PARAMS_SP}`, C_SEARCH.PRIORITY.ALL_TOPICS);
            }
        }

        const promise = store.dispatch('search/getSearchResults')
            .then(data => {
                const body = document.querySelector('body');
                body && body.focus();
                store.dispatch('graphic/updateGfxHoverTooltipBinding');
                return data;
            });
        return promise;
    }

    // This could be an HL7 search. However, ui-router does not
    // correctly recognize query parameter names containing periods,
    // so broken out HL7 check into separate method.
    if (checkInitHL7Search(stateParams)) {
        return store.dispatch('search/getSearchResults');
    }

    store.commit(`search/${SET_SEARCHBAR_BODY}`, true);
    store.commit(`search/${SET_SEARCHBAR_HEADER}`, false);

    store.commit(`search/${SET_IS_HOME_SEARCH_EMPTY}`, true);
    store.commit(`search/${SET_SEARCH_PARAMS_TEXT}`, '');

    // CORE-8542: Quick hacky fix to force scroll position of search page back to top
    // for initial search results (Safari only browsers, home search only).
    if (store.getters['device/shouldResetSearchScrollPosition']) {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, C_UI.SAFARI_SCROLL_RESET_DELAY_MS);
    }

    return true;
};

// eslint-disable-next-line complexity
const checkInitHL7Search = params => {
    // Look for existence of HL7 identifying parameters using angular method.
    const href = getWindow().location.href;
    const pMSC_v_cs = getQueryParamValues(href, 'mainSearchCriteria.v.cs');
    const pMSC_v_c = getQueryParamValues(href, 'mainSearchCriteria.v.c');
    let pMSC_v_dn = getQueryParamValues(href, 'mainSearchCriteria.v.dn', true);
    if (pMSC_v_dn) {
        pMSC_v_dn = pMSC_v_dn.split(',')[0];
    }
    const pMSC_v_ot = getQueryParamValues(href, 'mainSearchCriteria.v.ot');

    if (((!!pMSC_v_cs) && (!!pMSC_v_c)) || (!!pMSC_v_dn) || (!!pMSC_v_ot)) {
        store.commit(`search/${SET_SEARCH_RESULTS_SHOW}`, true);
        store.commit(`search/${SET_IS_HL7}`, true);
        store.commit(`search/${SET_SEARCH_PARAMS_TYPE}`, params.searchType);

        // Iterate over all Url query paramters, and add onto searchParams object
        const queryParams = queryParamsToCollection();
        for (const param in queryParams) {
            if (!store.getters['search/searchParams'][param]) {
                store.getters['search/searchParams'][param] = queryParams[param].split(',')[0];
            }
        }
    }
    return store.getters['search/isHL7'];
};

const commonTopicResolver = stateParams => {
    store.dispatch('topic/resetTopic');
    store.commit(`topic/${SET_TOPIC_URL}`, stateParams.topic);

    if (stateParams.language) {
        store.commit(`topic/${SET_TOPIC_URL_LANGUAGE}`, stateParams.language);
    }
};

const initTopic = stateParams => {
    commonTopicResolver(stateParams);
    return new Promise(resolve => {
        store.dispatch('feature/optimizelyTimeoutHandler', resolve);
    })
        .then(() => store.dispatch('topic/getTopic'))
        .then(() => store.dispatch('app/confirmConfigLoaded'))
        .then(() => {
            store.commit(`topic/${SET_INITIAL_TOPIC_LOAD_HASH}`, getUrlHash());
            if (store.getters['feature/showFormulinkContent']
             && store.getters['topic/isMedicalTopic']) {
                store.dispatch('formulary/reset');
                return store.dispatch('formulary/getFormulariesInfo',
                    { topicId: stateParams.topic, opts: { bypassAll: true } });
            }
        });
};

const initTopicForPrint = stateParams => {
    commonTopicResolver(stateParams);
    return store.dispatch('topic/getTopic', { forPrint: true });
};

function initLogin() {
    setBrowserTabTitle('Sign in');
    store.dispatch('login/reset');
}

const initTocLanding = stateParams => {
    store.commit(`toc/${SET_TOC_IS_SHOW_TOC_LANDING}`, true);
    return initToc(stateParams);
};

const initToc = stateParams => {
    store.commit(`toc/${SET_TOC_VIEW}`, true);
    store.commit(`toc/${SET_TOC_URL_ID}`, stateParams.id);
    store.commit(`toc/${SET_TOC_URL_SECTION}`, stateParams.section);
    store.commit(`toc/${SET_TOC_URL_LANGUAGE_CODE}`, stateParams.language);
    return store.dispatch('toc/getToc')
        .then(() => {
            new PubSub().publish(C_EVENTS.REFRESH_TOC);
        });
};

const initAuthorAndEditors = stateParams => {
    store.commit(`toc/${SET_TOC_VIEW}`, true);
    store.commit(`toc/${SET_TOC_URL_ID}`, 'authorseditors');
    store.commit(`toc/${SET_TOC_URL_SECTION}`, stateParams.section);
    store.commit(`toc/${SET_TOC_URL_SPECIALTY}`, stateParams.specialty);
    return store.dispatch('toc/getAuthorsEditors');
};

const graphic = $stateParams => {
    store.dispatch('graphic/reset');
    if ($stateParams) {
        if (initGraphic($stateParams)) {
            store.commit(`app/${SET_IS_PRINT_VIEW}`, false);
            store.commit(`graphic/${SET_IS_PRINT_GRAPHIC}`, false);
            store.commit(`graphic/${SET_TOOLS_IS_PRINT_VIEW}`, false);

            return store.dispatch('graphic/getGraphic').then(result => {
                if (!result) {
                    getWindow().location.replace();
                }
                return result;
            });
        }
    }
};

const graphicPrint = $stateParams => {
    store.dispatch('graphic/reset');
    if ($stateParams) {
        const graphicParams = removeNullOrUndefinedProperties($stateParams);
        if (initGraphic(graphicParams)) {
            store.commit(`app/${SET_IS_PRINT_VIEW}`, true);
            store.commit(`graphic/${SET_IS_PRINT_GRAPHIC}`, true);
            store.commit(`graphic/${SET_TOOLS_IS_PRINT_VIEW}`, true);

            if (store.getters['device/browserData'].os === 'ios') {
                store.commit(`app/${SET_FIXED_HTML}`, '');
            }

            return store.dispatch('graphic/getGraphic');
        }
    }
};

const initGraphic = $stateParams => {
    // Note that stateParams also contains topicKey and source
    // but we don't seem to need these currently

    // Grabbing the Graphic Ids from the imageKey
    // This algorithm may change
    const imageKey = $stateParams.imageKey;
    if (typeof imageKey !== 'undefined') {
        const ids = [];
        const keys = imageKey.split(C_GRAPHICS.MULTI_GRAPHIC_SEPARATOR);
        for (let i = 0; i < keys.length; i++) {
            const posA = keys[i].indexOf('/') + 1;
            ids[i] = keys[i].substring(posA);
        }
        const params = {
            id: ids,
            ...$stateParams
        };

        const search = cleanResetUriComponent($stateParams.search);
        if (search) {
            params.search = safeDecodeUriComponent(search);
        }

        store.commit(`graphic/${SET_GRAPHIC_PARAMS}`, params);

        if ($stateParams.language) {
            store.commit(`graphic/${SET_GRAPHIC_URL_LANGUAGE}`, $stateParams.language);
        }
        return true;
    }

    Logger.error(`imageKey missing from url=[${getWindow().location.url()}]`, '404');
    getWindow().location.replace('/error');
    return false;
};

const withSpinner = promise => {
    const spinnerTimeout = setTimeout(() => {
        store.commit(`app/${SET_IS_SPINNER_VISIBLE}`, true);
    }, C_UI.DEFAULT_SPINNER_DELAY_MS);
    return promise.finally(() => {
        if (spinnerTimeout) {
            store.commit(`app/${SET_IS_SPINNER_VISIBLE}`, false);
            clearTimeout(spinnerTimeout);
        }
    });
};

function error500() {
    store.commit(`error/${SET_ERROR_CODE}`, C_HTTP_STATUS.SERVER_ERROR);
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function error404() {
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.PAGE_NOT_FOUND');
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function errorCmeCertNotFound() {
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.CME_CERTIFICATE_NOT_FOUND');
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}
function errorPageNotAvailable() {
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.PAGE_NOT_AVAILABLE_MOBILE');
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function errorContentNotAvailable() {
    const params = {
        productName: store.getters['app/productName'],
        interpolation: { escapeValue: false }
    };
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.CONTENT_NOT_AVAILABLE');
    store.commit(`error/${SET_ERROR_PARAMS}`, params);
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function smgrGuidError(stateParams) {
    const errorMsg = C_SMGR_ERRORS[stateParams.status] || C_SMGR_ERRORS.systemError;
    store.commit(`error/${SET_ERROR_MESSAGE}`, errorMsg);
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function loadMobileHistory() {
    store.commit(`profile/${SET_IS_MY_UTD_VIEW}`, true, { root: true });
    setBrowserTabTitle('My UpToDate');
    return true;
}

function initRelatedGraphics($stateParams) {
    if ($stateParams.language) {
        store.commit(`topic/${SET_TOPIC_LANGUAGE}`, $stateParams.language);
    }
    return store.dispatch('topic/getRelatedGraphics', $stateParams.topic);
}

function initValidateCode() {
    setBrowserTabTitle('Sign In Validation');
    store.dispatch('app/setNoHeaderState');
    return store.dispatch('auth/initCode');
}

function errorIcgCookiesDisabled() {
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.ICG_COOKIES_DISABLED');
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function errorIcgNotAvailable() {
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.ICG_NOT_AVAILABLE');
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function trialAccountError() {
    store.commit(`error/${SET_ERROR_MESSAGE}`, 'ERROR.TRIAL_ACCOUNT_ERROR');
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}

function contentShareMessage($stateParams) {
    if ($stateParams.errorMessage === 'invalidLink') {
        store.commit(`error/${SET_ERROR_MESSAGE}`, 'SHARE.CONTENT_SHARE_LINK_INVALID');
    }
    else {
        store.commit(`error/${SET_ERROR_MESSAGE}`,
            'The service is currently unavailable. Please try again later');
    }
}

function error() {
    setBrowserTabTitle('Error');
    store.commit(`error/${SET_ERROR_CLASS}`, true);
}
