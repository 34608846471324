
import { updateElementAttribute } from './DOM';

let _indexMgrInstance = null;
export default class IndexManager {
    constructor() {
        if (_indexMgrInstance) {
            return _indexMgrInstance;
        }

        this._htmlClass = {
            defaultHtmlClass: '',
            fixedHtml: '',
            isProspectView: ''
        };

        this._bodyClass = {};
        this._appClass = {};

        _indexMgrInstance = this;

        this.appCss = {
            setOrClear: (prop, value) => {
                if (value) {
                    this._appClass[prop] = typeof value === 'boolean' ? prop : value;
                }
                else if (this._appClass[prop]) {
                    delete this._appClass[prop];
                }
                updateElementAttribute(
                    'div[id="appContainer"]',
                    'class',
                    Object.values(this._appClass).join(' ').trim());
            }
        };

        this.bodyCss = {
            set: (prop, val) => {
                this._bodyClass[prop] = val || prop;
                updateElementAttribute(
                    'body', 'class', Object.values(this._bodyClass).join(' ').trim());
            },
            clear: prop => {
                if (this._bodyClass[prop]) {
                    delete this._bodyClass[prop];
                    updateElementAttribute(
                        'body', 'class', Object.values(this._bodyClass).join(' ').trim());
                }
            },
            setOrClear: (prop, value) => {
                if (value) {
                    this._bodyClass[prop] = typeof value === 'boolean' ? prop : value;
                }
                else if (this._bodyClass[prop]) {
                    delete this._bodyClass[prop];
                }
                updateElementAttribute(
                    'body', 'class', Object.values(this._bodyClass).join(' ').trim());
            }
        };

        this.htmlCss = {
            setOrClear: (prop, value) => {
                if (value) {
                    this._htmlClass[prop] = typeof value === 'boolean' ? prop : value;
                }
                else if (this._htmlClass[prop]) {
                    delete this._htmlClass[prop];
                }
                updateElementAttribute(
                    'html', 'class', Object.values(this._htmlClass).join(' ').trim());
            }
        };
    }

    setLangInfo(langCode) {
        updateElementAttribute('html', 'lang', langCode);
    }

    setCanonicalUrl(canonicalUrl) {
        updateElementAttribute('link[rel="canonical"]', 'href', canonicalUrl);
    }

    setMetaDescription(metaDescription) {
        updateElementAttribute('meta[name="description"]', 'content', metaDescription);
    }

    setHtmlCssClassValue(property, value) {
        this._htmlClass[property] = value;
        updateElementAttribute(
            'html', 'class', Object.values(this._htmlClass).join(' ').trim());
    }

    setBodyCssClasses(classes) {
        this._bodyClass = { ...this._bodyClass, ...classes };
        updateElementAttribute(
            'body', 'class', Object.values(this._bodyClass).join(' ').trim());
    }

    setDefaultElementAttribute() {
        this.setLangInfo('en');
        this.setHtmlCssClassValue('defaultHtmlClass', 'no-js');
    }
}
