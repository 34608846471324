import { C_AUTOCOMPLETE } from '_acaSrc/utility/constants';

export default class ACDropdownValue {
    setElId(i) {
        this.elementId = i; return this;
    }
    setUniqueId(u) {
        this.uniqueId = u; return this;
    }
    setClasses(c) {
        this.classes = c; return this;
    }
    setSubClasses(c) { // Classes set on list item's iternal tag
        this.subClasses = c; return this;
    }
    setHref(h) {
        this.href = h; return this;
    }
    setIsNewTab(n) {
        this.isNewTab = n; return this;
    }
    setOnClick(fn) {
        this.onClick = fn; return this;
    }
    setLabel(l, options = { isSafeHTML: false }) {
        if (options.isSafeHTML) {
            this.labelHTML = l; return this;
        }
        this.label = l; return this;
    }
    setType(t) {
        this.type = t; return this;
    }
    setSubtype(t) {
        this.subType = t; return this;
    }

    // some getters and setters to enforce a default.
    set type(t) {
        this._type = t;
    }
    get type() {
        return this._type || C_AUTOCOMPLETE.ITEM_TYPES.CLICKABLE.DEFAULT;
    }
    get isClickable() {
        return Object.values(C_AUTOCOMPLETE.ITEM_TYPES.CLICKABLE).includes(this.type);
    }
    get isHeader() {
        return Object.values(C_AUTOCOMPLETE.ITEM_TYPES.HEADER).includes(this.type);
    }
}