<template>
  <a v-if="showResourceCenterGuide"
     id="pendo-resource-center-wrapper"
     v-hover-delay="{ afterDelay: showHelpNav,
                      afterUnhoverDelay: hideHelpNav,
                      sharedHoverState: 'helpNav' }"
     :aria-label="$t('HEADER.HELP')"
     href
     @click.prevent="launchResourceCenterGuide">
    <span class="wkce-icon-question" />
    <span class="pendo-resource-text">{{ $t('HEADER.HELP') }}</span>
    <span class="chevron-down" />
  </a>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HoverDelay from '_acaSrc/components/shared/utd/UtdHoverDelay.vue';
import { C_UI } from '_acaSrc/utility/constants';

export default {
    directives: {
        HoverDelay
    },
    computed: {
        ...mapGetters('pendo', [ 'showResourceCenterGuide' ])
    },
    methods: {
        ...mapActions('pendo', [ 'launchGuide' ]),
        launchResourceCenterGuide() {
            this.launchGuide({
                guideName: 'ResourceCenterGuide'
            });
        },

        hideHelpNav() {
            const elContainer = document.querySelector('#pendo-resource-center-container');
            const contentSelector = '#pendo-resource-center-container>div[data-layout=HomeViewModule]';

            if (!elContainer || !document.querySelector(contentSelector)) {
                return;
            }

            if (!elContainer.matches(':hover')) {
                pendo.onGuideDismissed();
                return;
            }
            elContainer.onmouseleave = () => {
                setTimeout(() => {
                    // This check here is to prevent the RC from disappearing when not in home view.
                    if (document.querySelector(contentSelector)) {
                        pendo.onGuideDismissed();
                    }
                }, C_UI.MENU_HIDE_DELAY_MS);
            };
        },

        showHelpNav() {
            this.launchResourceCenterGuide();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.chevron-down {
  color: @DS1-UTD-SUB-TEXT-COLOR;
}

.wkce-icon-question {
  color: @DS1-UTD-INFO;
}

#pendo-resource-center-wrapper {
  .chevron-down::before {
    position: relative;
    top: 0;
    font-family: "wk-icons-open";
    font-size: 12px;
    content: '\E90F';
  }

  > span {
    .ds1-mv-0();
  }

  .pendo-resource-text {
    display: none;
  }

  &:hover {
    .chevron-down {
      color: @DS1-UTD-INFO;
    }

    .utdWkHomePage2024 & .pendo-resource-text {
      color: @WKCE-BLUE;
    }
  }
}

@media only screen and (min-width: 992px) {
  #pendo-resource-center-wrapper {
      .pendo-resource-text {
        display: inline-block;
      }
    }
    
  .utdWkHomePage2024 {
    &:not(.isHomeSearchEmpty) {
        #pendo-resource-center-wrapper .pendo-resource-text {
          display: none;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .utdWkHomePage2024 {
    &.anonymous-user {
        &:not(.isHomeSearchEmpty) {
          #pendo-resource-center-wrapper .pendo-resource-text {
            display: inline-block;
          }
    
          .login-register #pendo-resource-center-wrapper .pendo-resource-text {
            display: none;
          }
        }
    
        &.isHomeSearchEmpty .login-register #pendo-resource-center-wrapper .pendo-resource-text {
          display: inline-block;
        }
    }

    &:not(.isHomeSearchEmpty) {
      #pendo-resource-center-wrapper .pendo-resource-text {
        display: inline-block;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .utdWkHomePage2024 {
    &.anonymous-user:not(.isHomeSearchEmpty) .login-register #pendo-resource-center-wrapper .pendo-resource-text,
    #pendo-resource-center-wrapper .pendo-resource-text {
      display: inline-block;
    }
  }
}
</style>