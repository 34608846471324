<template>
  <div class="utd-notification"
       v-bind="$attrs"
       :class="notificationClass"
       role="alert">
    <span class="utd-notification-icon" :class="notificationIconClass" />
    <p class="utd-notification-title">
      <slot name="title" />
    </p>
    <button v-if="hasCloseNotification"
            role="button"
            aria-label="close"
            class="wkce-icon-filled-close wkce-icon-button"
            @click="onClose" />
    <p class="utd-notification-content"><slot /></p>
  </div>
</template>

<script>

export default {
    props: {
        notificationMode: {
            type: String,
            default: 'info'
        },
        hasCloseNotification: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        notificationClass() {
            return `utd-notification-${this.notificationMode}`;
        },
        notificationIconClass() {
            let icon = 'filled-info';
            if (this.notificationMode === 'error') {
                icon = 'filled-stop-sign';
            }
            else if (this.notificationMode === 'success') {
                icon = 'filled-check-circle';
            }
            else if (this.notificationMode === 'warn') {
                icon = 'filled-caution';
            }
            return `wkce-icon-${icon}`;
        }
    },
    methods: {
        onClose() {
            this.$emit('closed');
        }
    }
};
</script>

<style scoped lang="less">
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  @UTD-DIALOG-XCLOSE-COLOR: @WKCE-GRAY-TINT1;

  /* Refer this link for notification DXG component styles:
        https://jumpstart.wolterskluwer.io/?path=/docs/components-notification-html-with-classes--inline-default
    */

  .utd-notification {
    display: block;
    border-color: @WKCE-GRAY;
    border-style: solid;
    border-width: 0.0625rem 0.0625rem 0.0625rem 0.25rem;
    margin-top: 1.5rem;
    padding: 1rem 1rem 1rem 3.25rem;
    background: @WKCE-WHITE;
    position: relative;

    .utd-notification-icon {
      left: 1rem;
      margin-left: 0;
      margin-right: 0;
      top: 1rem;
      font-size: 16px;
      position: absolute;
    }

    .utd-notification-title {
      color: @WKCE-GRAY-SHADE1;
      font-size: 0.875rem;
      line-height: 1.28571;
      margin-bottom: 0.25rem;
      text-align: left;
      margin-top: 0;
      padding: 0;
      font-weight: bold;
    }

    .utd-notification-content {
      margin: 0;
      padding: 0;
      color: @WKCE-GRAY-SHADE2;
      font-size: 0.875rem;
      line-height: 1.5;
      text-align: left;
    }
  }

  .utd-notification-info {
    border-color: @DS1-UTD-INFO;
    color: @DS1-UTD-INFO;
  }

  .utd-notification-error {
    border-color: @DS1-UTD-ERROR;
    color: @DS1-UTD-ERROR;
  }

  .utd-notification-success {
    border-color: @DS1-UTD-SUCCESS;
    color: @DS1-UTD-SUCCESS;
  }

  .utd-notification-warn {
    border-color: @DS1-UTD-WARN;
    color: @DS1-UTD-WARN;
  }

  .wkce-icon-filled-close {
    .ds1-ma-0();
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 14px;
    top: 16px;

    &::before {
      color: @UTD-DIALOG-XCLOSE-COLOR;
      font-size: 16px;
    }
  }
</style>
