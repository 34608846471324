<template>
  <div>
    <utd-modal-dialog v-if="isSuccess"
                      ref="contentFeedbackDialog"
                      modal-classes="content-feedback-modal"
                      @closed="emitFeedbackModalVisibility(false)">
      <template #header>{{ $t(feedbackTitle) }}</template>

      <iframe id="content-feedback-iframe"
              ref="contentFeedbackIframe"
              aria-label="Content feedback form"
              :src="getQualtricsURL" />
    </utd-modal-dialog>

    <div v-show="!isSuccess">
      <utd-content-feedback-error-dialog ref="errorDialog" />
    </div>

    <a :class="[feedbackClass, { 'topic-feedback-button': !isTopicCalculator }]"
       tabindex="0"
       :aria-label="`Provide ${$t(feedbackTitle)}`"
       @click.prevent="openModal()"
       @keydown.enter.prevent="openModal()">
      <span v-if="hasChatBoxIcon" class="wkce-icon-chat-box" />
      <span class="topic-feedback-button-text">{{ $t(buttonText) }}</span>
    </a>
  </div>
</template>

<script>
import UtdModalDialog from '_acaSrc/components/shared/stdlib/UtdModalDialog.vue';
import UtdContentFeedbackErrorDialog
    from '_acaSrc/components/shared/utd/UtdContentFeedbackErrorDialog.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters, mapActions } from 'vuex';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import { USER_CONTENT_FEEDBACK } from '_acaSrc/utility/constants';

export default {
    components: {
        UtdModalDialog,
        UtdButton,
        UtdContentFeedbackErrorDialog
    },
    props: {
        feedbackTitle: {
            type: String,
            default: USER_CONTENT_FEEDBACK.TITLE.TOPIC
        },
        feedbackClass: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: ''
        },
        hasChatBoxIcon: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'feedback-modal-visible' ],
    data() {
        return {
            contentId: null,
            contentTitle: null,
            feedbackClickEvent: null,
            isSuccess: false
        };
    },
    computed: {
        ...mapGetters('user', [ 'getQualtricsURL', 'feedbackContentType', 'getDeviceType' ]),
        ...mapGetters('feature', [ 'featureAttributes' ]),
        ...mapGetters('graphic', [
            'isGraphicView',
            'graphicId',
            'graphicTitle',
            'graphicViewerVisible'
        ]),
        ...mapGetters('topic', [ 'topicId', 'topicTitle', 'isTopicCalculator' ])
    },
    created() {
        this.contentId = this.graphicId || this.topicId;
        this.contentTitle = this.graphicTitle || this.topicTitle;
        this.contentType = this.feedbackContentType;
        this.isSuccess = this.contentId && this.contentTitle && this.contentType;
    },
    methods: {
        ...mapActions('user', [ 'fetchQualtricsURL' ]),
        ...mapActions('topic', [ 'resetAllTopicMenus' ]),
        async openModal() {
            await this.fetchQualtricsURL();
            if (this.isSuccess && this.getQualtricsURL) {
                this.openFeedbackForm();
            }
            else {
                this.$refs.errorDialog.openModal();
            }
            this.resetAllTopicMenus();
        },
        openFeedbackForm() {
            this.$refs.contentFeedbackDialog.open();

            if (this.isTopicCalculator) {
                this.feedbackClickEvent = 'contentFeedback_calculatorFeedback';
            }
            else if (this.graphicViewerVisible) {
                this.feedbackClickEvent = 'graphicViewerDialog_graphicsFeedback';
            }
            else if (this.isGraphicView) {
                this.feedbackClickEvent = 'contentFeedback_graphicsFeedback';
            }
            else {
                this.feedbackClickEvent = 'contentFeedback_topicFeedback';
            }

            logUiClickEventHelper({
                uiElementName: 'contentFeedback_provideFeedback',
                optData: this.feedbackClickEvent
            });
            this.emitFeedbackModalVisibility(true);
        },
        closeFeedbackForm() {
            this.$refs.contentFeedbackDialog.close();
            this.emitFeedbackModalVisibility(false);
        },
        emitFeedbackModalVisibility(isVisible) {
            this.$emit('feedback-modal-visible', isVisible);
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

iframe {
    overflow: scroll;
    border: none;
    width: 100%;
    height: 100%;
}

</style>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.content-feedback-modal {
    .utd-dialog-content {
        .ds1-pt-1();
        .ds1-pl-0();
        overflow: hidden;
        height: 510px;
    }

    .utd-dialog-header {
        .ds1-utd-weight-600();
    }

    @media only screen and (min-width: 768px) {
        .utd-dialog-frame {
            min-width: 650px;
        }
    }
}

</style>