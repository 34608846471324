<template>
  <div class="utd-inline-graphics"
       :class="{isHeaderFailsafe}">
    <div v-for="(graphic, index) in graphics"
         :key="index"
         class="utd-inline-graphic__container">
      <utd-thumbnail class="utd-inline-graphic__thumbnail"
                     :class="graphic.classLabel"
                     :graphic="graphic"
                     :large="true"
                     :has-border="true" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="utd-inline-graphic__label" v-html="graphic.label" />
    </div>
  </div>
</template>

<script>
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';

export default {
    components: {
        UtdThumbnail
    },
    props: {
        graphics: {
            type: Array
        },
        // Used when header placement failsafe activates
        isHeaderFailsafe: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

  // Mobile-first
  .utd-inline-graphics {
    display: flex;
    flex-direction: column;
    float: right;
    clear: right;

    // Floats left for right-to-left topics
    .utd-rtl & {
      float: left;
      clear: left;

      .utd-inline-graphic__container {
        padding: 7.5px 16px 8.5px 0; // RTL support
      }
    }

    // Hides the inline graphics from showing up in print view
    .topicPrint & {
      display: none;
    }

    // Used for failsafe when heading endMark can't be found
    .topicView &.isHeaderFailsafe {
      margin-top: 79.5px;
    }

    .utd-inline-graphic__container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 7.5px 0 16px 16px; // Mobile-web individual image padding
      z-index: 1;
      background: @DS1-UTD-PRIMARY-BG-COLOR;

      .utd-inline-graphic__label {
        .ds1-mt-1();
        .ds1-mb-0();
        color: @WKCE-GRAY-SHADE2;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        .textSize-S:not(.topicPrint) & {
          font-size: 12px;
          line-height: 16px;
        }

        .textSize-L:not(.topicPrint) & {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .utd-inline-graphic__thumbnail.utd-thumbnail__container {
        .ds1-ma-0();
        width: calc(25vw + 4px); // Creates padding around the mobile-web image
        height: auto; // For removing extra whitespace under text

        #topicContent &.thumbnail-border {
          border: 2px solid @DS1-UTD-GRAY-BORDER-COLOR;
          transition: border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

          :deep(.utd-thumbnail__label-anchor) {
            .topicView:not(.InternetExplorer) & {
              text-decoration: underline;
              text-decoration-color: transparent;
              transition: text-decoration-color 0.25s ease-in-out;
            }
          }

          &:hover,
          &.group-hover {
            border: 2px solid @WKCE-BLUE-TINT3;
            box-shadow: @DS1-BOX-SHADOW-OVERLAY;

            :deep(.utd-thumbnail__label-anchor) {
              text-decoration: underline; // IE11 support (no fade-in-out for underline)

              .topicView:not(.InternetExplorer) & {
                text-decoration-color: unset;
              }
            }
          }
        }

        :deep(.utd-thumbnail__image-container) {
          border: none;
          width: 25vw;
          height: 25vw;

          span {
            width: 25vw;
            height: 25vw;
            // Makes backgrounds printable (if not disabled on printView)
            print-color-adjust: exact; /* stylelint-disable-line */
            -webkit-print-color-adjust: exact; /* stylelint-disable-line property-no-vendor-prefix */
          }
        }

        :deep(.utd-thumbnail__label-anchor) {
          display: none;
          height: auto;
          padding-bottom: 12px;
          padding-top: 12px;

          span {
            -webkit-line-clamp: 5; // Default max lines in image title /* stylelint-disable-line property-no-vendor-prefix */

            .textSize-L:not(.topicPrint) & {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  // 145px images
  @media only screen and (min-width: 768px) {
    .topicArticle {
      .utd-rtl .utd-inline-graphics .utd-inline-graphic__container {
        padding: 4.5px 40px 40px 0; // RTL support
      }

      .utd-inline-graphics .utd-inline-graphic__container {
        display: flex;
        align-items: flex-end;
        padding: 4.5px 0 40px 40px; // Small individual image padding

        .utd-inline-graphic__label {
          margin-top: 12px;
        }

        .utd-inline-graphic__thumbnail.utd-thumbnail__container {
          width: 155px; // Creates padding around the small image

          :deep(.utd-thumbnail__image-container) {
            width: 145px;
            height: 145px;

            span {
              width: 145px;
              height: 145px;
            }
          }

          :deep(.utd-thumbnail__label-anchor) {
            display: blocK;
          }
        }
      }
    }
  }

  // 225px images
  @media only screen and (min-width: 1280px) {
    .topicArticle {
      .utd-rtl .utd-inline-graphics .utd-inline-graphic__container {
        padding: 4.5px 56px 48px 0; // RTL support
      }

      .utd-inline-graphics .utd-inline-graphic__container {
        padding: 4.5px 0 48px 56px; // Large individual image padding

        .utd-inline-graphic__thumbnail.utd-thumbnail__container {
          width: 250px; // Creates padding around the large image

          :deep(.utd-thumbnail__image-container) {
            width: 225px;
            height: 225px;

            span {
              width: 225px;
              height: 225px;
            }
          }
        }
      }
    }
  }
</style>