<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="prospect-banner-wrapper">
    <section id="prospect-banner" class="prospect-banner">
      <div id="panel-left">
        <span class="title"
              v-text="$t('MARKETING_BANNER.LEARN_HOW_UTD_CAN_HELP')" />
        <span class="subtitle"
              v-html="$t(subtitleKey)" />
      </div>
      <div id="panel-right">
        <ul class="anchor-link-list">
          <li v-for="(anchor, index) in anchors"
              :key="`${index}-${anchor.title}`"
              class="anchor-link"
              @click="navigateAnchor(anchor)">
            <a :href="anchor.href">
              <span>{{ $t(anchor.title) }}</span>
            </a>
          </li>
        </ul>
      </div>
      <div id="banner-footer">
        <utd-button button-type="link"
                    :button-link="storeUrl"
                    button-size="small"
                    button-color="green-shade-3">{{ $t('HEADER.SUBSCRIBE') }}</utd-button>
      </div>
      <div class="close-button wkce-icon-close"
           title="Close Banner"
           @click="closeBanner()" />
    </section>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { getWindow } from '_acaSrc/utility/DOM';
import { triggerResizeEvent } from '_acaSrc/utility/Events';
import { SET_HIDE_MARKETING_BANNER } from '_acaSrc/store/topic/topic.store';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

const HIDE_MARKETING_BANNER_DELAY = 600;
export default {
    components: { UtdButton },
    props: {
        subtitleKey: {
            type: String,
            default: 'MARKETING_BANNER.SELECT_OPTION_BEST_DESCRIBES'
        }
    },
    data() {
        return {
            localMarketingUrls: {}
        };
    },
    computed: {
        ...mapGetters('auth', [ 'marketingUrls', 'storeUrl' ]),
        ...mapGetters('user', [ 'language' ]),
        currentLanguage() {
            return this.language;
        },
        maketingUrlSet() {
            if (Object.keys(this.marketingUrls).length === 0) {
                this.updateMarketingUrls();
                return this.localMarketingUrls;
            }
            return this.marketingUrls;
        },
        anchors() {
            return [
                {
                    href: this.maketingUrlSet.medProfessionalUrl,
                    title: 'MARKETING_BANNER.MEDICAL_PROFESSIONAL'
                },
                {
                    href: this.maketingUrlSet.medTraineeUrl,
                    title: 'MARKETING_BANNER.RESIDENT_FELLOW_STUDENT'
                },
                {
                    href: this.maketingUrlSet.institutionUrl,
                    title: 'MARKETING_BANNER.HOSPITAL_INSTITUTION'
                },
                {
                    href: this.maketingUrlSet.groupPracticeUrl,
                    title: 'MARKETING_BANNER.GROUP_PRACTICE'
                }
            ];
        }
    },
    watch: {
        currentLanguage(newVal) {
            if (newVal) {
                this.updateMarketingUrls();
            }
        }
    },
    beforeUnmount() {
        clearTimeout(this.hideBannerTimeout);
    },
    methods: {
        ...mapMutations('topic', [
            SET_HIDE_MARKETING_BANNER
        ]),
        closeBanner() {
            this[SET_HIDE_MARKETING_BANNER]('bannerClosed bannerClosing');
            this.hideBannerTimeout = setTimeout(() => {
                this[SET_HIDE_MARKETING_BANNER]('bannerClosed');
                triggerResizeEvent();
            }, HIDE_MARKETING_BANNER_DELAY);
        },
        navigateAnchor(anchor) {
            getWindow().location.href = anchor.href;
        },
        languageMappings(currentLang) {
            const langs = {
                en: 'en',
                es: 'es',
                ja: 'ja-jp',
                pt: 'pt-br',
                de: 'de-de'
            };
            return langs[currentLang] || 'en';
        },
        updateMarketingUrls() {
            const anchorUrlPrefix = `https://www.wolterskluwer.com/${this.languageMappings(this.currentLanguage)}/solutions/uptodate/`;
            this.localMarketingUrls = {
                medProfessionalUrl: `${anchorUrlPrefix}professional-preview-individuals`,
                medTraineeUrl: `${anchorUrlPrefix}professional-preview-individuals`,
                institutionUrl: `${anchorUrlPrefix}who-we-help/hospitals-health-systems`,
                groupPracticeUrl: `${anchorUrlPrefix}who-we-help/individuals/groups`
            };
        }
    }
};
</script>
<style lang="less">
/* stylelint-disable property-no-vendor-prefix */
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) "~_acaAssets/global/global.less";
@import (reference) "~_acaAssets/utd-button/utd-button";

#prospect-banner {
  display: none;
}

/*
  Desktop Style Tweaks
  These styles affect only desktop size and up.
*/
@media only screen and (min-width: 768px) { /* stylelint-disable-line order/order */
  .wkProspect .isDesktop {
    #prospect-banner {
      display: flex;
      position: relative;
      background-color: @WKCE-BLUE-TINT5;
      font-family: @NOTOSANS_FONT_STACK;

      #panel-left {
        display: flex;
        flex-direction: column;
        position: relative;

        .title, .subtitle {
          position: relative;
        }
        
        .title {
          font-size: 20px;
          font-weight: 600;
          color: @WKCE-GREEN-TINT3;
        }
        
        .subtitle {
          line-height: 18px;
        }

        &::before {
          content: '';
          background-color: @WKCE-BLUE-SHADE2;
          border-top: 1px solid @WKCE-BLUE-SHADE2;
          border-bottom: 1px solid @WKCE-BLUE-SHADE2;
          border-right: 1px solid @WKCE-BLUE-TINT2;
          position: absolute;
          left: -1px;
          width: 100%;
          height: 100%;
          -webkit-transform-origin: 100% 0;
          -ms-transform-origin: 100% 0;
          -moz-transform-origin: 100% 0;
          -o-transform-origin: 100% 0;
          transform-origin: 100% 0;
          -webkit-transform: skew(-20deg);
          -moz-transform: skew(-20deg);
          -ms-transform: skew(-20deg);
          -o-transform: skew(-20deg);
          transform: skew(-20deg);
        }

        &::after {
          content: '';
          background-color: @WKCE-WHITE;
          position: absolute;
          left: -38px;
          width: 34px;
          z-index: 2;
          height: 100%;
          border: 2px solid @WKCE-WHITE;
        }
      }

      #panel-right {
        .anchor-link-list {
          display: flex;
          list-style-type: none;
          padding: 0;

          .anchor-link {
            font-weight: 600;
          }
        }
      }
    }

    /* Prospect Topic View Header Banner */
    header {
      & > section > .prospect-banner-wrapper {
        margin: 14px auto;
        padding: 0 11.5px;
        overflow: hidden;
      }

      #prospect-banner {
        margin: 0 auto;
        max-width: 1116px;
        border: 1px solid @WKCE-BLUE-TINT2;
        height: 87px;

        #panel-left {
          .ds1-pl-3();
          padding-right: 58px;
          min-width: 290px;
          justify-content: center;
          background-color: transparent;
          gap: 8px;
  
          .subtitle {
            font-size: 14px;
            color: @WKCE-BLUE-TINT6;
          }
        }
  
        #panel-right {
          display: flex;
          margin: 0 auto;
          overflow: hidden;
          flex-shrink: 2;

          .anchor-link-list {
            flex-wrap: wrap;
            align-self: center;
            row-gap: 8px;

            .anchor-link {
              margin-left: 15px;
              margin-right: 17px;
              position: relative;
              font-size: 12px;

              &::after {
                content: '';
                position: absolute;
                right: 100%;
                top: 50%;
                display: inline-block;
                background-color: @WKCE-BLUE-TINT1;
                width: 1px;
                height: 16px;
                transform: translateY(-50%);
                margin-right: 15.5px;
              }
            }
          }
        }
  
        #banner-footer {
          display: none;
        }
      }

      .close-button {
        .ds1-pt-1();
        .ds1-pr-1();
        display: block;
        margin: 0;
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        color: @WKCE-GRAY-SHADE1;
      }
    }

    /* Prospect Search Results Right Side Banner Below */
    .contentError #searchresults #prospect-banner {
      position: inherit;
      float: right;
    }
    
    #searchresults {
      #prospect-banner {
        width: 300px;
        flex-direction: column;
        border: 1px solid @WKCE-GRAY-TINT4;
      }

      #panel-left {
        padding: 24px 43px 16px;
        text-align: center;
        gap: 16px;
        background-color: @WKCE-BLUE-SHADE2;

        .subtitle {
          font-size: 12px;
          color: @WKCE-WHITE;
        }

        &::before, &::after {
          display: none;
        }
      }

      #panel-right {
        background-color: @WKCE-BLUE-SHADE2;

        .anchor-link-list {
          margin: 0 0 24px;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          .anchor-link a {
            color: @WKCE-BLUE-TINT3;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      #banner-footer {
        display: flex;
        padding: 16px 20px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        background-color: @WKCE-BLUE-TINT6;

        .utd-button.utd-button-link {
          min-width: 114px;
          height: 32px;
          font-size: 14px;

          &:visited {
            color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
          }
        }
      }

      .close-button {
        display: none;
      }
    }

    /* Prospect Topic View Header Banner Styles (Alt. Languages) Below */
    &.ja { // Japanese
      header #prospect-banner #panel-left {
        min-width: 397px;

        .title {
          font-size: 16px;
        }

        .subtitle {
          font-size: 12px;
          text-wrap: nowrap;
        }
      }
    }

    &.de { // German
      header #prospect-banner #panel-left {
        min-width: 344px;
        gap: 4px;
        padding: 0 45px 0 12px;

        .title {
          font-size: 14px;
        }

        .subtitle {
          font-size: 12px;
          text-wrap: nowrap;
        }
      }
    }

    &.es { // Spanish
      header #prospect-banner {
        #panel-left {
          min-width: 275px;
          gap: 4px;

          .title {
            font-size: 14px;
          }

          .subtitle {
            font-size: 12px;
            text-wrap: nowrap;
          }
        }

        #panel-right .anchor-link-list .anchor-link {
          font-size: 12px;
        }
      }
    }

    &.pt { // Portugues
      header #prospect-banner {
        #panel-left {
          gap: 4px;

          .title {
            font-size: 16px;
          }

          .subtitle {
            font-size: 12px;
            text-wrap: nowrap;
          }
        }

        #panel-right .anchor-link-list .anchor-link {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1196px) {
  .wkProspect .isDesktop {
    /* Prospect Topic View Header Banner Styles Below */
    header #prospect-banner #panel-right .anchor-link-list .anchor-link {
      font-size: 14px;
    }

    &.ja { // Japanese
      header #prospect-banner #panel-left {
        .title {
          font-size: 18px;
        }

        .subtitle {
          font-size: 14px;
        }
      }
    }

    &.de { // German
      header #prospect-banner #panel-left {
        .ds1-pl-3();
        min-width: 425px;
        padding-right: 58px;

        .title {
          font-size: 16px;
        }

        .subtitle {
          font-size: 12px;
        }
      }
    }

    &.es { // Spanish
      header #prospect-banner #panel-left {
        min-width: 330px;

        .title {
          font-size: 16px;
        }

        .subtitle {
          font-size: 12px;
        }
      }
    }

    &.pt { // Portugues
      header #prospect-banner #panel-left {
        gap: 4px;

        .title {
          font-size: 16px;
        }

        .subtitle {
          font-size: 12px;
          text-wrap: nowrap;
        }
      }
    }
  }
}
</style>
