import { safeDecodeHtmlUriComponent } from '_acaSrc/utility/http';
import IndexManager from '_acaSrc/utility/IndexManager';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import {
    C_EVENTS,
    C_AI_SEARCH
} from '_acaSrc/utility/constants';
import { updateAiCache } from '_acaSrc/utility/contents/search/ai/ai-cache';

const state = {
    aiSearchResults: {
        shouldMakeAiRequest: false,
        hasShownAiComponent: false,
        previousAiQueryData: {
            query: null,
            searchFacetIndex: null
        },
        error: {
            hasError: false,
            errorType: null
        }
    },
    aiDeadlineResolved: false,
    hasSwitchedTabs: false,
    switchedTabsWhileLoading: false,
    aiRequestInFlight: false,
    inFlightQuery: null,
    manuallyHideAiComponent: false
};

export const getters = {
    aiSearchResultsResults: state => state.aiSearchResults.results,
    shouldMakeAiRequest: state => state.aiSearchResults.shouldMakeAiRequest,
    aiSearchRequestReturned: state => state.aiSearchResults.requestReturned,
    aiSearchResultsHasError: state => state.aiSearchResults.error,
    aiComponentHasBeenShown: state => state.aiSearchResults.hasShownAiComponent,
    aiDeadlineResolved: state => state.aiDeadlineResolved,
    prevAiQueryData: state => state.aiSearchResults.previousAiQueryData,
    hasSwitchedTabs: state => state.hasSwitchedTabs,
    hasSwitchedTabsWhileLoading: state => state.switchedTabsWhileLoading,
    isAiRequestInFlight: state => state.aiRequestInFlight,
    inFlightQuery: state => state.inFlightQuery,
    shouldMakeRequestIfInFlight: (state, getters, rootState, rootGetters) => {
        const searchQuery = rootGetters['search/searchFor'];
        const currentQuery = safeDecodeHtmlUriComponent(searchQuery);

        // If the flight isn't in progress, or the currently in-progress query is different
        return !state.aiRequestInFlight || 
                (state.aiRequestInFlight && currentQuery !== state.inFlightQuery);
    },
    manuallyHideAiComponent: state => state.manuallyHideAiComponent,
    // eslint-disable-next-line complexity
    shouldShowPreviousAiResults: (state, getters, rootState, rootGetters) => {
        const isNewResults = rootGetters['search/isNewResults'];
        const searchParams = rootGetters['search/searchParams'];
        const currentQuery = safeDecodeHtmlUriComponent(rootGetters['search/searchFor']);
        const curSearchFacetIndex = searchParams.sp;

        const prevAiQueryData = state.aiSearchResults.previousAiQueryData;
        const prevQuery = prevAiQueryData && prevAiQueryData.query;
        const prevSearchFacetIndex = prevAiQueryData && prevAiQueryData.searchFacetIndex;
        const prevAiResultsHadError = state.aiSearchResults.error &&
                                      state.aiSearchResults.error.hasError;
        const shouldIgnorePreviousError = prevAiResultsHadError && 
                                          isStaticError(state.aiSearchResults.error.errorType);
        const onFirstQuery = currentQuery && curSearchFacetIndex &&
                             !prevQuery && !prevSearchFacetIndex;

        /* If we're on our first query, and user clicked collapse/show more results,
            show prev ai results (this helps avoid pop-in or pop-out of the component) */
        if (onFirstQuery && !isNewResults) {
            return true;
        }

        /* If we don't have 4 check values, or we had non-ignorable error before & user didn't click
        collapse/show more results to trigger this new 'search,' we're safe to do a new AI query */
        if (!currentQuery ||
            !curSearchFacetIndex ||
            !prevQuery ||
            !prevSearchFacetIndex ||
            (prevAiResultsHadError &&
             !shouldIgnorePreviousError &&
             isNewResults)// Non-ignorable error & no collpase/show more results clicked
        ) {
            return false;
        }

        /* To do request on all tabs, remove current/prev query and changed tabs check portion
           and add "rootGetters['search/searchParams'].sp !== C_SEARCH.PRIORITY.GRAPHICS && ..."
           to not do request on graphics tab (isGraphicsSearch is not set fast enough) */

        /* If our current and prev query are the same and the user has changed tabs, OR if request
           is not for new results (clicked collapse/show more results), show prev ai results*/
        if ((currentQuery === prevQuery && curSearchFacetIndex !== prevSearchFacetIndex) ||
            !isNewResults) {
            return true;
        }

        return false; // If all else fails, better to do a new ai query than to not
    }
};

export const SET_AI_SEARCH_RESULTS = 'SET_AI_SEARCH_RESULTS';
export const SET_HAS_SWITCHED_TABS = 'SET_HAS_SWITCHED_TABS';
export const SET_HAS_SWITCHED_TABS_WHILE_LOADING = 'SET_HAS_SWITCHED_TABS_WHILE_LOADING';
export const SET_IS_AI_REQUEST_IN_FLIGHT = 'SET_IS_AI_REQUEST_IN_FLIGHT';
export const SET_MANUALLY_HIDE_AI_COMPONENT = 'SET_MANUALLY_HIDE_AI_COMPONENT';
export const SET_SHOULD_MAKE_AI_REQUEST = 'SET_SHOULD_MAKE_AI_REQUEST';
export const SET_AI_SEARCH_REQUEST_RETURNED = 'SET_AI_SEARCH_REQUEST_RETURNED';
export const SET_AI_SEARCH_RESULTS_HAS_ERROR = 'SET_AI_SEARCH_RESULTS_HAS_ERROR';
export const SET_AI_COMPONENT_HAS_BEEN_SEEN = 'SET_AI_COMPONENT_HAS_BEEN_SEEN';
export const SET_AI_DEADLINE_RESOLVED = 'SET_AI_DEADLINE_RESOLVED';
export const SET_AI_PREV_QUERY_DATA = 'SET_AI_PREV_QUERY_DATA';
export const RESET_AI_SEARCH_STATE = 'RESET_AI_SEARCH_STATE';

export const mutations = {
    [SET_AI_SEARCH_RESULTS]: (state, value) => state.aiSearchResults.results = value,
    [SET_HAS_SWITCHED_TABS]: (state, value) => state.hasSwitchedTabs = value,
    [SET_HAS_SWITCHED_TABS_WHILE_LOADING]: (state, value) => state.switchedTabsWhileLoading = value,
    [SET_IS_AI_REQUEST_IN_FLIGHT]: (state, value) => {
        state.aiRequestInFlight = value.isInFlight;
        state.inFlightQuery = value.inFlightQuery;
    },
    /* In order to avoid the possiblility that the AI component appears (with an error)
       on a direct-hit for users that don't have the LMS feature enabled,
       we've decided to hide the component manually using the AI LMS value,
       now returned on the traditional search results endpoint */
    [SET_MANUALLY_HIDE_AI_COMPONENT]: (state, value) => state.manuallyHideAiComponent = value,
    [SET_SHOULD_MAKE_AI_REQUEST]: (state, value) => {
        state.aiSearchResults.shouldMakeAiRequest = value;
    },
    [SET_AI_SEARCH_REQUEST_RETURNED]: (state, value) => {
        state.aiSearchResults.requestReturned = value;
    },
    [SET_AI_SEARCH_RESULTS_HAS_ERROR]: (state, error) => {
        state.aiSearchResults.error.hasError = true;
        state.aiSearchResults.error.errorType = error.message || error;
    },
    [SET_AI_COMPONENT_HAS_BEEN_SEEN]: (state, value) => {
        state.aiSearchResults.hasShownAiComponent = value;
    },
    [RESET_AI_SEARCH_STATE]: (state) => {
        state.aiSearchResults.error.hasError = false;
        state.aiSearchResults.error.errorType = null;
        state.aiSearchResults.hasShownAiComponent = false;
        state.hasSwitchedTabs = false;
        state.hasSwitchedTabsWhileLoading = false;
        delete state.aiSearchResults.requestReturned;
        delete state.aiSearchResults.results;
    },
    [SET_AI_DEADLINE_RESOLVED]: (state, value) => state.aiDeadlineResolved = value,
    [SET_AI_PREV_QUERY_DATA]: (state, data) => {
        state.aiSearchResults.previousAiQueryData.query = data.query;
        state.aiSearchResults.previousAiQueryData.searchFacetIndex = data.searchFacetIndex;
    }
};

export const actions = {
    // eslint-disable-next-line complexity
    checkIfHasSwitchedTabs({ getters, commit, rootGetters }) {
        const searchParams = rootGetters['search/searchParams'];
        const prevAiQueryData = getters.prevAiQueryData;
        const curSearchFacetIndex = searchParams.sp;
        const prevSearchFacetIndex = prevAiQueryData && prevAiQueryData.searchFacetIndex;
        const currentQuery = safeDecodeHtmlUriComponent(rootGetters['search/searchFor']);
        const prevQuery = prevAiQueryData && prevAiQueryData.query;

        // If we don't have our values, just return
        if (!curSearchFacetIndex ||
            !prevSearchFacetIndex ||
            !currentQuery ||
            !prevQuery) {
            return;
        }

        // If the queries are different, we haven't switched tabs
        if (currentQuery !== prevQuery) {
            commit('SET_HAS_SWITCHED_TABS', false);
            return;
        }

        // If the search is the same and facet is different, we've switched tabs
        if (curSearchFacetIndex !== prevSearchFacetIndex) {
            commit('SET_HAS_SWITCHED_TABS', true);
            commit('SET_HAS_SWITCHED_TABS_WHILE_LOADING', !getters.aiSearchRequestReturned);
        }
    },
    checkIfAiRequestRequired({ commit, rootGetters }) {
        const hasLmsFeatureBeenChecked = rootGetters['feature/aiSearchFlagSet'];
        const hasAiFeatureFlag = rootGetters['feature/hasAiSearchFlag'] || false;

        // We make the request if they have the flag, or they're authed and we don't know flag yet
        const shouldMakeAiRequest =
            hasAiFeatureFlag || (!rootGetters['app/isProspectView'] && !hasLmsFeatureBeenChecked);

        commit('SET_SHOULD_MAKE_AI_REQUEST', shouldMakeAiRequest);
    },
    getAiSearchResults({ commit, dispatch, rootGetters }) {
        const searchTerm = rootGetters['search/searchParamsSearchText'];

        if (!searchTerm) {
            const error = new Error('Cannot find search term');
            dispatch('resolveAiSearchError', { error, searchTerm });
            return;
        }

        const url = 'contents/search/passage-retrieval/json';

        commit(
            SET_IS_AI_REQUEST_IN_FLIGHT,
            {
                isInFlight: true,
                inFlightQuery: searchTerm
            }
        );
        utdRest('search/passage-retrieval', {
            uri: url,
            params: {
                query: searchTerm
            }
        }).then(results => {
            dispatch('resolveAiSearchResults', { results, searchTerm });
        }).catch(error => {
            dispatch('resolveAiSearchError', { error, searchTerm });
        }).finally(() => {
            commit(
                SET_IS_AI_REQUEST_IN_FLIGHT,
                {
                    isInFlight: false,
                    inFlightQuery: null
                }
            );
        });
    },
    async resolveAiSearchResults({ commit, dispatch, getters }, { results, searchTerm }) {
        if (!returnedResultsAreForCurrentRequest(searchTerm, getters.inFlightQuery)) {
            return;
        }
        const error = checkForAiError(results);
        new IndexManager().bodyCss.setOrClear('aiNoResults', !!error);

        if (error) {
            dispatch('resolveAiSearchError', { error, searchTerm });
            dispatch('populatePreviousAiQueryData');
            return;
        }

        commit(SET_AI_SEARCH_RESULTS, results.searchResults);
        commit(SET_AI_SEARCH_REQUEST_RETURNED, true);
        new PubSub().publish(C_EVENTS.AI_SEARCH_DEADLINE);
        dispatch('populatePreviousAiQueryData');
        updateAiCache(
            searchTerm,
            results.searchResults,
            results.isFromCache
        );
    },
    resolveAiSearchError({ commit, getters }, { error, searchTerm }) {
        if (!returnedResultsAreForCurrentRequest(searchTerm, getters.inFlightQuery)) {
            return;
        }
        commit(SET_AI_SEARCH_RESULTS_HAS_ERROR, error);
        commit(SET_AI_SEARCH_REQUEST_RETURNED, true);
        new PubSub().publish(C_EVENTS.AI_SEARCH_DEADLINE);
    },
    populatePreviousAiQueryData({ commit, rootGetters }) {
        const searchParams = rootGetters['search/searchParams'];
        const query = safeDecodeHtmlUriComponent(rootGetters['search/searchFor']);
        const previousAiQueryData = {
            query,
            searchFacetIndex: searchParams.sp
        };
        commit(SET_AI_PREV_QUERY_DATA, previousAiQueryData);
    },
    shouldDoDeadline({ getters, rootGetters }) {
        /* User is authed; LMS AI flag hasn't been set to false;
           results haven't returned in error or in genereal */
        return !rootGetters['app/isProspectView'] &&
               !(rootGetters['feature/aiSearchFlagSet'] &&
                 !rootGetters['feature/hasAiSearchFlag']) &&
               !getters.aiSearchResultsHasError.hasError &&
               !getters.aiSearchRequestReturned;
    },
    deadlineLogic({ commit, getters }, resolve) {
        const deadlineHelper = () => {
            if (!getters.aiDeadlineResolved) {
                resolve();
            }
            commit(SET_AI_DEADLINE_RESOLVED, true);
        };
    
        function wrapupDeadline() {
            new PubSub().unsubscribe(C_EVENTS.AI_SEARCH_DEADLINE, deadlineHelper);
            if (!getters.aiDeadlineResolved) {
                resolve();
            }
            commit(SET_AI_DEADLINE_RESOLVED, true);
        }
    
        new PubSub().subscribe(C_EVENTS.AI_SEARCH_DEADLINE, deadlineHelper);
        setTimeout(() => {
            wrapupDeadline();
        }, C_AI_SEARCH.DEADLINE_MAX_MS);
    }
};

// eslint-disable-next-line complexity
function checkForAiError(results) {
    if (results &&
        results.error &&
        typeof results.error === 'string' &&
        results.error.length && results.error.length > 0
    ) {
        return new Error(results.error);
    }

    if (!results
        || !results.searchResults
        || !results.searchResults.length
        || !results.searchResults.length > 0
    ) {
        return new Error('No results for query');
    }
}

function returnedResultsAreForCurrentRequest(searchTerm, inFlightQuery) {
    /* If searchTerm (current query) is the same as in flight query (old query),
       or in flight query has been reset (no in flight requests) */
    return searchTerm === inFlightQuery || inFlightQuery === null;
}

/** Static errors do not need their queries to be re-attempted when user switches tabs,
    as reattempting the query will still return the same error */
function isStaticError(errorType) {
    return errorType &&
           errorType === C_AI_SEARCH.ERROR_TYPES.QUERY_REJECTION ||
           errorType === C_AI_SEARCH.ERROR_TYPES.NO_RESULTS ||
           errorType === C_AI_SEARCH.ERROR_TYPES.NOT_ENGLISH ||
           errorType === C_AI_SEARCH.ERROR_TYPES.FILTERED_RESULTS;
}

const ai = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default ai;