<template>
  <section id="homepage_awarenespanel" class="awareness-panel-section">
    <template v-for="(link, index) in links" :key="link.name">
      <awareness-panel-item :id="index === 0 ? 'first-awareness-panel-item' : link.langKey" 
                            :item-class="link.itemClass"
                            :lang-key="link.langKey"
                            :left-icon="link.leftIcon"
                            :url="link.url"
                            :with-appended-arrow="link.withAppendedArrow || false" />
    </template>
  </section>
</template>

<script>
import AwarenessPanelItem from '_acaSrc/components/homePageRedesign/AwarenessPanelItem.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'HomeSearchAwarenessPanel',
    components: { AwarenessPanelItem },
    computed: {
        ...mapGetters('app', [ 'isUccState', 'isProspectView' ]),
        ...mapGetters('feature', [ 'isShowRxTransitions', 'isWnPcuPanelEnabled' ]),
        links() {
            let links = [
                {
                    langKey: 'HEADER.DRUG_INTERACTIONS',
                    url: '/drug-interactions/?source=responsive_home#di-druglist',
                    leftIcon: 'drug-interactions',
                    withAppendedArrow: false,
                    itemClass: 'drug-interactions'
                },
                {
                    langKey: 'HEADER.PRACTICE_CHANGING_UPDATES',
                    url: '/contents/practice-changing-updates',
                    leftIcon: 'practice-changing-updates',
                    withAppendedArrow: false,
                    itemClass: 'practice-changing-updates'
                },
                {
                    langKey: 'HEADER.WHATS_NEW',
                    url: '/contents/table-of-contents/whats-new',
                    leftIcon: 'whats-new-by-specialty',
                    withAppendedArrow: false,
                    itemClass: 'whats-new'
                },
                {
                    langKey: 'HEADER.PATIENT_EDUCATION',
                    url: '/contents/table-of-contents/patient-education',
                    leftIcon: 'patient-education',
                    withAppendedArrow: false,
                    itemClass: 'patient-education'
                },
                {
                    langKey: 'HEADER.CALCULATORS',
                    url: '/contents/table-of-contents/calculators/categorized',
                    leftIcon: 'calculators',
                    withAppendedArrow: false,
                    itemClass: 'calculators'
                }
            ];

            if (!this.isProspectView && this.isWnPcuPanelEnabled) {
                links = links.filter(link => link.langKey !== 'HEADER.PRACTICE_CHANGING_UPDATES' 
                && link.langKey !== 'HEADER.WHATS_NEW');
            }
            
            if(!this.isUccState && this.isShowRxTransitions) {
                links.push({
                    langKey: 'HEADER.RX_TRANSITIONS',
                    url: '/rxtransitions?source=homepage_awarenespanel',
                    leftIcon: 'rx-transitions-for-mental-health',
                    withAppendedArrow: false,
                    itemClass: 'rx-transitions-for-mental-health'
                });
            }

            return links;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.awareness-panel-section {
  display: flex;
  flex-direction: column;
  width: 630px;
  margin: 32px auto 64px;

  .isWnPcuPanelEnabled & {
    .ds1-mv-4();
    margin-left: auto;
    margin-right: auto;
    width: unset;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .awareness-panel-section {
    width: unset;
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  }
}

@media only screen and (min-width: 768px) {
  .isWnPcuPanelEnabled .awareness-panel-section {
    margin-bottom: 14vh;
  }
}

@media only screen and (min-width: 992px) {
  .isWnPcuPanelEnabled .awareness-panel-section {
    margin-left: 15vw;
  }
}

</style>