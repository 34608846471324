import { C_UI, C_QAP } from '_acaSrc/utility/constants';
import { getQueryParamValues, setSearchUrlParamsHelper } from '_acaSrc/utility/http';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import UtdQueuedCache from '_acaSrc/utility/UtdQueuedCache';

export const initDxyContent = () => {
    return {
        monograph: {
            url: ''
        },
        dosages: []
    };
};

export const DEFAULT_DOSAGES_LIMIT = 10;

const state = {
    dxyQap: {
        hasError: false,
        hasMadeRequest: false,
        dosagesLimit: DEFAULT_DOSAGES_LIMIT,
        cancelSpinner: null,
        showSpinner: false,
        content: initDxyContent()
    }
};

export const getters = {
    dxyQap: state => state.dxyQap,
    dxyHasError: state => state.dxyQap.hasError,
    dxyHasMadeRequest: state => state.dxyQap.hasMadeRequest,
    dxyDosagesLimit: state => state.dxyQap.dosagesLimit,
    dxyCancelSpinner: state => state.dxyQap.cancelSpinner,
    dxyShowSpinner: state => state.dxyQap.showSpinner,
    dxyContent: state => state.dxyQap.content
};

export const RESET_DXY_CONTENT = 'RESET_DXY_CONTENT';
export const SET_DXY_HAS_ERROR = 'SET_DXY_HAS_ERROR';
export const SET_DXY_HAS_MADE_REQUEST = 'SET_DXY_HAS_MADE_REQUEST';
export const SET_DXY_DOSAGES_LIMIT = 'SET_DXY_DOSAGES_LIMIT';
export const SET_DXY_CANCEL_SPINNER = 'SET_DXY_CANCEL_SPINNER';
export const SET_DXY_SHOW_SPINNER = 'SET_DXY_SHOW_SPINNER';
export const SET_DXY_CONTENT = 'SET_DXY_CONTENT';

export const mutations = {
    [RESET_DXY_CONTENT]: state => {
        state.dxyQap.hasError = false;
        state.dxyQap.hasMadeRequest = false;
        state.dxyQap.showSpinner = false;
        state.dxyQap.content = initDxyContent();
    },
    [SET_DXY_HAS_ERROR](state, dxyHasError) {
        state.dxyQap.hasError = dxyHasError;
    },
    [SET_DXY_HAS_MADE_REQUEST](state, dxyHasMadeRequest) {
        state.dxyQap.hasMadeRequest = dxyHasMadeRequest;
    },
    [SET_DXY_DOSAGES_LIMIT](state, dxyDosagesLimit) {
        state.dxyQap.dosagesLimit = dxyDosagesLimit;
    },
    [SET_DXY_CANCEL_SPINNER](state, dxyCancelSpinner) {
        state.dxyQap.cancelSpinner = dxyCancelSpinner;
    },
    [SET_DXY_SHOW_SPINNER](state, dxyShowSpinner) {
        state.dxyQap.showSpinner = dxyShowSpinner;
    },
    [SET_DXY_CONTENT](state, dxyContent) {
        state.dxyQap.content = dxyContent;
    }
};

export const actions = {
    getPharmaList(context, { topicId, dosageFormId, search }) {
        return { promise: utdRest('dxy/getPharmaList', { topicId, dosageFormId, search })
            .then(data => {
                return data.map(item => {
                    return {
                        url: item.url,
                        linkText: item.title,
                        label: item.company
                    };
                });
            }
            ) };
    },
    getPackageInsert(context, { topicId, search }) {
        return utdRest('dxy/getPackageInsert', { topicId, search });
    },
    getDxyPanelResults({ rootGetters, commit, getters, dispatch }, payload) {
        const { contentId, resultUrl, searchTerm, drugName, subType, rankIndex } = payload;
        commit(RESET_DXY_CONTENT);
        commit(SET_DXY_CANCEL_SPINNER, setTimeout(() => {
            commit(SET_DXY_SHOW_SPINNER, true);
        }, C_UI.DEFAULT_SPINNER_DELAY_MS));

        if (subType === C_QAP.PANEL.DRUG_DXY) {
            return utdRest('dxy/getPanelContent', {
                topicId: contentId
            }).then(data => {
                commit(SET_DXY_CONTENT, {
                    monograph: data.monograph,
                    dosages: data.dosages
                });
                dispatch('setDxyContentUrlParams', { resultUrl, searchTerm, drugName, rankIndex });
                new UtdQueuedCache()
                    .setPersistent('utdSPAContentCache',
                        data.route, data, rootGetters['app/contentVersion']);
                commit(SET_DXY_DOSAGES_LIMIT, DEFAULT_DOSAGES_LIMIT);
            }).catch(() => {
                commit(SET_DXY_HAS_ERROR, true);
            }).finally(() => {
                commit(SET_DXY_HAS_MADE_REQUEST, true);
                commit(SET_DXY_SHOW_SPINNER, false);
                clearTimeout(getters.dxyCancelSpinner);
            });
        }
    },
    setDxyContentUrlParams({ commit, getters }, payload) {
        const { resultUrl, searchTerm, drugName, rankIndex } = payload;
        const selectedTitle = getQueryParamValues(resultUrl, 'selectedTitle');
        const urlParams = {
            rankIndex,
            searchTerm,
            kpTab: C_QAP.PANEL.DRUG_DXY,
            selectedTitle
        };
        if (getters.dxyContent.monograph) {
            setQapDxyUrlParams({ commit, getters, urlParams });
        }
        // Set drug name to be displayed as header for PharmaList template
        urlParams.drugName = drugName;
        if (getters.dxyContent.dosages) {
            setQapDxyPharmaListUrlParams({ getters, urlParams });
        }
    }
};

const setQapDxyUrlParams = ({ commit, getters, urlParams }) => {
    const url = setSearchUrlParamsHelper(
        getters.dxyContent.monograph.url, urlParams);
    commit(SET_DXY_CONTENT, {
        ...getters.dxyContent,
        monograph: {
            ...getters.dxyContent.monograph,
            url
        }
    });
};

const setQapDxyPharmaListUrlParams = ({ getters, urlParams }) => {
    for (let d = 0; d < getters.dxyContent.dosages.length; d++) {
        const dosage = getters.dxyContent.dosages[d];
        dosage.url = setSearchUrlParamsHelper(dosage.url, urlParams);
    }
};

const dxy = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default dxy;

export {
    setQapDxyUrlParams as _setQapDxyUrlParams,
    setQapDxyPharmaListUrlParams as _setQapDxyPharmaListUrlParams
};