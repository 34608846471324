<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div id="toc-toolbar" :class="tocCssToolbar">
      <utd-toolbar :class="{'utd-toolbar-fixed': isHeaderRedesign}">
        <div ref="tocName" class="toc-toolbar-content">
          <utd-toolbar-back-link :show-back-button="true" />
          <utd-toolbar-title :title="tocName" :size="isTitleSmall ? 'small': 'large'" />
        </div>
      </utd-toolbar>
      <div v-if=" tocIsShowPISubnav || showDisplayBar "
           class="toc-toolbar-tabs"
           :class="{ 'tabs-visible': tocIsShowPISubnav }">
        <utd-tabs v-if="tocIsShowPISubnav"
                  :tab-style="'wk-tabs-default'"
                  class="wk-js2 with-divider"
                  :active-tab="activeTab"
                  @selectedTab="onSwitchTab">
          <utd-tab tab-id="0" title="The Basics" />
          <utd-tab tab-id="1" title="Beyond the Basics" />
        </utd-tabs>
        <div v-if="showDisplayBar"
             class="toc-filter"
             :class="{'no-nav-toggle': !tocIsShowNavToggle}">
          <ul id="display" ref="displayBar">
            <li v-if="tocIsShowFilter" id="toc-filter-form ds1-utd-js2">
              <form v-show="tocIsShowFilter"
                    id="toc-search-form"
                    v-blur-input-onsubmit
                    role="form"
                    class="jumpstart-form "
                    @submit.prevent>
                <fieldset class="wk-js2 toc-filter-field">
                  <utd-text-input label="Search"
                                  input-id="toc-filter">
                    <input v-show="tocIsShowFilter"
                           id="toc-filter"
                           v-model="tocFilterModel"
                           type="text"
                           :class="tocFilterInputClasses"
                           class="form-field jumpstart-input"
                           autocorrect="off"
                           autocapitalize="off"
                           autocomplete="off"
                           spellcheck="false">
                  </utd-text-input>
                  <utd-clear-input-box id="clearFilter"
                                       :control="tocFilter"
                                       outer-class="filter-clear"
                                       :clear-fn="clearFilter" />
                </fieldset>
                <fieldset v-if="tocIsShowNavToggle"
                          class="wk-js2 sort-filter toc-filter-field"
                          :class="{ 'is-sub-cat': tocCssSubCats === 'selected' }">
                  <span class="sort-fitler-label">Sort:</span>
                  <utd-radio-set id="toc-sort-radio"
                                 :value="sortBy"
                                 :items="[{ option: 'By Specialty', ariaLabel: 'By Specialty'},
                                          { option: 'Alphabetical', ariaLabel: 'Alphabetical'}]"
                                 @input="onOrderChange($event)" />
                </fieldset>
              </form>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="toc-content" ref="tocContent">
      <div v-if="tocIsShowHeader"
           v-show="showTocHeader"
           id="toc-header"
           class="toc-header"
           :class="tocCssHeader">
        <span v-if="tocIsShowHeader" v-html="tocHeader" />
      </div>
      <div id="toc-list"
           :class="tocListClasses"
           @click="onTocListClick($event)">
        <strong v-if="tocIsShowTitle" v-html="tocTitle" />
        <div v-if="arrayPopulated(tocSections)"
             id="toc-sections"
             class="wk-js2 utd-listView"
             :class="[calculatorClass]">
          <utd-accordion ref="accordionRef"
                         :accordion-items="tocSections"
                         :accordion-style-class="'ds1-accordion'"
                         :show-expand-collapse="!hideExpandCollapse">
            <template v-slot:content="slotProps">
              <dl v-if="shouldRenderSubsections(slotProps.item)" :key="`section ${slotProps.index}`">
                <template v-for="(subsec, subsecIdx) in slotProps.item.subSections">
                  <dt v-if="shouldRenderSubsectionName(subsec)" :key="`subsec ${subsecIdx}`">
                    <span v-html="subsec.name" />
                  </dt>
                  <dd v-for="(item, itemIdx) in items
                        = expressionSearchFilter(subSectionItems(subsec), tocFilter)"
                      :key="`subsec item ${subsecIdx} ${itemIdx}`"
                      :class="subsectionItemClasses(items, itemIdx)">
                    <a :href="item.url" :class="customTocItemClass(item)" v-html="item.name" />
                    <utd-view-in-language :topic-info="item" />
                  </dd>
                </template>
              </dl>
              <ul v-if="hasTocSectionItems(sectionItems(slotProps.item))"
                  class="unit-conversion-calculator-list">
                <li v-for="(item, itemIdx)
                      in items = tocSectionItemsFilter(sectionItems(slotProps.item))"
                    :key="`section item ${slotProps.index} ${itemIdx}`"
                    class="toc-result-item"
                    :class="sectionItemClasses(items, item, itemIdx)">
                  <div v-if="item.customizedBy"
                       class="customized-pathway-label"
                       v-html="item.customizedBy" />
                  <a :href="item.url" :class="customTocItemClass(item)">
                    <span class="toc-result-item-link-text" v-html="item.name" />
                  </a>
                  <utd-view-in-language :topic-info="item" />
                </li>
              </ul>
            </template>
          </utd-accordion>
        </div>
        <ul v-show="arrayPopulated(tocItems)"
            class="utd-listView toc-alphabetical"
            :class="alphabeticalItemListClasses">
          <li v-for="(item, itemIdx) in filteredExpressions"
              v-show="showAlphabeticalItem(item)"
              :key="`item ${itemIdx}`"
              class="toc-result-item"
              :class="alphabeticalItemClasses(item)">
            <div v-if="item.customizedBy"
                 class="customized-pathway-label"
                 v-html="item.customizedBy" />
            <a :id="item.navClass"
               v-utd-new-tab="!!item.newTab"
               :href="item.url"
               :target="alphabeticalItemAnchorTarget(item)"
               :class="tocItemClasses(item)"
               v-html="item.name" />
            <utd-view-in-language :topic-info="item" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import {
    SET_TOC_FILTER,
    SET_TOC_CSU_CHECKED,
    SET_TOC_FILTER_TYPE,
    SET_TOC_SECTIONS,
    SET_TOC_ITEMS,
    SET_TOC_CSS_PI_FILTER
} from '_acaSrc/store/toc.store';

import { C_UI, C_TOC, C_EVENTS } from '_acaSrc/utility/constants';
import expressionSearchFilter from '_acaSrc/filters/ExpressionSearchFilter';
import { safeTimeout } from '_acaSrc/utility/timers';
import { getWindow, getDocument, checkAndSetEventBinding } from '_acaSrc/utility/DOM';
import { dispatchEvent } from '_acaSrc/utility/Events';

import UtdViewInLanguage from '_acaSrc/components/shared/utd/UtdViewInLanguage.vue';
import UtdClearInputBox from '_acaSrc/components/ui/ClearInputBox.vue';
import UtdToolbarTitle from '_acaSrc/components/shared/toolbar/UtdToolbarTitle.vue';
import UtdToolbarBackLink from '_acaSrc/components/shared/utd/UtdToolbarBackLink.vue';
import UtdToolbar from '_acaSrc/components/shared/toolbar/UtdToolbar.vue';
import UtdTabs from '_acaSrc/components/shared/stdlib/UtdTabs.vue';
import UtdTab from '_acaSrc/components/shared/stdlib/UtdTab.vue';

import BlurInputOnsubmit from '_acaSrc/components/ui/BlurInputOnsubmit';
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';

import { makeRestUrl } from '_acaSrc/utility/http/UtdUrlUtilities';
import PubSub from '_acaSrc/utility/PubSub';
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';
import { checkIfLinkVisited, setLinkVisitedClass } from '_acaSrc/utility/UtdVisitedLink';
import UtdTextInput from '_acaSrc/components/shared/input/UtdTextInput.vue';
import UtdRadioSet from '_acaSrc/components/shared/stdlib/UtdRadioSet.vue';

const TOC_NAME_PATHWAYS = 'Pathways';
const TOC_REPOSITION_DELAY = 100;
const BROADCAST_DELAY = 100;
const TAB_BASICS = 0;
const TAB_BEYOND_THE_BASICS = 1;
const MAX_ITEMS_PER_SINGLE_COLUMN = 10;
export default {
    components: {
        UtdViewInLanguage,
        UtdClearInputBox,
        UtdToolbarTitle,
        UtdTabs,
        UtdTab,
        UtdToolbarBackLink,
        UtdToolbar,
        UtdAccordion,
        UtdTextInput,
        UtdRadioSet
    },
    directives: {
        BlurInputOnsubmit,
        UtdNewTab
    },
    data() {
        return {
            tocFilterModel: '',
            cancelResizeTimeout: null,
            resetPositioningTimeout: null,
            isTitleSmall: false,
            hideExpandCollapse: false,
            sortBy: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'disabledPathways',
            'enabledPathways',
            'additiveContentKeys',
            'isAnonymousUser',
            'router'
        ]),
        ...mapGetters('toc', [
            'tocIsShowPISubnav',
            'tocIsShowNavToggle',
            'tocIsShowFilter',
            'tocName',
            'tocSections',
            'tocItems',
            'tocFilterType',
            'tocPlaceholder',
            'tocIsShowHeader',
            'tocHeader',
            'tocIsShowTitle',
            'tocTitle',
            'tocIsNotFinalNode',
            'tocCssPiFilter',
            'tocCssSubCats',
            'tocCssSubAlpha',
            'tocCssHeader',
            'tocCssToolbar',
            'tocFilter',
            'tocCsuChecked'
        ]),
        ...mapGetters('feature', [
            'isHeaderRedesign',
            'hasPathwaysCsuNotes',
            'hasPathwaysContent'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('user', [ 'localization', 'language' ]),
        currentLanguage() {
            return this.localization && this.language;
        },
        showDisplayBar() {
            return this.tocIsShowNavToggle || this.tocIsShowFilter;
        },
        tocFilterInputClasses() {
            return {
                'csu-toc-filter': this.hasPathwaysCsuNotes
                               && this.tocName === TOC_NAME_PATHWAYS
            };
        },
        showCsuOnlyCheckbox() {
            return this.hasPathwaysCsuNotes && this.tocName === TOC_NAME_PATHWAYS;
        },
        showTocHeader() {
            return !(this.isHeaderRedesign && !this.isDesktopView);
        },
        tocListClasses() {
            return {
                titled: this.tocIsShowTitle,
                'pathways-toc-no-csu-filter': this.hasPathwaysCsuNotes
            };
        },
        alphabeticalItemListClasses() {
            return { isNotFinalNode: this.tocIsNotFinalNode,
                isCalculatorList: this.tocName === 'Calculators',
                wrapColumns: this.tocName !== 'Calculators'
                 && this.filteredExpressions.length >= MAX_ITEMS_PER_SINGLE_COLUMN };
        },
        shouldWatchTocNameHeight() {
            return this.isHeaderRedesign
                && !this.tocIsShowPISubnav
                && !this.tocIsShowNavToggle
                && !this.tocIsShowFilter;
        },
        filteredExpressions() {
            return this.tocItems ? expressionSearchFilter(this.tocItems, this.tocFilter) : [];
        },
        activeTab() {
            return this.tocCssPiFilter === 'basicsOnly' ? TAB_BASICS : TAB_BEYOND_THE_BASICS;
        },
        calculatorClass() {
            return this.tocName === 'Calculators' ? 'isCalculatorList' : '';
        }
    },
    watch: {
        tocFilterModel(newVal) {
            this.onTocFilterChange(newVal);
        },
        tocCsuChecked() {
            if (this.hasPathwaysCsuNotes) {
                this.triggerDelayedResizeEvent();
            }
            this.checkHideExpandCollapse();
        },
        tocFilterType() {
            this.setFilterLabelOrder();
        },
        filteredExpressions(val, newval){
            if(val !== newval && this.tocName !== TOC_NAME_PATHWAYS) {
                this.$nextTick(() => this.resetTocContentPositioning());
            }
        }
    },
    mounted() {
        this.setup();
        this.setFilterLabelOrder();
        new PubSub().subscribe(C_EVENTS.REFRESH_TOC, this.onSectionChange);
    },
    beforeUnmount() {
        if (this.cancelResizeTimeout) {
            this.cancelResizeTimeout();
        }
        clearTimeout(this.resetPositioningTimeout);
        new PubSub().unsubscribe(C_EVENTS.REFRESH_TOC, this.onSectionChange);
        getWindow().removeEventListener('resize', this.setTocContentHeight, { passive: true });
        this.clearScrollListener(this.windowScrollListener);

        if (!this.isAnonymousUser) {
            new PubSub().unsubscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, setLinkVisitedClass);
        }
    },
    methods: {
        ...mapMutations('toc', [
            SET_TOC_FILTER,
            SET_TOC_CSU_CHECKED,
            SET_TOC_FILTER_TYPE,
            SET_TOC_SECTIONS,
            SET_TOC_ITEMS,
            SET_TOC_CSS_PI_FILTER
        ]),
        ...mapActions('toc', [
            'filterPathwaysTocBySearchTerms',
            'setPatientEducationMenuState'
        ]),
        ...mapActions('app', [ 'broadcast', 'delayExecuteUntilDataLoaded' ]),
        setup() {
            this.tocFilterModel = this.tocFilter;
            this.sortBy = this.tocCssSubAlpha? 1 : 0;
            if (this.showDisplayBar && this.tocIsShowFilter) {
                this.filterBySearchTerms();
            }
            this.resetTocContentPositioning();
            this.setScrollsListeners();
            this.setupEventHandlers();
            this.setupSetFocusOnFilter();
            this.initSearchResultClickHandlers();
        },
        onSectionChange() {
            this.setup();
            this.onTocFilterChange(this.tocFilterModel);
        },
        onTocFilterChange(newVal) {
            if (!(newVal && newVal.trim && newVal.trim())) {
                this.clearFilter();
                this.$refs.accordionRef && this.$refs.accordionRef.toggleAllItems(false);
                return;
            }
            this[SET_TOC_FILTER](newVal.trim());
            this.filterBySearchTerms();
            this.$refs.accordionRef && this.$refs.accordionRef.toggleAllItems(true);
            this.checkHideExpandCollapse();
        },
        checkHideExpandCollapse() {
            setTimeout(() => {
                const tocSectionsAccordion = getDocument()
                    .querySelector('#toc-sections > .wk-accordion');
                const hiddenAccordionItems = (tocSectionsAccordion
                    && tocSectionsAccordion.querySelectorAll('.empty-slot').length) || 0;

                this.hideExpandCollapse = !!this.tocSections
                    && (this.tocSections.length === hiddenAccordionItems);
            }, C_TOC.CHECK_HIDE_EXPAND_COLLAPSE_DELAY);
        },
        setupEventHandlers() {
            if (!this.isAnonymousUser) {
                new PubSub().subscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, setLinkVisitedClass);
            }
        },
        setTocContentHeight() {
            if (this.$refs.tocName) {
                this.$refs.tocContent.style.paddingTop = `${this.$refs.tocName.offsetHeight}px`;
            }
        },
        filterPathwaysList() {
            if (this.tocName === TOC_NAME_PATHWAYS) {
                if (this.tocSections) {
                    this[SET_TOC_SECTIONS](
                        this.filterSectionsByEnabledDisabledPathways(this.tocSections));
                }
                else {
                    this[SET_TOC_ITEMS](this.filterTopicsByEnabledDisabledPathways(this.tocItems));
                }
            }
        },
        filterTopicsByEnabledDisabledPathways(items) {
            if ((this.disabledPathways.length)) {
                items = items.filter(
                    item => !this.disabledPathways.includes(item.topicId));
            }
            else if (!this.hasPathwaysContent) {
                items = items.filter(
                    item => this.enabledPathways.includes(item.topicId));
            }
            return items;
        },
        filterSectionsByEnabledDisabledPathways(sections) {
            if (this.disabledPathways.length) {
                sections.forEach(section => {
                    section['items'] = section.items.filter(
                        item => !this.disabledPathways.includes(item.topicId));
                });
            }
            else if (!this.hasPathwaysContent) {
                sections.forEach(section => {
                    section['items'] = section.items.filter(
                        item => this.enabledPathways.includes(item.topicId));
                });
            }
            return sections;
        },
        checkTocItem(item) {
            return this.tocName === TOC_NAME_PATHWAYS && this.tocCsuChecked ? item.hasCSU : true;
        },
        clearFilter() {
            this.tocFilterModel = '';
            this[SET_TOC_FILTER]('');
        },
        filterBySearchTerms() {
            this.filterPathwaysTocBySearchTerms();
            this.checkFooter();
        },
        checkFooter() {
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true,
                delay: BROADCAST_DELAY,
                doFocusChange: false,
                fromLocation: 'toc.controller: checkFooter'
            });
        },
        getCalculatorFilterAnchor(calcFilterType) {
            const baseUrl = '/contents';
            const appendUrl = `/table-of-contents/${this.tocFilterType}/${calcFilterType}`;
            return makeRestUrl(baseUrl, this.currentLanguage, appendUrl);
        },
        piFilter(filterTag) {
            this[SET_TOC_CSS_PI_FILTER](filterTag);
            this.setPatientEducationMenuState();
            new PubSub().publish(C_EVENTS.POSITION_FOOTER, {
                reset: true,
                delay: BROADCAST_DELAY,
                doFocusChange: false,
                fromLocation: 'toc.controller: piFilter'
            });
        },
        shouldRenderSubsections(section) {
            let sc = 0;
            if (typeof section.subSections !== 'undefined') {
                for (let s = 0; s < section.subSections.length; s++) {
                    sc += this.expressionSearchFilter(
                        section.subSections[s].items, this.tocFilter).length;
                }
            }
            return sc > 0;
        },
        canViewTocEntry(item) {
            if (typeof item.displayTag !== 'undefined') {
                if (typeof this.additiveContentKeys[item.displayTag] === 'undefined') {
                    return false;
                }
            }
            return true;
        },
        hasTocSectionItems(items) {
            if (!items?.length) {
                return false;
            }

            const filteredItems = (this.tocFilter
                ? this.expressionSearchFilter(items, this.tocFilter)
                : items)
                .filter(item => item.display !== false && this.checkTocItem(item));

            return !!filteredItems.length;
        },
        tocSectionItemsFilter(items) {
            if (!items || !items.length) {
                return;
            }

            if (this.tocFilter) {
                items = this.expressionSearchFilter(items, this.tocFilter);
            }

            return items.filter(item => {
                return item.display !== false && this.checkTocItem(item);
            });
        },
        triggerDelayedResizeEvent() {
            this.cancelResizeTimeout = safeTimeout(() => {
                dispatchEvent('resize');
            }, C_UI.WINDOW_RESIZE_DELAY_MS, { cancelHook: true }).cancelTimeout;
        },
        expressionSearchFilter(array, expression) {
            return expressionSearchFilter(array, expression) || [];
        },
        subSectionItems(subsec) {
            return subsec.items || [];
        },
        sectionItems(section) {
            return section.items || [];
        },
        tocItemClasses(item) {
            return [
                item.navClass,
                { hasViewInLink: item.translatedTopicInfos },
                { customPathway: item.customizedBy }];
        },
        sectionItemClasses(items, item, itemIdx) {
            return {
                'last-dd': itemIdx === items.length - 1,
                'pathways-toc-has-csu': item.hasCSU,
                'customized-pathway': item.customizedBy
            };
        },
        subsectionItemClasses(items, itemIdx) {
            return { 'last-dd': itemIdx === items.length - 1 };
        },
        shouldRenderSubsectionName(subsec) {
            return this.expressionSearchFilter(
                this.subSectionItems(subsec), this.tocFilter).length > 0;
        },
        customTocItemClass(item) {
            return { customPathway: item.customizedBy };
        },
        showAlphabeticalItem(item) {
            return this.canViewTocEntry(item) && item.display !== false && this.checkTocItem(item);
        },
        alphabeticalItemClasses(item) {
            return {
                'pathways-toc-has-csu': item.hasCSU,
                'customized-pathway': item.customizedBy
            };
        },
        alphabeticalItemAnchorTarget(item) {
            return item.newTab
                ? '_blank'
                : null;
        },
        arrayPopulated(array) {
            return array && array.length > 0;
        },
        resetTocContentPositioning() {
            // Ensure that content area of ToC has sufficient top padding to
            // display underneath the ToC title bar, only after all of the
            // initial data has loaded. This is needed for when ToC is the first
            // page hit upon loading.
            this.delayExecuteUntilDataLoaded(() => {
                if (this.shouldWatchTocNameHeight) {
                    getWindow().addEventListener('resize', this.setTocContentHeight);
                    this.resetPositioningTimeout = setTimeout(() => {
                        this.setTocContentHeight();
                    }, TOC_REPOSITION_DELAY);
                }
                else {
                    this.$refs.tocContent.style.paddingTop = null;
                }
                this.filterPathwaysList();
            });
        },
        onTocListClick(e) {
            if (!e.target.href || e.target.href.indexOf('pathway/') === -1) {
                return;
            }
            e.target.setAttribute('disabled', true);
        },
        onSwitchTab(tab) {
            this.piFilter(tab === 0 ? 'basicsOnly' : 'btbOnly');
            this.$refs.accordionRef && this.$refs.accordionRef.toggleAllItems(false);
            this.checkHideExpandCollapse();
        },
        setScrollsListeners() {
            this.windowScrollListener
                = this.setScrollListener(getWindow(), this.onTocScroll);
        },
        onTocScroll() {
            const doc = getDocument();
            const tocOffset = doc && doc.documentElement && doc.documentElement.scrollTop || 0;
            this.isTitleSmall = tocOffset > 0;
        },
        initSearchResultClickHandlers() {
            Array.from(document.querySelectorAll('#toc-content a'))
                .forEach(link => {
                    checkIfLinkVisited(link, this.isAnonymousUser);

                    checkAndSetEventBinding(link, 'toc-content-link', 'click', evt => {
                        setLinkVisitedClass(
                            evt.currentTarget,
                            this.isAnonymousUser,
                            this.contentVersion
                        );
                    });
                });
        },
        setFilterLabelOrder() {
            const displayBar = this.$refs.displayBar;
            const viewBySpecialty = this.$refs.viewBySpecialty;
            const listAlphabetically = this.$refs.listAlphabetically;
            if (displayBar && viewBySpecialty && listAlphabetically) {
                if (this.tocFilterType === 'calculators') {
                    displayBar.insertBefore(listAlphabetically, viewBySpecialty);
                }
                else {
                    displayBar.insertBefore(viewBySpecialty, listAlphabetically);
                }
            }
        },
        onOrderChange(index) {
            const filter = index === 0 ? 'categorized' : 'alphabetized';
            const route = this.getCalculatorFilterAnchor(filter);
            localStorage.setItem('tocSort', filter);
            this.router.url(route);
        },
        setupSetFocusOnFilter(){
            const filter = localStorage.getItem('tocSort');  
            const index = filter === 'categorized' ? 0 : 1;
            const radio = Array.from(document.querySelectorAll('.wk-field-choice-label'))[index];
            if(radio){
                radio.focus();
                localStorage.removeItem('tocSort');
            }
        }}
};
</script>

<style lang="less">
@import './Toc.less';
</style>