<template>
  <div class="graphic__sidebar wk-js2"
       :class="{ 'graphic__appears-in': hasAppearsIn }">
    <div v-if="variant === 'appearsInTopic'"
         class="graphic__sidebar-content graphic__height-extents">
      <h3 class="automation_gvd-appears-title ds1-pv-1 ds1-ph-2 sidebar-appears-in-header
            ds1-mb-0 ds1-utd-size-2 ds1-utd-weight-600">{{ $t("CONTENT.APPEARS_IN_TOPICS") }}</h3>
      <GraphicViewerAppearsIn class="automation_gvd-appears-content ds1-pa-2" />
    </div>
    <template v-if="variant === 'gfxInTopic'">
      <div class="automation_gvd-gfx-in-topic-title sidebar-thumbnails__header
               ds1-utd-size-3 ds1-utd-weight-600 ds1-pv-1 ds1-ph-2"
           role="heading"
           aria-level="2"
      >{{ graphicViewerSidebarTitle }}</div>
      <div ref="thumbnailsContainer"
           class="automation_gvd-gfx-in-topic-content
                  graphic__sidebar-content graphic__thumbnails-container"
           :class="{ 'thumb-container-scrolled': thumbContainerScrolled }">
        <utd-thumbnails :is-visible="isSidebarExpanded"
                        @selected-item-id="handleSelectedItem" />
      </div>
    </template>
    <template v-if="variant === 'accordion'">
      <UtdAccordion :accordion-items="accordionItems"
                    :is-mutex-accordion="true"
                    :can-close-open-accordion="false"
                    :opened-index="initialAccordionIndex"
                    @itemOpened="accordionItemOpened($event)"
                    @next="evt => evt.stopPropagation()"
                    @previous="evt => evt.stopPropagation()">
        <template v-slot:title>
          <div class="automation_gvd-gfx-in-topic-title sidebar-thumbnails__header
               ds1-utd-size-3 ds1-utd-weight-600 ds1-pv-1 ds1-ph-2">
            {{ SIDEBAR_ACCORDION_TITLE }}
          </div>
        </template>
        <template v-slot:content="slotProps">
          <template v-if="slotProps.item.id==='appearsInTopics'">
            <GraphicViewerAppearsIn class="automation_gvd-appears-accordion-content ds1-pa-2" />
          </template>
          <template v-if="isThumbnailsItem(slotProps.item)">
            <div ref="thumbnailsContainer"
                 class="automation_gvd-gfx-in-topic-accordion-content
                        graphic__sidebar-content graphic__thumbnails-container">
              <UtdThumbnails :is-visible="true"
                             @selected-item-id="handleSelectedItem" />
            </div>
          </template>
        </template>
      </UtdAccordion>
    </template>
  </div>
</template>

<script>
import UtdThumbnails from '_acaSrc/components/contents/graphic/GraphicDialogThumbnails.vue';
import UtdAccordion from '_acaSrc/components/shared/accordion/UtdAccordion.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import GraphicViewerAppearsIn from './GraphicViewerAppearsIn.vue';
import { C_GRAPHICS } from '_acaSrc/utility/constants';
import { SET_MULTIGRAPHICS_MODE } from '_acaSrc/store/graphic.store';
import { logUiClickEventHelper, Listener } from '_acaSrc/utility/Events';
import { imageKeyToId } from '_acaSrc/utility/contents/graphic/graphic';

const { SIDEBAR_TOGGLE_GITT_TEXT, SIDEBAR_TOGGLE_AIT_TEXT, SIDEBAR_ACCORDION_TITLE } = C_GRAPHICS;

const ACCORDION_AIT_INDEX = 0;
const ACCORDION_GITT_INDEX = 1;
const THUMBNAIL_SCROLL_OFFSET_PX = 64;
export default {
    components: {
        UtdThumbnails,
        UtdAccordion,
        GraphicViewerAppearsIn
    },
    props: {
        hasAppearsIn: Boolean,
        hasGraphicCollection: Boolean,
        isSidebarExpanded: Boolean
    },
    emits: [ 'sidebarViewCreated', 'selectedItemId' ],
    data() {
        return {
            initialAccordionIndex: ACCORDION_GITT_INDEX,
            SIDEBAR_ACCORDION_TITLE,
            scrollListener: null,
            thumbContainerScrolled: false
        };
    },
    computed: {
        ...mapGetters('app', [ 'router' ]),
        ...mapGetters('topic', [ 'isTopicView' ]),
        ...mapGetters('graphic', [
            'graphicViewerSidebarTitle',
            'graphicViewerGraphics',
            'graphicViewerSidebarTitle',
            'graphicViewerGraphics',
            'graphicViewerAccordionItems',
            'graphicViewerImageKey'
        ]),
        ...mapGetters('search', [
            'searchParamsType'
        ]),
        variant() {
            if (this.hasRelatedGraphics
                && this.hasAppearsIn
                && !this.hasGraphicCollection) {
                return 'accordion';
            }
            else if (this.isTopicView || this.hasGraphicCollection) {
                return 'gfxInTopic';
            }
            return 'appearsInTopic';
        },
        isFromSearchFacet() {
            return this.router.current().name === 'search'
                && this.searchParamsType === 'GRAPHICS';
        },
        hasRelatedGraphics() {
            return this.graphicViewerGraphics && this.graphicViewerGraphics.length > 0;
        },
        accordionItems() {
            if (this.variant !== 'accordion') {
                return [];
            }
            return this.graphicViewerAccordionItems;
        }
    },
    watch: {
        variant() {
            this.updateMultigraphicsMode();
        },
        graphicViewerImageKey(newVal) {
            this.scrollToSelectedThumbnail(newVal);
        }
    },
    created() {
        let label;
        if (this.variant === 'accordion' || this.variant === 'gfxInTopic') {
            if (this.isFromSearchFacet) {
                this.initialAccordionIndex = ACCORDION_AIT_INDEX;
            }
            label = this.accordionItems && this.accordionItems.length > 0
                ? this.accordionItems[this.initialAccordionIndex].label
                : SIDEBAR_TOGGLE_GITT_TEXT;
        }
        else {
            label = SIDEBAR_TOGGLE_AIT_TEXT;
        }
        this.updateMultigraphicsMode();
        this.$emit('sidebarViewCreated', { label });
    },
    mounted() {
        this.scrollListener = new Listener(this.$refs.thumbnailsContainer, 'scroll',
            this.setThumbContainerScrolled, { passive: true });
        this.setListener(this.scrollListener);
        if (this.graphicViewerImageKey) {
            this.$nextTick(() => this.scrollToSelectedThumbnail(this.graphicViewerImageKey));
        }
    },
    beforeUnmount() {
        this.clearListener(this.scrollListener);
    },
    methods: {
        ...mapMutations('graphic', [ SET_MULTIGRAPHICS_MODE ]),
        ...mapActions('app', [ 'setListener', 'clearListener' ]),
        handleSelectedItem(selectedItemId) {
            this.$emit('selectedItemId', selectedItemId);
        },
        accordionItemOpened({ id }) {
            logUiClickEventHelper({
                uiElementName: `graphicViewerDialog_accordion-${id}-open`,
                optData: this.graphicViewerImageKey
            });

            if (id === 'otherGraphics' && this.graphicViewerImageKey) {
                this.$nextTick(() =>
                    this.scrollToSelectedThumbnail(this.graphicViewerImageKey));
            }
        },
        updateMultigraphicsMode(newVariant = this.variant) {
            this[SET_MULTIGRAPHICS_MODE](newVariant === 'accordion' || newVariant === 'gfxInTopic');
        },
        isThumbnailsItem({ id }) {
            return id === 'otherGraphics' || id === 'graphicsInTopic';
        },
        setThumbContainerScrolled(event) {
            this.thumbContainerScrolled = event.target.scrollTop > 0;
        },
        scrollToSelectedThumbnail(imageKey) {
            const thumbnailId = `#${imageKeyToId(imageKey)}`;
            const thumbnail = document.querySelector(thumbnailId);
            if (!thumbnail) {
                return;
            }
            const top = thumbnail.offsetTop - THUMBNAIL_SCROLL_OFFSET_PX;
            this.scrollToPosition(top);
        },
        scrollToPosition(top) {
            if (this.$refs.thumbnailsContainer) {
                this.variant === 'accordion'
                    ? this.$refs.thumbnailsContainer.parentElement.scroll({ top, behavior: 'smooth' })
                    : this.$refs.thumbnailsContainer.scroll({ top, behavior: 'smooth' });
            }

        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@GRAPHIC-VIEWER-TOOLBAR-BG-COLOR: @WKCE-GRAY-TINT5;

.graphic__appears-in {
  overflow-y: auto;

  h3 {
    .ds1-utd-h5();
    .ds1-utd-weight-bold();
    line-height: 24px;
    margin-top: 0;
    border-bottom: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;
    background-color: @GRAPHIC-VIEWER-TOOLBAR-BG-COLOR;
  }
}

.sidebar-thumbnails__header,
.sidebar-appears-in-header {
  display: flex;
  justify-content: center;
}

.sidebar-thumbnails__header {
  border-bottom: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;
  background-color: @GRAPHIC-VIEWER-TOOLBAR-BG-COLOR;
}

.graphic__thumbnails-container {
  overflow-y: auto;
  scroll-behavior: smooth;

  &.thumb-container-scrolled::before {
    .ds1-accordion-box-shadow-with-webkit();
    display: block;
    position: absolute;
    width: 100%;
    height: 8px;
    top: 32px;
    content: '';
  }
}

.graphic__sidebar .wk-accordion {
  background-color: transparent;
}
</style>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphic__sidebar .wk-accordion .wk-accordion-item-element > * {
  margin: 0;
}

.graphic__sidebar .wk-accordion .wk-accordion-item-header .wk-accordion-item-header-label {
  .ds1-pv-1();
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin: 0;
}
</style>
