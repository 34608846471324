<template>
  <div class="home-panel">
    <div class="home-panel-header wk-js2"
         :class="panelFilter.isVisible ? 'panel-filter-enabled' : ''">
      <span class="home-panel-title">
        {{ title }}
      </span>
      <a v-if="panelButton.isVisible" 
         :id="anchorId"
         class="home-panel-button" 
         :href="panelButton.url" 
         :aria-label="$t('HOMEPAGE.VIEW_ALL')">
        View All
      </a>
      <utd-select v-if="panelFilter.isVisible"
                  class="wn-dropdown"
                  placeholder="Select a Specialty"
                  :selected="panelFilter.selected"
                  :options="panelFilter.options"
                  :show-label="false"
                  label-prop="name"
                  label="Specialty"
                  @utd-select="handleOnSelectChange"
                  @click="logUiClickEvent(dropdownUiElementName)" />
    </div>
    <div class="home-panel-content">
      <slot />
    </div> 
  </div> 
</template>

<script>
import UtdSelect from '_acaSrc/components/shared/input/UtdSelect.vue';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';

export default {
    components: {
        UtdSelect
    },
    props: {
        anchorId: {
            type: String,
            default: ''
        },
        title: {
            required: true,
            type: String,
            default: ''
        },
        dropdownUiElementName: {
            type: String,
            default: ''
        },
        panelButton: {
            type: Object,
            default: () => ({})
        },
        panelFilter: {
            type: Object,
            default: () => ({
                isVisible: false,
                options: [],
                selected: ''
            })
        }
    },
    methods: {
        handleOnSelectChange(selected) {
            this.$emit('panel-filter-change', selected);
        },
        logUiClickEvent(element, url) {
            logUiClickEventHelper({
                uiElementName: `homepage_${element}`,
                targetUrl: url
            });
        }
    }
};
</script>
  
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
  
.home-panel {
  .ds1-flex-column-wrap();
  width: 100%;
  background-color: @WKCE-WHITE;
  border-right: solid 1px @WKCE-GRAY-TINT3;
}

.home-panel-header {
  .ds1-flex-row-wrap();
  background-color: @DS1-UTD-CARD-HEADING-BG-COLOR;
  font-size: 14px;
  align-items: center;
  min-height: 57px;

  .home-panel-title {
    .ds1-ml-2();
    .ds1-utd-weight-500();
  }

  .home-panel-button {
    .ds1-mr-2();
    .ds1-mt-compact();
    font-size: 12px;
    margin-left: auto;
  }

  .wn-dropdown {
    .ds1-ma-2();
    margin-top: 12px;
    width: 100%;

    :deep(.wk-field-select:not(:focus)) {
      border-color: @DS1-UTD-GRAY-BORDER-COLOR;
    }

    :deep(.wk-field-select) {
      .ds1-utd-size-1();
      color: @DS1-UTD-GRAY-TEXT-COLOR;
    }
  }

  &.panel-filter-enabled {
      .ds1-pt-2();
  }
}

.home-panel-content {
  .ds1-ml-2();
  .ds1-mr-2();
  .ds1-mt-2();  
}

@media only screen and (min-width: 768px) {
  .home-panel {
    min-width: 350px;
    width: 350px;
  }
}

@media only screen and (min-width: 1400px) {
  .home-panel {
    width: 25vw;
  }
}

</style>