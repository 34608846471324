<script>
import { mapGetters, mapActions } from 'vuex';
import { getDocument, getWindow, getVerticalScrollbarWidth } from '_acaSrc/utility/DOM';
import { roundToDecimalPlace as round } from '_acaSrc/utility/math';
import { onScrollEndEvent, throttleUiClickEvent } from '_acaSrc/utility/Events';

const FTH_DECIMAL_PRECISION = 1;
const COLUMN_SPACER_ROW_HEIGHT_PX = 1;
const DEFAULT_SHADOW_ADJUSTMENT_PX = 1;
const IE11_SHADOW_ADJUSTMENT_PX = 2;
const TABLE_HEADER_CLASSES = '.subtitle1, .subtitle1_left, .subtitle1_single';
const CHILD_FILTER_SELECTOR = '.graphic .figure > *:not(.ttl)';
const GRAPHIC_FIGURE_SELECTOR = '.graphic .figure';

export default {
    props: [ 'containerDimensions' ],
    data() {
        return {
            isActivelyScrolling: false,
            headerRows: [],
            fixedRows: [],
            rowColWidths: [],
            tableContainerEl: null,
            sourceTableEl: null,
            tableScrollEl: null,
            fixedTableEl: null,
            fixedTableBodyEl: null,
            columnSpacerRowHeightPx: 0,
            fixedHeadersEl: null,
            shadowContainerEl: null,
            shadowEl: null,
            lastTableRowEl: null,
            shadowAdjustment: DEFAULT_SHADOW_ADJUSTMENT_PX,
            isShiftPressed: false
        };
    },
    computed: {
        ...mapGetters('graphic', [ 'graphicViewerImageKey' ]),
        ...mapGetters('device', [
            'browserName',
            'isBrowserNameMSIE',
            'isBrowserNameSafari'
        ]),
        isValidSourceTableEl() {
            return this.sourceTableEl
                && this.sourceTableEl.tBodies
                && this.sourceTableEl.tBodies.length === 1;
        }
    },
    beforeUnmount() {
        this.clearListeners();
    },
    mounted() {
        this.setupListeners();
    },
    methods: {
        ...mapActions('app', [ 'subscribe', 'unsubscribe' ]),
        setupListeners() {
            this.subscribe({
                eventName: 'wkutd.setup-graphic-fixed-table-headers',
                handlerFn: this.setupFixedTableHeaders
            });
            this.subscribe({
                eventName: 'wkutd.update-graphic-fixed-table-headers',
                handlerFn: this.updateFixedTableHeaders
            });
        },
        clearListeners() {
            this.unsubscribe({
                eventName: 'wkutd.setup-graphic-fixed-table-headers',
                handlerFn: this.setupFixedTableHeaders
            });
            this.unsubscribe({
                eventName: 'wkutd.update-graphic-fixed-table-headers',
                handlerFn: this.updateFixedTableHeaders
            });
            this.tableScrollEl && this.tableScrollEl.removeEventListener('scroll',
                this.onTableScrollEvent, this.scrollEventOptions);
            this.tableScrollEl && this.tableScrollEl.removeEventListener('blur',
                this.onTableBlur);
            getDocument().removeEventListener('keydown', this.setIsShiftPressed);
            getDocument().removeEventListener('keyup', this.setIsShiftPressed);

        },
        initializeSelectors() {
            this.contentEl = getDocument().querySelector('.graphic__overlay-content');
            this.tableContainerEl = this.contentEl
                && this.contentEl.querySelector(GRAPHIC_FIGURE_SELECTOR);
            this.sourceTableEl = this.tableContainerEl
                && this.tableContainerEl.querySelector('.cntnt > table');
        },
        isTableStructureCompatible() {
            let isValidTable = false;

            this.initializeSelectors();

            if (this.contentEl
             && this.tableContainerEl
             && this.isValidSourceTableEl) {
                // Confirm table can have fixed headers applied:
                // - Table does not contain primary headers that span multiple rows.
                // - Table does not contain any element with .container class, as this
                //   class is used when displaying two tables side by side.
                this.headerElements = this.sourceTableEl.querySelectorAll(TABLE_HEADER_CLASSES);
                const headerRowspans = Array.from(this.headerElements)
                    .filter(el => el.getAttribute('rowspan'));

                const sideBySideTables = this.sourceTableEl.querySelectorAll('.container');

                isValidTable = this.headerElements.length > 0
                            && headerRowspans.length === 0
                            && sideBySideTables.length === 0;
            }

            return isValidTable;
        },
        setupFixedTableHeaders(fnIsFixedTable) {
            this.setIsFixedTable = fnIsFixedTable;

            if (!this.isTableStructureCompatible()) {
                this.clearFixedTableHeaders();
                return;
            }

            this.initFixedTableHeaders();
            this.setIsFixedTable(true, this.tableScrollEl);
        },
        clearFixedTableHeaders() {
            this.setIsFixedTable(false);
        },
        resetLocals() {
            // Necessary to reset these to support next/previous within GVD
            this.colSpacerRowEl = null;
            this.headerRows = [];
            this.fixedRows = [];
            this.rowColWidths = [];
        },
        initFixedTableHeaders() {
            if (this.isBrowserNameMSIE) {
                this.shadowAdjustment = IE11_SHADOW_ADJUSTMENT_PX;
            }

            this.resetLocals();
            this.restructureTableDom();
            this.setScrollEventOptions();
            this.processTableHeaders();
            this.buildHeaderRowsArray();
            this.installFixedTableHeaders();

            this.throttleScrollEvent = throttleUiClickEvent();
            this.tableScrollEl.addEventListener('scroll',
                this.onTableScrollEvent, this.scrollEventOptions);

            if (this.isBrowserNameMSIE) {
                this.fixIE11RenderBug();
            }
        },
        onTableBlur(event) {
            if(this.isShiftPressed) {
                event.preventDefault();
                this.setFocusOn('.graphic__sidebar-toggle');
                return;
            }
      
            if (event.relatedTarget
             && event.relatedTarget.classList.contains('graphic__counter-button')) {
                event.preventDefault();
                this.setFocusOn('.graphic-thumbnails > .graphic-thumbnail__container >  a');
                return;
            }
        },
        setFocusOn(query){
            const element = getDocument().querySelector(query);
            element?.focus();
            this.isShiftPressed = false;
        },
        setIsShiftPressed(event) {
            if (event.key === 'Shift') {
                this.isShiftPressed = event.type === 'keydown';
            }
        },
        restructureTableDom() {
            this.tableScrollEl = getDocument().createElement('div');
            this.tableScrollEl.setAttribute('id', 'tableScroller');
            this.tableScrollEl.setAttribute('tabindex', '0');
            this.tableScrollEl.setAttribute('aria-label', 'table-content');
            this.tableScrollEl.setAttribute('role', 'presentation');
            this.tableScrollEl.addEventListener('blur', this.onTableBlur);
            getDocument().addEventListener('keydown', this.setIsShiftPressed);
            getDocument().addEventListener('keyup', this.setIsShiftPressed);

            // Move all direct children of _containerSelector into #tableScroller
            // Use optional filter selector if supplied
            Array.from(getDocument().querySelectorAll(
                CHILD_FILTER_SELECTOR || GRAPHIC_FIGURE_SELECTOR
            )).forEach(el => {
                this.tableScrollEl.appendChild(el);
            });

            // Add new #tableScroller into container
            this.tableContainerEl.appendChild(this.tableScrollEl);
            this.tableScrollEl.focus();
        },
        setScrollEventOptions() {
            this.scrollEventOptions = {
                checkDelayMs: 100
            };
            if (!this.isBrowserNameMSIE) {
                this.scrollEventOptions.passive = true;
            }
        },
        processTableHeaders() {
            // Tag all headers rows with tracking class
            Array.from(this.headerElements).forEach(targetEl => {
                const trEl = targetEl.closest('tr');
                trEl && trEl.classList.add('sticky-row');
            });

            // Store reference to last row of table
            this.lastTableRowEl = this.sourceTableEl.querySelector('tr:last-child');
        },
        buildHeaderRowsArray() {
            let rowIdx = 0;
            Array.from(this.sourceTableEl.tBodies[0].rows)
                .forEach(rowEl => {
                    if (rowEl.classList.contains('sticky-row')) {
                        this.headerRows[rowIdx] = {
                            rowEl,
                            display: 'none'
                        };
                        rowIdx++;
                    }
                });
        },
        fixIE11RenderBug() {
            // See https://jira.ce.wolterskluwer.io/browse/CORE-9555
            // This code will force IE11 to redraw the table
            this.$nextTick(() => {
                getWindow().requestAnimationFrame(() => {
                    const origWidth = this.tableScrollEl.style.width;
                    this.tableScrollEl.style.width = `${this.tableScrollEl.offsetWidth + 1}px`;
                    getWindow().requestAnimationFrame(() => {
                        this.tableScrollEl.style.width = origWidth;
                    });
                });
            });
        },
        createColumnSpacerRow() {
            const colWidthsRowEl = getDocument().createElement('tr');
            colWidthsRowEl.setAttribute('class', 'trSpacer');
            let spacerTdHtml = '';
            this.rowColWidths[this.lastMaxColRow].forEach(colWdt => {
                spacerTdHtml += `<td class="tdSpacer"
                    ><img class="ds1-trans-png-1x1" style="width: ${colWdt}px;"></td>`;
            });
            colWidthsRowEl.innerHTML = spacerTdHtml;
            this.fixedTableBodyEl.appendChild(colWidthsRowEl);
            this.colSpacerRowEl = colWidthsRowEl;
        },
        updateColumnSpacerRowWidths() {
            if (!this.colSpacerRowEl) {
                this.createColumnSpacerRow();
                return;
            }

            Array.from(this.colSpacerRowEl.cells)
                .forEach((tdEl, cellIdx) => {
                    tdEl.firstChild.style.width
                        = `${this.rowColWidths[this.lastMaxColRow][cellIdx]}px`;
                });
        },
        onWheelScroll(event) {
            this.tableScrollYPos += event.deltaY;

            if (this.tableScrollYPos < 0) {
                this.tableScrollYPos = 0;
            }
            else if (this.tableScrollYPos > this.maxScrollY) {
                this.tableScrollYPos = this.maxScrollY;
            }

            this.tableScrollEl.scroll({ top: this.tableScrollYPos, behavior: 'smooth' });
        },
        onTableScrollEvent() {
            // Update tableScrollYPos when scrolling ends,
            // to coordinate with mouse wheel events
            if (!this.isActivelyScrolling) {
                this.isActivelyScrolling = true;
                onScrollEndEvent(this.tableScrollEl, () => {
                    this.isActivelyScrolling = false;
                    this.tableScrollYPos = this.tableScrollEl.scrollTop;
                }, this.scrollEventOptions);
            }

            this.throttleScrollEvent({
                uiElementName: 'graphicViewerDialog_graphicScroller',
                optData: this.graphicViewerImageKey
            });

            this.processTableScroll();
        },
        processTableScroll() {
            // Sync horizontal scroll
            this.fixedTableEl.style.left = `${this.tableScrollEl.scrollLeft * -1}px`;

            // Process visibility and vertical positions of sticky header rows
            let scrollOffset = 0;
            Array.from(this.headerRows).forEach((row, rowIdx) => {
                scrollOffset = this.onScrollProcessHeaderRow(row, rowIdx, scrollOffset);
                scrollOffset = this.onScrollProcessLastTableRow(rowIdx, scrollOffset);
            });

            // Adjust sticky table position by scrollOffset
            this.fixedTableEl.style.top = `${scrollOffset}px`;

            // Adjust height of the fixed sticky table container fixedHeadersEl
            // to equal only the visible rows.
            // This prevents mouse wheel events from being ignored, as otherwise
            // it would have a height equal to all of the rows set with 'display: table-row'.
            // NOTE:
            // - The column spacer row is not visible, but is still included in DOM calculations,
            //   as the borders still display, so the height needs to be subtracted.
            const newHeightPx = this.fixedTableEl.getBoundingClientRect().height
                - COLUMN_SPACER_ROW_HEIGHT_PX
                + scrollOffset;

            this.fixedHeadersEl.style.height = `${newHeightPx}px`;
            this.setDropshadowDimensions();
        },
        onScrollProcessHeaderRow(row, rowIdx, scrollOffset) {
            /**
             * Determines visiblity for specific header row, and amount fixed table
             * should be scrolled based on position of visible header rows.
             * @param {*} row: DOM element of row to process
             * @param {*} rowIdx: Index of row element in table
             * @param {*} scrollOffset: Current scrollOffset
             * @returns Adjusted scrollOffset
             */
            let rowDisplay = 'none';

            // Top position of row adjusted by scroll
            const currRowTopPx
                = round(row.rowEl.offsetTop - this.tableScrollEl.scrollTop, FTH_DECIMAL_PRECISION);

            // If processing any row beyond the first...
            if (rowIdx > 0) {
                const prevRow = this.fixedRows[rowIdx - 1];
                const prevRowTopPx
                    = round(prevRow.rowEl.offsetTop + scrollOffset, FTH_DECIMAL_PRECISION);

                // Next line uses getBoundingClientRect() for precision, otherwise
                // some top borders go missing due to rounding issues.
                const prevRowHgtPx
                    = round(prevRow.rowEl.getBoundingClientRect().height, FTH_DECIMAL_PRECISION);
                const prevRowBtmPx = round(prevRowTopPx + prevRowHgtPx, FTH_DECIMAL_PRECISION);

                // If top of current header row has breached bottom of previous
                let diff = currRowTopPx - prevRowBtmPx;
                if (diff < 0) {
                    rowDisplay = 'table-row';

                    // If top of current header row is above top of previous
                    if (currRowTopPx < prevRowTopPx) {
                        diff = prevRowHgtPx * -1;
                    }
                    scrollOffset += diff;
                }
            }
            // If top of first header row is at or above top of container
            else if (currRowTopPx <= 0) {
                rowDisplay = 'table-row';
            }

            scrollOffset = round(scrollOffset, FTH_DECIMAL_PRECISION);

            this.fixedRows[rowIdx].rowEl.style.display = rowDisplay;

            return scrollOffset;
        },
        onScrollProcessLastTableRow(rowIdx, scrollOffset) {
            /**
             * Check if row passed is the last table row, and if the last sticky row is visible.
             * If so then adjusts overall table scroll offset by difference between bottom of last
             * table row to bottom of last sticky header row.
             * This allows for the last header row to be scrolled out of view when the end of the
             * table is scrolled above the container.
             * @param {*} rowIdx: Index of row element in table
             * @param {*} scrollOffset: Current scrollOffset
             * @returns Adjusted scrollOffset
             */
            if (rowIdx === this.headerRows.length - 1
                && this.fixedRows[rowIdx].rowEl.style.display !== 'none') {
                const lastRowBtmPx = (this.lastTableRowEl.offsetTop
                    - this.tableScrollEl.scrollTop)
                    + this.lastTableRowEl.offsetHeight;

                const lastStickyRowEl = this.fixedRows[rowIdx].rowEl;
                const currRowBtmPx = lastStickyRowEl.offsetTop
                    + lastStickyRowEl.offsetHeight
                    + scrollOffset;

                const offset = lastRowBtmPx - currRowBtmPx;
                if (offset < 0) {
                    scrollOffset += offset;
                }
            }

            return scrollOffset;
        },
        setDropshadowDimensions() {
            if (this.fixedHeadersEl.offsetHeight === 1
             || this.tableScrollEl.scrollTop === 0
             || this.tableScrollEl.scrollTop > this.sourceTableEl.offsetHeight) {
                this.shadowContainerEl.style.visibility = 'hidden';
                return;
            }

            this.shadowContainerEl.style.top = `${(this.fixedHeadersEl.offsetTop
                + this.fixedHeadersEl.offsetHeight)
                - (this.shadowEl.offsetHeight + this.shadowAdjustment)}px`;

            this.shadowContainerEl.style.left = `${this.fixedHeadersEl.offsetLeft}px`;
            this.shadowContainerEl.style.width = `${this.fixedHeadersEl.offsetWidth}px`;
            this.shadowEl.style.width = `${this.fixedTableEl.offsetWidth}px`;

            this.shadowContainerEl.style.visibility = 'visible';
        },
        updateFixedTableHeaders(cb) {
            getWindow().requestAnimationFrame(() => {
                this.setTableContainerDimensions();

                this.tableScrollYPos = this.tableScrollEl.scrollTop;
                this.maxScrollY = this.tableScrollEl.scrollHeight - this.tableScrollEl.clientHeight;

                this.getSourceTableColumnWidths();
                this.setFixedColumnAndTableWidths();
                this.setFixedTableDimensions();

                // Resize width of overflow container for sticky table
                this.setFixedTableOverflowWidth();

                this.processTableScroll();
                cb && cb();
            });
        },
        installFixedTableHeaders() {
            // Clone source table
            this.fixedTableEl = this.sourceTableEl.cloneNode(true);
            this.fixedTableBodyEl = this.fixedTableEl.querySelector('tbody');

            // Next hide all non header rows from cloned table
            Array.from(this.fixedTableEl.querySelectorAll('tr:not(.sticky-row)'))
                .forEach(rowEl => rowEl.style.display = 'none');

            // Append sticky table to container
            this.fixedHeadersEl = getDocument().createElement('div');
            this.fixedHeadersEl.setAttribute('id', 'stickyHeaders');
            this.fixedHeadersEl.appendChild(this.fixedTableEl);
            this.tableContainerEl.appendChild(this.fixedHeadersEl);

            this.createDropShadow();

            this.updateFixedTableHeaders();
        },
        getSourceTableColumnWidths() {
            // This method is flawed in that it would incorrectly calculate column withs
            // when all of the table rows include some type of >1 colspan attribute, where the
            // columns containing colspan do not match up across all rows.
            // Example:
            //        +--------+--------+--------+--------+
            // Row 1: |    Cell 1 & 2   | Cell 3 | Cell 4 |
            // Row 2: | Cell 1 |    Cell 2 & 3   | Cell 4 |
            // Row 3: | Cell 1 | Cell 2 |    Cell 3 & 4   |
            //        +--------+--------+--------+--------+
            // To date, no tables have been identified with this structure, so spending time
            // refactoring to solve this issue is not warranted.
            // In the event tables are identified, we can add them to the deny-list and
            // schedule time as needed to support them.
            this.rowColWidths = [];
            this.lastMaxColRow = -1;
            let maxRowCol = 0;

            Array.from(this.sourceTableEl.tBodies[0].rows).forEach((trEl, trIdx) => {
                let rowCol = 0;
                const colWidths = [];
                Array.from(trEl.cells).forEach((tdEl, tdIdx) => {
                    rowCol++;

                    colWidths[tdIdx] = tdEl.getBoundingClientRect().width - 1;
                });

                this.rowColWidths[trIdx] = colWidths;

                if (rowCol > maxRowCol) {
                    maxRowCol = rowCol;
                }

                if (rowCol === maxRowCol) {
                    this.lastMaxColRow = trIdx;
                }
            });
        },
        setFixedColumnAndTableWidths() {
            let rowIdx = 0;
            Array.from(this.fixedTableEl.tBodies[0].rows).forEach(rowEl => {
                if (rowEl.classList.contains('sticky-row')) {
                    // Create references to each header row in the sticky table
                    if (!this.fixedRows[rowIdx]) {
                        this.fixedRows[rowIdx] = {
                            rowEl,
                            display: 'none'
                        };
                    }

                    // Hide row if original row's relative top position is greater than 0
                    if (this.headerRows[rowIdx].rowEl.offsetTop > 0) {
                        rowEl.style.display = 'none';
                    }
                    rowIdx++;
                }
            });

            this.updateColumnSpacerRowWidths();
            this.fixedTableEl.style.width
                = `${this.sourceTableEl.getBoundingClientRect().width - 1}px`;
        },
        setScrollWheelListener() {
            const oThis = this;
            this.fixedTableEl.addEventListener('wheel',
                event => this.onWheelScroll(event, oThis),
                this.scrollEventOptions);
        },
        setTableContainerDimensions() {
            if (!this.tableScrollEl) {
                return;
            }

            const dimensions = typeof this.containerDimensions === 'function'
                ? this.containerDimensions(this.tableScrollEl)
                : this.containerDimensions;

            if (dimensions && dimensions.width && dimensions.height) {
                const { width, height } = dimensions;
                this.tableScrollEl.style.width = width;
                this.tableScrollEl.style.height = height;
                return;
            }

            this.tableScrollEl.style.width = `${this.tableContainerEl.offsetWidth}px`;
            this.tableScrollEl.style.height = `${this.tableContainerEl.offsetHeight}px`;
        },
        setFixedTableOverflowWidth() {
            const tableWidthPx
                = this.sourceTableEl.parentNode.offsetWidth
                    - getVerticalScrollbarWidth(this.sourceTableEl.parentNode,
                        this.browserName);
            this.fixedHeadersEl.style.width = `${tableWidthPx}px`;
        },
        setFixedTableDimensions() {
            // Set position of sticky table, relative to optional container
            const _tableScrollElDims = this.sourceTableEl.getBoundingClientRect();

            let tableElTop = _tableScrollElDims.top + this.tableScrollEl.scrollTop;
            const tableElLeft = _tableScrollElDims.left + this.tableScrollEl.scrollLeft;

            // In Safari getBoundingClientRect returns fractional number
            // Fix 1px transparent line above sticky table header
            if (this.isBrowserNameSafari) {
                tableElTop = Math.floor(tableElTop);
            }

            this.fixedHeadersEl.style.top = `${tableElTop}px`;
            this.fixedHeadersEl.style.left = `${tableElLeft}px`;
        },
        createDropShadow() {
            this.shadowContainerEl = getDocument().createElement('div');
            this.shadowContainerEl.setAttribute('class', 'fixedTableShadow');

            this.shadowEl = getDocument().createElement('div');
            this.shadowContainerEl.appendChild(this.shadowEl);

            this.tableContainerEl.appendChild(this.shadowContainerEl);
        }
    },
    render: () => null
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
.isFixedTable {
  .graphic .figure .sticky-title-wrapper {
    z-index: @ZINDEX-STICKY-TITLE;

    &:after {
      content: '';
      height: 16px;
      position: absolute;
      width: 100%;
      bottom: -17px;
      background: @WKCE-WHITE;
    }
  }
}

.graphic .figure {
  .fixedTableShadow {
    position: fixed;
    overflow: hidden;
    visibility: hidden;
    height: 24px;
    left: 19px;
    top: 0;
    width: 0;

    > div {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
        height: 12px;
        width: 0;
    }
  }

  /**
  * PENDING ISSUE
  * This is an override to UTD_gx_gen_v2.css to fix random thickness being applied to
  * the bottom of the fixed header row at varying browser widths.
  * Have emailed Terrence H to inquire about why we have 0.3em bottom padding set on
  * the td.subttitle1 elements, as this renders out to a fractional pixel width of 4.8px.
  * As 0.5em renders to a whole pixel (8px), this appears to resolve the border thickness
  * variance occurring.
  */
  td.subtitle1 {
    padding-bottom: 0.5em;
  }

  #stickyHeaders {
    position: fixed;
    overflow: hidden;
    z-index: 1;

    .animate-sidebar & {
        z-index: auto;
    }

    > table {
        position: relative;
    }

    .trSpacer {
        visibility: hidden;
    }

    .tdSpacer {
        padding: 0;
        line-height: 0;
        font-size: 1px;

        > img {
            height: 1px;
        }
    }
  }
}

#tableScroller {
  overflow: auto;

  &:focus,
  &:focus-visible {
    outline: 2px solid;
  }

  .graphic_lgnd,
  .graphic_footnotes,
  .graphic_reference,
  #graphicVersion,
  .graphic__copyright {
    .ds1-mr-2();
  }
}

</style>
