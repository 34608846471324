import ACDropdownValue
    from '_acaSrc/components/contents/search/autocomplete/AutocompleteDropdownValue';
import { C_AUTOCOMPLETE } from '_acaSrc/utility/constants';

export function getDrugLabelFromSearchHit(drugSectionsHit) {
    const { title, brandName } = drugSectionsHit;
    return brandName ? `${brandName} (${title})` : title;
}

/* Below are the functions used to populate data for each individual AC result item */

/* Headers below */
export function makeGoToHeader() {
    return (new ACDropdownValue())
        .setType(C_AUTOCOMPLETE.ITEM_TYPES.HEADER.DEFAULT)
        .setClasses('ac-header')
        .setLabel('GO DIRECTLY TO')
        .setElId('ac-go-to-header');
}

export function makeRoutedDrugHeader(hit) {
    return (new ACDropdownValue())
        .setType(C_AUTOCOMPLETE.ITEM_TYPES.HEADER.ROUTED_DRUG)
        .setClasses('ac-routed-drug')
        .setSubClasses('ac-routed-drug-label ds1-utd-font ds1-utd-size-2 ds1-ph-2')
        .setUniqueId(`routed-drug-${hit.title}`)
        .setLabel(getDrugLabelFromSearchHit(hit));
}

export function makeSearchSuggestionsHeader() {
    return (new ACDropdownValue())
        .setType(C_AUTOCOMPLETE.ITEM_TYPES.HEADER.DEFAULT)
        .setClasses('ac-header')
        .setLabel('SEARCH UPTODATE FOR')
        .setElId('ac-search-suggestions-header');
}

export function makePersonalizedHeader() {
    return (new ACDropdownValue())
        .setType(C_AUTOCOMPLETE.ITEM_TYPES.HEADER.DEFAULT)
        .setClasses('ac-header')
        .setLabel('BOOKMARKS & HISTORY')
        .setElId('ac-profile');
}

/* Clickable items below */
export function makeDrugSectionLink(hit, idx, decoratedUrl, processAcFilteredClick) {
    const autoSuggestUrl = decoratedUrl;
    return (new ACDropdownValue())
        .setElId(`drug-suggestion-${idx}`)
        .setUniqueId(`${idx}${hit.url}${hit.title}`)
        .setClasses(`${hit.hitType} ac-drug-section-result`)
        .setSubClasses('ds1-utd-weight-bold')
        .setHref(autoSuggestUrl)
        .setIsNewTab(false)
        .setOnClick(e => processAcFilteredClick(e, autoSuggestUrl))
        .setLabel(getDrugLabelFromSearchHit(hit))
        .setSubtype('AutosuggestHit');
}

export function makeRoutedDrugSectionLink(hit, decoratedUrl, processAcFilteredClick) {
    const autoSuggestUrl = decoratedUrl;
    return (new ACDropdownValue())
        .setElId(`${hit.title}-route-${hit.label}`)
        .setUniqueId(`${hit.url}${hit.label}`)
        .setClasses('route-link')
        .setSubClasses('ds1-utd-weight-bold')
        .setHref(autoSuggestUrl)
        .setIsNewTab(false)
        .setOnClick(e => {
            e.preventDefault();
            processAcFilteredClick(e, autoSuggestUrl);
        })
        .setLabel(hit.label)
        .setSubtype('AutosuggestHit');
}

export function makeSearchSuggestionLink(r, i, processACclick, options = {}) {
    return (new ACDropdownValue())
        .setElId(`search-suggestion-${i}`)
        .setUniqueId(`search-suggestion-${r}-${i}`)
        .setClasses('ac-search-suggestion'
            + `${options.additionalClasses ? ` ${options.additionalClasses}` : ''}`)
        .setSubClasses('ds1-utd-weight-bold wkce-icon-search')
        .setOnClick(() => processACclick(r, i))
        .setLabel(r);
}

export function makePersonalizedLink(r, i, processAcFilteredClick) {
    return (new ACDropdownValue())
        .setType(C_AUTOCOMPLETE.ITEM_TYPES.CLICKABLE.PERSONALIZED)
        .setElId(`personalized-suggestion-${i}`)
        .setUniqueId(`${i}-${r.url}-${r.title}`)
        .setClasses(`ac-profile-result ${r.isHasWhatsNew ? 'changed' : ''} ${r.subtype || ''}`)
        .setOnClick(e => processAcFilteredClick(e))
        .setLabel(r.title, { isSafeHTML: true })
        .setHref(r.url);
}
