<template>
  <button :class="inlineCmeIconClass"
          class="inline-cme-button"
          @click="onClickIcon()">
    <div class="visuallyHidden">
      <div ref="tooltip" class="wk-js2 inline-reflection-tooltip-box">
        <div v-if="widgetState === 'error'"
             class="inline-reflection-error ds1-ma-1">
          <span class="wkce-icon-filled-stop-sign ds1-utd-size-2 ds1-ma-2">
            {{ $t("ERROR.INLINE_CME_ERROR") }}</span>
        </div>
        <div v-if="widgetState === 'loading'"
             class="inline-cme-spinner ds1-ma-2">
          <span class="wkce-icon-filled-spinner-alt wk-spin ds1-ma-1" />
          <span>Loading...</span>
        </div>
        <div v-if="widgetState === 'editing'"
             class="inline-widget-content">
          <div class="ds1-ph-2 ds1-pt-3 ds1-pb-2">
            <p class="inline-reflection-goals-credits-earned ds1-utd-size-2
                    ds1-pb-2 ds1-utd-weight-bold">
              Earn {{ accruedCredits }} credits for search "{{ searchTerm }}".
            </p>
            <p class="inline-reflection-goals-redeem ds1-utd-size-2">
              Reflect on your search now.
            </p>
          </div>
          <div class="wk-form inline-reflection-radio">
            <div v-for="question in questions" :key="question.id" class="wk-field">
              <div class="wk-field-header">
                <label class="wk-field-label utd-radioset-title-text ds1-pl-2 ds1-pb-2"><!--
                  -->{{ question.title }}
                </label>
              </div>
              <utd-radio-set :value="question.chosenOptionIdx"
                             class="ds1-pl-3"
                             :items="question.options"
                             :name="question.type"
                             @input="updateQuestion($event, question)" />
            </div>
          </div>
          <div class="inline-reflection-action ds1-pa-2">
            <utd-button class="automation_inline-reflection-cancel"
                        button-size="small"
                        button-style="text"
                        @click="handleCancel">{{ $t("CANCEL") }}
            </utd-button>
            <utd-button class="automation_inline-reflection-save"
                        button-size="small"
                        :disabled="formInvalid"
                        @click="handleSave">{{ $t("SAVE") }}
            </utd-button>
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import { RichContentTooltip } from '_acaSrc/utility/tooltip';
import { C_EVENTS, C_KEYCODES } from '_acaSrc/utility/constants';
import UtdRadioSet from '_acaSrc/components/shared/stdlib/UtdRadioSet.vue';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SET_QUESTION_CHOSEN_OPT, RESET_SELECTIONS } from '_acaSrc/store/inlineCme.store';
import PubSub from '_acaSrc/utility/PubSub';
import { getWindow } from '_acaSrc/utility/DOM';

export default {
    components: {
        UtdRadioSet,
        UtdButton
    },
    computed: {
        ...mapGetters('app', [ 'tooltipConfig' ]),
        ...mapGetters('inlineCme', [
            'accruedCredits',
            'searchTerm',
            'reflectionSuccess',
            'widgetState',
            'questions'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        formInvalid() {
            let formInvalid = false;
            this.questions.forEach(q => {
                formInvalid = formInvalid || typeof q.chosenOptionIdx === 'undefined'
                    || q.chosenOptionIdx === null
                    || q.chosenOptionIdx < 0;
            });
            return formInvalid;
        },
        inlineCmeIconClass() {
            return `wkce-icon-${this.reflectionSuccess ? 'filled-' : ''}check-circle`;
        }
    },
    watch: {
        isDesktopView() {
            if (!this.isDesktopView) {
                this.tooltip.hideTooltip();
            }
        }
    },
    mounted() {
        this.setupTooltip();
    },
    created() {
        this.checkWidgetState();
    },
    beforeUnmount() {
        this.stopDimensionsWatcher();
        getWindow().removeEventListener('keyup', this.onEscapePress);
    },
    methods: {
        ...mapMutations('inlineCme', [ SET_QUESTION_CHOSEN_OPT, RESET_SELECTIONS ]),
        ...mapActions('app', [ 'confirmConfigLoaded' ]),
        ...mapActions('inlineCme', [
            'saveReflection',
            'initReflectionWidget',
            'checkWidgetState'
        ]),
        async setupTooltip() {
            await this.confirmConfigLoaded();
            this.tooltip = new RichContentTooltip(this.$el, this.tooltipConfig, {
                allowFlip: false,
                placement: 'bottom',
                onShown: this.onShown,
                onHide: this.stopDimensionsWatcher
            });
            try {
                await this.tooltip.initialize();
                this.tooltip.setTooltipContent(this.$refs.tooltip);
            }
            catch {
                // Should never get here except possibly in a unit test, thanks
                // to toolbarInlineCmeVisible in the topic store. Catch error so
                // test output is clean
            }
        },
        async onShown() {
            if (!this.questions.length) {
                await this.initReflectionWidget();
            }
            await this.$nextTick();
            this.initDimensionsWatcher();
        },
        handleCancel() {
            this.tooltip.hideTooltip();
            this[RESET_SELECTIONS]();
            getWindow().removeEventListener('keyup', this.onEscapePress);
        },
        handleSave() {
            const type = this.reflectionSuccess ? 'update' : 'save';
            this.saveReflection();
            this.tooltip.hideTooltip();
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: `inline-cme-${type}`,
                optData: this.getOptEventData()
            });
        },
        getOptEventData() {
            const delimiter = '---';
            const mySearchGoals = this.getMySearchGoals();
            const getApplicationToPractice = this.getApplicationToPractice();
            return [ mySearchGoals, getApplicationToPractice, this.accruedCredits ].join(delimiter);
        },
        getApplicationToPractice() {
            const applicationQuestion = this.questions.find(q => q.type === 'application');
            return this.getResponse(applicationQuestion);

        },
        getMySearchGoals() {
            const applicationQuestion = this.questions.find(q => q.type === 'searchGoal');
            return this.getResponse(applicationQuestion);
        },
        getResponse(applicationQuestion) {
            if (!applicationQuestion) {
                return '';
            }
            const { options, chosenOptionIdx } = applicationQuestion;
            const chosenOption = options && options[chosenOptionIdx];
            return chosenOption ? chosenOption.option : '';
        },
        adjustDimensions() {
            const tooltipBoxEl = document.querySelector('.inline-reflection-tooltip-box');
            const winHeight = window.innerHeight;
            const rect = tooltipBoxEl.getBoundingClientRect();
            const excess = rect.top + rect.height - winHeight;
            if (excess > 0) {
                tooltipBoxEl.style.maxHeight = `${rect.height - excess}px`;
                tooltipBoxEl.style.overflowY = 'auto';
            }
            else {
                tooltipBoxEl.style.maxHeight = 'none';
            }
        },
        initDimensionsWatcher() {
            if (!this.listenerSetup) {
                this.adjustDimensionsArgs = [ 'resize', this.adjustDimensions,
                    { passive: true }];
                window.addEventListener(...this.adjustDimensionsArgs);
            }
            this.listenerSetup = true;
            this.adjustDimensions();
        },
        stopDimensionsWatcher() {
            if (this.adjustDimensionsArgs) {
                window.removeEventListener(...this.adjustDimensionsArgs);
                this.listenerSetup = false;
            }
        },
        updateQuestion(chosenOptionIdx, question) {
            this[SET_QUESTION_CHOSEN_OPT]({ chosenOptionIdx, question });
        },
        onClickIcon() {
            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: 'inline-cme-icon'
            });
            getWindow().addEventListener('keyup', this.onEscapePress);

        },
        onEscapePress(event){
            if (event.keyCode === C_KEYCODES.ESCAPE_KEYCODE) {
                this.handleCancel();
            }
        }
    }
};
</script>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
  @import (reference) '~_acaAssets/global/global.less';

  .inline-cme-button {
    .reset-button();
    .ds1-mv-1();
    color: @WKCE-BLUE-SHADE1;
    font-size: @DS1-MEDIUM-ICON-SIZE;
  }

  .inline-reflection-tooltip-box {
    min-width: 312px;
    box-shadow: @DS1-BOX-SHADOW;
    border: @DS1-UTD-GRAY-SINGLE-BORDER;
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    cursor: default;
  }

  .inline-reflection-radio {
    border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
    border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
    background-color: @DS1-UTD-GRAY-BG-COLOR;
  }

  .utd-radioset-title-text {
    .ds1-utd-weight-bold();
    color: @DS1-UTD-HEADER-TEXT-COLOR;
    margin: 0;
  }

  .inline-reflection-action {
    text-align: right;
  }

  .inline-reflection-goals-redeem {
    margin: 0;
  }

  .inline-reflection-goals-credits-earned {
    color: @WKCE-DARKGREEN;
    margin: 0;
  }

  .inline-cme-spinner {
    text-align: center;

    .wkce-icon-filled-spinner-alt {
      display: block;
    }
  }

  .inline-reflection-error {
    color: @DS1-UTD-GRAY-TEXT-COLOR;

    .wkce-icon-filled-stop-sign {
      &::before {
        color: @DS1-UTD-ERROR;
        .ds1-mr-2();
      }
      text-align: left;
      display: inline-flex;
    }
  }
</style>
