<template>
  <div class="home-search-view">
    <div id="redesign-header" :class="{ isUcc: isUccState }">
      <div class="body-container">
        <div v-if="isDesktopView" :class="isUccState ? 'utdcc-logo' : 'utd-logo'" />
        <utd-logo v-else :dark-logo="false" class="home-utd-logo" />
        <home-search-panel />
      </div>
    </div>
    <home-copyright-disclaimer class="homepage-redesign" />
    <home-search-disclaimer />
    <div class="utd-panels">
      <template v-if="isDesktopView">
        <div v-if="!isProspectView && isWnPcuPanelEnabled" class="wn-pcu-panels">
          <home-wn-pcu-panel />
        </div>
        <home-search-awareness-panel />
      </template>
      <template v-else>
        <home-search-awareness-panel />
        <div v-if="!isProspectView && isWnPcuPanelEnabled" class="wn-pcu-panels">
          <home-wn-pcu-panel />
        </div>
      </template>
    </div>
    <MobileProspectFooter v-if="!isHeaderRedesign && !isDesktopView" />
  </div>
</template>

<script>
import HomeSearchDisclaimer from '_acaSrc/components/homePageRedesign/HomeSearchDisclaimer.vue';
import HomeSearchPanel from '_acaSrc/components/homePageRedesign/HomeSearchPanel.vue';
import HomeCopyrightDisclaimer
    from '_acaSrc/components/contents/search/HomeCopyrightDisclaimer.vue';
import HomeSearchAwarenessPanel
    from '_acaSrc/components/homePageRedesign/HomeSearchAwarenessPanel.vue';
import HomeWnPcuPanel from '_acaSrc/components/homePageRedesign/HomeWnPcuPanel.vue';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';
import { mapGetters, mapMutations } from 'vuex';
import { SET_MOBILE_WEB_SEARCH_INTERACTION } from '_acaSrc/store/search.store';
import MobileProspectFooter from '_acaSrc/components/footer/MobileProspectFooter.vue';

export default {
    name: 'HomeSearchView',
    components: {
        HomeWnPcuPanel,
        HomeSearchAwarenessPanel,
        UtdLogo,
        HomeSearchPanel,
        MobileProspectFooter,
        HomeSearchDisclaimer,
        HomeCopyrightDisclaimer
    },
    computed: {
        ...mapGetters('device', [ 
            'isDesktopView', 
            'deviceType', 
            'isMobileOnDesktop' 
        ]),
        ...mapGetters('feature', [ 'isHeaderRedesign', 'isWnPcuPanelEnabled' ]),
        ...mapGetters('app', [ 'isUccState', 'isProspectView' ])
    },
    mounted() {
        this[SET_MOBILE_WEB_SEARCH_INTERACTION](false);
    },
    methods: {
        ...mapMutations('search', [ SET_MOBILE_WEB_SEARCH_INTERACTION ])
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utdWkHomePage2024 .home-search-view {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.utd-panels {
  display: flex;
  flex: 1;
}

.utdWkHomePage2024 {
  #redesign-header {
    background: url('~_acaAssets/utd-redesign/blue-gradient-background.svg') no-repeat center/cover;

    .isSmallScreen&,
    .isMobileOnDesktop&,
    .mobileonDesktop&,
    .isMobile& {
      background: url('~_acaAssets/utd-redesign/blue-gradient-background-292.png') no-repeat center/cover;

      .anonymous-user&, &.isUcc {
        background: url('~_acaAssets/utd-redesign/blue-gradient-background-232.png') no-repeat center/cover;
      }
    }

    .home-utd-logo {
      .ds1-mt-3();

      .isTablet& {
        .ds1-mt-0();
      }
    }

    .logo-properties {
      background-repeat: no-repeat;
      height: 62px;
      background-position-x: 50%;
      background-position-y: 50%;
    }

    .body-container {
      padding-top: clamp(30px, 5vh, 80px);
      padding-bottom: clamp(30px, 5vh, 80px);

      :deep(.wk-logo) {
        width: 100vw;
      }

      .isTablet& {
        :deep(.wk-logo) {
          width: 50vw;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .isSmallScreen&,
      .isMobile&,
      .isMobileOnDesktop&,
      .mobileonDesktop& {
        .ds1-ph-4();
        .ds1-pb-5();
        padding-top: 56px;
        width: unset;

        :deep(.wk-logo) {
          width: 50vw;
          margin-left: auto;
          margin-right: auto;
          margin-top: unset;
        }

        .body-container {
          .ds1-pb-5();
        }

        .anonymous-user&, &.isUcc {
          .body-container {
            .ds1-pb-6();
          }
        }
      }

      :deep(#new-search) {
        display: block;
      }

      .utdcc-logo {
        .logo-properties();
        background-image: url('~_acaAssets/utd-redesign/utdcc-logo.svg');
      }

      .utd-logo {
        .logo-properties();
      background-image: url('~_acaAssets/utd-redesign/utd-logo.svg');
      }
    }
  }
}

.isWnPcuPanelEnabled & {
  .utd-panels {
    display: flex;
    flex-direction: column; 
    flex: 1;
  }

  .wn-pcu-panels {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  .isWnPcuPanelEnabled .utd-panels {
    flex-direction: row;
  }
}

</style>