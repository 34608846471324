<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-if="!topicHasError"
         id="topicContainer"
         v-utd-formula-scaling
         class="topicContainer"
         :class="topicContainerClasses"
         :lang="topicLanguage">
      <topic-toolbar @toggle-outline="toggleOutline" />
      <find-in-topic v-if="toolbarFindInTopicVisible"
                     @toggle-outline="toggleOutline" />
      <topic-toolbar-mobile-overflow @formulink-clicked="navigateToFormulinkInfo" />
      <outline-toggle v-if="showOutlineToggle" />
      <graphic-viewer-inline v-if="hasInlineMedia"
                             id="articleInlineGfx"
                             class="inline-graphics"
                             source="topic_panel" />
      <section v-show="showTopicOutline"
               id="topicOutline"
               class="topicOutline">
        <graphic-viewer-inline v-if="hasInlineMedia"
                               id="outlineInlineGfx"
                               class="inline-graphics"
                               source="outline_thumbnail" />
        <div id="outlineContent"
             class="ds1-mb-8"
             v-html="topicOutlineHtml" />
      </section>
      <article id="topicArticle"
               :class="topicArticleClasses"
               class="topicArticle"
               v-html="topicBodyHtml" />
      <utd-print-tools :alternate-languages="alternatePrintLanguages"
                       :type="'topic'" />
      <utd-modeless-slider />
      <track-calculator-scroll />
    </div>
    <utd-content-error v-else
                       :message-key="topicError.messageKey"
                       :error-url="topicError.errorUrl" />
    <subscribe-interrupt v-if="showSubscribeBanner" />
    <topic-whats-new :key="refreshPCUBlock" />
    <graphic-hover-tooltip :hover-selector="topicType === 'drug' ? drugTypeHoverSelector : undefined"
    />
    <calculators-hover-tooltip />
    <topic-outline-manager />
    <topic-section-pointer />
    <topic-positioning />
    <topic-whats-new-dialog />
    <lexi-content-dialog />
    <topic-smooth-scroller />
    <lexi-content-sticky-table-header v-if="isDrugTopic" />
    <utd-view-in-language :topic-info="topic" />
    <international-brand-name v-if="showIbn" />
    <utd-share-dialog :triggered-by="shareFormTrigger" />
    <related-graphics-dialog />
    <topic-rating-and-feedback v-show="showRatingOrFeedback"
                               :show-only-feedback="!showStarRating" />
    <topic-graphics-gallery v-show="showGraphicTabContent"
                            :gallery-graphics="topicGalleryGraphics" />
    <translation-warning-modal ref="translationWarningModal" />
    <translation-feedback-instruction-modal ref="translationFeedbackInstructionModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import TopicWhatsNew from './TopicWhatsNew.vue';
import TopicToolbar from './toolbar/TopicToolbar.vue';
import TopicToolbarMobileOverflow from './toolbar/TopicToolbarMobileOverflow.vue';
import OutlineToggle from './OutlineToggle.vue';
import GraphicViewerInline from '_acaSrc/components/contents/graphic/GraphicViewerInline.vue';
import UtdModelessSlider from '_acaSrc/components/shared/utd/UtdModelessSlider.vue';
import TrackCalculatorScroll from './TrackCalculatorScroll.vue';
import UtdPrintTools from '_acaSrc/components/shared/utd/UtdPrintTools.vue';
import UtdContentError from '../../shared/utd/UtdContentError.vue';
import SubscribeInterrupt from './SubscribeInterrupt.vue';
import GraphicHoverTooltip from './GraphicHoverTooltip.vue';
import TopicOutlineManager from './TopicOutlineManager.vue';
import TopicSectionPointer from './TopicSectionPointer.vue';
import TopicPositioning from './TopicPositioning.vue';
import TopicWhatsNewDialog from './TopicWhatsNewDialog.vue';
import LexiContentDialog from './LexiContentDialog.vue';
import TopicSmoothScroller from './TopicSmoothScroller.vue';
import TopicGraphicsGallery from './TopicGraphicsGallery.vue';
import LexiContentStickyTableHeader from './LexiContentStickyTableHeader.vue';
import UtdFormulaScaling from './FormulaScaling.directive';
import UtdViewInLanguage from '_acaSrc/components/shared/utd/UtdViewInLanguage.vue';
import UtdShareDialog from '_acaSrc/components/shared/utd/UtdShare/UtdShareDialog.vue';
import InternationalBrandName from '_acaSrc/components/contents/topic/InternationalBrandName.vue';
import RelatedGraphicsDialog from '_acaSrc/components/contents/graphic/RelatedGraphicsDialog.vue';
import TopicRatingAndFeedback from './TopicRatingAndFeedback.vue';
import FindInTopic from '_acaSrc/components/contents/topic/toolbar/FindInTopic.vue';
import { lockRatingControls } from '_acaSrc/utility/PendoGuides/RatingGuide';
import initMachineLearningModule from '_acaSrc/utility/contents/topic/init-machine-learning';
import utdRest from '_acaSrc/utility/http/UtdRestHooks';
import PubSub from '_acaSrc/utility/PubSub';
import Logger from '_acaSrc/utility/Logger';
import { ScrollTarget } from '_acaSrc/utility/contents/topic/ScrollTarget';
import { dispatchEvent } from '_acaSrc/utility/Events';
import { safeTimeout } from '_acaSrc/utility/timers';
import { nullUndefinedOrEmpty } from '_acaSrc/utility/Validators';
import {
    injectCss,
    isSmallScreen,
    isTablet,
    getWindow,
    getDocument,
    checkAndSetEventBinding,
    decodeHtml,
    getElementFromHtmlString,
    isVisitableLink
} from '_acaSrc/utility/DOM';
import {
    encodeTopicLinksUrlParams,
    getUrlHash
} from '_acaSrc/utility/http';

import {
    C_RESPONSIVE,
    C_BROWSER,
    C_TOPIC,
    C_EVENTS,
    C_UI,
    C_LOCALIZATION
} from '_acaSrc/utility/constants';

import {
    SET_BODY_SCROLL_LOCK,
    SET_IOS_BODY_HIDE_LOCK,
    SET_META_DESCRIPTION,
    SET_MEDCALC_STYLES_LOADED
} from '_acaSrc/store/app.store';
import {
    SET_SHOW_BACK_TO_SEARCH
} from '_acaSrc/store/feature.store';
import { RESET_TOOLTIP, CLEAR_WHATS_NEW_INDICATORS } from '_acaSrc/store/profile.store';
import {
    SET_TOOLBAR_OVERFLOW_VISIBLE,
    SET_TOOLBAR_FORMULARY_VISIBLE,
    SET_TOPIC_BODY_HTML_TO_MOBILE,
    SET_TOPIC_BODY_HTML_TO_DESKTOP,
    SET_CALCULATOR_MARKUP_VIEW,
    SET_PREVIOUS_SECTION,
    SET_SCROLLER_RESET,
    UPDATE_LAST_SCROLL_TOPIC_ID,
    DO_NEXT_TOPIC_SCROLL_SMOOTHLY,
    SET_TOPIC_WHATS_NEW_DIALOG_DATA,
    SET_TOPIC_RATING,
    SET_SHOW_GRAPHIC_TAB_CONTENT,
    SET_TOOLBAR_OUTLINE_TOGGLE_VISIBLE,
    SET_IS_FIT_DIALOG_SHOWN,
    SET_IS_TITLE_COLLAPSE,
    SET_SHOW_REFERENCES,
    SET_BACK_TO_SEARCH_URL,
    SET_RESET_ELEMENTS_STABLE,
    SET_TOPIC_ELEMENTS_STABLE
} from '_acaSrc/store/topic/topic.store';

import {
    checkAppendDrugSectionLabel,
    processScrollTargets,
    TopicLinkTypes,
    assignTopicIndentationClasses
} from '_acaSrc/utility/contents/topic/topic';
import { setBrowserTabTitle } from '_acaSrc/utility/Browsers';
import CalculatorsHoverTooltip from '_acaSrc/components/contents/topic/CalculatorsHoverTooltip.vue';
import { checkIfLinkVisited, setLinkVisitedClass } from '_acaSrc/utility/UtdVisitedLink';
import InlineGraphics from '_acaSrc/utility/contents/graphic/inlineGraphics/inlineGraphics';
import LocalGuideline from '_acaSrc/components/localization/LocalGuideline.vue';
import PracticePoint from '_acaSrc/components/localization/PracticePoint.vue';
import TranslationWarningModal
    from '_acaSrc/components/localization/TranslationWarningModal.vue';
import TranslationFeedbackInstructionModal
    from '_acaSrc/components/localization/TranslationFeedbackInstructionModal.vue';

const INJECT_CALC_JS_DELAY_MS = 250;
const APPLY_TOPIC_DOM_CHANGES_WAIT_MS = 100;
const MINIMUM_DESKTOP_TOPIC_FIXED_SCROLL_PX = C_UI.FIXED_TOOLBAR_POSITION_PX;
const FORMULINK_ID_PREFIX = 'fiu';
const CONTENT_RENDERING_TIMEOUT = 200;

export default {
    components: {
        TopicWhatsNew,
        TopicToolbar,
        TopicToolbarMobileOverflow,
        OutlineToggle,
        GraphicViewerInline,
        UtdModelessSlider,
        TrackCalculatorScroll,
        UtdPrintTools,
        UtdContentError,
        SubscribeInterrupt,
        GraphicHoverTooltip,
        TopicOutlineManager,
        TopicSectionPointer,
        TopicPositioning,
        TopicWhatsNewDialog,
        LexiContentDialog,
        TopicSmoothScroller,
        TopicGraphicsGallery,
        LexiContentStickyTableHeader,
        UtdViewInLanguage,
        UtdShareDialog,
        RelatedGraphicsDialog,
        InternationalBrandName,
        FindInTopic,
        CalculatorsHoverTooltip,
        TopicRatingAndFeedback,
        TranslationWarningModal,
        TranslationFeedbackInstructionModal
    },
    directives: {
        UtdFormulaScaling
    },
    mixins: [ LocalGuideline, PracticePoint ],
    data() {
        return {
            onDestroyMachineLearning: null,
            topicReady: false,
            topicDomChangesApplied: false,
            currentUrl: getWindow().location.pathname,
            isIbnShown: false,
            topicTabHash: '',
            lastScrollTop: 0,
            scrollListeners: {
                outline: null,
                topic: null,
                window: null,
                graphics: null
            },
            drugTypeHoverSelector: '#topicContainer #topicArticle .graphic[class*="graphic_"]:not(.graphic_external_link)',
            inlineGraphics: null,
            inlineGraphicsComponents: [],
            refreshPCUBlock: false
        };
    },
    computed: {
        ...mapGetters('app', [
            'router',
            'contentVersion',
            'transitions',
            'isDesktopBrowser',
            'isProspectMode',
            'isProspectView',
            'isUccState',
            'isLapsedUser',
            'isAnonymousUser',
            'isPrintView',
            'medCalcStyles',
            'isTabletDevice'
        ]),
        ...mapGetters('pendo', [ 'showRatingGuide' ]),
        ...mapGetters('formulary', [ 'formularyInfoFormulariesList' ]),
        ...mapGetters('profile', [ 'permissions', 'tooltip', 'userProfile' ]),
        ...mapGetters('topic', [
            'topic',
            'topicId',
            'topicType',
            'topicSubtype',
            'topicLanguage',
            'topicRating',
            'topicTitle',
            'topicVersion',
            'topicMeta',
            'topicError',
            'topicScroller',
            'topicHasError',
            'topicLoaded',
            'topicFeedbackVisible',
            'isSampleTopic',
            'isSampleCalculator',
            'showOutlineToggle',
            'hasInlineMedia',
            'isShowOutline',
            'isOutlineVisible',
            'topicOutlineHtml',
            'isTopicShowing',
            'isDrugTopic',
            'isTopicCalculator',
            'isNarrativeLabI',
            'isMedicalTopic',
            'isTabbedContent',
            'isDxyPackageInsert',
            'isDxyDrugTopic',
            'topicBodyHtml',
            'calculatorScriptMobile',
            'calculatorScriptDesktop',
            'previousSection',
            'toolbarFormularyVisible',
            'topicWhatsNewList',
            'alternatePrintLanguages',
            'hasCalculatorContent',
            'ibnList',
            'topicGalleryGraphics',
            'showGraphicTabContent',
            'showTopicFeedback',
            'toolbarFindInTopicVisible',
            'isTitleCollapsed',
            'topicIsScrolling',
            'backToSearchUrl',
            'topicGraphicImageKeyMap',
            'isFitDialogShown',
            'findInTopicIsActive',
            'initialTopicLoadHash',
            'hasMachineTranslation',
            'isNotLocalizedTopicView',
            'selectedLocalizationLanguage'
        ]),
        ...mapGetters('search', [
            'searchParamsType',
            'searchParamsPriority'
        ]),
        ...mapGetters('device', [
            'browserType',
            'isDesktopView',
            'desktopViewType',
            'isMobileOnDesktop'
        ]),
        ...mapGetters('feature', [
            'showFormulinkContent',
            'isHeaderRedesign'
        ]),
        ...mapGetters('cme', [ 'lastCmeContentId', 'isTrackCme' ]),
        ...mapGetters('user', [ 'qualtricsURL' ]),
        ...mapGetters('graphic', [ 'graphicViewerVisible' ]),
        topicLanguageClass() {
            return `tl-${this.topicLanguage}`;
        },
        isTitleExtracted() {
            return !this.isPrintView;
        },
        topicContainerClasses() {
            return [
                this.topicLanguageClass,
                `topic-type-${this.topicType}`,
                `topic-subtype-${this.topicSubtype}`,
                { 'topic-showing': this.isTopicShowing },
                { 'topic-ready': this.topicReady },
                { 'outline-hidden': !this.isOutlineVisible },
                { inlineMedia: this.hasInlineMedia },
                { LAB: this.isNarrativeLabI },
                { drugTopic: this.isDrugTopic },
                { packageInsertMonograph: this.isDxyPackageInsert },
                { drugTopicDxy: this.isDxyDrugTopic },
                { isSampleTopic: this.isSampleTopic },
                { isTabbedContent: this.isTabbedContent },
                { isTitleExtracted: this.isTitleExtracted },
                { topicTab: !this.showGraphicTabContent },
                { graphicTab: this.showGraphicTabContent },
                { 'title-collapsed': this.isTitleCollapsed },
                { 'machine-translation': this.hasMachineTranslation }
            ];
        },
        showTopicOutline() {
            return this.isShowOutline
                && this.isOutlineVisible
                && !this.showGraphicTabContent;
        },
        showSubscribeBanner() {
            return (this.isProspectMode || this.isEmptyCalculatorWithLapsedUser)
                && !this.isSampleTopic
                && !this.isSampleCalculator
                && !this.isPrintView
                && !this.topicHasError
                && this.topicLoaded;
        },
        isEmptyCalculatorWithLapsedUser() {
            return this.isTopicCalculator
                && this.isLapsedUser
                && !this.hasCalculatorContent;
        },
        showTopicArticle() {
            return (this.isTopicShowing || this.isPrintView || this.isDesktopView || this.isTabletDevice)
                && (!this.showGraphicTabContent && this.isHeaderRedesign || !this.isHeaderRedesign);
        },
        topicArticleClasses() {
            return {
                LAB: this.hasInlineMedia,
                'is-hidden': !this.showTopicArticle
            };
        },
        // eslint-disable-next-line complexity
        showStarRating() {
            return this.showRatingGuide
                && this.showTopicFeedback
                && this.isDesktopBrowser
                && this.isOutlineVisible
                && !this.isMobileOnDesktop
                && !this.isTopicCalculator
                && !this.showGraphicTabContent
                && (!this.isUccState
                    || this.topicLanguage === 'en-US');
        },
        showRatingOrFeedback() {
            return !this.showGraphicTabContent
                && (this.showStarRating || this.topicFeedbackVisible)
                && this.isNotLocalizedTopicView;
        },
        showIbn() {
            return this.isIbnShown;
        },
        graphicTabScrollPosition() {
            return this.topicTabHash !== '' && this.isDesktopView
                ? MINIMUM_DESKTOP_TOPIC_FIXED_SCROLL_PX
                : 0;
        },
        topicArticleEl() {
            return this.$el && this.$el.querySelector('#topicArticle');
        },
        topicOutlineEl() {
            return this.$el && this.$el.querySelector('#topicOutline');
        },
        topicGraphicCollectionEl() {
            return this.$el && this.$el.querySelector('#topicGraphicCollection');
        },
        shareFormTrigger(){
            return this.graphicViewerVisible ? '#shareGraphic' : '#shareTopic';
        }
    },
    watch: {
        topicBodyHtml(){
            this.refreshPCUBlock = !this.refreshPCUBlock;
        },
        isDesktopView(newVal) {
            if (!newVal) {
                this.setupScrollListener();
            }
            else {
                this.clearOnScrollListener();
            }
        }
    },
    mounted() {
        new PubSub().subscribe('wkutd.switch-topic-tab', this.onSwitchTab);

        this.$nextTick(this.setup);
        // As the component template is reprocessed on first outline link clicked,
        // we need to restore the Find-In-Topic dialog here, if it's active.
        if (this.findInTopicIsActive) {
            this[SET_IS_FIT_DIALOG_SHOWN](true);
        }
    },
    beforeUnmount() {
        new PubSub().unsubscribe('RESPONSIVE_TRANSITION', this.onResponsiveTransition);
        this.resetTopicElementsStabilityFlags();
        this.teardownOptimizelyGraphicModalTrigger();
        if (this.onDestroyMachineLearning) {
            this.onDestroyMachineLearning();
        }
        this.unbindRehashHandler();
        this.unbindFormulinkRehashHandlers();
        new PubSub().unsubscribe('wkutd.relink-outline-events', this.onRelinkOutlineEvents);
        new PubSub().unsubscribe('wkutd.done-rendering-injectables',
            this.onDoneRenderingInjectables);
        new PubSub().unsubscribe('wkutd.$locationChangeStart', this.trackCme);
        new PubSub().unsubscribe('wkutd.star-rating', this.doRatingGuide);
        new PubSub().unsubscribe('wkutd.switch-topic-tab', this.onSwitchTab);
        new PubSub().unsubscribe(C_EVENTS.OPEN_TRANSLATION_WARNING, this.openTranslationModal);
        new PubSub().unsubscribe(C_EVENTS.OPEN_TRANSLATION_FEEDBACK_INSTRUCTION, this.openTranslationFeedbackInstructionModal);
        if (!this.isAnonymousUser) {
            new PubSub().unsubscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, setLinkVisitedClass);
        }
        this.clearResizeListener(this.resizeListener);
        this.clearOnScrollListener();
        this[SET_IS_FIT_DIALOG_SHOWN](false);
        this.inlineGraphics && this.inlineGraphics.removeInlineGraphicsSyncedHover();
    },
    methods: {
        ...mapActions('app', [
            'logEvent',
            'evalBodyScrollLock',
            'evalIosBodyHideLock',
            'confirmConfigLoaded'
        ]),
        ...mapActions('pendo', [ 'launchGuide' ]),
        ...mapActions('graphic', [ 'handleUseGraphicLink' ]),
        ...mapActions('formulary', [ 'enhanceDrugReferences' ]),
        ...mapActions('topic', [
            'findInTopic',
            'resetFindInTopic',
            'hideOutline',
            'showOutline',
            'navigateToFormulinkInfo',
            'injectCalculatorJs',
            'bindOptimizelyGraphicModalTrigger',
            'unbindOptimizelyGraphicModalTrigger',
            'bindRehashHandler',
            'unbindRehashHandler',
            'unbindFormulinkRehashHandlers',
            'handleTopicLinkClick',
            'processScrollRequest',
            'scrollToActiveHash',
            'hideOrShowPathways',
            'showRxTransitionsLinks',
            'renderInjectableAssets',
            'rebindWhatsNewLinks',
            'rebindCollapsibleIndicationLinks',
            'getTopicWhatsNewChanges',
            'setupLocalizationFeature',
            'setLocalizationInfo'
        ]),
        ...mapMutations('app', [
            SET_BODY_SCROLL_LOCK,
            SET_IOS_BODY_HIDE_LOCK,
            SET_META_DESCRIPTION,
            SET_MEDCALC_STYLES_LOADED ]),
        ...mapMutations('profile', [ RESET_TOOLTIP, CLEAR_WHATS_NEW_INDICATORS ]),
        ...mapMutations('topic', [
            SET_TOOLBAR_OVERFLOW_VISIBLE,
            SET_TOOLBAR_FORMULARY_VISIBLE,
            SET_TOPIC_BODY_HTML_TO_MOBILE,
            SET_TOPIC_BODY_HTML_TO_DESKTOP,
            SET_CALCULATOR_MARKUP_VIEW,
            SET_PREVIOUS_SECTION,
            SET_SCROLLER_RESET,
            UPDATE_LAST_SCROLL_TOPIC_ID,
            DO_NEXT_TOPIC_SCROLL_SMOOTHLY,
            SET_TOPIC_WHATS_NEW_DIALOG_DATA,
            SET_TOPIC_RATING,
            SET_SHOW_GRAPHIC_TAB_CONTENT,
            SET_TOOLBAR_OUTLINE_TOGGLE_VISIBLE,
            SET_IS_FIT_DIALOG_SHOWN,
            SET_IS_TITLE_COLLAPSE,
            SET_SHOW_REFERENCES,
            SET_BACK_TO_SEARCH_URL,
            SET_RESET_ELEMENTS_STABLE,
            SET_TOPIC_ELEMENTS_STABLE
        ]),
        ...mapMutations('feature', [ SET_SHOW_BACK_TO_SEARCH ]),
        setup() {
            this.setupRatingEvent();
            this.setBrowserTitle();
            this.setBrowserMeta();
            this.setToolbarFormularyVisible();
            this.setupOptimizelyGraphicModalTrigger();
            this.setupBackToSearch();
            this.setupMachineLearning();
            this.renderLinksIcons();
            !this.isDesktopView && this.setupScrollListener();
            !this.isAnonymousUser && this.encodeSearchUrlParams();
            this.setBodyFocus();
            safeTimeout(this.secondarySetup, 0);
            !this.isTabbedContent && this.moveTitle(true);
            this.resizeListener = this.setResizeListener(getWindow(), this.moveTitle);

        },
        setBodyFocus() {
            const body = getDocument().body;
            body && body.focus();
        },
        canShowInlineGraphics() {
            return this.topicType === 'medical';
        },
        /** Removes any floating refs to inline graphics that may take up memory */
        cleanupInlineGraphicsRefs() {
            if (this.inlineGraphicsComponents) {
                this.inlineGraphicsComponents = [];
            }
        },
        inlineGraphicsInjectedEventsHandler() {
            this.inlineGraphics
                && this.inlineGraphics.setInlineGraphicsSyncedHover(this.topicArticleEl);
            this.initGraphicModalClickHandlers(
                '#topicContainer .utd-inline-graphic__thumbnail'
            );
        },
        injectInlineGraphicsOnHTMLString(html) {
            if (!html || !this.canShowInlineGraphics()) {
                return html;
            }

            const topicContentEle = getElementFromHtmlString(html);
            const topicContent = topicContentEle && topicContentEle.querySelector('#topicContent');

            if (!topicContent) {
                return html;
            }

            this.setupInlineGraphicsIfMissing(topicContent);
            return topicContent.outerHTML || html;
        },
        setupInlineGraphicsIfMissing(selectorBase = this.topicArticleEl) {
            const inlineGraphics = selectorBase
                                && selectorBase.querySelectorAll('.utd-inline-graphics');
            // Confirms that inline-graphics haven't already been injected
            if (inlineGraphics && inlineGraphics.length === 0) {
                this.cleanupInlineGraphicsRefs();
                this.setupInlineGraphics(selectorBase);
            }
        },
        setupCssIndentationClasses() {
            if (this.isMedicalTopic) {
                assignTopicIndentationClasses(this.topicArticleEl);
            }
        },
        setupInlineGraphics(selectorBase = this.topicArticleEl) {
            if (!this.canShowInlineGraphics()) {
                return;
            }
            this.$nextTick(()=>{
                // eslint-disable-next-line max-len
                this.inlineGraphics = new InlineGraphics(this, selectorBase, this.topicGraphicImageKeyMap)
                    .createInlineGraphicsTemplates()
                    .populateParagraphData()
                    .injectInlineGraphics()
                    .setInlineGraphicsSyncedHover();

                // Adds on-click GVD capability to inline graphics
                this.initGraphicModalClickHandlers(
                    '#topicContainer .utd-inline-graphic__thumbnail'
                );
            });
        },
        setMobileBodyLock(isLocked) {
            if (isSmallScreen() || isTablet()) {
                this[SET_BODY_SCROLL_LOCK](isLocked);
                this[SET_IOS_BODY_HIDE_LOCK](isLocked);
            }
        },
        renderLinksIcons() {
            if (this.isProspectMode) {
                return;
            }
            const links = document.querySelectorAll('.topicArticle #topicText a:not(.graphic)');
            Array.from(links).forEach(link => {
                if (TopicLinkTypes.isExternal(link)) {
                    link.classList.add('graphic', 'graphic_external_link');
                }
                else if (TopicLinkTypes.isPathway(link)) {
                    link.classList.add('graphic', 'graphic_related_pathway');

                }
            });
        },
        onSwitchTab(event) {
            if (event.activeTab === C_TOPIC.TOPIC_TAB_INDEX) {
                if (event.event) {
                    this.$router.setUrlHash(this.topicTabHash);
                }
                else if (this.topicTabHash) {
                    this.$nextTick(() => {
                        this.processScrollRequest(new ScrollTarget({
                            hash: this.topicTabHash,
                            fromClick: false
                        }));
                    });
                }

                this[SET_SHOW_GRAPHIC_TAB_CONTENT](false);
            }
            else if (event.activeTab === C_TOPIC.GRAPHIC_TAB_INDEX) {
                this.topicTabHash = getUrlHash();
                if (this.topicTabHash === C_TOPIC.GRAPHIC_TAB_HASH) {
                    this.topicTabHash = '';
                }
                if (event.event) {
                    this.topicScroller.scroll({
                        top: this.graphicTabScrollPosition
                    });
                }
                this.$router.setUrlHash(C_TOPIC.GRAPHIC_TAB_HASH);

                this[SET_SHOW_GRAPHIC_TAB_CONTENT](true);

            }
            this.evalBodyScrollLock();
            this.evalIosBodyHideLock();
            this.setOutlineToggleVisibility();
        },
        setOutlineToggleVisibility() {
            if (!isSmallScreen()
             && !isTablet()
             && !this.isMobileOnDesktop) {
                return;
            }

            this[SET_TOOLBAR_OUTLINE_TOGGLE_VISIBLE](!this.showGraphicTabContent);
        },
        encodeSearchUrlParams() {
            // This method replaces unnecessary 'space', '+' and '/' in search link params.
            // Need for visited links browser behaviour.

            const topicArticle = this.$el.querySelector('.topicArticle');
            if (!topicArticle) {
                return;
            }

            const links = topicArticle.querySelectorAll('a');
            Array.from(links).forEach(link => {
                if (!isVisitableLink(link)) {
                    return;
                }
                link.href = encodeTopicLinksUrlParams(link.href);
            });
        },
        secondarySetup() {
            new PubSub().publish('wkutd.close-toolbar-menus');
            this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
            this.topicReady = true;
            checkAppendDrugSectionLabel(this.topicTitle, this.initialTopicLoadHash);
            this.setupEventHandlers();
            this.setupCalculators();
            this.setupTopicScroller();
            this.setupLogLcoClickEvent();
            this.applyTopicDomChanges();
            this.setupCssIndentationClasses();
            this.setupInlineGraphics();
            this.setupPracticePoints({
                contentId: this.topicId,
                languageCode: this.selectedLocalizationLanguage,
                source: C_LOCALIZATION.PP_SOURCE.TOPIC
            });
            this.setUpLocalGuidelines({
                languageCode: this.selectedLocalizationLanguage
            });
            this.setLocalizationInfo();
        },
        openTranslationModal({ shouldShowAcceptanceButtons }) {
            if (!this.hasMachineTranslation) {
                return;
            }
            else if (nullUndefinedOrEmpty(shouldShowAcceptanceButtons)) {
                shouldShowAcceptanceButtons = false;
            }
            this.$refs.translationWarningModal
                && this.$refs.translationWarningModal.open
                && this.$refs.translationWarningModal.open(shouldShowAcceptanceButtons);
        },
        openTranslationFeedbackInstructionModal() {
            if (!this.hasMachineTranslation) {
                return;
            }
            this.$refs.translationFeedbackInstructionModal && this.$refs.translationFeedbackInstructionModal.open();
        },
        setBrowserTitle() {
            if (!this.topicTitle) {
                return;
            }
            const title = decodeHtml(this.topicTitle);
            setBrowserTabTitle(title);
        },
        setBrowserMeta() {
            this[SET_META_DESCRIPTION](this.topicMeta);
        },
        setToolbarFormularyVisible() {
            const isVisible = this.showFormulinkContent
                && this.isMedicalTopic
                && this.formularyInfoFormulariesList.length;

            this[SET_TOOLBAR_FORMULARY_VISIBLE](isVisible);
        },
        setupCalculators() {
            if (!this.calculatorScriptMobile
             && !this.calculatorScriptDesktop) {
                return;
            }

            if (!this.medCalcStyles.cssLoaded) {
                const context = this;
                safeTimeout(() => {
                    injectCss(this.medCalcStyles, () => {
                        context[SET_MEDCALC_STYLES_LOADED](true);
                    });
                });
            }

            this.transitionCalculatorView();
        },
        onResponsiveTransition(transition) {
            this.evalBodyScrollLock();
            this.evalIosBodyHideLock();
            this.setOutlineToggleVisibility();
            new PubSub().publish('wkutd.utd-tabs-update-label-dimensions');
            this.transitionCalculatorView(transition);
        },
        transitionCalculatorView(transition) {
            if (!this.calculatorScriptMobile
             && !this.calculatorScriptDesktop) {
                return;
            }

            let currentView = this.desktopViewType;
            if (transition) {
                currentView = transition.current;
            }
            else {
                currentView = C_RESPONSIVE.DESKTOP_VIEW;
                if (this.browserType !== C_BROWSER.TYPE_DESKTOP
                 || this.isMobileOnDesktop) {
                    currentView = C_RESPONSIVE.MOBILE_VIEW;
                }
            }

            if (currentView === C_RESPONSIVE.MOBILE_VIEW) {
                this[SET_TOPIC_BODY_HTML_TO_MOBILE]();
            }
            else {
                this[SET_TOPIC_BODY_HTML_TO_DESKTOP]();
            }

            this[SET_CALCULATOR_MARKUP_VIEW](currentView);

            safeTimeout(this.injectCalculatorJs, INJECT_CALC_JS_DELAY_MS);
        },
        setupOptimizelyGraphicModalTrigger() {
            if (this.userProfile.optimizelyWebInitialized) {
                getWindow().addEventListener('wireOptimizelyGraphicModalTrigger',
                    this.bindOptimizelyGraphicModalTrigger);
            }
        },
        resetTopicElementsStabilityFlags() {
            this[SET_RESET_ELEMENTS_STABLE](true);
            this[SET_TOPIC_ELEMENTS_STABLE](false);
        },
        teardownOptimizelyGraphicModalTrigger() {
            if (this.userProfile.optimizelyWebInitialized) {
                getWindow().removeEventListener('wireOptimizelyGraphicModalTrigger',
                    this.bindOptimizelyGraphicModalTrigger);
                this.unbindOptimizelyGraphicModalTrigger();
            }
        },
        setupBackToSearch() {
            if ((this.router.stateParams().search || this.backToSearchUrl)
              && this.router.stateParams().source !== 'autocomplete') {
                const searchType = this.searchParamsType;
                const search = this.router.stateParams().search;
                const sp = this.searchParamsPriority;
                let url = this.backToSearchUrl;
                if (search) {
                    url = `/contents/search?source=backtosearch&searchType=${
                        searchType}&search=${search}&sp=${sp}`;
                    this[SET_BACK_TO_SEARCH_URL](url);
                }
                this[SET_SHOW_BACK_TO_SEARCH](true);
            }
            else {
                this[SET_SHOW_BACK_TO_SEARCH](false);
                this[SET_BACK_TO_SEARCH_URL]('');
            }
        },
        async setupMachineLearning() {
            await this.confirmConfigLoaded();
            const { onDestroy } = await initMachineLearningModule(this.router.stateParams().topic,
                this.$store,
                this.onReadingSection);
            if (onDestroy) {
                this.onDestroyMachineLearning = onDestroy;
            }
        },
        onReadingSection(data) {
            const section = data.split(',').shift();
            this[SET_PREVIOUS_SECTION](section === `${C_TOPIC.ANCHOR_HASH_PREFIX}H1`
                ? this.previousSection
                : section);
        },
        toggleOutline(outlineTarget) {
            new PubSub().publish('wkutd.close-toolbar-menus');
            this[SET_IS_TITLE_COLLAPSE](false);
            this[SET_TOOLBAR_OVERFLOW_VISIBLE](false);
            if (!this.isTopicShowing) {
                this.hideOutline();
                if (outlineTarget) {
                    this.$router.setUrlHash(outlineTarget);
                    setTimeout(this.scrollToActiveHash, CONTENT_RENDERING_TIMEOUT);
                }
            }
            else {
                this.$router.setUrlHash('');
                this.showOutline();
            }

            // [CORE-3479] If topic is being displayed, and we're
            // on the new header redesign, trigger a resize event
            // to ensure formula scaling gets applied correctly.
            if (this.isHeaderRedesign) {
                dispatchEvent('resize');
            }
        },
        transitionOnBeforeHandler(transition) {
            this.resetBookmarkTooltip();
            this.clearInjectedCss(transition);
        },
        setupEventHandlers() {
            new PubSub().subscribe(C_EVENTS.OPEN_TRANSLATION_WARNING, this.openTranslationModal);
            new PubSub().subscribe(C_EVENTS.OPEN_TRANSLATION_FEEDBACK_INSTRUCTION, this.openTranslationFeedbackInstructionModal);
            new PubSub().subscribe('wkutd.transitionsOnBefore', this.transitionOnBeforeHandler);
            new PubSub().subscribe('RESPONSIVE_TRANSITION', this.onResponsiveTransition);
            new PubSub().subscribe('wkutd.$locationChangeStart', this.trackCme);
            new PubSub().subscribe('wkutd.relink-outline-events', this.onRelinkOutlineEvents);
            new PubSub().subscribe('wkutd.done-rendering-injectables',
                this.onDoneRenderingInjectables);
            if (!this.isAnonymousUser) {
                new PubSub().subscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, setLinkVisitedClass);
            }
            this.bindRehashHandler();
        },
        resetBookmarkTooltip() {
            if (this.tooltip.show) {
                this[RESET_TOOLTIP]();
            }
        },
        clearInjectedCss(transition) {
            // Remove any dynamically injected CSS styles, unless to/from
            // match (topic outline clicked)
            if (transition.from().name !== transition.to().name) {
                const injectedCss = document.querySelector('#injectedCss');
                if (injectedCss) {
                    document.querySelector('head').removeChild(injectedCss);
                }
            }
        },
        canRecordCmeView(lastCmeContentId, newUrl) {
            return this.isTrackCme
                && lastCmeContentId !== null
                && lastCmeContentId !== ''
                && newUrl !== this.currentUrl;
        },
        trackCme(eventData) {
            const lastCmeContentId = this.lastCmeContentId;
            // Saving the URL paths and not the full URL so that it
            // does not trigger on outline link clicks
            const newUrl = eventData.pathname;

            if (this.canRecordCmeView(lastCmeContentId, newUrl)) {
                this.currentUrl = newUrl;
                utdRest('/cme/topicViewEnd', lastCmeContentId);
            }
        },
        initGraphicModalClickHandlers(baseSelector = '#topicContainer .graphic') {
            Array.from(document.querySelectorAll(baseSelector)).forEach(link => {
                checkIfLinkVisited(link, this.isAnonymousUser);

                checkAndSetEventBinding(link, 'gfx-modal-btn', 'click', evt => {
                    setLinkVisitedClass(
                        evt.currentTarget,
                        this.isAnonymousUser,
                        this.contentVersion
                    );
                    this.handleUseGraphicLink({ evt });
                });
            });
        },
        onRelinkOutlineEvents() {
            safeTimeout(() => {
                processScrollTargets(this.topicType);
                this.initGraphicModalClickHandlers();
            });
        },
        onDoneRenderingInjectables() {
            const hash = getUrlHash();
            if (hash && hash.indexOf(FORMULINK_ID_PREFIX) > -1) {
                this.handleTopicLinkClick();
            }
        },
        setupTopicScroller() {
            if (!this.topicDomChangesApplied) {
                setTimeout(this.setupTopicScroller, CONTENT_RENDERING_TIMEOUT);
                return;
            }
            this[SET_SCROLLER_RESET](true);
            setTimeout(this.scrollToActiveHash, CONTENT_RENDERING_TIMEOUT);

            // If no hash target specified, then reset scroll properties so
            // the next in-topic navigation smoothly scrolls to position.
            if (!getUrlHash()) {
                this[UPDATE_LAST_SCROLL_TOPIC_ID]();
                this[DO_NEXT_TOPIC_SCROLL_SMOOTHLY](true);
            }

            // Check if references section exist for skip to references link
            if (document.getElementById(`${C_TOPIC.ANCHOR_HASH_PREFIX}references`)) {
                this[SET_SHOW_REFERENCES](true);
            }
        },
        logLcoClickEvent() {
            this.logEvent({
                eventUrl: 'event/lcoSearchClick/json',
                eventParams: { topicId: this.topicId }
            });
        },
        setupLogLcoClickEvent() {
            const elLcoUrl = document.getElementById('lcoSearchClickUrl');
            if (elLcoUrl) {
                elLcoUrl.addEventListener('click', this.logLcoClickEvent);
            }
        },
        processWhatsNewModalContent(data, modalData) {
            data.forEach(whatsNewId => {
                Array.from(this.topicWhatsNewList).forEach(whatsNew => {
                    if (whatsNewId === whatsNew.contentId) {
                        modalData.content = `${modalData.content}${whatsNew.content}`;
                        const el = document.querySelector(`[contentId="${whatsNew.contentId}"]`);
                        el && el.classList.add('indicicator');
                    }
                });
            });
        },
        resolvePcuContentIds(pcuIds, modalData) {
            if (!(pcuIds && pcuIds.length > 0)) {
                return;
            }
            modalData.title = 'Practice Changing UpDate';
            modalData.selectors = '#pcuOutlineItem';

            this.processWhatsNewModalContent(pcuIds, modalData);
        },
        resolveWhatsNewContentIds(whatsNewIds, modalData) {
            if (!(whatsNewIds && whatsNewIds.length > 0)) {
                return;
            }
            modalData.title += `${modalData.title !== '' ? ' and ' : ''}What's New`;
            modalData.content += (modalData.content !== '' ? '<p>&nbsp;</p>' : '');
            modalData.selectors += `${modalData.selectors !== '' ? ',' : ''}#whatsNewOutlineItem`;

            this.processWhatsNewModalContent(whatsNewIds, modalData);
        },
        publishWhatsNewContent(modalData) {
            if (modalData.title === '' || modalData.content === '') {
                return;
            }
            const { title, content } = modalData;
            this[SET_TOPIC_WHATS_NEW_DIALOG_DATA]({ title, content });
            new PubSub().publish('WHATS_NEW_DIALOG_OPEN');
            Array.from(document.querySelectorAll(modalData.selectors))
                .forEach(el => {
                    el.classList.add('indicator');
                });
            this[CLEAR_WHATS_NEW_INDICATORS](this.topicId);
        },
        resolveCheckTopicChangesModal(data) {
            try {
                const modalData = {
                    title: '',
                    content: '',
                    selectors: ''
                };

                this.resolvePcuContentIds(data.pcuContentIds, modalData);
                this.resolveWhatsNewContentIds(data.whatsNewContentIds, modalData);
                this.publishWhatsNewContent(modalData);
            }
            catch (err) {
                Logger.warn(`Error resolving Topic Changes modal: ${err}`);
            }
        },
        processTopicChangeNarratives() {
            // Abort if we don't find the container, or user doesn't have feature
            const elWhatsNew = getDocument().querySelector('#topicWhatsNewContainer');
            if (elWhatsNew && this.permissions.topicsThatChanged) {
                // Determine if modal containing topic changes should be displayed
                this.getTopicWhatsNewChanges().then(result => {
                    if (Object.keys(result).length > 0) {
                        this.resolveCheckTopicChangesModal(result);
                        this.rebindWhatsNewLinks();
                        this.rebindCollapsibleIndicationLinks();
                    }
                }).catch(err => {
                    Logger.warn(`Couldn't determine if the topic version changed: ${err}`);
                });
            }
        },
        applyTopicDomChanges() {
            this.confirmConfigLoaded().then(() => {
                if (this.topicReady && this.topicLoaded) {
                    this.hideOrShowPathways();
                    this.showRxTransitionsLinks();
                    this.initGraphicModalClickHandlers();
                    this.processTopicChangeNarratives();
                    this.renderInjectableAssets();
                    this.rebindCollapsibleIndicationLinks({ openAll: this.isPrintView });
                    this.setupLocalizationFeature(getDocument().querySelector('.local-guideline'));
                    if (this.toolbarFormularyVisible) {
                        this.enhanceDrugReferences();
                    }
                    this.isIbnShown = this.$el
                        && this.$el.querySelector('.ibn-search') && this.ibnList.length;
                    this.topicDomChangesApplied = true;
                }
                else {
                    setTimeout(this.applyTopicDomChanges, APPLY_TOPIC_DOM_CHANGES_WAIT_MS);
                }
            });
        },
        setupRatingEvent() {
            new PubSub().subscribe('wkutd.star-rating', this.doRatingGuide);
        },
        doRatingGuide(eventData) {
            if (eventData.id !== 'desktopTopicStarRating') {
                return;
            }

            lockRatingControls();
            this[SET_TOPIC_RATING](eventData.rating);

            this.launchGuide({
                guideName: 'RatingGuide',
                guideData: {
                    topicId: this.topicId,
                    topicTitle: this.topicTitle,
                    topicVersion: this.topicVersion,
                    topicLanguage: this.topicLanguage,
                    element: eventData.id,
                    rating: eventData.rating,
                    feedbackUrl: eventData.feedbackUrl
                }
            });
        },
        setupScrollListener() {
            if (this.isDesktopView) {
                return;
            }

            if (this.isProspectMode) {
                this.scrollListeners.topic
                = this.setScrollListener(this.topicArticleEl,
                        this.handleScroll.bind(this, this.topicArticleEl));
                this.scrollListeners.outline
                = this.setScrollListener(this.topicOutlineEl,
                        this.handleScroll(this.topicOutlineEl));
            }
            else {
                this.scrollListeners.window
                = this.setScrollListener(getWindow(),
                        this.handleScroll.bind(this, getWindow()));
                this.scrollListeners.graphics
                = this.setScrollListener(this.topicGraphicCollectionEl,
                        this.handleScroll.bind(this, this.topicGraphicCollectionEl));
            }
        },
        clearOnScrollListener() {
            Object.keys(this.scrollListeners).forEach(key => {
                this.scrollListeners[key] && this.clearScrollListener(this.scrollListeners[key]);
            });
        },
        handleScroll(element) {
            const scrollTop = Math.floor(element.scrollTop || document.documentElement.scrollTop);
            if (scrollTop === this.lastScrollTop) {
                return;
            }

            const isTitleCollapse = (scrollTop !== 0) && (scrollTop > this.lastScrollTop);
            if (!this.topicIsScrolling) {
                this[SET_IS_TITLE_COLLAPSE](isTitleCollapse);
            }
            this.lastScrollTop = Math.ceil(scrollTop, 0);
        },
        moveTitle(){
            const toolbar = getDocument().querySelector('#topic-tools');
            const topicTitle = getDocument().querySelector('#topic-title');
            if(toolbar && topicTitle) {
                topicTitle.style.marginRight =
                 window.innerWidth < 768 || !this.isDesktopView ? '0px': `${toolbar.offsetWidth}px`;
            }
        },
        handleWindowSizeChange() {
            this.moveTitle();
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

/* CORE-10858 override to prevent top border after title removed from article into toolbar */
#topicContainer.topic-subtype-medical_society_guidelines #topicContent #topicText > .headingAnchor:first-child span[class$=_scrollTarget] {
  border-top: 0;
  padding-top: 0;
}

.topicPrint #topicContainer #topicContent.utdArticleSection .authorSectionElem,
.lexiSectionElem {
  .ds1-ma-0();
}

.lexiSectionElem * {
  font-size: 14px;
}

#topicContainer #topicArticle.is-hidden  {
    .ds1-pa-0();
    .ds1-ma-0();
    visibility: hidden;
    position: absolute;
    width: 0;
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .isTablet.utdWkHeader.topicView #topicContainer.isTabbedContent.graphicTab #topicArticle {
    display: none !important;
  }
}

.utd-content-main #topicContainer.machine-translation {
  #topicContent.utdArticleSection .authorSectionElem {
    .ds1-mt-6();
  }
}

#topicContainer #topicContent.utdArticleSection .authorSectionElem,
.lexiSectionElem {
  .ds1-pa-2();
  display: flex;
  flex-direction: column;
  background-color: @DS1-UTD-LIGHT-BLUE-BG-COLOR;
  // Used negative margin for particular case on mobile view to fill general parent padding
  margin: -16px -16px 0;
  position: relative;

  .authorsElementsLeft {
    #literatureReviewDate {
      border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
      .ds1-pt-1();
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      .ds1-pb-1();

      &:last-child {
        .ds1-pb-0();
      }
    }

    &:nth-child(2) {
      border-top: @DS1-UTD-GRAY-SINGLE-BORDER;
    }

    .disclosureLink {
      .ds1-mb-0();
    }

    .disclosureLink,
    #reviewProcess,
    #literatureReviewDate,
    #topicContributors {
      .ds1-utd-size-1();

      .textSize-L & {
        .ds1-utd-size-2();
      }
    }

    #literatureReviewDate {
      .ds1-utd-weight-bold();

      .emphasis {
        .ds1-utd-weight-normal();
      }
    }

    #litReviewSingleLine {
      display: block;
    }

    #topicContributors {
      .ds1-mt-0();

      > dt {
        .ds1-utd-size-1();
        .ds1-utd-weight-600();
        text-transform: uppercase;
        font-variant: all-small-caps;
        letter-spacing: 1px;
        color: @DS1-UTD-TOPIC-TEXT-COLOR;

        .textSize-L & {
          .ds1-utd-size-2();
        }
      }
    }
  }
}

.middleScreenStyles() {
  #topicContainer #topicContent.utdArticleSection #authorSectionElem,
  .lexiSectionElem {
    .ds1-ma-0();

    > div {
      .disclosureLink,
      #reviewProcess,
      #literatureReviewDate,
      #topicContributors {
        .ds1-utd-size-2();

        .textSize-L & {
          .ds1-utd-size-3();
        }

        .textSize-S & {
          .ds1-utd-size-1();
        }
      }

      &:first-child {
        .ds1-pb-2();
      }

      &:last-child {
        .ds1-pt-2();
      }
    }

    #reviewProcess {
      .ds1-mt-0();
    }
  }
}

.largeScreenStyles {
  #topicContainer #topicContent.utdArticleSection .authorSectionElem,
  .lexiSectionElem {
    .ds1-ma-0();
    flex-direction: row;

    > div {
      &:first-child {
        .ds1-pb-0();

        &:not(:only-child) {
          margin-right: 120px;
        }
      }

      &:last-child {
        flex-basis: 350px;
        .ds1-pt-0();
        border-top: none;
        flex-grow: 1;
      }
    }

    .authorsElementsRight {
      #literatureReviewDate {
        .ds1-pt-0();
      }

      #reviewProcess {
        .ds1-mt-0();
      }
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .isTablet {
    .middleScreenStyles();
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .middleScreenStyles();
  }
}

@media only screen and (min-width: 1140px) and (orientation: landscape) {
  .isTablet {
    .largeScreenStyles();
  }
}

@media only screen and (min-width: 1140px) {
  .isDesktop {
    .largeScreenStyles();
  }
}

.print-section-hidden {
  display: none;
}
</style>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#topicContainer.isTabbedContent.graphicTab {
  :deep(#outlineToggle),
  & ~ .utdStarRating_topicDesktop {
    display: none;
  }
}
</style>
