<template>
  <div class="floating-labels-form login-register-section">
    <form v-if="loginSubscriberBox" class="form-login">
      <slot name="registerTitle" />
      <div class="form-row form-break">
        <utd-button button-type="link"
                    class="subscribe-btn"
                    :button-link="storeUrl"
                    button-size="small"
                    button-color="green-shade-3">{{ $t('HEADER.SUBSCRIBE') }}
        </utd-button>
      </div>
      <slot name="registerSectionText" />
    </form>

    <form v-if="loginRegisterButton"
          id="registerRedirect"
          class="form-login jumpstart-form"
          method="post"
          action="/login-register">
      <slot name="registerTitle" />
      <input type="hidden" name="isRegister" value="true">
      <input type="hidden" name="source" value="login-register-view">
      <div class="form-break form-row">
        <span class="form-control">
          <input type="submit"
                 name="submit"
                 class="form-button toggleLrb"
                 :value="$t('LOGIN.REGISTER_NOW')">
        </span>
      </div>

      <slot name="registerSectionText" />
      <a v-if="showSkipLink"
         class="login-register-section-link"
         :href="skipLinkUrl">{{ $t('LOGIN.CONTINUE_WITH_SEARCH') }}</a>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

export default {
    components: { UtdButton },
    computed: {
        ...mapGetters('feature', [
            'showSkipLink'
        ]),
        ...mapGetters('login', [
            'loginSubscriberBox',
            'loginRegisterButton',
            'skipLinkUrl'
        ]),
        ...mapGetters('auth', [ 'storeUrl' ])
    }
};
</script>

<style scoped lang="less">
@import '~_acaAssets/global/variables';
@import '~_acaAssets/wkce/colors/wkce-app-styles';
@import (reference) '~_acaAssets/utd-button/utd-button';

.login-register-section {
  .ds1-mv-0();
  .ds1-pt-1();
  .ds1-pb-0();

  #registerRedirect {
    .form-button {
      background-color: @DS1-GREEN-SHADE-3-BUTTON-FACE;

      &:hover {
        background-color: @DS1-GREEN-SHADE-3-BUTTON-HOVER;
      }
    }
  }

  .login-register-title {
    .ds1-utd-size-3();
    .ds1-pb-0();
  }

  .login-register-section-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
    .ds1-mt-1();
  }

  .form-break {
    .ds1-pt-2();
  }

  :deep(.utd-button.subscribe-btn) {
    .ds1-utd-font();
    .ds1-utd-size-2();
    .ds1-utd-weight-500();
    color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    height: 40px;
    width: 100%;

    &:hover {
      color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
      text-decoration: none;
    }
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop .login-view.utd-form {
    .floating-labels-form.login-register-section {
      .ds1-mt-4();
      .ds1-pv-4();

      .login-register-title {
        .ds1-utd-h3();
        line-height: 1;
      }
    }
  }
}
</style>