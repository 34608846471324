<template>
  <div id="search__container"
       :class="{ 'mobile-homepage-search-expanded': isMobileWebSearchExpanded }">
    <home-search-view v-if="isHomepageRedesign2024 && !searchResultsShow" />

    <template v-else>
      <utd-logo v-if="showUtdLogo" />
      <home-search-container />
      <div v-if="searchBarBody">
        <home-copyright-disclaimer />
      </div>
      <div v-if="searchBarBody" id="promo-container">
        <utd-promo-box :category="'home'" :type="'specific-messaging'" />
      </div>
      <div v-if="renderUtdAddWidget"
           v-show="isShowMyUtdWidget">
        <my-utd-widget />
      </div>
      <my-utd-mobile-links />
    </template>

    <div v-if="searchResultsShow" id="searchresults" class="search-results">
      <div class="utd-tabControl">
        <div>
          <utd-toolbar-back-link :show-back-button="isAlwaysShowBackButton" />
          <search-facets />
        </div>
      </div>
      <utd-oidc-banner />
      <search-suggestions />
      <ai-search v-if="shouldShowAiResults"
                 :search-results="aiSearchResultsResults"
                 :error="aiSearchResultsHasError" />
      <div id="search-results-container"
           :class="{ 'graphicsSearchLayout': isGraphicsSearch,
                     'altsearchResults': searchResultAlternate,
                     'rightPanelVisible': isQuickAccessPanel,
                     'prospectBannerShown': showProspectBanner }">
        <div id="leftSidePanel">
          <utd-content-error v-if="searchResultsError"
                             :message-key="searchResultsError" />
          <ul v-if="searchResultsResults && !isGraphicsSearch"
              class="utd-listView"
              :class="{authenticatedResultsCollapsed}"
              role="list">
            <li v-for="(result, idx) in desktopFeaturedSearchResults"
                v-show="isDesktopView && !result.hideInResults"
                :key="'Featured-Result-'+idx"
                class="featured-search-result search-result-list-item"
                :class="featuredSearchResultClass(result)">
              <search-results-adapter :result="result" />
            </li>
            <li v-for="(result, idx) in templateSetSearchResultParams"
                v-show="!result.hideInResults"
                :id="result.id"
                :key="idx"
                role="listitem"
                class="search-result-list-item"
                :class="[ featuredSearchResultClass(result), {
                  'unlink': result.suppressLink,
                  'LAB': result.subtype === C_QAP.SUBTYPES.LABI,
                  'PCU': result.subtype === C_QAP.SUBTYPES.PCU,
                  'QAP': result.type === C_QAP.UI.QAP_TYPE,
                  'DRUG': result.type === C_QAP.PANEL.DRUG_INFO_PANEL,
                  'QapVue': isVueQap(result),
                  'featuredResult': isFeaturedSearchResult(result),
                  'graphicalAnswers': isGraphicalAnswers(result),
                  'outlineFlyoutIsOpen': outlineFlyoutIsOpen(result.id),
                  'result-list-hovered': result.isHovered
                }]">
              <search-results-adapter v-if="isMobileFeaturedResult(result)"
                                      :result="result" />

              <search-results-adapter v-if="isMobileGraphicalAnswers(result)"
                                      :result="result" />

              <qap-mobile-adapter v-if="isQap(result)"
                                  :result="result" />

              <search-result v-if="isRegularResult(result)"
                             class="search-result"
                             :result="result" />

              <outline-flyout-toggle :result="result"
                                     :index="idx" />
            </li>
            <utd-content-error v-if="mainSearchResultsEmpty"
                               class="qap-results-only"
                               :message-key="emptyResultsMessageKey" />
          </ul>
          <!-- Graphic search results -->
          <search-results-graphics v-if="hasGraphicResults" />
          <div v-if="hasGraphicResults" class="clear" />
          <search-results-show-more />
        </div>
        <div v-if="isQuickAccessPanel" id="rightSidePanel">
          <search-results-adapter v-if="desktopGraphicalAnswerResult"
                                  v-show="isDesktopView"
                                  :result="desktopGraphicalAnswerResult" />
          <qap-desktop-adapter v-if="showDesktopQap" />
        </div>
        <prospect-banner v-if="showProspectBanner"
                         :subtitle-key="'MARKETING_BANNER.INFO_ON_SUBSCRIPTION_OPTIONS'" />
        <outline-flyout-container />
      </div>
    </div>
    <utd-partner-oidc-modal />
    <oidc-footer />
    <utd-modeless-slider />
    <graphic-viewer-dialog show-counter @mounted="onGfxViewerReady" />
    <related-graphics-dialog />
    <utd-share-dialog triggered-by="#shareGraphic" />
    <graphic-hover-tooltip :hover-selector="hoverSelector"
                           :off-topic-variant="true" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';
import HomeSearchContainer from '_acaSrc/components/contents/search/HomeSearchContainer.vue';
import HomeCopyrightDisclaimer
    from '_acaSrc/components/contents/search/HomeCopyrightDisclaimer.vue';
import UtdPromoBox from '_acaSrc/components/promobox/PromoBox.vue';
import MyUtdWidget from '_acaSrc/components/myuptodate/MyUtdWidget.vue';
import MyUtdMobileLinks from '_acaSrc/components/contents/search//MyUtdMobileLinks.vue';
import UtdToolbarBackLink from '_acaSrc/components/shared/utd/UtdToolbarBackLink.vue';
import SearchFacets from '_acaSrc/components/contents/search/SearchFacets.vue';
import UtdOidcBanner from '_acaSrc/components/shared/utd/UtdOidcBanner.vue';
import SearchSuggestions from '_acaSrc/components/contents/search/SearchSuggestions.vue';
import UtdContentError from '_acaSrc/components/shared/utd/UtdContentError.vue';
import SearchResultsAdapter from '_acaSrc/components/contents/search/SearchResultsAdapter.vue';
import QapMobileAdapter from '_acaSrc/components/contents/search/quickAccessPanel/QapMobileAdapter.vue';
import QapDesktopAdapter from '_acaSrc/components/contents/search/quickAccessPanel/QapDesktopAdapter.vue';
import SearchResult from '_acaSrc/components/contents/search/SearchResult.vue';
import OutlineFlyoutToggle from '_acaSrc/components/contents/search/OutlineFlyoutToggle.vue';
import SearchResultsGraphics from '_acaSrc/components/contents/search/SearchResultsGraphics.vue';
import SearchResultsShowMore from '_acaSrc/components/contents/search/SearchResultsShowMore.vue';
import ProspectBanner from '_acaSrc/components/ui/ProspectBanner.vue';
import OutlineFlyoutContainer from '_acaSrc/components/contents/search/OutlineFlyoutContainer.vue';
import UtdPartnerOidcModal from '_acaSrc/components/login/UtdPartnerOidcModal.vue';
import OidcFooter from '_acaSrc/components/footer/OidcFooter.vue';
import UtdModelessSlider from '_acaSrc/components/shared/utd/UtdModelessSlider.vue';
import GraphicViewerDialog from '_acaSrc/components/contents/graphic/GraphicViewerDialog.vue';
import RelatedGraphicsDialog from '_acaSrc/components/contents/graphic/RelatedGraphicsDialog.vue';
import UtdShareDialog from '_acaSrc/components/shared/utd/UtdShare/UtdShareDialog.vue';
import GraphicHoverTooltip from '_acaSrc/components/contents/topic/GraphicHoverTooltip.vue';
import AiSearch from '_acaSrc/components/contents/search/aiSearch/AiSearch.vue';

import {
    isVueQap,
    setSearchResultParams,
    isFeaturedSearchResult,
    isRegularSearchResult,
    isGraphicalAnswers
} from '_acaSrc/utility/contents/search/search';
import { SET_IS_SEARCH_VIEW } from '_acaSrc/store/search.store';
import { getWindow,
    checkAndSetEventBinding } from '_acaSrc/utility/DOM';
import { C_QAP, C_EVENTS, C_LOCALIZATION } from '_acaSrc/utility/constants';
import { getQueryParamValues } from '_acaSrc/utility/http';
import { safeTimeout, debounce } from '_acaSrc/utility/timers';
import PubSub from '_acaSrc/utility/PubSub';
import { setBrowserTabTitle } from '_acaSrc/utility/Browsers';
import { checkIfLinkVisited, setLinkVisitedClass } from '_acaSrc/utility/UtdVisitedLink';
import UrlScrollTracker from '_acaSrc/utility/http/UrlScrollTracker';
import { isBqpKpp } from '_acaSrc/utility/Validators';
import HomeSearchView from '_acaSrc/components/homePageRedesign/HomeSearchView.vue';

const RETRY_SHOW_MODAL_DELAY_MS = 100;
const CHECK_RESTORE_SCROLL_DELAY_MS = 200;

export default {
    components: {
        HomeSearchView,
        UtdLogo,
        HomeSearchContainer,
        HomeCopyrightDisclaimer,
        UtdPromoBox,
        MyUtdWidget,
        MyUtdMobileLinks,
        UtdToolbarBackLink,
        SearchFacets,
        UtdOidcBanner,
        SearchSuggestions,
        UtdContentError,
        SearchResultsAdapter,
        QapMobileAdapter,
        QapDesktopAdapter,
        SearchResult,
        OutlineFlyoutToggle,
        SearchResultsGraphics,
        SearchResultsShowMore,
        ProspectBanner,
        OutlineFlyoutContainer,
        UtdPartnerOidcModal,
        OidcFooter,
        UtdModelessSlider,
        GraphicViewerDialog,
        RelatedGraphicsDialog,
        UtdShareDialog,
        GraphicHoverTooltip,
        AiSearch
    },
    data() {
        return {
            // Providing a noop until graphic viewer is mounted. This race condition
            // is mostly just theoretical but allows a fallback of normal browser
            // link behavior in case mounting fails somehow.
            openGfxViewer: () => 0,
            hoverSelector: '.search-result-subhit-container'
                         + ' .search-result-subhit-link[class*=graphic],'
                         + '.featured-search-result .utd-graphic-link__container,'
                         + '.key-points-accordion-text__container a.graphic,'
                         + '.broad-query-panel__container .accordion-text__container a.graphic,'
                         + '.ai-passage-retrieval-container .topic-snippet .snippet a.graphic',
            cancelRetryShowModalTimeout: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'isAlwaysShowBackButton',
            'isProspectView',
            'isUccState',
            'isAppDataLoaded',
            'contentVersion',
            'isAnonymousUser'
        ]),
        ...mapGetters('search', [
            'searchBarBody',
            'searchResultsShow',
            'searchResultsError',
            'isGraphicsSearch',
            'searchResultAlternate',
            'searchResultsResults',
            'desktopFeaturedSearchResults',
            'outlineFlyoutTopicId',
            'outlineFlyoutShowOutline',
            'mainSearchResultsEmpty',
            'searchFor',
            'desktopGraphicalAnswerResult',
            'hasGraphicalAnswers',
            'searchParamsSearchText',
            'isCollapse',
            'searchParams',
            'isAllSearch',
            'isMobileWebSearchExpanded'
        ]),
        ...mapGetters('ai', [
            'aiSearchResultsResults',
            'shouldMakeAiRequest',
            'aiSearchResultsHasError',
            'aiComponentHasBeenShown',
            'hasSwitchedTabs',
            'hasSwitchedTabsWhileLoading',
            'manuallyHideAiComponent'
        ]),
        ...mapGetters('device', [
            'browserType',
            'isDesktopView',
            'isNotDesktopView',
            'isBrowserTypeSmallScreen',
            'isMobileOnDesktop'
        ]),
        ...mapGetters('profile', [ 'isShowMyUtdWidget', 'userProfile' ]),
        ...mapGetters('qap', [ 'isQuickAccessPanel', 'isDesktopQapVisible' ]),
        ...mapGetters('graphic', [ 'graphicViewerVisible' ]),
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('feature', [ 'isHomepageRedesign2024' ]),
        // eslint-disable-next-line complexity
        shouldShowAiResults() {
            /* Manually hide component; supersedes all below logic.
               USE WITH CAUTION: Will cause pop-out and undesirable scroll on search results
               due the vue's reactivity.  */
            if (this.manuallyHideAiComponent) {
                return false;
            }

            // Only show on the "All" tab; remove this to show results on all tabs (non-graphic)
            if (!this.isAllSearch) {
                return false;
            }

            let hasBqpOrKpp = false;
            this.desktopFeaturedSearchResults.forEach(result => {
                if (isBqpKpp(result.type)) {
                    hasBqpOrKpp = true;
                }
            });

            if (hasBqpOrKpp) {
                return false;
            }

            this.checkIfAiRequestRequired();

            // If the AI component hasn't been seen, and we have an error, hide from user
            if (!this.aiComponentHasBeenShown
                && this.aiSearchResultsHasError
                && this.aiSearchResultsHasError.hasError) {
                return false;
            }

            // If we have an error, but the user has switched tabs, we can hide it
            if (this.aiSearchResultsHasError
                && this.aiSearchResultsHasError.hasError
                && this.hasSwitchedTabs
                && !this.hasSwitchedTabsWhileLoading
            ) {
                return false;
            }

            return !this.isProspectView
                && !this.isUccState 
                && !this.isGraphicsSearch
                && this.searchResultsResults
                && this.shouldMakeAiRequest;
        },
        showUtdLogo() {
            return this.searchBarBody && this.isNotDesktopView;
        },
        renderUtdAddWidget() {
            return this.searchBarBody
                && !this.isBrowserTypeSmallScreen
                && !this.isMobileOnDesktop;
        },
        emptyResultsMessageKey() {
            return `All available search results are displayed in the panel for ${this.searchFor}`;
        },
        hasGraphicResults() {
            return this.searchResultsResults && this.isGraphicsSearch;
        },
        showDesktopQap() {
            return this.isDesktopQapVisible && !this.hasGraphicalAnswers;
        },
        showProspectBanner() {
            return this.isProspectView && !this.isUccState;
        },
        templateSetSearchResultParams() {
            return setSearchResultParams(this.searchResultsResults, 'search_result', {
                isMobileOnDesktop: this.isMobileOnDesktop,
                browserType: this.browserType
            });
        },
        authenticatedResultsCollapsed() {
            return this.isCollapse && !this.showProspectBanner;
        },
        isGraphicalAnswers: () => isGraphicalAnswers,
        isFeaturedSearchResult: () => isFeaturedSearchResult,
        isVueQap: () => isVueQap,
        C_QAP: () => C_QAP
    },
    created() {
        this.unwatchGfxStore
            = this.$store.watch(() => this.graphicViewerVisible,
                visible => visible && this.openGfxViewer());
    },
    mounted() {
        const oThis = this;
        this.delayExecuteUntilDataLoaded(function() {
            oThis.showModal();
            if (oThis.userProfile.optimizelyWebInitialized) {
                getWindow().addEventListener(
                    'wireOptimizelyGraphicModalTrigger',
                    oThis.bindOptimizelyGraphicModalTrigger
                );
            }
        });
        this.$nextTick(() => {
            this.setupEventHandlers();
            this.initSearchResultClickHandlers();
            new PubSub().subscribe(C_EVENTS.TRANSLATE_FEATURED_SEARCH_RESULT,
                this.updateLocalizedFeaturedResultTranslation);
        });
    },
    updated() {
        if (this.searchParamsSearchText) {
            setBrowserTabTitle(this.searchParamsSearchText);
            this[SET_IS_SEARCH_VIEW](true);
        }
        if (!new UrlScrollTracker().restoreLastPosition) {
            return;
        }
        debounce(this.restoreScrollPosition, CHECK_RESTORE_SCROLL_DELAY_MS)();
    },
    beforeUnmount() {
        this.cancelRetryShowModalTimeout && this.cancelRetryShowModalTimeout();
        getWindow().removeEventListener(
            'wireOptimizelyGraphicModalTrigger',
            this.bindOptimizelyGraphicModalTrigger
        );
        this.unwatchGfxStore();
        this.unbindOptimizelyGraphicModalTrigger();
        if (!this.isAnonymousUser) {
            new PubSub().unsubscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, setLinkVisitedClass);

            new PubSub().unsubscribe(
                'SEARCH_RESULT_SHOW_MORE',
                this.initSearchResultClickHandlers
            );
        }
        new PubSub().unsubscribe(C_EVENTS.TRANSLATE_FEATURED_SEARCH_RESULT,
            this.updateLocalizedFeaturedResultTranslation);
        this.clearScrollListener(this.scrollListener);
    },
    methods: {
        ...mapMutations('search', [ 'SET_IS_SEARCH_VIEW' ]),
        ...mapActions('app', [ 'delayExecuteUntilDataLoaded' ]),
        ...mapActions('ai', [ 'checkIfAiRequestRequired' ]),
        ...mapActions('topic', [
            'bindOptimizelyGraphicModalTrigger',
            'unbindOptimizelyGraphicModalTrigger'
        ]),
        updateLocalizedFeaturedResultTranslation() {
            this.desktopFeaturedSearchResults.forEach(i => {
                if (i.hideInResults !== 'undefined') {
                    i.hideInResults = !i.hideInResults;
                }
            });
            this.searchResultsResults.forEach(i => {
                if (i.hideInResults !== 'undefined' && isBqpKpp(i.type)) {
                    i.hideInResults = !i.hideInResults;
                }
            });
        },
        featuredSearchResultClass(result) {
            const hasTranslations = result.availableLanguageCodeSet
                && result.availableLanguageCodeSet.length > 1
                ? ' hasTranslations'
                : '';
            const isLocalLanguage = result.languageCode !== C_LOCALIZATION.ENGLISH_CODE
                ? ' isLocalLanguage'
                : '';
            return `${result.type}${hasTranslations}${isLocalLanguage}`;
        },
        onGfxViewerReady({ open }) {
            this.openGfxViewer = open;
        },
        isMobileFeaturedResult(result) {
            return isFeaturedSearchResult(result) && this.isNotDesktopView;
        },
        isMobileGraphicalAnswers(result) {
            return isGraphicalAnswers(result) && this.isNotDesktopView;
        },
        isQap(result) {
            return isVueQap(result);
        },
        isRegularResult(result) {
            return isRegularSearchResult(result);
        },
        isDrugInfoPanel(result) {
            return result.isPanelResult && result.type === C_QAP.PANEL.DRUG_INFO_PANEL;
        },
        isNotDrugInfoPanel(result) {
            return result.isPanelResult && result.type !== C_QAP.PANEL.DRUG_INFO_PANEL;
        },
        outlineFlyoutIsOpen(searchResultId) {
            return searchResultId === this.outlineFlyoutTopicId
                && this.outlineFlyoutShowOutline;
        },
        showPersonalizationModal(event, tab) {
            new PubSub().publish(C_EVENTS.OPEN_MYUPTODATE_MODAL, { event, tab });
        },
        tryToShowModal() {
            if (this.isAppDataLoaded) {
                this.showPersonalizationModal(null);
            }
            else {
                this.cancelRetryShowModalTimeout
                    = safeTimeout(
                        this.tryToShowModal,
                        RETRY_SHOW_MODAL_DELAY_MS,
                        { cancelHook: true }
                    ).cancelTimeout;
            }
        },
        showModal() {
            const urlHasMyUtd = getQueryParamValues(getWindow().location.href, 'myUpToDate');
            if (urlHasMyUtd === 'true' && this.userLoggedIn) {
                // Handle redirecting to mobile version of MyUpTodate as needed
                if (this.isNotDesktopView) {
                    getWindow().location.href = '/myuptodatemobile';
                    return true;
                }

                this.tryToShowModal();
            }
        },
        setupEventHandlers() {
            if (!this.isAnonymousUser) {
                new PubSub().subscribe(C_EVENTS.HOVER_TOOLTIP_CLICK, setLinkVisitedClass);

                new PubSub().subscribe(
                    'SEARCH_RESULT_SHOW_MORE',
                    this.initSearchResultClickHandlers
                );
            }
            this.scrollListener = this.setScrollListener(getWindow(), this.trackScrollPosition);
        },
        restoreScrollPosition() {
            const scrollTo = new UrlScrollTracker().getPosition();
            if (typeof scrollTo !== 'undefined') {
                getWindow().scrollTo(0, scrollTo);
            }
            new UrlScrollTracker().restoreLastPosition = false;
        },
        trackScrollPosition() {
            // Do not track scroll position until we've checked
            // if we need to restore the last scroll position.
            if (!this.doScrollCheck && !new UrlScrollTracker().restoreLastPosition) {
                new UrlScrollTracker().setPosition();
            }
        },
        initSearchResultClickHandlers() {
            Array.from(document.querySelectorAll('#search-results-container a'))
                .forEach(link => {
                    checkIfLinkVisited(link, this.isAnonymousUser);

                    checkAndSetEventBinding(link, 'search-result-link', 'click', evt => {
                        setLinkVisitedClass(
                            evt.currentTarget,
                            this.isAnonymousUser,
                            this.contentVersion
                        );
                    });
                });
        }
    }
};
</script>

<style lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

#search__container {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.mobile-homepage-search-expanded {
    visibility: hidden;
    height: 80vh;

    #homepage_awarenespanel .item .item-link {
        transition-delay: 0s;
    }
  }

  .isTablet:not(.utdWkHomePage2024) & .wk-logo {
    .ds1-mt-2();
  }
}

#searchresults #search-results-container .hasTranslations.BQP,
#searchresults #search-results-container .hasTranslations.KPP {
  .translate-button {
    display: inline-block;
  }

  &.isLocalLanguage .utd-pendo-feedback {
      display: none;
  }
}

#searchresults #search-results-container a {
  .ds1-utd-link-visited();

  &.visited:hover {
    color: @DS1-UTD-VISITED-LINKS-COLOR;
    border-bottom-color: @DS1-UTD-VISITED-LINKS-COLOR;
  }

  &.utd-thumbnail__container:hover {
    border-bottom-color: @DS1-UTD-ACCOUNT-BUTTON-DISABLED-BG-COLOR;
  }
}
</style>