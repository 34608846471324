<template>
  <div v-show="showWhatsNew" ref="topicWhatsNewContent">
    <div v-for="(whatsNew, index) in topicWhatsNewList"
         :key="index">
      <div :id="whatsNewId(whatsNew)"
           :contentId="whatsNew.contentId"
           class="topicCallout collapsed">
        <p class="bulletIndent1"
           :class="whatsNewScrollTarget(whatsNew)">
          <span class="glyph">&#9679;</span>{{ whatsNew.title }}</p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="whatsNew.content" />
        <p class="readMore" @click="onReadMoreClick($event)">
          <a aria-label="Read More"
             href="#"
             tabindex="0"
             role="button">
            <span class="chevron bottom" />Read more
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { elRemoveClass } from '_acaSrc/utility/DOM';
import { C_TOPIC } from '_acaSrc/utility/constants';
import PubSub from '_acaSrc/utility/PubSub';

const { ANCHOR_HASH_PREFIX, SCROLL_TARGET_SUFFIX } = C_TOPIC;

export default {
    data() {
        return {
            readMores: [],
            elContainer: null
        };
    },
    computed: {
        ...mapGetters('topic', [ 'topicWhatsNewList' ]),
        showWhatsNew() {
            return this.topicWhatsNewList && this.elContainer;
        }
    },
    mounted() {
        this.activate();
        new PubSub().subscribe('wkutd.relink-outline-events', this.activate);
        new PubSub().subscribe('wkutd.render-whats-new-topics-event', this.expandWhatNewOnSearch);
    },
    beforeUnmount() {
        new PubSub().unsubscribe('wkutd.relink-outline-events', this.activate);
        new PubSub().unsubscribe('wkutd.render-whats-new-topics-event', this.expandWhatNewOnSearch);
    },
    methods: {
        activate() {
            this.elContainer = document.getElementById('topicWhatsNewContainer');
            if (!this.elContainer) {
                return;
            }
            this.elContainer.innerHTML = '';
            this.elContainer.appendChild(this.$refs.topicWhatsNewContent);
            this.$nextTick(() => {
                this.disabledHiddenLinks();
            });
        },
        whatsNewId(entry) {
            return `${ANCHOR_HASH_PREFIX}${entry.divId}`;
        },
        whatsNewScrollTarget(entry) {
            return `${ANCHOR_HASH_PREFIX}${entry.divId}${SCROLL_TARGET_SUFFIX}`;
        },
        onReadMoreClick(evt) {
            evt.preventDefault();
            if (evt && evt.currentTarget && evt.currentTarget.parentNode) {
                elRemoveClass(evt.currentTarget.parentNode, 'collapsed');
                this.enableLinks();
            }
        },
        expandWhatNewOnSearch() {
            const elems = this.$el && this.$el.querySelectorAll('.collapsed');
            elems && Array.from(elems).forEach(elem => {
                if (elem.querySelector('.highlighted')) {
                    elRemoveClass(elem, 'collapsed');
                }
            });
        },
        disabledHiddenLinks() {
            const topicWhatsNewContent = this.$refs.topicWhatsNewContent;
            const topicCallouts = topicWhatsNewContent.querySelectorAll('.topicCallout');
            Array.from(topicCallouts).forEach((callout) => {
                const calloutRect = callout.getBoundingClientRect();

                const links = callout.querySelectorAll('a');
                Array.from(links).forEach(link => {
                    const linkRect = link.getBoundingClientRect();

                    const isHidden =
                      linkRect.right > calloutRect.right || 
                      linkRect.bottom > calloutRect.bottom || 
                      linkRect.left < calloutRect.left || 
                      linkRect.top < calloutRect.top; 

                    if (isHidden){
                        link.setAttribute('tabindex', '-1');
                    }

                });
            });
        },
        enableLinks(){
            const links = this.$refs.topicWhatsNewContent.querySelectorAll('a[tabindex="-1"]');
            links.forEach(link => {
                link.removeAttribute('tabindex');
            });
        }
    }
};
</script>
