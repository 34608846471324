<template>
  <a v-if="showBackButton"
     class="toolbar-back-link ds1-utd-js2-link"
     :tabindex="tabIndex"
     aria-label="Go Back"
     @keypress="$event.which === ENTER_KEY && doBack($event)"
     @click="doBack($event)"><!--
    --><span wk-icon="chevron-left" :title="$t('BACK')" /><!--
    --><span id="backToSearchText">{{ $t('BACK') }}</span><!--
--></a>
</template>

<script>
import { C_KEYCODES } from '_acaSrc/utility/constants';

export default {
    props: {
        showBackButton: Boolean,
        tabIndex: {
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            ENTER_KEY: C_KEYCODES.ENTER_KEY
        };
    },
    methods: {
        doBack(event) {
            history.back();
            event.preventDefault();
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

@media (min-width: 768px) {
  .isDesktop {
    .toolbar-back-link {
      color: @DS1-UTD-JS2-LINK-BASE;
      display: inline-block;

      &:hover {
        color: @DS1-UTD-JS2-LINK-HOVER;
        text-decoration: none;
      }
    }
  }
}

.toolbar-back-link {
  .ds1-mr-1();
  .ds1-utd-size-2();
  display: none; // currently not used for mobile
  cursor: pointer;
  .ds1-utd-weight-600();
  padding-right: 1px;
  flex-shrink: 0;
  white-space: nowrap;

  #searchresults & {
    .ds1-mr-3();
    padding: 9px 0;
    position: relative;
    top: 2px;
  }

  .utd-toolbar & {
    margin-right: 20px;
  }

  &:hover {
    text-decoration: none;
  }

  #backToSearchText {
    padding-left: 6px;

    &:hover {
      text-decoration: underline;
    }
  }

  [wk-icon] {
    text-decoration: none;
    color: @DS1-UTD-JS2-LINK-BASE;
  }

  [wk-icon=chevron-left]::before {
    content: '\e910';
  }

  [wk-icon]::before {
    font-family: "wk-icons-open";
  }
}

/*
  Desktop Style Tweaks
  These styles affect only desktop size and up.
*/
@media only screen and (min-width: 768px) {
  .isDesktop .toolbar-back-link {
    display: inline;
  }
}
</style>