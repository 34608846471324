<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="autocomplete ds1-utd-font">
    <ul id="search-listbox"
        class="autocomplete-ul"
        role="listbox"
        aria-labelledby="search-uptodate">
      <template v-for="item in list">
        <li v-if="item.isHeader"
            :id="item.elementId"
            :key="item.label"
            class="autocomplete-li"
            :class="getItemClasses(item)">
          <span :class="item.subClasses"
                v-text="item.label" />
        </li>
        <li v-else-if="item.isClickable"
            :id="item.elementId"
            :key="item.uniqueId"
            class="autocomplete-li needsclick ac-navable"
            :class="getItemClasses(item)"
            role="option"
            :aria-selected="isActive(item)"><!--
          --><span v-if="isPersonalized(item)" class="glyph">&#9679;</span><!--
          --><!-- eslint-disable vue/no-v-html -->
          <a v-utd-new-tab="item.isNewTab" 
             :href="item.href"
             :class="getNewTabClasses(item)" 
             @mousedown="onClicked($event, item)"
             @click="onClicked($event, item)"
             v-html="item.labelHTML || getBoldfacedLabel(item.label)"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import UtdNewTab from '_acaSrc/directives/UtdNewTab.directive';
import { mapGetters } from 'vuex';

export default {
    directives: {
        UtdNewTab
    },
    props: {
        list: Array,
        activeIndex: Number,
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    computed: {
        ...mapGetters('search', [
            'searchParams'
        ]),
        navableItems() {
            return this.list.filter(i => i.type === 'link' || i.type === 'personalized');
        },
        activeItemId() {
            if (!(Array.isArray(this.navableItems)
                && this.activeIndex > -1
                && this.activeIndex < this.navableItems.length)) {
                return -1;
            }
            return this.navableItems[this.activeIndex].uniqueId;
        }
    },
    methods: {
        onClicked(evt, item) {
            item.onClick && item.onClick(evt);
        },
        isActive(item) {
            return item.uniqueId === this.activeItemId;
        },
        getBoldfacedLabel(label) {
            if (!label) {
                return '';
            }
            const autocompleteTerm = this.options?.autoCompleteTerm ||
                                     this.searchParams.autoCompleteTerm;
            if (!autocompleteTerm || !autocompleteTerm.trim()) {
                return label;
            }
            const replaceBadRegex = autocompleteTerm.trim()
                .replace(/\\/g, '')
                .replace(/\[/g, '\\[')
                .replace(/\(/g, '\\(')
                .replace(/\)/g, '\\)')
                .replace(/\+/g, '\\+')
                .replace(/\*/g, '\\*');
            const regExp = new RegExp(`(${replaceBadRegex})`, 'i');
            return label.replace(regExp,
                '<span class="ds1-utd-weight-normal">$1</span>');
        },
        isPersonalized(item) {
            return item.type === 'personalized';
        },
        getItemClasses(item) {
            const returnArray = [ item.classes ];
            if (!item.isHeader) {
                returnArray.push({ active: this.isActive(item) });
            }
            return returnArray;
        },
        getNewTabClasses(item) {
            let classString = item.subClasses || '';
            classString += !this.isPersonalized(item) ? ' ds1-utd-weight-bold' : '';
            return classString;
        }
    }
};
</script>

<style lang="less" scoped>
  @import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';
  @import (reference) '~_acaAssets/global/global.less';

  .autocomplete-ul {
    .reset-ul();
    .ds1-pb-compact();
    background-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border: @DS1-UTD-GRAY-SINGLE-BORDER;
    box-shadow: @DS1-BOX-SHADOW-POPOUT;
  }

  .autocomplete-li {
    .reset-li();
    position: relative;

    .ac-routed-drug-label {
      display: inline-block;
    }

    a {
      .ds1-ph-2();
      .ds1-utd-size-2();
      .ds1-pv-var(9.5px, 9.5px);
      
      // Click area things (we want entire row to be clickable)
      .ds1-mr-0();
      .ds1-ml-0();
      width: 100%;
      box-sizing: border-box;
      display: inline-block;
      cursor: pointer;


      &::before {
        font-size: 15px;
        vertical-align: -15%;
        display: inline-block;
        text-decoration: underline;
      }

      // This hack is for IE. https://stackoverflow.com/a/21902566/752916
      &:hover {
        text-decoration: underline;
      }

      &::before,
      &:hover::before {
        text-decoration: none;
      }
    }
  }

  .wkce-icon-search {
    text-align: left;

    &::before {
      font-weight: normal;
      position: absolute;
      line-height: 0;
      left: 16px;
      top: 22.5px;
    }
  }

  .ac-header {
    .ds1-ph-2();
    .ds1-utd-size-1();
    .ds1-utd-weight-600();
    padding-top: 12px;
    padding-bottom: 2px;
    color: @DS1-UTD-SUB-TEXT-COLOR;
    margin-right: 0;
    margin-left: 0;

    &:not(:first-child) {
      .ds1-mt-compact();
      border-top: 1px solid @DS1-UTD-GRAY-BORDER-COLOR;
    }
  }

  .ac-generic {
    .ds1-utd-size-2();
    .ds1-ph-2();
    padding-top: 10px;
    padding-bottom: 2px;
  }

  .ac-italic {
    font-style: italic;
  }

  .route-link {
    .ds1-pl-4();
  }

  .ac-search-suggestion > a {
    .ds1-pl-5();
    color: @WKCE-GRAY-SHADE2;
  }

  .ac-navable:hover,
  .ac-navable.active {
    background-color: @DS1-UTD-AUTOCOMPLETE-ACTIVE-BG-COLOR;
  }

  .ac-drug-section-result.DRUG_INTERACTION > a::before {
    .ds1-utd-pills();
    .ds1-mr-1();
    position: relative;
    top: -2px;
    background-size: 21px 11px;
    width: 21px;
    height: 11px;
  }

  .ac-drug-section-result.DRUG_SUBSECTION {
    .ds1-pl-4();

    > a::before {
      left: calc(@ds1-spacing-multiplier * 4);
      content: '•';
      color: @WKCE-BLACK;
      position: absolute;
      line-height: 0;
      top: 20.5px;
    }
  }

  .ac-profile-result {
    display: flex;
    align-items: center;

    .glyph {
      .ds1-orange-dot();
      display: none;
      font-size: 12px;
      left: 5.5px;
    }

    &.changed {
      .glyph {
        display: block;
      }
    }

    &[class*="graphic_"] a::before {
      .ds1-mr-1();
      .ds1-utd-image();
      position: relative;
      display: inline-block;
    }

    &.graphic_figure a::before {
      .ds1-utd-figure();
    }

    &.graphic_table a::before {
      .ds1-utd-table();
    }

    &.graphic_movie a::before {
      .ds1-utd-movie();
    }

    &.graphic_algorithm a::before {
      .ds1-utd-algorithm();
    }
  }

  // Specificity shims (remove after searchbar.less is redone)
  .utdWkHeader .utd-search .search-bar-ac-unit .autocomplete ul.utd-listView li.ac-header {
    padding: 12px 16px 6px;
  }

  /* stylelint-disable-next-line order/order */
  @media only screen and (min-width: 768px) {
    .autocomplete-li {
      a {
        .ds1-pv-var(5.5px, 5.5px);
      }
    }

    .ac-drug-section-result.DRUG_SUBSECTION > a::before {
      top: 16.5px;
    }

    .wkce-icon-search::before {
      top: 18.5px;
    }

    .ac-search-suggestion > a {
      .ds1-pl-5();
    }
  }
</style>
