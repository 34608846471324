<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="!isProspectMode" class="home-copyright-disclaimer">
    <span class="home-copyright-text" v-html="getCopyrightText" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { C_SEARCH } from '_acaSrc/utility/constants';

export default {
    name: 'HomeCopyrightDisclaimer',
    computed: {
        ...mapGetters('app', [ 'isUccState', 'isProspectMode' ]),
        getCopyrightText() {
            return this.isUccState
                ? C_SEARCH.HOMEPAGE_COPYRIGHT.UCC : C_SEARCH.HOMEPAGE_COPYRIGHT.DEFAULT;
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.home-copyright-disclaimer {
  /* Pre-homepage-redesign styles */
  color: @WKCE-GRAY-SHADE1;
  font-size: 12px;
  line-height: 16px;
  
  .home-copyright-text {
    .ds1-mt-2();
    .ds1-mb-4();
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 690px;
    display: block;

    :deep(> a) {
      color: #454545; // stylelint-disable-line color-no-hex
      text-decoration: underline;
    }
  }

  &.homepage-redesign {  
    background-color: @WKCE-BLUE-SHADE2;
    color: @WKCE-BLUE-TINT3;
    font-size: 11px;
    line-height: 14px;

    .home-copyright-text {
      .ds1-mv-0();
      .ds1-pv-2();
      max-width: 85vw;
      width: 100%; // Legacy browser backup
      width: fit-content;
  
      :deep(> strong) {
        color: @WKCE-WHITE;
      }
  
      :deep(> a) {
        color: @WKCE-BLUE-TINT3;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-copyright-disclaimer {
    .home-copyright-text {
      width: auto;
      margin: 0 10vw;
    }

    &.homepage-redesign .home-copyright-text {
      .ds1-mv-0();
      .ds1-mh-2();
      width: unset;
      max-width: unset;
    }
  }
}
</style>