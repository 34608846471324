<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="my-history-tab-content-wrapper"
       data-autotest="myuptodateHistory">
    <div class="tabcontent"
         :class="{'disable-content': !showClearHistory && hasHistoryData}">
      <span v-if="history.isLoadingResults"
            class="loading-message"
            v-text="$t('MYUPTODATE.PLEASE_WAIT')" />
      <span v-if="history.error"
            class="error">Error loading history, please try again.</span>
      <template v-if="!history.isLoadingResults && !history.error">
        <ul v-show="hasHistoryData"
            class="my-utd-tab-list">
          <template v-for="(aggregated, i) in filteredHistory" :key="i">
            <li class="historyDates text-box">
              <h3 v-text="getDaysFromToday(aggregated)" />
            </li>
            <li v-for="(item, hIdx) in aggregated.historyItems"
                :key="`${i}-${hIdx}`"
                class="text-box">
              <a :href="generateLink(item)"
                 :class="getTabItemClasses(item)"
                 @keydown="handleKeyPress"
                 @click="handleLinkClick($event, item.contentType)"
                 v-html="item.title" />
            </li>
          </template>
        </ul>
        <div v-if="!filteredHistory.length"
             class="no-content"
             v-text="$t('MYUPTODATE.HISTORY_EMPTY')" />
        <div v-show="showAllHistoryButton" class="all-history-wrapper">
          <utd-button button-size="small"
                      button-color="blue"
                      button-style="ghost"
                      @click="showPersonalizationModal">
            All History
          </utd-button>
        </div>

        <div v-if="view.source !== '_widget'"
             v-show="hasHistoryData"
             id="toolbar"
             :class="{'history-clear-tab': !localHistory.hideClearHistory}" />
      </template>
    </div>

    <div class="clear-history-button-wrapper all-history-wrapper">
      <utd-button v-show="showClearHistory"
                  :id="`myuptodate-clear-history${view.source}`"
                  class="myuptodate-clear-history"
                  button-size="small"
                  button-color="blue"
                  button-style="ghost"
                  @click.prevent="clearHistoryConfirmation($event)"
      > {{ $t('MYUPTODATE.CLEAR_HISTORY') }} </utd-button>
      <div v-show="localHistory.clearHistoryConfirm"
           id="myuptodate-clear-history-confirm">
        <div v-text="$t('MYUPTODATE.HISTORY_CLEAR_CONFIRM')" />
        <div class="myuptodate-clear-history-confirm-btns">
          <utd-button :id="`myuptodate-continue-button${view.source}`"
                      class="clear-all-history-btn"
                      button-size="small"
                      button-color="red"
                      @click="clearHistory($event)">
            {{ $t('MYUPTODATE.CLEAR_ALL') }}
          </utd-button>
          <utd-button :id="`myuptodate-continue-button${view.source}`"
                      button-size="small"
                      button-color="light"
                      @click="clearHistoryCancel($event)">
            {{ $t('MYUPTODATE.CANCEL') }}
          </utd-button>
        </div>
      </div>
    </div>
    <graphic-hover-tooltip v-if="hasHistoryData && !history.isLoadingResults"
                           :hover-selector="graphicHoverTooltipSelector"
                           :off-topic-variant="true" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { C_EVENTS, C_MYUTD_TABS } from '_acaSrc/utility/constants';
import { setQueryParamValue } from '_acaSrc/utility/http';
import PubSub from '_acaSrc/utility/PubSub';
import { CLEAR_HISTORY, CLEAR_MOST_VIEWED } from '_acaSrc/store/profile.store';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';
import { getTabItemClasses } from './myuptodate';
import GraphicHoverTooltip from '_acaSrc/components/contents/topic/GraphicHoverTooltip.vue';

const WIDGET_HISTORY_ROW_LIMIT = 10;

export default {
    components: {
        UtdButton,
        GraphicHoverTooltip
    },
    props: {
        view: {
            type: Object,
            default: () => {
                return {
                    source: ''
                };
            }
        }    
    },
    emits: [ 'disable-tabs' ],
    data() {
        return {
            localHistory: {
                hideClearHistory: true,
                clearHistoryConfirm: false
            },
            scrollPosition: 0,
            allHistoryVisible: false
        };
    },
    computed: {
        ...mapGetters('profile', [
            'history',
            'historyData',
            'historyLoaded'
        ]),
        myUtdTabs() {
            return C_MYUTD_TABS;
        },
        setQueryParamValue() {
            return setQueryParamValue;
        },
        filteredHistory() {
            // Returns conditionally filtered list of historyData rows.
            // Limits to this.view.limit history items if view.source === '_widget'
            // Otherwise returns whatever is in this.historyData
            if (this.view.source !== '_widget' || this.allHistoryVisible) {
                return this.historyData;
            }

            let visible = 0;
            const historyRows = [];
            Array.from(this.historyData).some(rec => {
                if (visible > WIDGET_HISTORY_ROW_LIMIT) {
                    return true;
                }
                historyRows.push({ ...rec });
                const items = historyRows[historyRows.length - 1]
                    .historyItems.filter(() => ++visible <= WIDGET_HISTORY_ROW_LIMIT);
                if (items.length === 0) {
                    historyRows.splice(-1);
                }
                else {
                    historyRows[historyRows.length - 1].historyItems = items;
                }
                return false;
            });

            return historyRows;
        },
        hasHistoryData() {
            return this.historyData
                && this.historyData.length;
        },
        showClearHistory() {
            return !this.localHistory.hideClearHistory
                && this.hasHistoryData;
        },
        showAllHistoryButton() {
            return !this.allHistoryVisible
                && this.hasHistoryData && this.view.source === '_widget'
                && this.historyData.length > WIDGET_HISTORY_ROW_LIMIT;
        },
        graphicHoverTooltipSelector() {
            return `.my-uptodate-wrapper${
                this.view.source} .my-history-tab-content-wrapper a[class*=graphic]`;
        }
    },
    watch: {
        historyLoaded() {
            this.setTabContentScroll();
            this.localHistory.hideClearHistory
                = !this.hasHistoryData || this.localHistory.clearHistoryConfirm;
        },
        localHistory: {
            deep: true,
            handler(data) {
                this.$emit('disable-tabs', data.clearHistoryConfirm);
            }
        }
    },
    beforeUnmount() {
        this.clearScrollListener(this.scrollListener);
    },
    mounted() {
        this.scrollListener = this.setScrollListener(this.$el, this.onScrollTabContent);
    },
    methods: {
        ...mapMutations('profile', [
            CLEAR_HISTORY,
            CLEAR_MOST_VIEWED
        ]),
        ...mapActions('graphic', [
            'handleUseGraphicLink'
        ]),
        ...mapActions('profile', [
            'refreshHistory',
            'deleteHistory'
        ]),
        ...mapActions('app', [ 'publish' ]),
        handleKeyPress(ev) {
            if (this.localHistory.clearHistoryConfirm) {
                return ev.preventDefault();
            }
        },
        getTabItemClasses,
        generateLink(item) {
            return this.localHistory.clearHistoryConfirm ? '#'
                : setQueryParamValue(item.url, 'source', `history${this.view.source}`);
        },
        showPersonalizationModal(event) {
            const historyTab = C_MYUTD_TABS.HISTORY;
            this.publish({
                eventName: 'wkutd.open-myuptodate-modal',
                eventData: { event, historyTab }
            });
        },
        getDaysFromToday(history) {
            if (history.numDaysFromToday === 0) {
                return 'Today';
            }
            else if (history.numDaysFromToday === 1) {
                return 'Yesterday';
            }
            else if (typeof history.numDaysFromToday === 'undefined') {
                const viewed = new Date(history.date);
                const result = viewed.toLocaleDateString(undefined, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });

                return result;
            }
        },
        handleLinkClick(evt, contentType) {
            this.publish({
                eventName: 'wkutd.close-myuptodate-modal',
                eventData: {}
            });

            if (!contentType || contentType.indexOf('graphic') === -1) {
                return;
            }
            this.handleUseGraphicLink({
                evt,
                options: { showAppearsInTopics: true }
            });
        },
        clearHistoryConfirmation(event) {
            this.localHistory.clearHistoryConfirm = true;
            this.localHistory.hideClearHistory = true;

            new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                uiElementName: event.currentTarget.id
            });
            event.stopImmediatePropagation();
        },
        clearHistory(e) {
            this.localHistory.hideClearHistory = true;
            this.localHistory.clearHistoryConfirm = false;
            this[CLEAR_HISTORY]();
            this[CLEAR_MOST_VIEWED]();
            this.deleteHistory();
            e.stopImmediatePropagation();
        },
        clearHistoryCancel() {
            this.localHistory.hideClearHistory = false;
            this.localHistory.clearHistoryConfirm = false;
        },
        setTabContentScroll() {
            if (this.scrollPosition) {
                this.$el.scrollTop = this.scrollPosition;
            }
        },
        onScrollTabContent() {
            if (this.$el.scrollTop) {
                this.scrollPosition = this.$el.scrollTop;
            }
        }
    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.clear-all-history-btn {
  background-color: @WKCE-RED-SHADE1;
}

.myuptodate-clear-history-confirm-btns {
  padding-top: 15px;
}

#my-uptodate-widget .clear-history-button-wrapper {
  display: none;
}

.my-history-tab-content-wrapper {
  position: relative;
  height: 100%;

  .tabcontent {
    .ds1-pt-2();

    #my-uptodate-widget & {
      .ds1-pv-2();
    }

    .myUptodateModal &,
    .isSmallScreen & {
      padding-bottom: 80px;
    }
  }

  #toolbar {
    .ds1-ph-3();
    .ds1-pb-1();

    > a {
      cursor: pointer;
    }
  }

  .my-utd-tab-list {
    > li {
      .ds1-mt-0();

      > a {
        .ds1-pr-2();
      }

      &.historyDates {
        .ds1-mt-2();
        .ds1-pa-0();

        &:first-child {
          .ds1-mt-0();
        }

        > h3 {
          .ds1-ph-2();
          .ds1-utd-size-2();
          padding-bottom: 5px;
          color: @DS1-UTD-SUB-TEXT-COLOR;
          border-bottom: 1px solid @WKCE-GRAY-TINT1;
        }
      }
    }
  }

  .all-history-wrapper {
    .ds1-mt-2();
    display: flex;
    justify-content: center;

    &.clear-history-button-wrapper {
      .ds1-pa-3();
      .ds1-pt-4();
      position: absolute;
      bottom: 0;
      width: calc(100% - 17px);
      box-sizing: border-box;
      z-index: @ZINDEX-MYUTD-EDIT-BUTTON-WRAPPER;
      background: linear-gradient(to bottom, rgba(256, 256, 256, 0) 0%, rgba(256, 256, 256, 1) 36%);
      text-align: center;

      .isSmallScreen & {
        position: fixed;
      }
    }
  }

  #myuptodate-clear-history-confirm {
    .ds1-pv-var(20px, 0);
    .ds1-utd-size-2();

    .utd-button + .utd-button {
      .ds1-ml-1();
    }

    .myuptodate-clear-history-confirm-btns {
      .ds1-mv-1();
      display: flex;
      justify-content: center;
    }
  }

  .history-clear-tab {
    .ds1-utd-size-1();
    text-align: right;
  }
}

.my-utd-history-mixin {
  .my-history-tab-content-wrapper {
    .tabcontent {
      padding-bottom: 80px;
    }

    .my-utd-tab-list {
      > li {
        > a {
          .ds1-pr-3();
        }

        &.historyDates {
          .ds1-ph-2();

          > h3 {
            .ds1-pa-0();
            .ds1-utd-size-1();
            padding-bottom: 2px;
            border-bottom: @DS1-UTD-GRAY-SINGLE-BORDER;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
  .isTablet {
    .my-utd-history-mixin();
  }
}

@media only screen and (min-width: 768px) {
  .isDesktop {
    .my-utd-history-mixin();
  }
}

.disable-content {
  touch-action: none;
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  z-index: -1;
}
</style>
