<template>
  <div v-show="isDesktopView"
       id="subscribeInterrupt"
       ref="subscribeInterrupt"
       :class="bannerClass">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="headerMessage" class="subscribe_header_message" v-html="headerMessage" />
    <div v-if="isUccState && headerMessage2" v-text="headerMessage2" />
    <div v-if="!isUccState" class="subscribe-interrupt-login-register-section">
      <div class="login-register-section-item">
        <utd-button :button-color="secondButtonColor"
                    :button-link="firstButtonAnchor"
                    button-size="large"
                    button-type="link"
                    class="link-button-1"
                    :class="{'ucc-button': isUccState}"
                    track="enabled">
          <img v-if="isUccState" src="/topic/hospital.png">
          {{ firstButtonText }}</utd-button>
      </div>
      <div class="login-register-section-item">
        <utd-button button-color="blue"
                    :button-link="secondButtonAnchor"
                    button-size="large"
                    button-type="link"
                    class="link-button-2"
                    :class="{'ucc-button': isUccState}"
                    track="enabled">
          <img v-if="isUccState" src="/topic/doctor.png">
          {{ secondButtonText }}</utd-button>
      </div>
    </div>
    <div v-else class="subscribe-interrupt-login-register-section">
      <div class="login-register-section-item">
        <utd-button id="wk-first-button "
                    :button-color="secondButtonColor"
                    :button-link="firstButtonAnchor"
                    button-size="large"
                    button-type="link"
                    button-icon="medical"
                    class="wk-first-button"
                    :class="{'ucc-button': isUccState}"
                    track="enabled">

          <span v-text="firstButtonText" />
        </utd-button>
      </div>
      <div class="login-register-section-item">
        <utd-button id="wk-second-button"
                    :button-link="secondButtonAnchor"
                    button-size="large"
                    button-type="link"
                    button-icon="user"
                    class="wk-second-button"
                    :class="{'ucc-button': isUccState}"
                    track="enabled">
          <span class="ucc-button-text" v-text="secondButtonText" />
        </utd-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import {
    SET_TOOLBAR_FINDINTOPIC_VISIBLE,
    SET_TOPIC_TOOLS_SAMPLE_TOPIC
} from '_acaSrc/store/topic/topic.store';
import { getDocument } from '_acaSrc/utility/DOM';
import UtdButton from '_acaSrc/components/shared/stdlib/UtdButton.vue';

export default {
    components: { UtdButton },
    computed: {
        ...mapGetters('topic', [ 'topicLoaded', 'isTopicCalculator' ]),
        ...mapGetters('app', [
            'isProspectMode',
            'isUccState'
        ]),
        ...mapGetters('device', [ 'isDesktopView' ]),
        ...mapGetters('auth', [ 'storeUrl' ]),
        headerMessage() {
            return this.isUccState
                ? this.$t('SUBSCRIBE_INTERRUPT.HEADER_MESSAGE_UCC')
                : this.$t('SUBSCRIBE_INTERRUPT.HEADER_MESSAGE');
        },
        firstButtonText() {
            return this.isUccState
                ? this.$t('SUBSCRIBE_INTERRUPT.INSTITUTIONAL_USER') : this.$t('HEADER.SUBSCRIBE');
        },
        secondButtonText() {
            return this.isUccState
                ? this.$t('SUBSCRIBE_INTERRUPT.PERSONAL_USER') : this.$t('MISC.LOG_IN');
        },
        secondButtonClass() {
            return this.isUccState ? 'wk-blue' : 'wk-green';
        },
        secondButtonColor() {
            return 'green-shade-3';
        },
        bannerClass() {
            return this.isUccState ? 'ucc-subscribe-message-banner' : 'subscribe-message-banner';
        },
        firstButtonAnchor() {
            return this.isUccState
                ? 'http://www.uptodate.cn/home/Institutional-Subscribers?SourceCode=TopicPreview'
                : this.storeUrl;
        },
        secondButtonAnchor() {
            return this.isUccState
                ? 'https://uptodatechina.secure.force.com/'
                + 'IndividualSubscribers?SourceCode=TopicPreview'
                : this.$t('SUBSCRIBE_INTERRUPT.LOGIN_LINK');
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.seekReplaceMessage();
        });
    },
    methods: {
        ...mapMutations('topic', [
            SET_TOOLBAR_FINDINTOPIC_VISIBLE,
            SET_TOPIC_TOOLS_SAMPLE_TOPIC
        ]),
        seekReplaceMessage() {
            if (!this.topicLoaded || !this.isProspectMode) {
                return;
            }

            const subMsg = getDocument().getElementById('subscribeMessage');
            if (!subMsg) {
                // If we cannot find the #subscribeMessage element, and this is
                // not for a topic calculator, as we know we're in prospect mode,
                // then this must be a sample topic.
                if (!this.isTopicCalculator) {
                    this[SET_TOOLBAR_FINDINTOPIC_VISIBLE](true);
                    this[SET_TOPIC_TOOLS_SAMPLE_TOPIC](true);
                }
                return;
            }
            else if (!this.isDesktopView) {
                // If we made it here, then this is not a sample topic, and we should display
                // the subscriber interrupt markup as it is returned from the server.
                return;
            }

            subMsg.parentNode.replaceChild(this.$refs.subscribeInterrupt, subMsg);
        }
    }
};
</script>

<style lang="less">
@import (reference) "~_acaAssets/wkce/colors/wkce-app-styles";
@import (reference) '~_acaAssets/utd-button/utd-button';

@DS1_BUTTON_HOVER_COLOR: @WKCE-ORANGE;

.subscribe-message-banner,
.ucc-subscribe-message-banner {
  text-align: center !important;
  border: 1px solid @DS1-UTD-DISABLED-TEXT-COLOR !important;
  border-radius: 0 !important;
  margin: 20px 0 !important;
  background: @DS1-LIGHT-ON-DARK-FONT-COLOR !important;
  padding: 0 !important;
  font-size: 1rem;
  filter: none !important;

  div {
    padding: 24px;
    line-height: 24px;

    &:first-child,
    &:nth-child(2) {
      font-weight: bold;
    }

    &:nth-child(2) {
      padding-top: 0;
    }

    a {
      text-decoration: none !important;
    }
  }

  .wk-navbar {
    z-index: @ZINDEX-SUBSCRIBE-MESSAGE;
    display: inline-block;
    background-color: @DS1-LIGHT-ON-DARK-FONT-COLOR;
    padding-top: 0;

    ul {
      li {
        height: 48px;

        a {
          text-decoration: none !important;
          font-weight: 400;
        }
      }
    }
  }
}

.ucc-subscribe-message-banner {
  background-color: rgba(242, 242, 242, 1) !important;
  border: none !important;

  .loginLink {
    /* stylelint-disable-next-line color-no-hex */
    color: #3c0;
  }

  .wk-navbar {
    background-color: rgba(242, 242, 242, 1);

    li {
      width: 180px !important;
      padding-right: 0 !important;

      a {
        border-radius: 5px;

        img {
          height: 40px;
          margin: -10px 0 -15px -10px;
          float: left;
        }
      }

      &:first-child {
        margin-right: 35px !important;
      }
    }

    .wk-orange {
      a:hover {
        background-color: @DS1_BUTTON_HOVER_COLOR !important;
      }
    }

    .wk-blue {
      a {
        /* stylelint-disable-next-line color-no-hex */
        background-color: #0083cb;

        &:hover {
          /* stylelint-disable-next-line color-no-hex */
          background-color: #0083cb !important;
        }
      }
    }
  }

  .wk-first-button,
  .wk-second-button {
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: start;
    height: auto;

    .utd-button-link-text {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-size: 15px;
      line-height: 24px;

      .zh-Hans & {
        text-align: left;
      }
    }

    .zh-Hans & .utd-button-icon {
      .ds1-mr-2();
      font-size: 24px;
    }
  }

  #wk-first-button, 
  #wk-second-button{
    min-width: 180px;
  }
}

#subscribeInterrupt .subscribe-interrupt-login-register-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  gap: 24px;

  .login-register-section-item {
    .ds1-pa-0();
    flex: 1 0 auto;
    text-align: center;
    width: 120px;

    &:nth-child(odd) {
      text-align: right;
    }

    &:nth-child(even) {
      text-align: left;
    }

    .utd-button.utd-button-link {
      font-weight: 500;
      font-size: 15px;
      min-width: 120px;
      color: @WKCE-WHITE;
    }
  }

  .ucc-subscribe-message-banner& {
    gap: 42px;

    .login-register-section-item .utd-button.utd-button-link {
      min-width: 180px;
    }
  }
}
</style>
