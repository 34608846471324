<template>
  <div class="graphic-thumbnails ds1-mb-2"
       :class="{ 'utd-rtl': thumbnailsRightToLeftOrder }">
    <div v-for="(graphic, gfxIdx) in thumbnails"
         :key="gfxIdx"
         class="graphic-thumbnail__container"
         :class="{ 'selected': graphic.imageKey === graphicViewerImageKey}">
      <utd-thumbnail :id="imageKeyToId(graphic.imageKey)"
                     class="graphic-thumbnail"
                     :graphic="graphic"
                     :selected="graphic.imageKey === graphicViewerImageKey"
                     :tab-index="isVisible ? 0 : -1"
                     :has-border="true"
                     @utd-thumbnail-click="updateGraphic(graphic, $event)" />
    </div>
  </div>
</template>
<script>
import UtdThumbnail from '_acaSrc/components/shared/stdlib/UtdThumbnail.vue';
import { C_EVENTS } from '_acaSrc/utility/constants';
import { setQueryParamValue } from '_acaSrc/utility/http';
import { mapGetters, mapActions } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';
import { imageKeyToId } from '_acaSrc/utility/contents/graphic/graphic';

export default {
    components: {
        UtdThumbnail
    },
    mixins: [{
        methods: { imageKeyToId }
    }],
    props: {
        isVisible: {
            type: Boolean,
            default: true
        }
    },
    emits: [ 'selectedItemId' ],
    data() {
        return {
            thumbnails: [],
            thumbnailsRightToLeftOrder: false,
            selectedThumbnailId: null
        };
    },
    computed: {
        ...mapGetters('graphic', [
            'graphicViewerGraphics',
            'graphicViewerCollection',
            'graphicViewerImageKey',
            'graphicViewerImageUrl',
            'graphicViewerLanguage'
        ])
    },
    mounted() {
        this.thumbnails = this.graphicViewerGraphics;
        if (this.graphicViewerCollection.length > 0) {
            this.thumbnails = this.graphicViewerCollection;
        }

        if (this.graphicViewerLanguage === 'ar') {
            this.thumbnailsRightToLeftOrder = true;
        }
    },
    methods: {
        ...mapActions('graphic', [ 'launchGraphicViewerModal' ]),
        focusThumbnail() {
            if(!this.selectedThumbnailId) {
                return;
            }

            const thumbnailSelected = document.getElementById(this.selectedThumbnailId);

            if (thumbnailSelected) {
                thumbnailSelected.focus();
            }

            this.$emit('selectedItemId', this.selectedThumbnailId);
        },
        updateGraphic(graphic, event) {
            if(event) {
                event.preventDefault();
            }

            // Ignore if thumbnail is already selected
            if (graphic.imageKey === this.graphicViewerImageKey) {
                return;
            }

            this.selectedThumbnailId = this.imageKeyToId(graphic.imageKey);
            let url = setQueryParamValue(this.graphicViewerImageUrl, 'imageKey', graphic.imageKey);
            url = setQueryParamValue(url, 'source', 'GraphicModalSidebar');
            url = setQueryParamValue(url, 'tabTitle');
            url = setQueryParamValue(url, 'accordionTitle');
            this.launchGraphicViewerModal({
                skipSidebarStateReset: true,
                imageKey: graphic.imageKey,
                imageUrl: url,
                isFromCollection: this.graphicViewerCollection.length > 0
            }).then(() => {
                new PubSub().publish(C_EVENTS.TRACK_UI_CLICK_EVENT, {
                    targetUrl: url,
                    uiElementName: 'GraphicModalSidebar',
                    contentId: graphic.imageKey
                });
            });
            this.focusThumbnail(graphic.imageKey);
        }

    }
};
</script>

<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.graphic-thumbnail__container {
  border: 1px solid transparent;
  display: inline-block;
  margin: 14px 0 0 16px;

  &.selected {
    border: 1px solid @DS1-SELECTED-BORDER-COLOR;
  }

  .utd-rtl & {
    margin: 16px 16px 0 0;
  }
}

:deep(.utd-thumbnail__container) {
  margin: 0;

  .selected & {
    border: 1px solid @DS1-SELECTED-BORDER-COLOR;
  }
}
</style>
