<template>
  <div class="wk-js2 utd-dropdown-panel"
       :class="modalClasses"
       aria-labelledby="dropdownPanelAnchor"
       tabindex="-1"
       @keyup.enter="handleMouseEnter"
       @mouseenter="handleMouseEnter"
       @mouseleave="handleMouseLeave"
       @click="handleMouseEnter"
       @keyup.escape="handleEscape">
    <a id="dropdownPanelAnchor"
       ref="dropdownPanelAnchor"
       class="utd-dropdown-panel-anchor"
       :class="isOpen ? 'utd-dropdown-panel-anchor-open' : ''"
       :aria-expanded="isOpen"
       role="button"
       href="#">
      {{ anchorText }}
    </a>
    <div v-if="isOpen" ref="dropdownPanelContent" class="utd-dropdown-panel-content">
      <slot />
    </div>
  </div>
</template> 
<script>

const PANEL_HOVER_DELAY_MS = 250;

export default {
    props: {
        modalClasses: {
            type: String,
            default: ''
        },
        anchorText: {
            type: String,
            default: ''
        },
        panelId: {
            type: [ Number, String ],
            default: null
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        allowEscape: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            cancelTimeout: null
        };
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.adjustPanelPosition();
                });
            }
        }
    },
    methods: {
        handleMouseEnter() {
            clearTimeout(this.cancelTimeout);
            this.cancelTimeout = setTimeout(() => {
                this.$refs.dropdownPanelAnchor.focus({ focusVisible: false });
                this.$emit('panel-opened', { id: this.panelId });
            }, PANEL_HOVER_DELAY_MS);
        },
        handleMouseLeave() {
            clearTimeout(this.cancelTimeout);
            this.cancelTimeout = setTimeout(() => {
                this.$refs.dropdownPanelAnchor.blur();
                this.$emit('panel-closed', { id: this.panelId });
            }, PANEL_HOVER_DELAY_MS);
        },
        handleEscape() {
            if (this.allowEscape) {
                this.handleMouseLeave();
            }
        },
        adjustPanelPosition() {
            const dropdown = this.$refs.dropdownPanelContent;
            if (!dropdown) {
                return;
            }

            const rect = dropdown.getBoundingClientRect();
            const buffer = 50;

            if (rect.right + buffer > window.innerWidth) {
                this.$refs.dropdownPanelContent.style.left = `${window.innerWidth - rect.right}px`;
                this.alignArrow();
            }

            if (rect.left < 0) {
                this.$refs.dropdownPanelContent.style.left = '0';
                this.alignArrow();
            }
        },
        alignArrow() {
            const panel = this.$refs.dropdownPanelContent;
            const anchor = this.$refs.dropdownPanelAnchor;

            if (!panel || !anchor) {
                return;
            }

            const panelRect = panel.getBoundingClientRect();
            const anchorRect = anchor.getBoundingClientRect();

            const arrowLeft = anchorRect.left + anchorRect.width / 2 - panelRect.left;
            panel.style.setProperty('--arrow-left', `${arrowLeft}px`);
        }
    }
};
</script>
  
<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utd-dropdown-panel-content {
  .ds1-mt-2();
  border: @DS1-UTD-GRAY-SINGLE-BORDER;
  background-color: @DS1-UTD-PRIMARY-BG-COLOR;
  position: absolute;
  height: 100%;
  width: 700px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: @DS1-BOX-SHADOW-OUTLINE;

  &::before, &::after {
    content: '';
    position: absolute;
    border: solid transparent;
    left: var(--arrow-left, 50%);
    transform: translate(-50%, -100%);
  }

  &::before {
    border-bottom-color: @DS1-UTD-GRAY-BORDER-COLOR;
    border-width: 16px;
  }

  &::after {
    border-bottom-color: @DS1-UTD-PRIMARY-BG-COLOR;
    border-width: 15px;
    top: 0;
  }
}

.utd-dropdown-panel-anchor {
  font-size: 14px;
  line-height: 24px;
  color: @WKCE-WHITE;

  &:hover, &.utd-dropdown-panel-anchor-open {
    text-decoration: underline;
    outline: none;
  }
}

</style>