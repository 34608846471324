<template>
  <div class="home-wn-pcu-panel">
    <navigation-skip-links :show-quick-links="!isMobileOnDesktop" />
    <home-search-left-panel class="pcu-panel"
                            :title="$t('HEADER.PRACTICE_CHANGING_UPDATES')"
                            anchor-id="homepage_pcupanel_viewall"
                            :panel-button="{
                              url: '/contents/practice-changing-updates',
                              isVisible: true }">
      <div v-if="status.pcu.isLoading"
           class="home-panel-loading">
        <span class="wkce-icon-filled-spinner-alt wk-spin" />
      </div>
      <div v-if="status.pcu.isError"
           class="home-panel-error wkce-icon-filled-caution"
           role="alert">
        Content could not be loaded
      </div>
      <div v-if="status.pcu.isEmpty" class="home-panel-empty">
        No recent Practice Changing UpDates
      </div>
      <ul v-else
          class="panel-content"
          role="list" 
          aria-labelledby="pcu-panel"
          aria-live="polite">
        <li v-for="(item, index) in panelContent.pcuPanelInfo"
            :key="index"
            class="home-panel-list-item"
            role="listitem">
          <span class="home-panel-category">{{ item.category }}:</span>
          <a id="homepage_pcupanel"
             class="home-panel-link"
             :href="item.url"
             :aria-label="`${item.category} ${item.title}`">{{ item.title }}</a>
          <span class="home-panel-date">({{ formatDate(item.date) }})</span>
        </li>
      </ul>
    </home-search-left-panel>
    <home-search-left-panel class="wn-panel"
                            :title="$t('HEADER.WHATS_NEW')"
                            anchor-id="homepage_whatsnewpanel_viewall"
                            dropdown-ui-element-name="whatsnewpanel_dropdown"
                            :panel-button="{
                              url: '/contents/table-of-contents/whats-new',
                              isVisible: true }" 
                            :panel-filter="{
                              isVisible: true,
                              options: panelContent.specialtyList,
                              selected: ''
                            }"
                            @panel-filter-change="handlePanelFilterChange">
      <div v-if="status.wn.isLoading"
           class="home-panel-loading">
        <span class="wkce-icon-filled-spinner-alt wk-spin" />
      </div>
      <div v-if="status.wn.isError"
           class="home-panel-error wkce-icon-filled-caution"
           role="alert">
        Content could not be loaded
      </div>
      <span v-if="status.wn.isEmpty" class="home-panel-empty">
        No recent What's New in this specialty
      </span>
      <ul v-else
          class="panel-content"
          role="list"
          aria-labelledby="wn-panel"
          aria-live="polite">
        <li v-for="(item, index) in panelContent.wnPanelInfo"
            :key="index"
            class="home-panel-list-item"
            role="listitem">
          <span class="home-panel-category">{{ item.category }}:</span>
          <a class="home-panel-link"
             :href="item.url"
             :aria-label="`${item.category} ${item.title}`"
             @click="logUiClickEvent('homepage_whatsnewpanel', item.url)">{{ item.title }}</a>
          <span class="home-panel-date">({{ formatDate(item.date) }})</span>
        </li>
      </ul>                     
    </home-search-left-panel>
  </div>
</template>

<script>
import HomeSearchLeftPanel from '_acaSrc/components/homePageRedesign/HomeSearchLeftPanel.vue';
import {mapGetters, mapActions} from 'vuex';
import { logUiClickEventHelper } from '_acaSrc/utility/Events';
import NavigationSkipLinks from '../header/NavigationSkipLinks.vue';

export default {
    name: 'HomeWnPcuPanel',
    components: {
        HomeSearchLeftPanel,
        NavigationSkipLinks
    },
    data() {
        return {
            panelContent: {
                pcuPanelInfo: {},
                wnPanelInfo: {},
                specialtyList: []
            },
            selectedSpecialty: '',
            status: {
                pcu: {
                    isLoading: false,
                    isError: false,
                    isEmpty: false
                },
                wn: {
                    isLoading: false,
                    isError: false,
                    isEmpty: false
                }
            },
            fetchTimeout: null
        };
    },
    computed: {
        ...mapGetters('feature', [ 'isHeaderRedesign', 'isWnPcuPanelEnabled' ]),
        ...mapGetters('app', [ 'isUccState', 'isProspectView' ]),
        ...mapGetters('topic', [ 'wnPcuPanelInfo' ]),
        ...mapGetters('device', [ 'isMobileOnDesktop' ])
    },
    watch: {
        wnPcuPanelInfo: {
            handler() {
                this.panelContent = { ...this.panelContent, ...this.wnPcuPanelInfo };
                clearTimeout(this.fetchTimeout);
            },
            deep: true
        }
    },
    async mounted() {
        if (this.isWnPcuPanelEnabled &&
            (this.isInfoEmpty(this.wnPcuPanelInfo?.pcuPanelInfo) || 
            this.isInfoEmpty(this.wnPcuPanelInfo?.wnPanelInfo))) {
            await this.fetchWnPcuPanelContent({ infoType: 'pcu' });
        }
    },
    methods: {
        ...mapActions('topic', [ 'fetchWnPcuPanelInfo' ]),
        formatDate(date) {
            if (!date) {
                return '';
            }

            return new Date(date).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric'
            });
        },
        isInfoEmpty(obj) {
            return !obj || Object.keys(obj).length === 0;
        },
        async fetchWnPcuPanelContent(payload = {}) {
            const { infoType } = payload;
            this.fetchTimeout = setTimeout(() => {
                this.status[infoType].isError = false;
                this.status[infoType].isLoading = true;
            }, 2000);
            try {
                this.status[infoType].isEmpty = false;
                await this.fetchWnPcuPanelInfo(payload);
                if (this.isInfoEmpty(this.wnPcuPanelInfo[`${infoType}PanelInfo`])) {
                    this.status[infoType].isEmpty = true;
                }
                this.status[infoType].isError = false;
            }
            catch {
                this.status[infoType].isError = true;
            }
            finally {
                clearTimeout(this.fetchTimeout);
                this.status[infoType].isLoading = false;
            }
        },
        async handlePanelFilterChange(selected) {
            this.panelContent.wnPanelInfo = {};
            await this.fetchWnPcuPanelContent({
                infoType: 'wn',
                specialtyItem: this.panelContent.specialtyList[selected]?.name
            });
        },
        logUiClickEvent(element, url) {
            logUiClickEventHelper({
                uiElementName: element,
                targetUrl: url
            });
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.home-panel-error,
.home-panel-empty {
  .ds1-mb-2();
  font-size: 14px;
  font-style: italic;
}

.home-panel-error {
    color: @DS1-UTD-ERROR;

    &::before {
        .ds1-mr-compact();
        position: relative;
        top: 2px;
        font-style: normal;
    }
}

.home-panel-empty {
  color: @DS1-UTD-SUB-TEXT-COLOR;
}

.home-panel-loading {
  .ds1-mb-2();
  display: flex;
  justify-content: center;
}

.home-wn-pcu-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.isWnPcuPanelEnabled & {
  .wn-panel {
    flex-grow: 1;

    :deep(.home-panel-content) {
      .ds1-mb-1();
    }
  }

  .home-panel-category {
    font-size: 12px;
    color: @WKCE-GRAY;
    line-height: 21px;
    font-weight: 500;
  }

  .home-panel-link {
    color: @DS2-UTD-TOPIC-LINK-COLOR;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .home-panel-date {
    color: @DS1-UTD-SUB-TEXT-COLOR;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .home-panel-list-item {
    .ds1-mb-2();
  }

  .panel-content {
    .ds1-pl-0();
    .ds1-mt-0();
    .ds1-mb-0();
    list-style-type: none;
  }
}

</style>