<script>
import { C_TOPIC } from '_acaSrc/utility/constants';
import { safeTimeout } from '_acaSrc/utility/timers';
import { Listener } from '_acaSrc/utility/Events';
import {
    isGraphicRef,
    getTopicOutlineMap,
    getTopicArticleHeadings
} from '_acaSrc/utility/contents/topic/topic';
import {
    SET_URL_SCROLL_POSITION,
    SET_NEAREST_OUTLINE_HEADING_ID
} from '_acaSrc/store/topic/topic.store';
import { getUrlHash } from '_acaSrc/utility/http';
import {
    elAddClass,
    elRemoveClass,
    getWindow,
    checkAndSetEventBinding,
    getDocument
} from '_acaSrc/utility/DOM';
import { ScrollTarget } from '_acaSrc/utility/contents/topic/ScrollTarget';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import PubSub from '_acaSrc/utility/PubSub';

const { ANCHOR_HASH_PREFIX } = C_TOPIC;

const TOM_HIGHLIGHT_CLICK_LOCK_TIME_MS = 1000;
const TOM_HIGHLIGHT_CLASS = 'in-view';
const TOM_HIGHLIGHT_THRESHOLD_PX = 230;
const TOM_OUTLINE_SELECTOR = '#topicOutline';
const TOM_SCROLL_POS_OFFSET_PX = 45;
const TOM_FIX_TOP_OFFSET_PX = 110;
const TOM_SCROLL_TIMEOUT = 50;

export default {
    data() {
        return {
            outlineMap: {},
            topicHeadings: [],
            highlightLocationHash: false,
            outlineEl: null,
            firstRun: true,
            lastClickTime: -1,
            previousElement: null,
            onDestroyFns: [],
            scrollTimer: null
        };
    },
    computed: {
        ...mapGetters('app', [
            'isDesktopBrowser',
            'isProspectView'
        ]),
        ...mapGetters('topic', [
            'topicLoaded',
            'getUrlScrollIndex',
            'isTabbedContent',
            'findInTopicIsActive'
        ]),
        ...mapGetters('graphic', [ 
            'graphicViewerIsOrWasLastVisibleMsAgo',
            'graphicTriggerElement', 
            'graphicViewerVisible' 
        ]),
        ...mapGetters('device', [ 'isDesktopView' ])
    },
    watch: {
        graphicViewerVisible(newval){
            if(!newval){
                this.$nextTick(this.setFocusOnTrigger);
            }
        }  
    },
    mounted() {
        this.$nextTick(() => this.initializeManager());
    },
    beforeUnmount() {
        this.clearListener(this.windowScrollListener);
        clearTimeout(this.scrollTimer);

        this.onDestroyFns.forEach(fn => fn());
    },
    methods: {
        ...mapActions('topic', [
            'logTopicSectionViewEvent',
            'hideOutline',
            'processScrollRequest',
            'showRelatedGraphicsModal'
        ]),
        ...mapGetters('device', [ 'isMobileOnDesktop' ]),
        ...mapMutations('topic', [ SET_URL_SCROLL_POSITION, SET_NEAREST_OUTLINE_HEADING_ID ]),
        async initializeManager() {
            this.linkOutlineEvents();

            new PubSub().subscribe('wkutd.relink-outline-events', this.linkOutlineEvents);
            this.onDestroyFns.push(() => {
                new PubSub().unsubscribe('wkutd.relink-outline-events', this.linkOutlineEvents);
            });

            if (this.isDesktopView && !this.isProspectView) {
                this.outlineEl = document.querySelector(TOM_OUTLINE_SELECTOR);
                this.setupOutlineSectionHighlightingEvents();
            }
        },
        linkOutlineEvents() {
            this.$nextTick(() => {
                this.setupOutlineSectionViewLogging();
                this.setupSeeLinkSectionViewLogging();
                this.setupOutlineGraphicsLinks();
                this.setupOutlineRelatedPathways();
            });
        },
        setupOutlineSectionViewLogging() {
            Array.from(document.querySelectorAll('#outlineSections li a')).forEach(link => {
                checkAndSetEventBinding(link, 'outLine-section-view-event',
                    'click', this.onOutlineSectionViewEvent);
            });
        },
        setupSeeLinkSectionViewLogging() {
            const elemsCollection
                = document.querySelectorAll('#topicArticle #topicText a.local, #disclaimerLink a');
            Array.from(elemsCollection).forEach(el => {
                checkAndSetEventBinding(el, 'see-link-view-event',
                    'click', this.onSeeLinkViewEvent);
            });
        },
        setupOutlineGraphicsLinks() {
            const eViewAllLink = document.getElementById('viewAllGraphicsLink');
            if (eViewAllLink) {
                checkAndSetEventBinding(
                    eViewAllLink, 'view-all-gfx-btn', 'click', this.onViewAllRelatedGraphics);
            }

            if (this.isDesktopBrowser) {
                const relatedGraphicLinkEls = document.querySelectorAll('.openRelatedGraphics a');
                for (let i = 0; i < relatedGraphicLinkEls.length; i++) {
                    const relatedGraphicLinkEl = relatedGraphicLinkEls[i];
                    checkAndSetEventBinding(relatedGraphicLinkEl, 'view-related-gfx-btn',
                        'click', this.onViewRelatedGraphic);
                }
            }
        },
        setupOutlineRelatedPathways() {
            const pathwaysLinks = getDocument().querySelectorAll('#outlinePathways a');
            Array.from(pathwaysLinks).forEach(elem => {
                checkAndSetEventBinding(elem, 'pathway-onclick',
                    'click', this.onRelatedPathway);
            });
        },
        onOutlineSectionViewEvent(evt) {
            let logHash = '';

            // Check if the parent LI has a class of 'sr-button' as we need to
            // log the anchor differently
            if (evt.target.nodeName === 'SPAN'
                && (evt.target.parentNode.parentNode.classList.contains('sr-button'))
                   || (evt.target.parentNode.parentNode.classList.contains('topicChanged'))) {
                logHash = evt.target.childNodes[0].data.toLowerCase();
            }
            else {
                const hashVal = getUrlHash(evt.target.hash);
                if (hashVal) {
                    logHash = hashVal;
                }
            }

            if (logHash !== '') {
                this.logTopicSectionViewEvent({ hashVal: logHash, source: 'outline_link' });
            }

            // Destroy any prior scrollTop stored for this hash
            const urlIndex = this.getUrlScrollIndex(getUrlHash(evt.target.hash));

            this[SET_URL_SCROLL_POSITION]({ urlIndex, position: undefined });
            this.hideOutline();

            this.highlightElement(evt.currentTarget, true);
        },
        onSeeLinkViewEvent(evt) {
            const hashVal = getUrlHash(evt.target.hash);
            if (hashVal) {
                this.logTopicSectionViewEvent({ hashVal, source: 'see_link' });
            }
        },
        onViewRelatedGraphic(evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if (this.isMobileOnDesktop) {
                return;
            }

            const objLink = evt.target;
            const parentDiv = objLink.parentNode;
            const parentDivId = parentDiv.attributes.id.value;
            if (parentDivId.indexOf('|') >= 0) {
                this.showRelatedGraphicsModal({ graphicType: parentDivId.split('|')[1] });
            }
        },
        onViewAllRelatedGraphics(evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if (this.isTabbedContent) {
                new PubSub().publish('wkutd.switch-topic-tab', {
                    event: evt,
                    activeTab: C_TOPIC.GRAPHIC_TAB_INDEX
                });
            }
            else {
                this.showRelatedGraphicsModal({});
            }
        },
        onRelatedPathway(e) {
            if (!e || !e.currentTarget) {
                return;
            }
            e.currentTarget.setAttribute('disabled', true);
        },
        onTopicScroll(e) {
            // Prevents the topic outline from changing position when
            // the user is viewing a graphic, or has just closed the
            // graphic viewer dialog.
            if (this.graphicViewerIsOrWasLastVisibleMsAgo(500)) {
                return;
            }

            // We assume all elements with ids in #topicText have an outline item
            // associated with them with the same id.
            if (e && e.target.id === 'topicOutline') {
                // Ignore topic outline scrolling (IE specific)
                return;
            }

            if (this.topicLoaded && !this.topicHeadings.length) {
                this.topicHeadings = getTopicArticleHeadings();
            }

            if (!this.topicHeadings.length) {
                return;
            }

            this.updateOutlineSectionHighlight();
            this.firstRun = false;
        },
        resetScroll(e, options) {
            this.topicHeadings = [];
            this.firstRun = true;
            this.highlightLocationHash = options && options.highlightHash;
            safeTimeout(this.onTopicScroll);
        },
        async setupOutlineSectionHighlightingEvents() {
            this.windowScrollListener = new Listener(getWindow(), 'scroll',
                this.onTopicScroll, { passive: true });
            this.setListener(this.windowScrollListener);
            new PubSub().subscribe('topic:font-size-changed', this.scrollToHighlightedElement);
            new PubSub().subscribe('wkutd.relink-outline-events', this.resetScroll);
            new PubSub().subscribe('ui-event: whatsNewsReady', this.resetScroll);

            this.onDestroyFns.push(() => {
                new PubSub().unsubscribe('topic:font-size-changed', this.scrollToHighlightedElement);
                new PubSub().unsubscribe('wkutd.relink-outline-events', this.resetScroll);
                new PubSub().unsubscribe('ui-event: whatsNewsReady', this.resetScroll);
            });
        },
        // eslint-disable-next-line complexity
        updateOutlineSectionHighlight() {
            let outlineHeadingId;

            // If the index is empty, the content is probably not loaded yet.
            this.firstRun = this.firstRun ? Object.keys(this.outlineMap).length === 0 : false;
            if (this.firstRun) {
                this.outlineMap = getTopicOutlineMap();
            }

            // The first time this is run, we need to guarantee that we
            // highlight the outline element that corresponds to the url hash
            const activeHash = getUrlHash();
            if ((this.firstRun || this.highlightLocationHash)
                && activeHash
                && !isGraphicRef(activeHash)) {
                outlineHeadingId = `xhash_${activeHash}`;
            }

            // Try to find lowest topicHeading element, that is above scrollPos
            if (!outlineHeadingId) {
                // Get current scroll position of main topic article viewport
                const scrollPos = window.pageYOffset + TOM_HIGHLIGHT_THRESHOLD_PX;

                for (let i = this.topicHeadings.length - 1; i >= 0; i--) {
                    if (this.topicHeadings[i].offsetTop <= scrollPos
                        && this.outlineMap[this.topicHeadings[i].id]) {
                        outlineHeadingId = this.topicHeadings[i].id;
                        this[SET_NEAREST_OUTLINE_HEADING_ID](outlineHeadingId);
                        break;
                    }
                }
            }

            clearTimeout(this.scrollTimer);

            if (this.outlineMap[outlineHeadingId]) {
                this.highlightElement(this.outlineMap[outlineHeadingId], this.firstRun);
            }
            else {
                // Added timeout to avoid scroll blink if highlightElement change too fast.
                this.scrollTimer = setTimeout(() => {
                    this.scrollTimer = null;
                    this.checkAndFixOutlineScrollTop();
                }, TOM_SCROLL_TIMEOUT);
            }
        },
        highlightElement(element, isClick) {
            // Due to time-delayed scroll to compensate for header,
            // we need to briefly lock the highlighted element after a click or it may "jump"
            // when sections are closely packed together
            if (!isClick && ((Date.now() - this.lastClickTime) < TOM_HIGHLIGHT_CLICK_LOCK_TIME_MS)) {
                return;
            }
            if (this.previousElement) {
                elRemoveClass(this.previousElement, TOM_HIGHLIGHT_CLASS);
            }
            elAddClass(element, TOM_HIGHLIGHT_CLASS);
            this.previousElement = element;
            this.lastClickTime = isClick ? Date.now() : this.lastClickTime;
            this.scrollElIntoView(element, isClick);
        },
        scrollElIntoView(el, isClick) {
            try {
                this.outlineEl = this.outlineEl || document.querySelector(TOM_OUTLINE_SELECTOR);
                const outlineBox = this.outlineEl.getBoundingClientRect();
                const elBox = el.getBoundingClientRect();

                if (!isClick && this.checkAndFixOutlineScrollTop()) {
                    return;
                }

                // Otherwise, keep the highlighted element within the visible scroll region.
                const topComparison = outlineBox.top + TOM_SCROLL_POS_OFFSET_PX - elBox.top;
                const needsScrollUp = topComparison > 0;
                if (needsScrollUp) {
                    this.outlineEl.scrollTop -= topComparison;
                    return;
                }

                const bottomComparison = outlineBox.bottom - TOM_SCROLL_POS_OFFSET_PX - elBox.bottom;
                const needsScrollDown = bottomComparison < 0;
                if (needsScrollDown) {
                    this.outlineEl.scrollTop -= bottomComparison;
                }
            }
            catch (ex) {
                // IE11 is throwing an "Unspecified error" on line 278 when the Find-in-Topic
                // dialog is active/dismissed, or navigating between FIT results.
                // Simply doing a `el && el.getBoundingClientRect && el.getBoundingClientRect()`
                // does not solve it, as it appears to be something inside of getBoundingClientRect()
                // that is firing the exception. This doesn't happen on Chrome/Firefox/Safari browsers.
                // As the FIT functionality appears to work correctly with this issue,
                // the try/catch prevents the logs from getting flooded, or cascading more errors.
            }
        },
        scrollToHighlightedElement() {
            const highlightedEls = document.getElementsByClassName(TOM_HIGHLIGHT_CLASS);
            if (!(highlightedEls.length && highlightedEls[0].href)) {
                return;
            }
            const scrollEleId = `${ANCHOR_HASH_PREFIX}${getUrlHash(highlightedEls[0].href)}`;
            const element = document.getElementById(scrollEleId);
            safeTimeout(() => {
                this.processScrollRequest(new ScrollTarget({ element }));
            }, 1);
        },
        checkAndFixOutlineScrollTop() {
            // This is just an extra flourish so that the outline autoscrolls
            // all to the top of its scroll area when article is scrolled
            // to the top.
            this.outlineEl = this.outlineEl || document.querySelector(TOM_OUTLINE_SELECTOR);
            if (!this.outlineEl) {
                return false;
            }
            const needsScroll = window.scrollY < TOM_FIX_TOP_OFFSET_PX;
            if (needsScroll && !this.findInTopicIsActive) {
                this.outlineEl.scrollTop = 0;
            }
            return needsScroll;
        },
        setFocusOnTrigger(){
            if (this.graphicTriggerElement){
                this.graphicTriggerElement.focus();
            }
        }
    },
    render: () => null
};

</script>