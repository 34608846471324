<template>
  <div v-if="showShortcuts"
       class="search-personalized-shortcuts">
    <ul role="menu">
      <li v-for="dropdown in dropdowns"
          :id="dropdown.id"
          :key="dropdown.id"
          role="menuitem">
        <a v-if="isNotDesktopView"
           :href="`/myuptodatemobile?myUtdTab=${dropdown.tab}`"
           @click="showPersonalizationModal($event, dropdown.tab)">
          {{ $t(dropdown.label) }}
        </a>
        <utd-dropdown-panel v-if="isDesktopView"
                            modal-classes="personalized-shortcuts-panel my-uptodate-wrapper"
                            :anchor-text="$t(dropdown.label)"
                            :panel-id="dropdown.tab"
                            :is-open="dropdown.tab === openPanel"
                            :allow-escape="allowEscape"
                            @panel-opened="handlePanelOpened"
                            @panel-closed="handlePanelClosed">
          <component :is="dropdown.component" />
        </utd-dropdown-panel>
      </li>
    </ul>
  </div>
</template>

<script>
import { C_EVENTS, C_MYUTD_TABS } from '_acaSrc/utility/constants';
import { mapGetters, mapActions } from 'vuex';
import UtdDropdownPanel from '_acaSrc/components/shared/stdlib/UtdDropdownPanel.vue';
import MyUtdHistory from '_acaSrc/components/myuptodate/MyUtdHistory.vue';
import MyUtdMostViewed from '_acaSrc/components/myuptodate/MyUtdMostViewed.vue';
import MyUtdBookmarks from '_acaSrc/components/myuptodate/MyUtdBookmarks.vue';

export default {
    name: 'PersonalizedShortcuts',
    components: {
        UtdDropdownPanel,
        MyUtdHistory,
        MyUtdMostViewed,
        MyUtdBookmarks
    },
    data() {
        return {
            myUtdTabs: C_MYUTD_TABS,
            dropdowns: [
                {
                    id: 'homeSearchPanel_history',
                    tab: C_MYUTD_TABS.HISTORY,
                    label: 'MYUPTODATE.HISTORY',
                    component: 'my-utd-history'
                },
                {
                    id: 'homeSearchPanel_mostViewed',
                    tab: C_MYUTD_TABS.MOST_VIEWED,
                    label: 'MYUPTODATE.MOST_VIEWED',
                    component: 'my-utd-most-viewed'
                },
                {
                    id: 'homeSearchPanel_bookmarks',
                    tab: C_MYUTD_TABS.BOOKMARKS,
                    label: 'MYUPTODATE.BOOKMARKS',
                    component: 'my-utd-bookmarks'
                }
            ],
            openPanel: null,
            allowEscape: true
        };
    },
    computed:{
        ...mapGetters('user', [ 'userLoggedIn' ]),
        ...mapGetters('profile', [ 'permissions' ]),
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('device', [ 'isNotDesktopView', 'isDesktopView' ]),
        ...mapGetters('graphic', [ 'isGfxHoverTooltipShown' ]),
        showShortcuts() {
            return this.userLoggedIn
              && this.permissions.myUpToDate
              && !this.isUccState;
        }
    },
    watch: {
        showShortcuts(data) {
            this.$emit('show-shortcuts', data);
        },
        isGfxHoverTooltipShown(newVal) {
            this.allowEscape = !newVal;
        }
    },
    mounted() {
        this.$emit('show-shortcuts', this.showShortcuts);
    },
    methods: {
        ...mapActions('profile', [
            'refreshHistory',
            'refreshMostViewed',
            'refreshBookmarks' ]),
        ...mapActions('app', [ 'publish' ]),
        showPersonalizationModal(event, tab) {
            this.publish(C_EVENTS.TRACK_CLICK_EVENT, { event });
            this.publish({
                eventName: 'wkutd.open-myuptodate-modal',
                eventData: { event, tab }
            });
        },
        handlePanelOpened(payload) {
            const { id } = payload;
            this.openPanel = id;
            switch (id) {
            case C_MYUTD_TABS.HISTORY:
                this.refreshHistory();
                break;
            case C_MYUTD_TABS.MOST_VIEWED:
                this.refreshMostViewed();
                break;
            case C_MYUTD_TABS.BOOKMARKS:
                this.refreshBookmarks();
                break;
            }
        },
        handlePanelClosed(payload) {
            const { id } = payload;
            if (id === this.openPanel) {
                this.openPanel = null;
            }
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utdWkHomePage2024 .search-personalized-shortcuts {
  padding-right: 90px;
  width: 339px;
  margin-left: auto;

  a {
    font-size: 14px;
    line-height: 24px;
    color: @WKCE-WHITE;
  }

  > ul {
    .ds1-utd-list-style-none();
    .ds1-pa-0();
    .ds1-ma-0();
    .ds1-utd-weight-500();
    justify-content: flex-end;
    display: flex;
    font-size: 14px;
    line-height: 24px;

    li {
      position: relative;
      padding: 0 10px;

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background-color: @WKCE-GRAY-TINT2;
      }

      &:last-child{
        .ds1-pr-0();
      }
    }
  }

  .isSmallScreen&,
  .isMobileOnDesktop&,
  .mobileonDesktop&,
  .isTablet&,
  .isMobile& {
    .ds1-pr-0();
    .ds1-pv-1();
    width: 100%;
    margin-top: 28px;

    > ul {
      width: 100%;
      justify-content: center;
    }
  }

  :deep(.personalized-shortcuts-panel .utd-dropdown-panel-content) {
    height: clamp(280px, calc(90vh - 300px), 512px);
  }
}

 @media only screen and (min-width: 768px) {
  .utdWkHomePage2024 .search-widget {
    ul li:not(:first-child) {
      .ds1-ph-2();
    }

    ul li:first-child {
      .ds1-pr-2();
    }

    ul li:last-child {
      .ds1-pr-0();
    }
  }
}
</style>