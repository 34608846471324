<template>
  <article class="content-error-view ">
    <div v-show="isUnspecifiedError" id="error500">
      <h1>Unknown Error</h1>
      <img src="/utd-menu/unknown-error-icon.svg" alt="Unknow error logo">
      <div class="content-error-title">Technical Support:</div>
      <div class="content-error-information">
        <dl id="phones">
          <dt>Toll-free Phone number:</dt>
          <dd>+1-800-998-6374</dd>
          <dt>International Phone Number:</dt>
          <dd>+1-781-392-2000</dd>
        </dl>
        <dl id="mail_mobile">
          <dt>Email:</dt>
          <dd> <a href="mailto:support@uptodate.com">support@uptodate.com</a><br>  </dd>
        </dl>
        <dl id="atention">
          <dt>Support Center Hours:</dt>
          <dd>Monday - Friday, 7 AM - 9 PM ET (-5 GMT)</dd>
          <dt>Current Support Center Time & Date:</dt>
          <dd>Monday, September 12, 2023 11:36 AM ET (-5 GMT)</dd>
        </dl>
        <dl id="mail_desktop">
          <dt>Email:</dt>
          <dd> <a href="mailto:support@uptodate.com">support@uptodate.com</a><br>  </dd>
        </dl>
      </div>
    </div>
    <div v-show="!isUnspecifiedError">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="translatedErrorMessage" />
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('error', [
            'errorMessage',
            'errorParams',
            'isUnspecifiedError'
        ]),
        translatedErrorMessage() {
            return this.$t(`${this.errorMessage}`, this.errorParams);
        }
    }
};
</script>
<style lang="less" scoped>
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.content-error-view {
  padding: 40px 62px;
  text-align: center;

  h1 {
    align-items: center;
    font-size: 28px;
    line-height: 36px;
    font-weight: 400;
    margin: 0;
  }

  img {
    .ds1-mv-5();
    width: 112px;
    height: 112px;
  }

  #error500 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.content-error-information {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.content-error-information,
.content-error-title {
  width: 100%;
  text-align: left;
  max-width: 684px;
}

.content-error-title {
  .ds1-pb-3();
  .ds1-mb-3();
  .ds1-utd-weight-600();
  border-bottom: 1px solid @UTD-LEGACY-WHITE-TINT5;
  .ds1-mb-2();
}

.content-error-information dl {
  margin-block-start: 0;
  margin-block-end: 0;
}

.content-error-title,
dl dd,
dl dt {
  color: @WKCE-GRAY-SHADE2;
  font-size: 14px;
  line-height: 18px;
}

dl dt {
  .ds1-utd-weight-300();
}

dl dd {
  .ds1-utd-weight-500();
  .ds1-mb-2();
}

dl dd a {
  color: @WKCE-BLUE-SHADE1;
}

#mail_desktop {
  display: none;
}

.largeScreenStyles {
  .content-error-view {
    padding: 0;
    margin-top: 56px;

    h1 {
      font-size: 60px;
    }

    img {
      width: 224px;
      height: 224px;
      margin: 64px 0;
    }
  }

  .content-error-title {
    .ds1-mb-3();
    font-size: 16px;
    line-height: 22px;
  }

  .content-error-information {
    flex-direction: row;
  }

  .content-error-information dl {
    .ds1-ph-3();
    margin-block-start: 0;
    margin-block-end: 0;
    width: 40.2%;
  }

  .content-error-information dl:first-child {
    .ds1-pl-0();
    width: 31.9%;
  }

  .content-error-information dl:last-child {
    .ds1-pr-0();
    width: 27.5%;
  }

  .content-error-information dl:not(:last-child) {
    border-right: 1px solid @UTD-LEGACY-WHITE-TINT5;
  }

  #mail_mobile {
    display: none;
  }

  #mail_desktop {
    display: block;

    dl {
      float: right;
    }
  }

  dl dd {
    .ds1-utd-weight-600();
  }

  #phones dd:not(:last-child) {
    margin-bottom: 36px;
  }
}

@media only screen and (min-width: 1140px) and (orientation: landscape) {
  .largeScreenStyles();
}
</style>